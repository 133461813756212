"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteSource = deleteSource;
exports.deleteSourceCate = deleteSourceCate;
exports.getSourceCateList = getSourceCateList;
exports.getSourceList = getSourceList;
exports.moveCate = moveCate;
exports.updateSource = updateSource;
exports.updateSourceCate = updateSourceCate;
var _request = _interopRequireDefault(require("@/utils/request"));
function getSourceCateList(data) {
  return (0, _request.default)({
    url: '/system/sourceCate/getList',
    method: 'get',
    params: data
  });
}
function updateSourceCate(data) {
  return (0, _request.default)({
    url: 'system/sourceCate/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteSourceCate(data) {
  return (0, _request.default)({
    url: 'system/sourceCate/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getSourceList(data) {
  return (0, _request.default)({
    url: '/system/source/getList',
    method: 'get',
    params: data
  });
}
function updateSource(data) {
  return (0, _request.default)({
    url: 'system/source/editSubmit',
    method: 'post',
    data: data
  });
}
function moveCate(data) {
  return (0, _request.default)({
    url: 'system/source/moveCate',
    method: 'post',
    data: data
  });
}
function deleteSource(data) {
  return (0, _request.default)({
    url: 'system/source/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}