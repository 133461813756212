var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "10px" }, attrs: { shadow: "hover" } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "京东万象", name: "jdwx" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "淘发客", name: "taofake" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-alert",
        {
          staticClass: "warn-content",
          attrs: {
            title: "温馨提示",
            type: "success",
            "show-icon": "",
            closable: false,
          },
        },
        [
          _vm._v(
            "\n             本接口使用" +
              _vm._s(_vm.configName) +
              ", 详情请查阅"
          ),
          _c("a", { attrs: { href: _vm.docUrl, target: "_blank" } }, [
            _vm._v("《相关文档》"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticStyle: { width: "60%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.params,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "appId", prop: "appId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "appId", $$v)
                  },
                  expression: "params.appId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeName == "taofake"
            ? _c(
                "el-form-item",
                { attrs: { label: "密钥", prop: "secret" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.params.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "secret", $$v)
                      },
                      expression: "params.secret",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "请求链接", prop: "apiUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.params.apiUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "apiUrl", $$v)
                  },
                  expression: "params.apiUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateConfig } },
                [_vm._v("更新配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }