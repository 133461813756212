"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminGetWithdrawList = adminGetWithdrawList;
exports.applyWithdraw = applyWithdraw;
exports.countWithdraw = countWithdraw;
exports.countWithdrawcount = countWithdrawcount;
exports.getAccountLog = getAccountLog;
exports.getAccountTypeSelector = getAccountTypeSelector;
exports.getLastWithdraw = getLastWithdraw;
exports.getMissionLog = getMissionLog;
exports.getPointLogList = getPointLogList;
exports.getPublishPointList = getPublishPointList;
exports.getRechargeLog = getRechargeLog;
exports.getSpmissionLogList = getSpmissionLogList;
exports.getSupplierMissionLog = getSupplierMissionLog;
exports.getWithdrawList = getWithdrawList;
exports.setDoingWithdraw = setDoingWithdraw;
exports.suppliercount = suppliercount;
exports.verifyRecharge = verifyRecharge;
exports.verifyWithdraw = verifyWithdraw;
var _request = _interopRequireDefault(require("@/utils/request"));
function getAccountLog(data) {
  return (0, _request.default)({
    url: '/system/account/getList',
    method: 'get',
    params: data
  });
}
function getRechargeLog(data) {
  return (0, _request.default)({
    url: '/system/recharge/getList',
    method: 'get',
    params: data
  });
}
function verifyRecharge(data) {
  return (0, _request.default)({
    url: 'system/recharge/verifyEditSubmit',
    method: 'post',
    data: data
  });
}
function getWithdrawList(data) {
  return (0, _request.default)({
    url: '/system/withdraw/getList',
    method: 'get',
    params: data
  });
}
function adminGetWithdrawList(data) {
  return (0, _request.default)({
    url: '/system/admin/withdraw/getList',
    method: 'get',
    params: data
  });
}
function countWithdraw(data) {
  return (0, _request.default)({
    url: '/system/withdraw/count',
    method: 'get',
    params: data
  });
}
function countWithdrawcount(data) {
  return (0, _request.default)({
    url: '/system/supplier/withdraw/count',
    method: 'get',
    params: data
  });
}
function verifyWithdraw(data) {
  return (0, _request.default)({
    url: 'system/withdraw/verifyEditSubmit',
    method: 'post',
    data: data
  });
}
function setDoingWithdraw(data) {
  return (0, _request.default)({
    url: 'system/withdraw/setDoing',
    method: 'post',
    data: data
  });
}
function getLastWithdraw(data) {
  return (0, _request.default)({
    url: '/system/withdraw/getLast',
    method: 'get',
    params: data
  });
}
function applyWithdraw(data) {
  return (0, _request.default)({
    url: 'system/withdraw/apply',
    method: 'post',
    data: data
  });
}
function getPublishPointList(data) {
  return (0, _request.default)({
    url: '/system/publishPoint/getList',
    method: 'get',
    params: data
  });
}
function getSpmissionLogList(data) {
  return (0, _request.default)({
    url: '/system/spmissionLog/getList',
    method: 'get',
    params: data
  });
}
function getAccountTypeSelector(data) {
  return (0, _request.default)({
    url: '/system/accountType/getSelector',
    method: 'get',
    params: data
  });
}
function getPointLogList(data) {
  return (0, _request.default)({
    url: '/system/point/getList',
    method: 'get',
    params: data
  });
}
function getMissionLog(data) {
  return (0, _request.default)({
    url: 'system/mission/getList',
    method: 'get',
    params: data
  });
}
function getSupplierMissionLog(data) {
  return (0, _request.default)({
    url: 'system/supplier/mission/getList',
    method: 'get',
    params: data
  });
}
function suppliercount(data) {
  return (0, _request.default)({
    url: '/system/supplier/count',
    method: 'get',
    params: data
  });
}