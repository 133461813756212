var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.header, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "todayOrderNum", label: "今日订单数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c("span", { style: { color: "red" } }, [
                                  _vm._v(_vm._s(column.label)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "todayOrderSkuNum",
                          label: "今日商品销售数量",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c("span", { style: { color: "red" } }, [
                                  _vm._v(_vm._s(column.label)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "todayOrderSkuCost",
                          label: "今日商品销售金额",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c("span", { style: { color: "red" } }, [
                                  _vm._v(_vm._s(column.label)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "currentExpressFee",
                          label: "本月物流费",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c("span", { style: { color: "green" } }, [
                                  _vm._v(_vm._s(column.label)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "currentExpressProcessFee",
                          label: "本月操作费",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c("span", { style: { color: "green" } }, [
                                  _vm._v(_vm._s(column.label)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "currentOrderNum",
                          label: "本月订单数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "currentOrderSkuNum",
                          label: "本月商品销售数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "currentOrderSkuCost",
                          label: "本月商品销售金额",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.list, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "ID", width: "100" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "仓库名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.warehouseName))]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "todayUnprocessed",
                          label: "本日未处理订单",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#", title: "点击查看详情" },
                                  },
                                  [_vm._v(_vm._s(row.todayOrderNum))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "yesterdayUnprocessed",
                          label: "昨日未处理订单",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#ffd666" },
                                    attrs: { href: "#", title: "点击查看详情" },
                                  },
                                  [_vm._v(_vm._s(row.yesterdayOrderNum))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "overThreeDays",
                          label: "三天或以上未处理订单",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { href: "#", title: "点击查看详情" },
                                  },
                                  [_vm._v(_vm._s(row.threeOrderNum))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "totalUnprocessed",
                          label: "昨日出库订单",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#", title: "点击查看详情" },
                                  },
                                  [_vm._v(_vm._s(row.yesterdayDelOrderNum))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }