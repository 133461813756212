"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _bind = require("@/api/product/bind");
var _ship = require("@/api/ship/ship");
var _warehouse = require("@/api/warehouse/warehouse");
var _product = require("@/api/product/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ShipList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        shopId: "",
        shopMainId: "",
        startTime: "",
        endTime: "",
        orderNo: "",
        skuTitle: "",
        warehouseIds: "",
        shipNo: "",
        settlementFlag: "",
        page: 1,
        pageSize: 20
      },
      envNameOptions: [],
      getShopList_loading: false,
      warehouseOptions: [],
      getShop_list: [],
      time: [],
      settlementFlag_data: [{
        value: 0,
        label: "未结算"
      }, {
        value: 1,
        label: "已结算"
      }, {
        value: 2,
        label: "取消结算"
      }],
      warehouseIds: [],
      detailDialogVisible: false,
      detailsskuList: [],
      getsupplyIds_loading: false,
      total_amount: 0,
      total_commission: 0,
      total_lumpsum: 0,
      supplyOptions: [],
      multipleSelection: []
    };
  },
  methods: {
    loadSupplyOptions: function loadSupplyOptions() {
      var _this = this;
      // 调用接口获取供应商选项并更新supplyOptions
      (0, _bind.getSupplyList)().then(function (response) {
        _this.supplyOptions = response.data;
        console.log("this.supplyOptions", _this.supplyOptions);
      });
    },
    rowKey: function rowKey(row) {
      return row.id;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 导出
    handleTimePickerConfirm: function handleTimePickerConfirm() {
      // if (!this.listQuery.startTime || !this.listQuery.endTime) {
      //   this.$message.warning("请选择开始时间和结束时间");
      //   return;
      // }
      console.info(this.multipleSelection);
      var selectedOrders = this.multipleSelection;
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderId;
      }); // 提取选中订单的ID数组
      localStorage.setItem("selectedOrderIds", JSON.stringify(selectedOrders));
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      if (this.warehouseIds.length > 0) {
        this.listQuery.warehouseIds = this.warehouseIds.join(",");
      } else {
        this.listQuery.warehouseIds = "";
      }
      var myObj = {
        method: "get",
        url: "ship/exportShipOrder?shopId=" + this.listQuery.shopId + "&shopMainId=" + this.listQuery.shopMainId + "&startTime=" + this.listQuery.startTime + "&endTime=" + this.listQuery.endTime + "&orderNo=" + orderIdsString + "&skuTitle=" + this.listQuery.skuTitle + "&warehouseIds=" + this.listQuery.warehouseIds + "&shipNo=" + this.listQuery.shipNo + "&settlementFlag=" + this.listQuery.settlementFlag,
        fileName: "shipOrder"
      };
      (0, _utils.exportMethod)(myObj);
    },
    // 查看sku明细
    add_SKU: function add_SKU(val) {
      this.detailsskuList = val.skuList;
      this.detailDialogVisible = true;
      console.log("val", val, this.detailsskuList);
    },
    // 点击时间
    add_effective_time: function add_effective_time(e) {
      if (e) {
        this.listQuery.startTime = e[0];
        this.listQuery.endTime = e[1];
      } else {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      }
      console.log("点击有效期", e, this.listQuery);
    },
    // 搜索供应商
    add_supplyIds: function add_supplyIds(val) {
      var _this2 = this;
      if (val != "") {
        this.getShopList_loading = true;
        (0, _product.getShopList)(val).then(function (resp) {
          _this2.envNameOptions = resp.data.list;
          _this2.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }
    },
    // 搜索店铺id
    add_getShopList: function add_getShopList(val) {
      var _this3 = this;
      if (val != "") {
        this.getShopList_loading = true;
        (0, _product.getShopList)(val).then(function (resp) {
          _this3.envNameOptions = resp.data.list;
          _this3.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }

      // console.log("输入了什么", val);
    },
    // 删除
    // deleteDetail(val) {
    //   // return;
    //   this.$confirm("您确定要删除该数据吗?", "提醒", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   })
    //     .then(async () => {
    //       uscitydeleteById(val.id)
    //         .then(() => {
    //           this.getList();
    //           this.$message({
    //             message: "删除成功！",
    //             type: "success"
    //           });
    //         })
    //         .catch(error => {
    //           this.$message.error("删除失败");
    //         });
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // },
    // 小店下拉框数据
    getShopList_url: function getShopList_url() {
      var _this4 = this;
      (0, _ship.shopgetShopList)().then(function (res) {
        _this4.getShop_list = res.data;
        console.log("小店数据", res);
      });
    },
    //仓库列表
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this5 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.warehouseGetList)().then(function (response) {
        if (response.code === 200) {
          _this5.warehouseOptions = response.data;
        } else {
          Message.error(response.message);
        }
      });
    },
    getList: function getList() {
      var _this6 = this;
      this.listLoading = true;
      this.total_amount = 0;
      this.total_commission = 0;
      this.total_lumpsum = 0;
      console.log("数据", this.listQuery);
      if (this.warehouseIds.length > 0) {
        this.listQuery.warehouseIds = this.warehouseIds.join(",");
      } else {
        this.listQuery.warehouseIds = "";
      }
      (0, _ship.getShipOrderPage)(this.listQuery).then(function (response) {
        _this6.list = response.data.list;
        _this6.total = response.data.total;
        _this6.list.forEach(function (val) {
          _this6.total_amount = _this6.total_amount + Number(val.orderAmount * 100);
          val.skuList.forEach(function (item) {
            if (item.mission) {
              console.log("mission", Number(item.mission));
              _this6.total_commission = _this6.total_commission + Number(item.mission * 100);
            }
            if (item.supplierAmount) {
              _this6.total_lumpsum = _this6.total_lumpsum + Number(item.supplierAmount * 100);
            }
          });
        });
        _this6.listLoading = false;
      }).catch(function (error) {
        _this6.listLoading = false;
      });
    }
  },
  created: function created() {
    this.getShopList_url();
    this.loadWarehouseOptions();
    this.getList();
    this.loadSupplyOptions();
  }
};
exports.default = _default;