var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-divider", [_c("h3", [_vm._v("基本信息")])]),
      _vm._v(" "),
      _c("div", { staticClass: "contain" }, [
        _c("div", { staticClass: "bg-color-w b-color-4 wrap-info-table" }, [
          _c("div", { staticClass: "flex f-a-c h-50 f-j-c bg-color-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            头像\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _c("el-avatar", {
                    attrs: { shape: "square", src: _vm.memberDetail.avatar },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户ID\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.memberDetail.id) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户名\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            username: _vm.memberDetail.username,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "username", $$v)
                      },
                      expression: "memberDetail.username",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            手机号码\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            phone: _vm.memberDetail.phone,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "phone", $$v)
                      },
                      expression: "memberDetail.phone",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            姓名\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            realname: _vm.memberDetail.realname,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.realname,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "realname", $$v)
                      },
                      expression: "memberDetail.realname",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户状态\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("memberStatusFilter")(_vm.memberDetail.status)
                    )
                ),
                _vm.memberDetail.freezeReason
                  ? _c("span", [
                      _vm._v("/" + _vm._s(_vm.memberDetail.freezeReason)),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            环境名称\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            envName: _vm.memberDetail.envName,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.envName,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "envName", $$v)
                      },
                      expression: "memberDetail.envName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            是否同步\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.updateShopAccount(
                            {
                              id: _vm.memberDetail.id,
                              isAsync: _vm.memberDetail.isAsync,
                            },
                            "修改成功"
                          )
                        },
                      },
                      model: {
                        value: _vm.memberDetail.isAsync,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberDetail, "isAsync", $$v)
                        },
                        expression: "memberDetail.isAsync",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            注册时间\n          ")]
              ),
              _vm._v(" "),
              _vm.memberDetail.addTime
                ? _c(
                    "div",
                    { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("parseTime")(
                              _vm.memberDetail.addTime,
                              "{y}-{m}-{d} {h}:{i}:{s}"
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            仓库编码\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            warehouseCode: _vm.memberDetail.warehouseCode,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.warehouseCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "warehouseCode", $$v)
                      },
                      expression: "memberDetail.warehouseCode",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            最后登录时间\n          ")]
              ),
              _vm._v(" "),
              _vm.memberDetail.lastLoginTime
                ? _c(
                    "div",
                    { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("parseTime")(
                              _vm.memberDetail.lastLoginTime,
                              "{y}-{m}-{d} {h}:{i}:{s}"
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            最后登录IP\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("span", [_vm._v(_vm._s(_vm.memberDetail.lastLoginIp))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
              },
              [_vm._v("\n          操作\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex flex-1 bg-color-w f-w",
                staticStyle: { padding: "0 0px 10px 10px" },
              },
              [
                _vm.memberDetail.status == "1"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定冻结用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            _vm.dialogFreezeVisible = true
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("冻结用户\n            ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.status == "-1" ||
                _vm.memberDetail.status == "-2"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定解冻用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateShopAccount(
                              { id: _vm.memberDetail.id, status: "1" },
                              "解冻成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("解冻用户\n            ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.status != "-2"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定注销用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateShopAccount(
                              { id: _vm.memberDetail.id, status: "-2" },
                              "注销成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("注销用户\n            ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "10px 10px 0 0" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.handleAccount },
                  },
                  [_vm._v("充值")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            备注\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c " },
                [
                  _c("el-input", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "mini", type: "textarea", placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateShopAccount(
                          {
                            id: _vm.memberDetail.id,
                            remark: _vm.memberDetail.remark,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "remark", $$v)
                      },
                      expression: "memberDetail.remark",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            店铺余额\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.shopAccount) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c("div", {
                staticClass:
                  "flex f-s-0 f-a-c f-j-c w-120 padding-10  border-r",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "初始密码为123456",
                        placement: "top-start",
                      },
                    },
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            "confirm-button-text": "确定",
                            "cancel-button-text": "不用了",
                            icon: "el-icon-info",
                            "icon-color": "red",
                            title: "确定将用户重置初始密码, 初始密码为123456？",
                          },
                          on: { confirm: _vm.initPassword },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                size: "small",
                                type: "text",
                              },
                              slot: "reference",
                            },
                            [_vm._v("初始化登录密码")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "冻结用户",
            visible: _vm.dialogFreezeVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFreezeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "freezeForm",
              attrs: {
                rules: _vm.freezeRules,
                model: _vm.freezeForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "冻结理由", prop: "freezeReason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.freezeForm.freezeReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.freezeForm, "freezeReason", $$v)
                      },
                      expression: "freezeForm.freezeReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFreezeVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.updateShopAccount(
                        {
                          id: _vm.memberDetail.id,
                          status: "-1",
                          freezeReason: _vm.freezeForm.freezeReason,
                        },
                        "冻结成功"
                      )
                      _vm.dialogFreezeVisible = false
                    },
                  },
                },
                [_vm._v("\n        确认冻结\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("所属部门和角色")])]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.handleCreateRole },
                },
                [_vm._v("\n        添加所属部门和角色\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.memberRoleListLoading,
              expression: "memberRoleListLoading",
            },
          ],
          key: _vm.memberRoleTableKey,
          ref: "memberRoleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.memberRoleList,
            fit: "",
            border: "",
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "所属部门", prop: "deptName", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.deptName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拥有角色", align: "center", prop: "roleName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.roleName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteMemberRole(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.dialogTransferVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTransferVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "transferForm",
              attrs: {
                rules: _vm.transferRules,
                model: _vm.transferForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "可用余额", prop: "" } }, [
                _c("span", { staticClass: "tips" }, [
                  _vm._v("$" + _vm._s(_vm.mainAccount)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转账金额", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入转账金额" },
                    model: {
                      value: _vm.transferForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferForm, "amount", $$v)
                      },
                      expression: "transferForm.amount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogTransferVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmTransfer },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加角色", visible: _vm.dialogMemberRoleVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMemberRoleVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "span",
                {
                  staticClass: "mytree flex",
                  staticStyle: {
                    width: "65%",
                    "border-right": "1px solid #f4f4f4",
                    "margin-right": "5%",
                  },
                },
                [
                  _c("el-tree", {
                    ref: "myTree",
                    attrs: {
                      data: _vm.deptList,
                      "node-key": "id",
                      "expand-on-click-node": false,
                      "default-expand-all": true,
                      indent: 16,
                      props: _vm.deptProps,
                      "highlight-current": true,
                    },
                    on: { "node-click": _vm.handleNodeClickDept },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "30%" },
                  attrs: { data: _vm.roleList, "max-height": "800px" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roleName",
                      label: "选择角色",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.confirmMemberRole },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getRoleSelector()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                查看全部\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值",
            visible: _vm.dialogAccountVisible,
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAccountVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "accountForm",
              attrs: {
                model: _vm.accountForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账户资金", prop: "amount" } },
                [
                  _c("code", [
                    _c("span", [
                      _vm._v("账户余额:$" + _vm._s(_vm.shopAccount)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "增减余额", prop: "amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写金额" },
                    model: {
                      value: _vm.accountForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "amount", $$v)
                      },
                      expression: "accountForm.amount",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*增加金额请填写正数,减少金额请填写负数"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea", placeholder: "备注" },
                    model: {
                      value: _vm.accountForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "remark", $$v)
                      },
                      expression: "accountForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogAccountVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.updateMemberAccount },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }