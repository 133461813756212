"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _goods = require("@/api/material/goods");
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/components/WangEditor/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MaterialPage",
  components: {
    WangEnduit: _index.default
  },
  data: function data() {
    return {
      material: {},
      dialogFormVisible: false,
      dialogType: "",
      form: {},
      isClear: false
    };
  },
  created: function created() {
    this.getMaterialInfo();
  },
  methods: {
    changeEditor: function changeEditor(val) {
      this.form.content = val;
    },
    getMaterialInfo: function getMaterialInfo() {
      var _this = this;
      (0, _goods.getMaterial)().then(function (response) {
        if (response.code === 200) {
          _this.material = response.data;
        }
      });
    },
    handleEdit: function handleEdit() {
      this.dialogType = "edit";
      this.dialogFormVisible = true;
      this.form = Object.assign({}, this.material);
    },
    handleConfirm: function handleConfirm() {
      var _this2 = this;
      (0, _goods.updateMaterial)(this.form).then(function () {
        _this2.dialogFormVisible = false;
        _this2.getMaterialInfo();
        _this2.$message.success("保存成功");
      });
    },
    formatTime: function formatTime(time) {
      return (0, _utils.parseTime)(time, "{y}-{m}-{d} {h}:{i}:{s}");
    }
  }
};
exports.default = _default;