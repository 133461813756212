var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "品类名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "5px" },
                        attrs: { placeholder: "请输入品类名称", clearable: "" },
                        model: {
                          value: _vm.searchTypeName,
                          callback: function ($$v) {
                            _vm.searchTypeName = $$v
                          },
                          expression: "searchTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "产品名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "5px" },
                        attrs: { placeholder: "请输入产品名称", clearable: "" },
                        model: {
                          value: _vm.searchTitle,
                          callback: function ($$v) {
                            _vm.searchTitle = $$v
                          },
                          expression: "searchTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "用户名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px", "margin-right": "5px" },
                        attrs: { placeholder: "请输入用户名称", clearable: "" },
                        model: {
                          value: _vm.searchUserName,
                          callback: function ($$v) {
                            _vm.searchUserName = $$v
                          },
                          expression: "searchUserName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "产品状态：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-right": "5px",
                          },
                          attrs: { placeholder: "请选择状态" },
                          model: {
                            value: _vm.searchAuditStatus,
                            callback: function ($$v) {
                              _vm.searchAuditStatus = $$v
                            },
                            expression: "searchAuditStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "待审批", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "审批通过", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "审批拒绝", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n          查询\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.productList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "45",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "title", label: "产品标题", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "typeName", label: "品类名称", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrls",
              label: "封面图",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "50px", width: "50px" },
                      attrs: {
                        src: scope.row.imgUrls[0],
                        "preview-src-list": [scope.row.imgUrls[0]],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "供应商", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.userName) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "auditStatus",
              label: "审核状态",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.auditStatus === 1
                      ? _c("span", [_vm._v("待审核")])
                      : scope.row.auditStatus === 2
                      ? _c("span", [_vm._v("已通过")])
                      : scope.row.auditStatus === 3
                      ? _c("span", [_vm._v("已拒绝")])
                      : scope.row.auditStatus === 0
                      ? _c("span", [_vm._v("未申请")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "auditStatus",
              label: "侵权检查状态",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.infringementCheckStatus === 0
                      ? _c("span", [_vm._v("未检查")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.infringementCheckStatus === 1
                      ? _c("span", [_vm._v("检查中")])
                      : scope.row.infringementCheckStatus === 2
                      ? _c("span", [_vm._v("通过")])
                      : scope.row.infringementCheckStatus === 3
                      ? _c("span", [_vm._v("不通过")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "auditStatus",
              label: "不通过的原因",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.infringementCheckMessage)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "class-name": "small-padding",
              fixed: "right",
              "min-width": "200",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看明细\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.add_infringementCheck(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          侵权检测\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showAuditDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          审核\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核产品", visible: _vm.auditDialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.auditDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "auditForm",
              attrs: { model: _vm.auditFormData, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.auditFormData.auditStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.auditFormData, "auditStatus", $$v)
                        },
                        expression: "auditFormData.auditStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "9" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("拒绝"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核原因" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.auditFormData.auditReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.auditFormData, "auditReason", $$v)
                      },
                      expression: "auditFormData.auditReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.auditDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAudit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑产品" : "新增产品",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "productForm",
              attrs: {
                model: _vm.productForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品标题" },
                    model: {
                      value: _vm.productForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "title", $$v)
                      },
                      expression: "productForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "sellerSku" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名称" },
                    model: {
                      value: _vm.productForm.sellerSku,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "sellerSku", $$v)
                      },
                      expression: "productForm.sellerSku",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品中文名称", prop: "typeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品中文名称" },
                    model: {
                      value: _vm.productForm.typeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "typeName", $$v)
                      },
                      expression: "productForm.typeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "imgUrls" } },
                [
                  _vm.SortableUpload_sw
                    ? _c("dragUpload", {
                        attrs: { imgUrls: _vm.productForm.imgUrls },
                        on: { emit_picture: _vm.emit_picture },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "cateIds",
                    label: "产品类目",
                    rules: [
                      { required: false, message: "请选择", trigger: "change" },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      props: _vm.props,
                      placeholder: "试试搜索",
                      options: _vm.shopCateOptions,
                      filterable: "",
                      disabled: true,
                    },
                    on: { change: _vm.handleCascaderChange },
                    model: {
                      value: _vm.productForm.shopCateId,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "shopCateId", $$v)
                      },
                      expression: "productForm.shopCateId",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/product/cate")
                        },
                      },
                    },
                    [_vm._v("新建分类")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "danger" },
                      on: { click: _vm.getShopCateList },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产地", prop: "place" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产地" },
                    model: {
                      value: _vm.productForm.place,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "place", $$v)
                      },
                      expression: "productForm.place",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "素材链接", prop: "link" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入素材链接" },
                    model: {
                      value: _vm.productForm.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "link", $$v)
                      },
                      expression: "productForm.link",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brand" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入品牌" },
                    model: {
                      value: _vm.productForm.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "brand", $$v)
                      },
                      expression: "productForm.brand",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "认证材料", prop: "material" } },
                [
                  _c("UploadPdf", {
                    model: {
                      value: _vm.productForm.material,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "material", $$v)
                      },
                      expression: "productForm.material",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否反倾销", prop: "isAntiDumping" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.productForm.isAntiDumping,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "isAntiDumping", $$v)
                      },
                      expression: "productForm.isAntiDumping",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否促销", prop: "isSalesPromotion" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.productForm.isSalesPromotion,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "isSalesPromotion", $$v)
                      },
                      expression: "productForm.isSalesPromotion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "HS编码", prop: "hsCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入HS编码" },
                    model: {
                      value: _vm.productForm.hsCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "hsCode", $$v)
                      },
                      expression: "productForm.hsCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用途", prop: "hsCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用途" },
                    model: {
                      value: _vm.productForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "desc", $$v)
                      },
                      expression: "productForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品介绍", prop: "intro" } },
                [
                  _c("WangEnduit", {
                    attrs: { isClear: _vm.isClear },
                    on: {
                      change: _vm.changeEditor,
                      changeClear: function ($event) {
                        return _vm.$emit("changeClear")
                      },
                    },
                    model: {
                      value: _vm.productForm.intro,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "intro", $$v)
                      },
                      expression: "productForm.intro",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.conditionalConfigList, function (field, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: field.fieldName } },
                      [
                        field.fieldType === 1
                          ? [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                model: {
                                  value: _vm.conditionalConfigList[index].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.conditionalConfigList[index],
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "conditionalConfigList[index].value",
                                },
                              }),
                            ]
                          : field.fieldType === 2
                          ? [
                              _c("Upload", {
                                model: {
                                  value: _vm.conditionalConfigList[index].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.conditionalConfigList[index],
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "conditionalConfigList[index].value",
                                },
                              }),
                            ]
                          : field.fieldType === 3
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value:
                                      _vm.conditionalConfigList[index].value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.conditionalConfigList[index],
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "conditionalConfigList[index].value",
                                  },
                                },
                                _vm._l(
                                  field.options.split(","),
                                  function (option) {
                                    return _c("el-option", {
                                      key: option,
                                      attrs: { label: option, value: option },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "SKU列表" } },
                [
                  _vm.Skulist_sw
                    ? _c("seeSkulist", {
                        attrs: { variations: _vm.productForm.variations },
                        model: {
                          value: _vm.productForm.skuList,
                          callback: function ($$v) {
                            _vm.$set(_vm.productForm, "skuList", $$v)
                          },
                          expression: "productForm.skuList",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Cropper", {
        ref: "cropper1",
        attrs: {
          show_cropper: _vm.show_cropper,
          id: "avater_id_2",
          img_url: _vm.img_url,
        },
        on: {
          InitCropper: _vm.InitCropper,
          CloseCropper: _vm.CloseCropper,
          SaveCallBack: _vm.SaveCallBack,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }