"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
var _default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    pieData: {
      type: Object,
      required: true
    }
  },
  watch: {
    pieData: {
      deep: true,
      handler: function handler(val) {
        this.initChart(val);
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart(this.pieData);
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart(pieData) {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['下单男性', '下单女性']
        },
        calculable: true,
        series: [{
          name: '顾客画像下单男女比例',
          type: 'pie',
          roseType: 'radius',
          radius: [15, 95],
          center: ['50%', '48%'],
          data: [{
            value: pieData.manNum,
            name: '下单男性'
          }, {
            value: pieData.womanNum,
            name: '下单女性'
          }],
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};
exports.default = _default;