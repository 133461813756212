var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-view", [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-alert", {
                  attrs: {
                    title: "通过菜单权限标识控制菜单权限",
                    type: "info",
                    "show-icon": "",
                    closable: false,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-left" }, [
            _c(
              "div",
              { staticClass: "my-card" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "margin-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", icon: "el-icon-download" },
                            on: {
                              click: function ($event) {
                                return _vm.expandAll(true)
                              },
                            },
                          },
                          [_vm._v("展开")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", icon: "el-icon-upload2" },
                            on: {
                              click: function ($event) {
                                return _vm.expandAll(false)
                              },
                            },
                          },
                          [_vm._v("折叠")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-tree", {
                  ref: "myTree",
                  attrs: {
                    data: _vm.menuList,
                    "node-key": "id",
                    "expand-on-click-node": false,
                    "default-expand-all": false,
                    indent: 16,
                    props: _vm.defaultProps,
                    "highlight-current": true,
                  },
                  on: { "node-click": _vm.handleNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c("span", [_vm._v(_vm._s(node.label))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function () {
                                      return _vm.append(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    添加\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function () {
                                      return _vm.remove(node, data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    删除\n                  "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-right" },
            [
              _c(
                "el-card",
                { staticClass: "box-card", staticStyle: { height: "100%" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "postForm",
                      staticClass: "form-container",
                      attrs: {
                        model: _vm.menu,
                        rules: _vm.rules,
                        "label-position": "right",
                        "label-width": "160px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级菜单:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: {
                              disabled: "",
                              placeholder: "直接添加顶级菜单",
                            },
                            model: {
                              value: _vm.menu.parentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "parentName", $$v)
                              },
                              expression: "menu.parentName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "menuName", label: "菜单名称:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入菜单名称" },
                            model: {
                              value: _vm.menu.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "menuName", $$v)
                              },
                              expression: "menu.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "menuType", label: "菜单类型:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.menu.menuType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.menu, "menuType", $$v)
                                },
                                expression: "menu.menuType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "C", border: "" } },
                                [_vm._v("菜单")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { attrs: { label: "F", border: "" } },
                                [_vm._v("按钮")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "perms", label: "权限标识:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入菜单权限" },
                            model: {
                              value: _vm.menu.perms,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "perms", $$v)
                              },
                              expression: "menu.perms",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: { prop: "sort", label: "菜单排序:" },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "50%" },
                            attrs: { min: 1, label: "修改排序" },
                            model: {
                              value: _vm.menu.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "sort", $$v)
                              },
                              expression: "menu.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCreate()
                                },
                              },
                            },
                            [_vm._v("添加顶级菜单")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSave()
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }