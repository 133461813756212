"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _params = require("@/api/params");
var _multipleImage = _interopRequireDefault(require("@/components/Upload/multipleImage"));
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _imageSource = _interopRequireDefault(require("@/components/Upload/imageSource"));
var _index = _interopRequireDefault(require("@/components/WangEditor/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Waves directive

// Secondary package based on el-pagination

var languageOptions = [{
  key: "all",
  text: "用户自主选择"
}, {
  key: "folowSystem",
  text: "跟随手机系统"
}, {
  key: "zh_CN",
  text: "简体中文"
}, {
  key: "zh_TW",
  text: "繁体中文"
}, {
  key: "en_US",
  text: "英文"
}];
var _default = {
  name: "configTable",
  components: {
    Pagination: _Pagination.default,
    MultipleImageUpload: _multipleImage.default,
    ImageSource: _imageSource.default,
    WangEnduit: _index.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      config: {},
      dialogFormVisible: false,
      dialogType: "",
      multipleSelection: [],
      dialogImageUrl: "",
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_API + "/system/uploadSyjapk",
      languageOptions: languageOptions,
      configList: [],
      CS: {
        "word-break": "break-all" //过长时自动换行
      },

      LS: {
        "max-width": "50px" //最大宽度
      },

      isClear: false
    };
  },
  created: function created() {
    this.getList();
    this.ConfigGetList();
  },
  methods: {
    // 富文本内容发生变化时触发
    changeEditor: function changeEditor(val) {
      this.configList.forEach(function (item) {
        if (val == "商务合作联系方式") {
          item.content = val;
        }
      });
    },
    ConfigGetList: function ConfigGetList() {
      var _this = this;
      this.listLoading = true;
      (0, _params.configGetList)().then(function (response) {
        _this.configList = response.data;
        _this.configList.forEach(function (val) {
          if (val.desc == "首页中置轮播图" || val.desc == "首页置顶轮播图") {
            val.content = JSON.parse(val.content);
          }
        });
        _this.listLoading = false;
      });
    },
    UpdateByIdList: function UpdateByIdList() {
      this.listLoading = true;
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _params.getConfigList)(this.listQuery).then(function (response) {
        _this2.config = response.data;
        _this2.listLoading = false;
      });
    },
    confirm: function confirm(item) {
      var _this3 = this;
      // let data = JSON.stringify(item);
      // return;
      var obj;
      if (item.desc == "首页中置轮播图" || item.desc == "首页置顶轮播图") {
        obj = {
          content: JSON.stringify(item.content),
          id: item.id
        };
      } else {
        obj = {
          content: item.content,
          id: item.id
        };
      }
      (0, _params.configUpdateById)(obj).then(function (response) {
        _this3.$notify({
          title: "成功",
          message: "参数保存成功",
          type: "success",
          duration: 2000
        });
        _this3.listLoading = false;
      });
    },
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    }
  }
};
exports.default = _default;