"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _member = require("@/api/member");
var _config = require("@/api/config");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
var _imageSource = _interopRequireDefault(require("@/components/Upload/imageSource"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Waves directive

var bankOptions = [{
  key: "ICBC",
  text: "中国工商银行"
}, {
  key: "ABC",
  text: "中国农业银行"
}, {
  key: "BOC",
  text: "中国银行"
}, {
  key: "CCB",
  text: "中国建设银行"
}, {
  key: "CIB",
  text: "兴业银行"
}, {
  key: "SPDB",
  text: "浦东发展银行"
}, {
  key: "BCM",
  text: "交通银行"
}, {
  key: "ECITIC",
  text: "中信银行"
}, {
  key: "CEB",
  text: "中国光大银行"
}, {
  key: "CMB",
  text: "招商银行"
}, {
  key: "GDB",
  text: "广发银行"
}, {
  key: "PSBC",
  text: "中国邮政储蓄银行"
}, {
  key: "PAB",
  text: "平安银行"
}, {
  key: "CMSB",
  text: "民生银行"
}];
var marriageOptions = [{
  key: "1",
  text: "已婚"
}, {
  key: "2",
  text: "未婚"
}, {
  key: "3",
  text: "离异"
}];
var marriageKeyValue = marriageOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var healthTypeOptions = [{
  key: 1,
  text: "卡制"
}, {
  key: 2,
  text: "本制"
}, {
  key: 3,
  text: "纸质"
}, {
  key: 4,
  text: "电子版"
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var healthTypeKeyValue = healthTypeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var _default = {
  name: "editMember",
  directives: {
    waves: _waves.default
  },
  components: {
    Upload: _singleImage.default,
    ImageSource: _imageSource.default
  },
  filters: {
    marriageFilter: function marriageFilter(status) {
      return marriageKeyValue[status];
    },
    healthTypeFilter: function healthTypeFilter(status) {
      return healthTypeKeyValue[status];
    }
  },
  data: function data() {
    return {
      memberDetail: {},
      memberRealname: {},
      memberAccount: {},
      accountForm: {},
      currentActive: "1",
      bankOptions: bankOptions,
      groupOptions: [],
      missionGroupOptions: [],
      secretOptions: []
    };
  },
  created: function created() {
    var id = this.$route.params && this.$route.params.id;
    this.getMember(id);
    this.getMemberGroupSelector();
    this.getConfigMissionSelector();
    this.getSecretSelector();
  },
  methods: {
    getMemberGroupSelector: function getMemberGroupSelector() {
      var _this = this;
      (0, _member.getMemberGroupSelector)().then(function (response) {
        _this.groupOptions = response.data;
      });
    },
    getConfigMissionSelector: function getConfigMissionSelector() {
      var _this2 = this;
      (0, _config.getConfigMissionSelector)().then(function (response) {
        _this2.missionGroupOptions = response.data;
      });
    },
    getSecretSelector: function getSecretSelector() {
      var _this3 = this;
      (0, _config.getSecretSelector)().then(function (response) {
        _this3.secretOptions = response.data;
      });
    },
    getMember: function getMember(id) {
      var _this4 = this;
      (0, _member.getMember)({
        memberId: id
      }).then(function (response) {
        var result = response.data;
        _this4.memberDetail = result.member;
        _this4.memberDetail.password = null;
        _this4.memberDetail.payPassword = null;
        _this4.memberRealname = result.realname;
        _this4.memberAccount = result.memberAccount;
      }).catch(function (err) {
        console.log(err);
      });
    },
    updateMemberInfo: function updateMemberInfo() {
      var _this5 = this;
      this.memberDetail.addTime = null;
      this.memberDetail.updTime = null;
      this.memberDetail.birthday = (0, _utils.renderTime)(this.memberDetail.birthday);
      (0, _member.updateMember)(this.memberDetail).then(function (response) {
        _this5.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000
        });
      });
    },
    updateMemberAccount: function updateMemberAccount() {
      var _this6 = this;
      this.accountForm.memberId = this.memberDetail.id;
      (0, _member.updateMemberAccount)(this.accountForm).then(function (response) {
        _this6.memberAccount = response.data;
        _this6.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000
        });
      });
    }
  }
};
exports.default = _default;