"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBusinessPage = getBusinessPage;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getBusinessPage(data) {
  return (0, _requestJson.default)({
    url: '/business/orderPage',
    method: 'get',
    params: data
  });
}