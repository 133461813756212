"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGoodsOrderPage = getGoodsOrderPage;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getGoodsOrderPage(data) {
  return (0, _requestJson.default)({
    url: '/goods/orderPage',
    method: 'get',
    params: data
  });
}