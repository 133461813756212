import Layout from '@/layout';
var customerRouter = {
  path: '/customer',
  component: Layout,
  redirect: '/customer/list',
  name: 'customer',
  alwaysShow: true,
  meta: {
    title: '客服',
    icon: 'food-list'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/customer/list/index');
    },
    name: 'customerList',
    meta: {
      title: '客服列表',
      role: []
    }
  }]
};
export default customerRouter;