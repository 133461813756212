import Layout from '@/layout';
var courseRouter = {
  path: '/course',
  component: Layout,
  redirect: '/course/type',
  name: 'course',
  alwaysShow: true,
  meta: {
    title: '课程',
    icon: 'table'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/course/type/index');
    },
    name: 'CourseType',
    meta: {
      title: '课程类型',
      role: []
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/course/detail/index');
    },
    name: 'CourseDetail',
    meta: {
      title: '课程详情',
      role: []
    }
  }, {
    path: 'detailInfo/:id(\\d+)',
    component: function component() {
      return import('@/views/course/detail/detailInfo');
    },
    name: 'CourseDetailInfo',
    meta: {
      title: '查看课程详情',
      role: [],
      activeMenu: '/course/detail'
    },
    hidden: true
  }]
};
export default courseRouter;