"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'MultipleImageUpload',
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // fileList: [],
      tempFile: [],
      times: 0,
      tempUrl: '',
      uploadUrl: process.env.VUE_APP_BASE_API + "system/upload",
      dialogImageUrl: '',
      dialogVisible: false,
      dataObj: {
        token: '',
        key: ''
      }
    };
  },
  created: function created() {
    this.initFileList();
  },
  computed: {
    fileList: {
      get: function get() {
        return this.initFileList();
      },
      set: function set() {}
    }
  },
  methods: {
    initFileList: function initFileList() {
      var val = [];
      if (this.value) {
        this.value.forEach(function (item, index) {
          var imgForm = {
            name: index,
            url: item
          };
          val.push(imgForm);
        });
      }
      return val;
    },
    emitInput: function emitInput(val) {
      this.$emit('input', val);
    },
    handleRemove: function handleRemove(file, fileList) {
      var temp = [];
      fileList.forEach(function (item) {
        temp.push(item);
      });
      // this.fileList = temp
      this.emitInput(this.getUrlArray(temp));
    },
    handleImageSuccess: function handleImageSuccess(result, file, fileList) {
      var curTimes = fileList.length;
      var oldTimes = this.fileList.length;
      var temp = this.fileList;
      file.url = result.data.imgUrl;
      temp.push(file);
      if (curTimes == oldTimes + 1) {
        this.emitInput(this.getUrlArray(temp));
      }
    },
    getUrlArray: function getUrlArray(fileList) {
      if (fileList && fileList.length > 0) {
        return fileList.map(function (item) {
          return item.url;
        });
      }
    },
    arraysToString: function arraysToString(fileList) {
      if (fileList && fileList.length > 0) {
        var temp = '';
        fileList.forEach(function (item) {
          temp = temp + "|" + item.url;
        });
        return temp.substring(1);
      }
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default2;