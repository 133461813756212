import Layout from '@/layout';
var materialRouter = {
  path: '/material',
  component: Layout,
  redirect: '/material/goods',
  name: 'material',
  alwaysShow: true,
  meta: {
    title: '素材库',
    icon: 'table'
  },
  children: [{
    path: 'goods',
    component: function component() {
      return import('@/views/material/goods/index');
    },
    name: 'MaterialGoods',
    meta: {
      title: '素材库商品',
      role: []
    }
  }, {
    path: 'lib',
    component: function component() {
      return import('@/views/material/lib/index');
    },
    name: 'MaterialLib',
    meta: {
      title: '素材管理',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/material/order/index');
    },
    name: 'MaterialOrder',
    meta: {
      title: '素材库订单',
      role: []
    }
  }]
};
export default materialRouter;