"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _list = require("@/api/slideshow/list");
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadImage: _singleImage.default
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      tableKey: 0,
      dialogVisible: false,
      form: {
        id: null,
        typeId: null,
        imgUrl: "",
        router: "",
        sort: 0
      },
      typeList: [],
      typeId: null,
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created: function created() {
    this.getSlideshowTypeList();
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        typeId: this.typeId
      };
      (0, _list.getSlideshowPage)(params).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    getSlideshowTypeList: function getSlideshowTypeList() {
      var _this2 = this;
      (0, _list.getSlideshowTypeList)().then(function (response) {
        _this2.typeList = response.data;
      });
    },
    handleCreate: function handleCreate() {
      this.form = {
        id: null,
        typeId: null,
        imgUrl: "",
        router: "",
        sort: 0
      };
      this.dialogVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form = (0, _objectSpread2.default)({}, row);
      this.dialogVisible = true;
    },
    handleSave: function handleSave() {
      var _this3 = this;
      if (this.form.id) {
        (0, _list.updateSlideshow)(this.form).then(function () {
          _this3.dialogVisible = false;
          _this3.getList();
        });
      } else {
        (0, _list.addSlideshow)(this.form).then(function () {
          _this3.dialogVisible = false;
          _this3.getList();
        });
      }
    },
    handleDelete: function handleDelete(id) {
      var _this4 = this;
      (0, _list.deleteSlideshow)(id).then(function () {
        _this4.getList();
        _this4.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      var date = new Date(timestamp);
      return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate(), " ").concat(date.getHours(), ":").concat(date.getMinutes(), ":").concat(date.getSeconds());
    },
    handlePageChange: function handlePageChange(page) {
      this.currentPage = page;
      this.getList();
    },
    handleTypeChange: function handleTypeChange(typeId) {
      this.typeId = typeId;
      this.currentPage = 1;
      this.getList();
    }
  }
};
exports.default = _default;