"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecialArea = addSpecialArea;
exports.deleteSpecialArea = deleteSpecialArea;
exports.getSpecialAreaList = getSpecialAreaList;
exports.getSpecialAreaPage = getSpecialAreaPage;
exports.updateSpecialArea = updateSpecialArea;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialAreaPage(data) {
  return (0, _requestJson.default)({
    url: '/special/area/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialAreaList(data) {
  return (0, _requestJson.default)({
    url: '/special/area/getList',
    method: 'get',
    params: data
  });
}
function addSpecialArea(data) {
  return (0, _requestJson.default)({
    url: '/special/area/save',
    method: 'post',
    data: data
  });
}
function updateSpecialArea(data) {
  return (0, _requestJson.default)({
    url: '/special/area/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecialArea(id) {
  return (0, _requestJson.default)({
    url: "/special/area/deleteById/".concat(id),
    method: 'post'
  });
}