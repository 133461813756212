"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var wswarehouseRouter = {
  path: '/warehouseModule',
  component: _layout.default,
  redirect: '/warehouseModule/order',
  name: 'warehouse',
  alwaysShow: true,
  meta: {
    title: '仓库系统',
    icon: 'report-brand'
  },
  children: [{
    path: 'wsindex',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/wsindex/index'));
      });
    },
    name: 'wsWarehouseList',
    meta: {
      title: '仓库管理',
      role: []
    }
  }, {
    path: 'apiconfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/apiconfig/index'));
      });
    },
    name: 'wsApiConfig',
    meta: {
      title: '物流渠道',
      role: []
    }
  }, {
    path: 'weightZone',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/weightZone/index'));
      });
    },
    name: 'weightZone',
    meta: {
      title: '运费上浮表',
      role: []
    }
  }, {
    path: 'withholdFreight',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/weightZone/withholdFreightindex'));
      });
    },
    name: 'withholdFreight',
    meta: {
      title: '预计运费表',
      role: []
    }
  }, {
    path: 'inventory',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/inventory/index'));
      });
    },
    name: 'WarehouseInventory',
    meta: {
      title: '库存管理',
      role: []
    }
  }, {
    path: 'locator',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/locator/index'));
      });
    },
    name: 'WarehouseLocator',
    meta: {
      title: '库位管理',
      role: []
    }
  }, {
    path: 'deliverynote',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/deliverynote/index'));
      });
    },
    name: 'WarehouseDeliverynote',
    meta: {
      title: '发货单管理',
      role: []
    }
  }, {
    path: 'batchadmin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/batchadmin/index'));
      });
    },
    name: 'WarehouseBatchadmin',
    meta: {
      title: '批次管理',
      role: []
    }
  }, {
    path: 'supplier',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/supplier/index'));
      });
    },
    name: 'warehouseSupplier',
    meta: {
      title: '供应商管理',
      role: []
    }
  }, {
    path: 'wssku',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/wssku/index'));
      });
    },
    name: 'warehouseWssku',
    meta: {
      title: '商品管理',
      role: []
    }
  }, {
    path: 'bindWarehouse',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/bindWarehouse/index'));
      });
    },
    name: 'warehouseBindWarehouse',
    meta: {
      title: '仓库账号',
      role: []
    }
  }, {
    path: 'bulletin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/bulletin/index'));
      });
    },
    name: 'warehouseBulletin',
    meta: {
      title: '公示板',
      role: []
    }
  }, {
    path: 'inventoryLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/inventory/inventoryLogs.vue'));
      });
    },
    name: 'warehouseInventoryLog',
    meta: {
      title: '库存日志',
      role: []
    }
  }]
};
var _default = wswarehouseRouter;
exports.default = _default;