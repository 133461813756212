"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _member = require("@/api/member");
var _config = require("@/api/config");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "configMember",
  data: function data() {
    return {
      activeName: "first",
      withdrawConfig: {},
      blackConfig: {},
      shangbaoConfig: {},
      realnameConfig: {},
      inviteCodeConfig: {},
      loading: true,
      memberGroupOptions: [],
      withdrawMethodArr: [""],
      activeGroup: null
    };
  },
  created: function created() {
    this.getMemberGroupSelector();
  },
  methods: {
    getMemberGroupSelector: function getMemberGroupSelector() {
      var _this = this;
      (0, _member.getMemberGroupSelector)().then(function (response) {
        _this.memberGroupOptions = response.data;
        if (_this.memberGroupOptions && _this.memberGroupOptions.length > 0) {
          _this.activeGroup = _this.memberGroupOptions[0].id + "";
          _this.get(_this.activeGroup);
        }
      });
    },
    get: function get(groupId) {
      var _this2 = this;
      (0, _config.getParams)({
        key: "config_withdraw",
        groupId: groupId
      }).then(function (response) {
        _this2.withdrawConfig = response.data;
        if (_this2.withdrawConfig) {
          _this2.$nextTick(function () {
            _this2.withdrawMethodArr = _this2.withdrawConfig.withdrawMethod.split(",");
          });
        } else {
          _this2.withdrawMethodArr = [];
          _this2.withdrawConfig = {};
        }
      });
      (0, _config.getParams)({
        key: "config_black",
        groupId: groupId
      }).then(function (response) {
        _this2.blackConfig = response.data;
        if (!_this2.blackConfig) {
          _this2.blackConfig = {};
        }
      });
      (0, _config.getParams)({
        key: "config_shangbao",
        groupId: groupId
      }).then(function (response) {
        var params = response.data;
        if (params && params.length > 0) {
          var str = "";
          params.forEach(function (item) {
            var temp = item.amount + "=" + item.takeOverAmount;
            str = str + "\n" + temp;
          });
          _this2.shangbaoConfig = {
            priceParams: str.substring(1)
          };
        } else {
          _this2.shangbaoConfig = {};
        }
      });
      (0, _config.getParams)({
        key: "config_realname",
        groupId: groupId
      }).then(function (response) {
        _this2.realnameConfig = response.data;
        if (!_this2.realnameConfig) {
          _this2.realnameConfig = {};
        }
      });
      (0, _config.getParams)({
        key: "config_invite_code",
        groupId: groupId
      }).then(function (response) {
        _this2.inviteCodeConfig = response.data;
        if (!_this2.inviteCodeConfig) {
          _this2.inviteCodeConfig = {};
        }
      });
    },
    updateConfig: function updateConfig() {
      var _this3 = this;
      if (this.activeName == "first") {
        this.$refs["withdrawForm"].validate(function (valid) {
          if (valid) {
            var formData = Object.assign({}, _this3.withdrawConfig);
            formData.groupId = _this3.activeGroup;
            formData.addTime = null;
            formData.updTime = null;
            (0, _config.updateParams)({
              key: "config_withdraw",
              jsonData: JSON.stringify(formData)
            }).then(function () {
              _this3.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              _this3.get(_this3.activeGroup);
            });
          }
        });
      } else if (this.activeName == "second") {
        this.$refs["blackForm"].validate(function (valid) {
          if (valid) {
            var formData = Object.assign({}, _this3.blackConfig);
            formData.groupId = _this3.activeGroup;
            formData.addTime = null;
            formData.updTime = null;
            (0, _config.updateParams)({
              key: "config_black",
              jsonData: JSON.stringify(formData)
            }).then(function () {
              _this3.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              _this3.get(_this3.activeGroup);
            });
          }
        });
      } else if (this.activeName == "third") {
        this.$refs["shangbaoForm"].validate(function (valid) {
          if (valid) {
            var arr = [];
            _this3.shangbaoConfig.priceParams.split("\n").forEach(function (item) {
              arr.push({
                groupId: _this3.activeGroup,
                amount: item.split("=")[0],
                takeOverAmount: item.split("=")[1]
              });
            });
            (0, _config.updateParams)({
              key: "config_shangbao",
              jsonData: JSON.stringify(arr)
            }).then(function () {
              _this3.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              _this3.get(_this3.activeGroup);
            });
          }
        });
      } else if (this.activeName == "fourth") {
        this.$refs["realnameForm"].validate(function (valid) {
          if (valid) {
            var formData = Object.assign({}, _this3.realnameConfig);
            formData.groupId = _this3.activeGroup;
            formData.addTime = null;
            formData.updTime = null;
            (0, _config.updateParams)({
              key: "config_realname",
              jsonData: JSON.stringify(formData)
            }).then(function () {
              _this3.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              _this3.get(_this3.activeGroup);
            });
          }
        });
      } else if (this.activeName == "five") {
        this.$refs["inviteCodeForm"].validate(function (valid) {
          if (valid) {
            var formData = Object.assign({}, _this3.inviteCodeConfig);
            formData.groupId = _this3.activeGroup;
            formData.addTime = null;
            formData.updTime = null;
            (0, _config.updateParams)({
              key: "config_invite_code",
              jsonData: JSON.stringify(formData)
            }).then(function () {
              _this3.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              _this3.get(_this3.activeGroup);
            });
          }
        });
      }
    },
    handleClick: function handleClick(tab, event) {
      this.activeName = tab.name;
    },
    handleClickGroup: function handleClickGroup() {
      this.get(this.activeGroup);
    }
  }
};
exports.default = _default;