"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var memberRouter = {
  path: '/member',
  component: _layout.default,
  redirect: '/member/list',
  name: 'member',
  alwaysShow: true,
  meta: {
    title: '会员',
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index/index'));
      });
    },
    name: 'memberList',
    meta: {
      title: '会员列表',
      role: ['admin']
    }
  }, {
    path: 'detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index/detailInfo'));
      });
    },
    name: 'memberDetailInfo',
    meta: {
      title: '会员详情',
      role: ['admin'],
      activeMenu: '/member/list'
    },
    hidden: true
  }, {
    path: 'detail/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index/detail'));
      });
    },
    name: 'editMember',
    meta: {
      title: '编辑会员详情',
      noCache: true,
      role: [],
      activeMenu: '/member/list'
    },
    hidden: true
  }, {
    path: 'group',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/group/index'));
      });
    },
    name: 'group',
    meta: {
      title: '会员组别',
      role: ['admin']
    }
  }, {
    path: 'realname',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/realname/index'));
      });
    },
    name: 'realnameVerify',
    meta: {
      title: '实名认证',
      role: ['admin']
    }
  }, {
    path: 'bank',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/bank/index'));
      });
    },
    name: 'bankVerify',
    meta: {
      title: '银行认证',
      role: ['admin']
    }
  }, {
    path: 'punishment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/punishment/index'));
      });
    },
    name: 'memberPunishment',
    meta: {
      title: '用户处罚',
      role: ['admin']
    }
  }, {
    path: 'phonebook',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/phonebook/index/index'));
      });
    },
    name: 'phonebook',
    meta: {
      title: '手机通讯录',
      role: ['admin']
    }
  }, {
    path: 'phonebook/viewLog/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/phonebook/log/index'));
      });
    },
    name: 'viewPhonebookLog',
    meta: {
      title: '查看通讯录',
      noCache: true,
      role: [],
      activeMenu: '/member/phonebook'
    },
    hidden: true
  }, {
    path: 'phonebook/viewMsg/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/phonebook/message/index'));
      });
    },
    name: 'viewPhonebookMsg',
    meta: {
      title: '查看短信',
      noCache: true,
      role: [],
      activeMenu: '/member/phonebook'
    },
    hidden: true
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/log/index'));
      });
    },
    name: 'memberLog',
    meta: {
      title: '会员日志',
      role: ['admin']
    }
  }, {
    path: 'member',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/memberGroup/index'));
      });
    },
    name: 'configMember',
    meta: {
      title: '会员组参数',
      role: ['admin']
    }
  }]
};
var _default = memberRouter;
exports.default = _default;