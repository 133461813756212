"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addShopAccountRole = addShopAccountRole;
exports.addVip = addVip;
exports.addVipMember = addVipMember;
exports.deleteShopAccountRole = deleteShopAccountRole;
exports.deleteVip = deleteVip;
exports.externalAccount = externalAccount;
exports.getDeptList = getDeptList;
exports.getMainAccount = getMainAccount;
exports.getRoleSelector = getRoleSelector;
exports.getShopAccountDetail = getShopAccountDetail;
exports.getShopAccountList = getShopAccountList;
exports.getShopAccountRoleList = getShopAccountRoleList;
exports.getShopAuthUUID = getShopAuthUUID;
exports.getTypeList = getTypeList;
exports.getVipMember = getVipMember;
exports.getVipOrderList = getVipOrderList;
exports.getVipTypeList = getVipTypeList;
exports.register = register;
exports.transferAmount = transferAmount;
exports.updateMemberAccount = updateMemberAccount;
exports.updateShopAccount = updateShopAccount;
exports.updateShopAccountInfo = updateShopAccountInfo;
exports.updateVip = updateVip;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
var _request = _interopRequireDefault(require("@/utils/request"));
function getVipOrderList(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/orderPage',
    method: 'get',
    params: data
  });
}
function getVipMember(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/shopVipPage',
    method: 'get',
    params: data
  });
}
function addVipMember(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/addVip',
    method: 'post',
    data: data
  });
}
function getVipTypeList(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/configPage',
    method: 'get',
    params: data
  });
}
function addVip(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/config/save',
    method: 'post',
    data: data
  });
}
function updateVip(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/config/updateById',
    method: 'post',
    data: data
  });
}
function deleteVip(id) {
  return (0, _requestJson.default)({
    url: "/shop/vip/config/removeById/".concat(id),
    method: 'post'
  });
}
function getTypeList(data) {
  return (0, _requestJson.default)({
    url: '/shop/vip/configGetList',
    method: 'get',
    params: data
  });
}
function getShopAccountList(query) {
  return (0, _requestJson.default)({
    url: '/system/shopAccount/getList',
    method: 'get',
    params: query
  });
}
function updateShopAccount(data) {
  return (0, _requestJson.default)({
    url: '/system/shopAccount/editSubmit',
    method: 'post',
    data: data
  });
}
function register(data) {
  return (0, _requestJson.default)({
    url: '/member/shopAccount/register',
    method: 'post',
    data: data
  });
}
function getDeptList(data) {
  return (0, _requestJson.default)({
    url: '/member/dept/getList',
    method: 'get',
    params: data
  });
}
function externalAccount(data) {
  return (0, _requestJson.default)({
    url: '/member/audit/externalAccount',
    method: 'post',
    data: data
  });
}
function updateShopAccountInfo(data) {
  return (0, _requestJson.default)({
    url: '/member/shopAccount/updateInfo',
    method: 'post',
    data: data
  });
}
function getShopAccountDetail(query) {
  return (0, _requestJson.default)({
    url: '/system/shopAccount/getDetail',
    method: 'get',
    params: query
  });
}
function getShopAccountRoleList(query) {
  return (0, _requestJson.default)({
    url: '/system/shopAccount/getRoleList',
    method: 'get',
    params: query
  });
}
function addShopAccountRole(data) {
  return (0, _requestJson.default)({
    url: '/member/shopAccount/addRole',
    method: 'post',
    data: data
  });
}
function deleteShopAccountRole(data) {
  return (0, _requestJson.default)({
    url: '/member/shopAccount/deleteRole',
    method: 'post',
    data: data
  });
}
function getRoleSelector(data) {
  return (0, _requestJson.default)({
    url: '/member/role/getSelector',
    method: 'get',
    params: data
  });
}
function transferAmount(data) {
  return (0, _requestJson.default)({
    // url: '/member/transferAmount',
    url: '/system/member/updateShopAccount',
    method: 'post',
    data: data
  });
}
function updateMemberAccount(data) {
  return (0, _request.default)({
    url: '/system/member/updateShopAccount',
    method: 'post',
    data: data
  });
}
function getShopAuthUUID() {
  return (0, _requestJson.default)({
    url: '/tk/getShopAuthUUID',
    method: 'get'
  });
}
function getMainAccount(id) {
  return (0, _requestJson.default)({
    url: '/system/member/getAccount?id=' + id,
    method: 'get'
  });
}