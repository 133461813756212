import "core-js/modules/es6.array.sort";
import _objectSpread from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { wsWarehouseGetPage, warehouseDelete, updateWarehouseById, warehouseSaveInfo } from '@/api/warehouse/warehouse';
import { Message } from 'element-ui';
import Pagination from '@/components/Pagination';
export default {
  name: 'WarehouseList',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      form: {
        id: '',
        name: '',
        sort: null,
        type: 6,
        warehouseAddressInfo: {
          recipient: '',
          phone: '',
          country: 'US',
          state: '',
          city: '',
          zipcode: '',
          detailAddress1: '',
          detailAddress2: ''
        }
      },
      typeMap: {
        1: '海外仓',
        2: '虚拟仓',
        3: '自定义',
        4: '开亚仓',
        5: '易境通仓',
        6: 'WS仓'
      },
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        id: '',
        name: '',
        warehouseId: null
      },
      name: '',
      id: null,
      Warehouse_list: [],
      Warehouse_sw: false,
      Warehouse_rules: {
        'warehouseAddressInfo.recipient': [{
          required: true,
          message: '请输入收件人姓名',
          trigger: 'blur'
        }],
        'warehouseAddressInfo.phone': [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        'warehouseAddressInfo.city': [{
          required: true,
          message: '请输入城市',
          trigger: 'blur'
        }],
        'warehouseAddressInfo.state': [{
          required: true,
          message: '请输入州',
          trigger: 'blur'
        }],
        'warehouseAddressInfo.zipcode': [{
          required: true,
          message: '请输入邮编',
          trigger: 'blur'
        }],
        'warehouseAddressInfo.detailAddress1': [{
          required: true,
          message: '请输入地址1',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleSearch: function handleSearch() {
      this.listQuery.page = 1;
      this.listQuery.name = this.name;
      this.listQuery.id = this.id;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        title: this.searchTitle,
        name: this.listQuery.name,
        warehouseId: this.listQuery.id
      };
      this.listLoading = true;
      wsWarehouseGetPage(params).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.form = {
        id: '',
        name: '',
        sort: null,
        type: 6,
        warehouseAddressInfo: {
          recipient: '',
          phone: '',
          country: 'US',
          state: '',
          city: '',
          zipcode: '',
          detailAddress1: '',
          detailAddress2: ''
        }
      };
      this.dialogFormVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form = _objectSpread({}, row);
      if (this.form.warehouseAddressInfo) {
        this.form.warehouseAddressInfo.country = 'US';
      } else {
        this.form.warehouseAddressInfo = {
          recipient: '',
          phone: '',
          country: 'US',
          state: '',
          city: '',
          zipcode: '',
          detailAddress1: '',
          detailAddress2: ''
        };
      }
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      warehouseDelete(id).then(function () {
        _this2.getList();
        Message.success('删除成功');
      }).catch(function (error) {
        console.error('删除失败', error);
        Message.error('删除失败');
      });
    },
    saveWarehouse: function saveWarehouse(form) {
      var _this3 = this;
      this.$refs[form].validate(function (valid) {
        if (valid) {
          console.error(_this3.form);
          _this3.form.addTime = '';
          _this3.form.updTime = '';
          var obj = {
            id: _this3.form.id,
            name: _this3.form.name,
            sort: _this3.form.sort,
            code: _this3.form.code,
            type: _this3.form.type,
            addBy: _this3.form.addBy,
            delFlag: _this3.form.delFlag,
            status: _this3.form.status,
            aliasName: _this3.form.aliasName,
            warehouseCode: _this3.form.warehouseCode,
            wsAddressInfo: _this3.form.wsAddressInfo,
            operatingLow: _this3.form.operatingLow,
            operatingHighest: _this3.form.operatingHighest,
            operationFee: _this3.form.operationFee,
            startOperationFee: _this3.form.startOperationFee,
            operationFeeType: _this3.form.operationFeeType,
            logisticsFee: _this3.form.logisticsFee,
            logisticsFeeIncreaseType: _this3.form.logisticsFeeIncreaseType,
            logisticsFlag: _this3.form.logisticsFlag,
            recipient: _this3.form.warehouseAddressInfo.recipient,
            phone: _this3.form.warehouseAddressInfo.phone,
            country: _this3.form.warehouseAddressInfo.country,
            state: _this3.form.warehouseAddressInfo.state,
            city: _this3.form.warehouseAddressInfo.city,
            zipcode: _this3.form.warehouseAddressInfo.zipcode,
            detailAddress1: _this3.form.warehouseAddressInfo.detailAddress1,
            detailAddress2: _this3.form.warehouseAddressInfo.detailAddress2
          };
          console.info(obj);
          if (obj.id) {
            updateWarehouseById(obj).then(function () {
              _this3.getList();
              Message.success('编辑成功');
            }).catch(function (error) {
              console.error('编辑失败', error);
              Message.error('编辑失败');
            });
          } else {
            warehouseSaveInfo(obj).then(function () {
              _this3.getList();
              Message.success('新增成功');
            }).catch(function (error) {
              console.error('新增失败', error);
              Message.error('新增失败');
            });
          }
          _this3.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    }
  }
};