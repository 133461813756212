"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addNotice = addNotice;
exports.deleteNotice = deleteNotice;
exports.getNoticePage = getNoticePage;
exports.updateNotice = updateNotice;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getNoticePage(data) {
  return (0, _requestJson.default)({
    url: '/notice/getPage',
    method: 'get',
    params: data
  });
}
function addNotice(data) {
  return (0, _requestJson.default)({
    url: '/notice/save',
    method: 'post',
    data: data
  });
}
function updateNotice(data) {
  return (0, _requestJson.default)({
    url: '/notice/updateById',
    method: 'post',
    data: data
  });
}
function deleteNotice(id) {
  return (0, _requestJson.default)({
    url: "/notice/deleteById/".concat(id),
    method: 'post'
  });
}