"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAddressLimit = deleteAddressLimit;
exports.deleteConfigAccount = deleteConfigAccount;
exports.deleteConfigAgent = deleteConfigAgent;
exports.deleteConfigMission = deleteConfigMission;
exports.deleteConfigPoint = deleteConfigPoint;
exports.deleteConfigSearch = deleteConfigSearch;
exports.deleteRechargeCard = deleteRechargeCard;
exports.deleteShopCate = deleteShopCate;
exports.deleteShopTarget = deleteShopTarget;
exports.deleteTaskColum = deleteTaskColum;
exports.getAddressLimitList = getAddressLimitList;
exports.getConfigAccountList = getConfigAccountList;
exports.getConfigAgentList = getConfigAgentList;
exports.getConfigMissionList = getConfigMissionList;
exports.getConfigMissionSelector = getConfigMissionSelector;
exports.getConfigPointList = getConfigPointList;
exports.getConfigScanBlack = getConfigScanBlack;
exports.getConfigScanBlackList = getConfigScanBlackList;
exports.getConfigSearchList = getConfigSearchList;
exports.getParams = getParams;
exports.getRechargeCardList = getRechargeCardList;
exports.getSecretSelector = getSecretSelector;
exports.getShopCateList = getShopCateList;
exports.getShopCateSelector = getShopCateSelector;
exports.getShopTargetList = getShopTargetList;
exports.getShopTargetSelector = getShopTargetSelector;
exports.getTaskColumList = getTaskColumList;
exports.getTaskColumSelector = getTaskColumSelector;
exports.scanBlack = scanBlack;
exports.updateAddressLimit = updateAddressLimit;
exports.updateConfigAccount = updateConfigAccount;
exports.updateConfigAgent = updateConfigAgent;
exports.updateConfigMission = updateConfigMission;
exports.updateConfigMissionParams = updateConfigMissionParams;
exports.updateConfigPoint = updateConfigPoint;
exports.updateConfigScanBlack = updateConfigScanBlack;
exports.updateConfigSearch = updateConfigSearch;
exports.updateParams = updateParams;
exports.updateRechargeCard = updateRechargeCard;
exports.updateShopCate = updateShopCate;
exports.updateShopTarget = updateShopTarget;
exports.updateTaskColum = updateTaskColum;
var _request = _interopRequireDefault(require("@/utils/request"));
function getParams(data) {
  return (0, _request.default)({
    url: '/system/params/get',
    method: 'get',
    params: data
  });
}
function updateParams(data) {
  return (0, _request.default)({
    url: '/system/params/editSubmit',
    method: 'post',
    data: data
  });
}
function getShopCateList(query) {
  return (0, _request.default)({
    url: '/system/shopCate/getList',
    method: 'get',
    params: query
  });
}
function getShopCateSelector(query) {
  return (0, _request.default)({
    url: '/system/shopCate/getSelector',
    method: 'get',
    params: query
  });
}
function updateShopCate(data) {
  return (0, _request.default)({
    url: '/system/shopCate/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteShopCate(data) {
  return (0, _request.default)({
    url: '/system/shopCate/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getRechargeCardList(query) {
  return (0, _request.default)({
    url: '/system/rechargeCard/getList',
    method: 'get',
    params: query
  });
}
function updateRechargeCard(data) {
  return (0, _request.default)({
    url: '/system/rechargeCard/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteRechargeCard(data) {
  return (0, _request.default)({
    url: '/system/rechargeCard/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getConfigMissionList(query) {
  return (0, _request.default)({
    url: '/system/configMission/getList',
    method: 'get',
    params: query
  });
}
function getConfigMissionSelector() {
  return (0, _request.default)({
    url: '/system/configMission/getSelector',
    method: 'get'
  });
}
function updateConfigMission(data) {
  return (0, _request.default)({
    url: '/system/configMission/editSubmit',
    method: 'post',
    data: data
  });
}
function updateConfigMissionParams(data) {
  return (0, _request.default)({
    url: '/system/configMission/updateParams',
    method: 'post',
    data: data
  });
}
function deleteConfigMission(data) {
  return (0, _request.default)({
    url: '/system/configMission/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getConfigAgentList(query) {
  return (0, _request.default)({
    url: '/system/generalAgent/getList',
    method: 'get',
    params: query
  });
}
function updateConfigAgent(data) {
  return (0, _request.default)({
    url: '/system/generalAgent/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteConfigAgent(data) {
  return (0, _request.default)({
    url: '/system/generalAgent/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getTaskColumList(query) {
  return (0, _request.default)({
    url: '/system/taskColum/getList',
    method: 'get',
    params: query
  });
}
function getTaskColumSelector(query) {
  return (0, _request.default)({
    url: '/system/taskColum/getSelector',
    method: 'get',
    params: query
  });
}
function updateTaskColum(data) {
  return (0, _request.default)({
    url: '/system/taskColum/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteTaskColum(data) {
  return (0, _request.default)({
    url: '/system/taskColum/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getSecretSelector(query) {
  return (0, _request.default)({
    url: '/system/secret/getList',
    method: 'get',
    params: query
  });
}
function getConfigAccountList(query) {
  return (0, _request.default)({
    url: '/system/configAccount/getList',
    method: 'get',
    params: query
  });
}
function updateConfigAccount(data) {
  return (0, _request.default)({
    url: '/system/configAccount/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteConfigAccount(data) {
  return (0, _request.default)({
    url: '/system/configAccount/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getShopTargetList(query) {
  return (0, _request.default)({
    url: '/system/shopTarget/getList',
    method: 'get',
    params: query
  });
}
function getShopTargetSelector(query) {
  return (0, _request.default)({
    url: '/system/shopTarget/getSelector',
    method: 'get',
    params: query
  });
}
function updateShopTarget(data) {
  return (0, _request.default)({
    url: '/system/shopTarget/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteShopTarget(data) {
  return (0, _request.default)({
    url: '/system/shopTarget/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getAddressLimitList(query) {
  return (0, _request.default)({
    url: '/system/addressLimit/getList',
    method: 'get',
    params: query
  });
}
function updateAddressLimit(data) {
  return (0, _request.default)({
    url: '/system/addressLimit/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteAddressLimit(data) {
  return (0, _request.default)({
    url: '/system/addressLimit/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getConfigScanBlack(query) {
  return (0, _request.default)({
    url: '/system/configScanBlack/get',
    method: 'get',
    params: query
  });
}
function updateConfigScanBlack(data) {
  return (0, _request.default)({
    url: '/system/configScanBlack/editSubmit',
    method: 'post',
    data: data
  });
}
function scanBlack(data) {
  return (0, _request.default)({
    url: '/system/configScanBlack/scanBlack',
    method: 'post',
    data: data
  });
}
function getConfigScanBlackList(query) {
  return (0, _request.default)({
    url: '/system/configScanBlack/getList',
    method: 'get',
    params: query
  });
}
function getConfigPointList(query) {
  return (0, _request.default)({
    url: '/system/configPoint/getList',
    method: 'get',
    params: query
  });
}
function updateConfigPoint(data) {
  return (0, _request.default)({
    url: '/system/configPoint/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteConfigPoint(data) {
  return (0, _request.default)({
    url: '/system/configPoint/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getConfigSearchList(query) {
  return (0, _request.default)({
    url: '/system/configSearch/getList',
    method: 'get',
    params: query
  });
}
function updateConfigSearch(data) {
  return (0, _request.default)({
    url: '/system/configSearch/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteConfigSearch(data) {
  return (0, _request.default)({
    url: '/system/configSearch/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}