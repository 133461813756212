var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            size: "mini",
                            "label-width": "60px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态:", prop: "status" } },
                                _vm._l(_vm.staticStatus, function (item) {
                                  return _c(
                                    "el-button",
                                    {
                                      key: item.value,
                                      class: {
                                        "is-selected":
                                          _vm.currentStatus === item.value,
                                        "is-tuikuan":
                                          item.value == 10 &&
                                          _vm.currentStatus1 !== item.value &&
                                          !(
                                            item.label == "订单包裹审核" ||
                                            item.label == "订单包裹审核（0）"
                                          ),
                                      },
                                      attrs: {
                                        value: item.value,
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilter(item.value)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.label) + "\n              "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "status",
                                    "label-width": "100",
                                  },
                                },
                                _vm._l(_vm.refundStatus, function (item) {
                                  return _c(
                                    "el-button",
                                    {
                                      key: item.value,
                                      class: {
                                        "is-selected":
                                          _vm.currentStatus1 === item.value,
                                        "is-tuikuan":
                                          item.value == 0 &&
                                          _vm.currentStatus1 !== item.value &&
                                          !(
                                            item.label == "申请退款" ||
                                            item.label == "申请退款（0）"
                                          ),
                                      },
                                      attrs: {
                                        value: item.value,
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilter2(item.value)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.label) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { margin: "0 50px" },
                                  attrs: {
                                    label: "包裹类型(开发中):",
                                    prop: "status",
                                    "label-width": "100",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterSku(0)
                                        },
                                      },
                                    },
                                    [_vm._v("一票一件\n              ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterSku(1)
                                        },
                                      },
                                    },
                                    [_vm._v("一票多件\n              ")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "面单:",
                                    prop: "labelUrl",
                                    "label-width": "100",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterLabel(1)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "存在( " +
                                          _vm._s(_vm.labelNum) +
                                          ")\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterLabel(0)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "不存在(" +
                                          _vm._s(_vm.labelNotNum) +
                                          ")\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: { clearable: false },
                                  on: { change: _vm.handleChangeQueryType },
                                  model: {
                                    value: _vm.selectedQueryType,
                                    callback: function ($$v) {
                                      _vm.selectedQueryType = $$v
                                    },
                                    expression: "selectedQueryType",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        value: "orderNoList",
                                        label: "订单号",
                                      },
                                    },
                                    [_vm._v("订单号")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        value: "trackingNo",
                                        label: "跟踪号",
                                      },
                                    },
                                    [_vm._v("跟踪号")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    { attrs: { value: "sku", label: "SKU" } },
                                    [_vm._v("SKU")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  placeholder: "请输入查询条件",
                                  clearable: true,
                                },
                                model: {
                                  value: _vm.queryValue,
                                  callback: function ($$v) {
                                    _vm.queryValue = $$v
                                  },
                                  expression: "queryValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { label: "服务：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.carrier,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "carrier", $$v)
                                    },
                                    expression: "listQuery.carrier",
                                  },
                                },
                                _vm._l(
                                  _vm.carrierShaixuanOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.name,
                                      attrs: {
                                        label: item.name,
                                        value: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { label: "仓库：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.warehouseId",
                                  },
                                },
                                _vm._l(_vm.warehouseOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { "label-width": "60", label: "供货商：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.adminId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "adminId", $$v)
                                    },
                                    expression: "listQuery.adminId",
                                  },
                                },
                                _vm._l(_vm.supplierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: {
                                "label-width": "60",
                                label: "物流渠道：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.apiId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "apiId", $$v)
                                    },
                                    expression: "listQuery.apiId",
                                  },
                                },
                                _vm._l(
                                  _vm.apiWarehouseOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.key,
                                      attrs: {
                                        label: item.description,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "100" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  on: { change: _vm.handleTimeOptionChange },
                                  model: {
                                    value: _vm.selectedTimeOption,
                                    callback: function ($$v) {
                                      _vm.selectedTimeOption = $$v
                                    },
                                    expression: "selectedTimeOption",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: { value: "0", label: "创建时间" },
                                    },
                                    [_vm._v("创建时间")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    {
                                      attrs: { value: "2", label: "发货时间" },
                                    },
                                    [_vm._v("发货时间")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: { width: "260px" },
                                attrs: {
                                  type: "daterange",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                  disabled: !_vm.selectedTimeOption,
                                },
                                on: { input: _vm.handleDateRangeChange },
                                model: {
                                  value: _vm.dateRange,
                                  callback: function ($$v) {
                                    _vm.dateRange = $$v
                                  },
                                  expression: "dateRange",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v("\n              查询\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                { on: { click: _vm.batch_earch } },
                                [_vm._v("批量查询")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "space",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["createOrder"]),
                          expression: "$checkPermission(['createOrder'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "success" },
                      on: { click: _vm.add_smallsave_sw },
                    },
                    [_vm._v("\n        创建订单\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["orderBatchManage"]),
                          expression: "$checkPermission(['orderBatchManage'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-edit",
                      },
                      on: { click: _vm.creatBatch },
                    },
                    [_vm._v("创建发货批次\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermissionCustom([
                            "orderStatusManage",
                          ]),
                          expression:
                            "$checkPermissionCustom(['orderStatusManage'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleBatchStatus },
                    },
                    [_vm._v("\n        订单状态操作\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["exportOrderData"]),
                          expression: "$checkPermission(['exportOrderData'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.exportExcel },
                    },
                    [_vm._v("导出订单\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["exportOrderData"]),
                          expression: "$checkPermission(['exportOrderData'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.exportTemuExcel },
                    },
                    [_vm._v("导出Temu订单物流\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["pdfManage"]),
                          expression: "$checkPermission(['pdfManage'])",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        disabled: _vm.btn_loading,
                        size: "mini",
                        "split-button": "",
                      },
                      on: { click: _vm.handleBatchFace },
                    },
                    [
                      _vm._v("\n        批量打印面单\n        "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: "1",
                                disabled: _vm.btn_face_label_loading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleBatchZipWindow($event)
                                },
                              },
                            },
                            [_vm._v("批量打印面单+标签ZIP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: "2",
                                disabled: _vm.btn_face_label_loading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleBatchZipWindowMege($event)
                                },
                              },
                            },
                            [_vm._v(" 批量打印面单+标签ZIP(合并)")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: "3",
                                disabled: _vm.btn_face_label_loading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleBatchFaceAndLabel($event)
                                },
                              },
                            },
                            [_vm._v("批量打印面单+标签")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: "4",
                                disabled: _vm.btn_face_label_loading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleBatchLabelSort($event)
                                },
                              },
                            },
                            [_vm._v("批量打印面单SKU排序")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["refundReview"]),
                          expression: "$checkPermission(['refundReview'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.refund_smallupdate_sw },
                    },
                    [_vm._v("\n        退款审核\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["createOrder"]),
                          expression: "$checkPermission(['createOrder'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.add_smallsave_pingtai },
                    },
                    [_vm._v("\n        自传Label\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["equestRefund"]),
                          expression: "$checkPermission(['equestRefund'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleCancelClick },
                    },
                    [_vm._v("申请退款\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.add_uploadExcel },
                    },
                    [_vm._v("导入模版\n      ")]
                  ),
                  _vm._v(" "),
                  !_vm.mainFlag
                    ? _c(
                        "el-upload",
                        {
                          ref: "input",
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.actionZip,
                            "show-file-list": false,
                            "auto-upload": true,
                            "on-change": _vm.importZipExcel,
                            "on-success": _vm.uploadZip_success,
                            "on-error": _vm.uploadZip_error,
                            type: "file",
                            headers: _vm.headers,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-upload",
                                loading: _vm.loading_sw,
                              },
                            },
                            [_vm._v("上传label\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["shipMethodOrder"]),
                          expression: "$checkPermission(['shipMethodOrder'])",
                        },
                      ],
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.shipMethodOrder },
                    },
                    [_vm._v("手动发货\n      ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "1%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "45", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "序号", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.$index + 1) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "订单号",
              prop: "orderId",
              align: "center",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_content" }, [
                      _vm._v(_vm._s(scope.row.orderId)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_top" }, [_vm._v("供应商")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_content" }, [
                      _vm._v(_vm._s(scope.row.sysAdmin.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "收件人", prop: "recipient", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "地址", prop: "orderNo", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_padding" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.detailAddress) +
                          "-" +
                          _vm._s(scope.row.detailAddress2) +
                          "-" +
                          _vm._s(scope.row.city) +
                          "-" +
                          _vm._s(scope.row.state) +
                          "-" +
                          _vm._s(scope.row.country) +
                          "-" +
                          _vm._s(scope.row.zipCode) +
                          "-" +
                          _vm._s(scope.row.phone) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU信息", prop: "orderNo", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.wsOrderSkuList,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table_padding" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.sku) +
                              " * " +
                              _vm._s(item.quantity) +
                              "\n        "
                          ),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中文名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.wsOrderSkuList,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table_padding" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.wsSku ? item.wsSku.cnTitle : "") +
                              "\n          "
                          ),
                          index > 0 ? _c("span", [_c("br")]) : _vm._e(),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "尺寸", prop: "orderNo", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.packageInfoParam
                      ? _c("div", { staticClass: "table_padding" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.packageInfoParam.length) +
                              " *\n          " +
                              _vm._s(scope.row.packageInfoParam.width) +
                              " *\n          " +
                              _vm._s(scope.row.packageInfoParam.height) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货仓库", prop: "orderNo", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_content" }, [
                      scope.row.warehouse
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.warehouse.name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_top" }, [_vm._v("重量")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_content" }, [
                      scope.row.packageInfoParam
                        ? _c("div", { staticClass: "table_padding" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.packageInfoParam.weight) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "跟踪号",
              prop: "orderNo",
              align: "center",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_content" }, [
                      _vm._v(_vm._s(scope.row.trackingNo)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_arr_content" }, [
                      _c("div", { staticClass: "table_arr_bottom" }, [
                        _c("div", { staticClass: "table_top" }, [
                          _vm._v("运输服务"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table_content" }, [
                          _vm._v(_vm._s(scope.row.carrier)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table_arr_bottom" }, [
                        _c("div", { staticClass: "table_top" }, [
                          _vm._v("运费"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table_content" }, [
                          _vm._v(_vm._s(scope.row.expressFee)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table_arr_bottom" }, [
                        _c("div", { staticClass: "table_top" }, [
                          _vm._v("操作费"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table_content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(scope.row.expressProcessFee) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              prop: "status",
              align: "center",
              width: "165px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.orderAddTime
                      ? _c("span", [
                          _vm._v(
                            "\n            已创建 " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.orderAddTime,
                                  "{y}-{m}-{d} {h}:{i}:{s}"
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.pickingTime
                      ? _c("span", [
                          _vm._v(
                            "\n            捡货中 " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.pickingTime,
                                  "{y}-{m}-{d} {h}:{i}:{s}"
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.pickupTime
                      ? _c("span", [
                          _vm._v(
                            "\n            已捡货 " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.pickupTime,
                                  "{y}-{m}-{d} {h}:{i}:{s}"
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.packTime
                      ? _c("span", [
                          _vm._v(
                            "\n            已打包 " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.packTime,
                                  "{y}-{m}-{d} {h}:{i}:{s}"
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.deliveryTime
                      ? _c("span", [
                          _vm._v(
                            "\n            已发货 " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.deliveryTime,
                                  "{y}-{m}-{d} {h}:{i}:{s}"
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    scope.row.exceptionStatus === 1
                      ? _c("span", [
                          _vm._v("\n            异常单 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 6
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            申清退款 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 7
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            已退款 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.refundStatus === 0
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            申请退款 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.refundStatus === 1
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            已退款 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.refundStatus === 2
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            拒绝退款 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 10
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            待审核包裹尺寸 \n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 20
                      ? _c("span", { staticStyle: { color: "#990105" } }, [
                          _vm._v("\n            手动发货 \n        "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "面单", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_content" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.labelUrl ? "存在" : "不存在") +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_top" }, [
                      _vm._v("下载次数"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table_content" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.labelDownNum) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table_content" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            staticStyle: { width: "100%" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.updateLabel(scope.row.orderId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.labelUrl ? "更新面单" : "上传面单"
                              ) + "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table_content" },
                      [
                        scope.row.status == 10 &&
                        _vm.$checkPermission(["applyPackageOrder"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.applyOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("包裹审核\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku编码", prop: "sku" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku编码" },
                        model: {
                          value: _vm.ruleForm.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sku", $$v)
                          },
                          expression: "ruleForm.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku自编码", prop: "selfCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku自编码" },
                        model: {
                          value: _vm.ruleForm.selfCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "selfCode", $$v)
                          },
                          expression: "ruleForm.selfCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中文名称", prop: "cnTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入中文名称" },
                        model: {
                          value: _vm.ruleForm.cnTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "cnTitle", $$v)
                          },
                          expression: "ruleForm.cnTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "英文名称", prop: "enTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入英文名称" },
                        model: {
                          value: _vm.ruleForm.enTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "enTitle", $$v)
                          },
                          expression: "ruleForm.enTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品重量", prop: "weight" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品重量" },
                          model: {
                            value: _vm.ruleForm.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "weight", $$v)
                            },
                            expression: "ruleForm.weight",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("LB\n          "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品长度", prop: "length" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品长度" },
                          model: {
                            value: _vm.ruleForm.length,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "length", $$v)
                            },
                            expression: "ruleForm.length",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("IN\n          "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品宽度", prop: "width" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品宽度" },
                          model: {
                            value: _vm.ruleForm.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "width", $$v)
                            },
                            expression: "ruleForm.width",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("IN\n          "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品高度", prop: "height" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品高度" },
                          model: {
                            value: _vm.ruleForm.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "height", $$v)
                            },
                            expression: "ruleForm.height",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("IN\n          "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "价钱", prop: "declarePrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品价钱" },
                          model: {
                            value: _vm.ruleForm.declarePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "declarePrice", $$v)
                            },
                            expression: "ruleForm.declarePrice",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("$\n          "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "安全库存", prop: "safetyAmount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品安全库存" },
                        model: {
                          value: _vm.ruleForm.safetyAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "safetyAmount", $$v)
                          },
                          expression: "ruleForm.safetyAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dialogType == "add"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "绑定仓库", prop: "warehouseIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.warehouseIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "warehouseIds", $$v)
                                },
                                expression: "ruleForm.warehouseIds",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "绑定仓库", prop: "warehouseId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: { disabled: true, placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "warehouseId", $$v)
                                },
                                expression: "ruleForm.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.smallsave_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title_dialogType,
                visible: _vm.smallsave_sw,
                "close-on-click-modal": false,
                width: "95%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.smallsave_sw = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "productForm",
                      attrs: {
                        inline: true,
                        model: _vm.productForm,
                        rules: _vm.small_rules,
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单号", prop: "orderId" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "338px" },
                            attrs: { placeholder: "请输入订单号" },
                            on: { input: _vm.changeMemberId },
                            model: {
                              value: _vm.productForm.orderId,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "orderId", $$v)
                              },
                              expression: "productForm.orderId",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              订单号长度最少8个字符，最多18个字符，由数字组成，不允许带字母或特殊字符\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.generateOrderId()
                                },
                              },
                            },
                            [_vm._v("生成订单号\n          ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收件人", prop: "recipient" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入收件人" },
                            model: {
                              value: _vm.productForm.recipient,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "recipient", $$v)
                              },
                              expression: "productForm.recipient",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c("el-input", {
                            staticClass: "custom-input",
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.productForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "phone", $$v)
                              },
                              expression: "productForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址1", prop: "detailAddress" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入地址1" },
                            model: {
                              value: _vm.productForm.detailAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "detailAddress", $$v)
                              },
                              expression: "productForm.detailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址2", prop: "detailAddress2" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入地址2" },
                            model: {
                              value: _vm.productForm.detailAddress2,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "detailAddress2", $$v)
                              },
                              expression: "productForm.detailAddress2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市", prop: "city" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入城市" },
                            model: {
                              value: _vm.productForm.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "city", $$v)
                              },
                              expression: "productForm.city",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "州/省", prop: "state" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入州/省" },
                            model: {
                              value: _vm.productForm.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "state", $$v)
                              },
                              expression: "productForm.state",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "国家", prop: "country" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: {
                              placeholder: "请输入国家",
                              disabled: true,
                            },
                            model: {
                              value: _vm.productForm.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "country", $$v)
                              },
                              expression: "productForm.country",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮编", prop: "zipcode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入邮编" },
                            model: {
                              value: _vm.productForm.zipcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "zipcode", $$v)
                              },
                              expression: "productForm.zipcode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$checkPermission(["wsOrderSupplier"]),
                              expression:
                                "$checkPermission(['wsOrderSupplier'])",
                            },
                          ],
                          attrs: { label: "供货商：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择", clearable: true },
                              model: {
                                value: _vm.productForm.supplierId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productForm, "supplierId", $$v)
                                },
                                expression: "productForm.supplierId",
                              },
                            },
                            _vm._l(_vm.supplierOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "flex-wrap": "wrap",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: "",
                                prop: "warehouseId",
                                label: "仓库",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "280px" },
                                  attrs: { placeholder: "请选择仓库" },
                                  on: { change: _vm.warehouseIdGetApiList },
                                  model: {
                                    value: _vm.productForm.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.productForm,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "productForm.warehouseId",
                                  },
                                },
                                _vm._l(_vm.warehouseOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "SKU", required: "" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "280px" },
                                attrs: { placeholder: "搜索SKU" },
                                on: { input: _vm.commodity_url },
                                model: {
                                  value: _vm.sku_title,
                                  callback: function ($$v) {
                                    _vm.sku_title = $$v
                                  },
                                  expression: "sku_title",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                请选择仓库后选择SKU\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-question icon_question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#606266",
                                      "line-height": "17px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: "",
                                prop: "apiId",
                                label: "API",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "280px" },
                                  attrs: { placeholder: "请选择仓库API" },
                                  model: {
                                    value: _vm.productForm.apiId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.productForm, "apiId", $$v)
                                    },
                                    expression: "productForm.apiId",
                                  },
                                },
                                _vm._l(_vm.apiOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.description,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                请选择SKU后选择API\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-question icon_question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#606266",
                                      "line-height": "17px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_tab",
                          staticStyle: { width: "100%" },
                          attrs: { label: " " },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: {
                                data: _vm.tabskulist,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.sku)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  210453835
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品标题",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cnTitle)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3431832491
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  prop: "id",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.quantity)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2476752509
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                size: "medium",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delete_list(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  删除\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1294575019
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: {
                                data: _vm.shipSkuList,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.sku)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  210453835
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品标题",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cnTitle)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3431832491
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "库存",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.effectiveAmount)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  991600487
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: scope.row.quantity,
                                                expression:
                                                  "scope.row.quantity",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "输入发货SKU数量",
                                              oninput:
                                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                                            },
                                            domProps: {
                                              value: scope.row.quantity,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  scope.row,
                                                  "quantity",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1689138660
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          !scope.row.button_sw
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "medium",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.add_skuId(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  添加\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2385016603
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.smallsave_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm("productForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.statusDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "修改订单状态",
                visible: _vm.statusDialog,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.statusDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.statuForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择订单状态" },
                          model: {
                            value: _vm.statuForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.statuForm, "status", $$v)
                            },
                            expression: "statuForm.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "已创建" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "捡货中" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "已捡货" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "已打包" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 4, label: "已发货" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 5, label: "异常单" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 6, label: "去除异常单" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.statusDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.add_status("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.zipDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "标签需要",
                visible: _vm.zipDialog,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zipDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.zipTypeForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签需要", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.zipTypeForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.zipTypeForm, "type", $$v)
                            },
                            expression: "zipTypeForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "不需要标签" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "需要标签" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.zipDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchZip("form")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btn_loading ? "下载中" : "确定"))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reviewDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "审核",
                visible: _vm.reviewDialog,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.reviewDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.reviewTypeForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.reviewTypeForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.reviewTypeForm, "type", $$v)
                            },
                            expression: "reviewTypeForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "同意" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "拒绝" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.reviewDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchreview("form")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btn_loading ? "审核中" : "确定"))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.smallsave_pingtai
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.pingtai_dialogType,
                visible: _vm.smallsave_pingtai,
                "close-on-click-modal": false,
                width: "95%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.smallsave_pingtai = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "productForm",
                      attrs: {
                        inline: true,
                        model: _vm.productForm,
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单号", prop: "orderId" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "338px" },
                            attrs: { placeholder: "请输入订单号" },
                            on: { input: _vm.changeMemberId },
                            model: {
                              value: _vm.productForm.orderId,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "orderId", $$v)
                              },
                              expression: "productForm.orderId",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              订单号长度最少8个字符，最多18个字符，由数字组成，不允许带字母或特殊字符\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.generateOrderId()
                                },
                              },
                            },
                            [_vm._v("生成订单号\n          ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收件人", prop: "recipient" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入收件人" },
                            model: {
                              value: _vm.productForm.recipient,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "recipient", $$v)
                              },
                              expression: "productForm.recipient",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c("el-input", {
                            staticClass: "custom-input",
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.productForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "phone", $$v)
                              },
                              expression: "productForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            prop: "warehouseId",
                            label: "仓库",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "218px" },
                              attrs: { placeholder: "请选择仓库" },
                              on: { change: _vm.warehouseIdGetApiList },
                              model: {
                                value: _vm.productForm.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productForm, "warehouseId", $$v)
                                },
                                expression: "productForm.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              请选择SKU后选择仓库\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "flex-wrap": "wrap",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "SKU", required: "" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "440px" },
                                attrs: { placeholder: "搜索SKU" },
                                on: { input: _vm.commodity_url },
                                model: {
                                  value: _vm.sku_title,
                                  callback: function ($$v) {
                                    _vm.sku_title = $$v
                                  },
                                  expression: "sku_title",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                请输入SKU搜索添加SKU\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-question icon_question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#606266",
                                      "line-height": "17px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$checkPermission([
                                    "wsOrderSupplier",
                                  ]),
                                  expression:
                                    "$checkPermission(['wsOrderSupplier'])",
                                },
                              ],
                              attrs: { label: "供货商：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.productForm.supplierId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.productForm,
                                        "supplierId",
                                        $$v
                                      )
                                    },
                                    expression: "productForm.supplierId",
                                  },
                                },
                                _vm._l(_vm.supplierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上传面单", prop: "1" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "input",
                                  staticClass: "upload-demo",
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: {
                                    action: _vm.action,
                                    "show-file-list": false,
                                    "auto-upload": true,
                                    "on-change": _vm.importLabel,
                                    "on-success": _vm.upload_success,
                                    "on-error": _vm.upload_error,
                                    type: "file",
                                    headers: _vm.headers,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "filter-item",
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-upload",
                                      },
                                    },
                                    [_vm._v("上传面单\n              ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "面单地址", prop: "1" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "450px" },
                                attrs: { placeholder: "请输入面单地址" },
                                model: {
                                  value: _vm.labelUrl,
                                  callback: function ($$v) {
                                    _vm.labelUrl = $$v
                                  },
                                  expression: "labelUrl",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟踪号", prop: "1" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "450px" },
                                attrs: { placeholder: "请输入跟踪号" },
                                model: {
                                  value: _vm.productForm.trackingNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.productForm, "trackingNo", $$v)
                                  },
                                  expression: "productForm.trackingNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_tab",
                          staticStyle: { width: "100%", "margin-top": "20px" },
                          attrs: { label: " " },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: {
                                data: _vm.tabskulist,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.sku)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  210453835
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "中文名称",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cnTitle)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3431832491
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  prop: "id",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.quantity)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2476752509
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                size: "medium",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delete_list(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  删除\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1294575019
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: {
                                data: _vm.shipSkuList,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.sku)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  210453835
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "中文名称",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cnTitle)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3431832491
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: scope.row.quantity,
                                                expression:
                                                  "scope.row.quantity",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "输入发货SKU数量",
                                              oninput:
                                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                                            },
                                            domProps: {
                                              value: scope.row.quantity,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  scope.row,
                                                  "quantity",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1689138660
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          !scope.row.button_sw
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "medium",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.add_skuId(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  添加\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2385016603
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.smallsave_pingtai = false
                        },
                      },
                    },
                    [_vm._v("取消\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirmPinTai("productForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editLabelDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "修改Label",
                visible: _vm.editLabelDialog,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editLabelDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.editLabelForm, "label-width": "90px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传面单", prop: "1" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "input",
                          staticClass: "upload-demo",
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            action: _vm.action,
                            "show-file-list": false,
                            "auto-upload": true,
                            "on-change": _vm.importLabel,
                            "on-success": _vm.upload_success,
                            "on-error": _vm.upload_error,
                            type: "file",
                            headers: _vm.headers,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-upload",
                              },
                            },
                            [_vm._v("上传面单\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "面单地址", prop: "1" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { placeholder: "请输入面单地址" },
                        model: {
                          value: _vm.labelUrl,
                          callback: function ($$v) {
                            _vm.labelUrl = $$v
                          },
                          expression: "labelUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editLabelDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.update_Label("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "800px" },
          attrs: { title: "审核订单", visible: _vm.applyOrderDialog },
          on: {
            "update:visible": function ($event) {
              _vm.applyOrderDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.applyOrderForm,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果", prop: "applyStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.applyOrderForm.applyStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyOrderForm, "applyStatus", $$v)
                        },
                        expression: "applyOrderForm.applyStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0, border: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-bottom": "10px",
                    "font-size": "16px",
                  },
                  attrs: {
                    "label-width": "60",
                    label: "物流渠道：",
                    prop: "apiId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "请选择", clearable: true },
                      model: {
                        value: _vm.applyOrderForm.apiId,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyOrderForm, "apiId", $$v)
                        },
                        expression: "applyOrderForm.apiId",
                      },
                    },
                    _vm._l(_vm.apiNotLabelWarehouseOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.description, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包裹长", prop: "packageInfo" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput:
                        "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                    },
                    model: {
                      value: _vm.applyOrderForm.packageInfoParam.length,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applyOrderForm.packageInfoParam,
                          "length",
                          $$v
                        )
                      },
                      expression: "applyOrderForm.packageInfoParam.length",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包裹宽", prop: "packageInfo" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput:
                        "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                    },
                    model: {
                      value: _vm.applyOrderForm.packageInfoParam.width,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applyOrderForm.packageInfoParam,
                          "width",
                          $$v
                        )
                      },
                      expression: "applyOrderForm.packageInfoParam.width",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包裹高", prop: "packageInfo" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput:
                        "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                    },
                    model: {
                      value: _vm.applyOrderForm.packageInfoParam.height,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applyOrderForm.packageInfoParam,
                          "height",
                          $$v
                        )
                      },
                      expression: "applyOrderForm.packageInfoParam.height",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.applyOrderDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.applyConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "800px" },
          attrs: { title: "手动发货订单", visible: _vm.shipMethodOrderDialog },
          on: {
            "update:visible": function ($event) {
              _vm.shipMethodOrderDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.shipMethodOrderForm,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-right": "20px",
                    "margin-bottom": "10px",
                    "font-size": "20px",
                  },
                  attrs: {
                    "label-width": "60",
                    label: "物流渠道：",
                    prop: "apiId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "请选择", clearable: true },
                      model: {
                        value: _vm.shipMethodOrderForm.apiId,
                        callback: function ($$v) {
                          _vm.$set(_vm.shipMethodOrderForm, "apiId", $$v)
                        },
                        expression: "shipMethodOrderForm.apiId",
                      },
                    },
                    _vm._l(_vm.apiNotLabelWarehouseOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.description, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.shipMethodOrderDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.shipMethodOrderConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uploadExcel_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "导入模版",
                visible: _vm.uploadExcel_sw,
                "close-on-click-modal": false,
                width: "85%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadExcel_sw = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "uploadExcel_list",
                      attrs: {
                        model: _vm.uploadExcel_list,
                        rules: _vm.small_rules,
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$checkPermission(["wsOrderSupplier"]),
                              expression:
                                "$checkPermission(['wsOrderSupplier'])",
                            },
                          ],
                          attrs: { label: "供货商：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择", clearable: true },
                              on: { change: _vm.warehouseIdGetApiList },
                              model: {
                                value: _vm.uploadExcel_list.supplierId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.uploadExcel_list,
                                    "supplierId",
                                    $$v
                                  )
                                },
                                expression: "uploadExcel_list.supplierId",
                              },
                            },
                            _vm._l(_vm.supplierOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            prop: "warehouseId",
                            label: "仓库",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "280px" },
                              attrs: { placeholder: "请选择仓库" },
                              on: { change: _vm.warehouseIdGetApiList },
                              model: {
                                value: _vm.uploadExcel_list.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.uploadExcel_list,
                                    "warehouseId",
                                    $$v
                                  )
                                },
                                expression: "uploadExcel_list.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              选择仓库\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            prop: "apiIdExcel",
                            label: "快递",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "280px" },
                              attrs: { placeholder: "请选择快递" },
                              on: { change: _vm.apiIdExcelSelect },
                              model: {
                                value: _vm.uploadExcel_list.apiIdExcel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.uploadExcel_list,
                                    "apiIdExcel",
                                    $$v
                                  )
                                },
                                expression: "uploadExcel_list.apiIdExcel",
                              },
                            },
                            _vm._l(_vm.apiOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.description,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              选择仓库后选择快递\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.uploadExcel_list.apiIdExcel === 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: "",
                                prop: "carrier",
                                "show-message": false,
                                label: "服务",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "280px" },
                                  attrs: { placeholder: "请选择服务" },
                                  on: { change: _vm.onCarrierChange },
                                  model: {
                                    value: _vm.uploadExcel_list.carrier,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.uploadExcel_list,
                                        "carrier",
                                        $$v
                                      )
                                    },
                                    expression: "uploadExcel_list.carrier",
                                  },
                                },
                                _vm._l(_vm.carrierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.name,
                                    attrs: {
                                      label: item.name,
                                      value: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadExcel_list.apiIdExcel === 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: "",
                                prop: "carrierServiceName",
                                "show-message": false,
                                label: "服务名称",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "280px" },
                                  attrs: { placeholder: "请选择服务名称" },
                                  model: {
                                    value:
                                      _vm.uploadExcel_list.carrierServiceName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.uploadExcel_list,
                                        "carrierServiceName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "uploadExcel_list.carrierServiceName",
                                  },
                                },
                                _vm._l(
                                  _vm.currentCarrierServices,
                                  function (service, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: service, value: service },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传模版", required: true } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                action:
                                  _vm.excel_action +
                                  "?adminId=" +
                                  (this.uploadExcel_list.supplierId
                                    ? this.uploadExcel_list.supplierId
                                    : "") +
                                  "&warehouseId=" +
                                  this.uploadExcel_list.warehouseId,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-progress": _vm.excel_progress,
                                "on-change": _vm.excel_importExcel,
                                "on-success": _vm.excel_upload_success,
                                "on-error": _vm.excel_upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.excel_loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.excel_loading
                                          ? "导入中"
                                          : "批量创建发货"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btns",
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFun(
                                        "wsBatchCreateOrderTemplate_01.xlsx"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载模板\n            ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                action:
                                  _vm.my_excel_action +
                                  "?adminId=" +
                                  (this.uploadExcel_list.supplierId
                                    ? this.uploadExcel_list.supplierId
                                    : "") +
                                  "&warehouseId=" +
                                  this.uploadExcel_list.warehouseId,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-progress": _vm.excel_progress,
                                "on-change": _vm.excel_importExcel,
                                "on-success": _vm.excel_upload_success,
                                "on-error": _vm.excel_upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.excel_loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.excel_loading
                                          ? "导入中"
                                          : "批量自传订单"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btns",
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFun(
                                        "wsBatchMyCreateOrderTemplate_01.xlsx"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载模板\n            ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                action:
                                  _vm.temu_excel_action +
                                  "?adminId=" +
                                  (this.uploadExcel_list.supplierId
                                    ? this.uploadExcel_list.supplierId
                                    : "") +
                                  "&warehouseId=" +
                                  this.uploadExcel_list.warehouseId,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-progress": _vm.excel_progress,
                                "on-change": _vm.excel_importExcel,
                                "on-success": _vm.excel_upload_success,
                                "on-error": _vm.excel_upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.excel_loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.excel_loading
                                          ? "导入中"
                                          : "批量导入Temu订单"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btns",
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFun(
                                        "wsBatchTemuCreateOrderTemplate_01.xlsx"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载模板\n            ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: {
                                data: _vm.excel_list,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                              on: { "selection-change": _vm.add_selection },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  selectable: _vm.checkSelectable,
                                  type: "selection",
                                  width: "45",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货单",
                                  prop: "orderId",
                                  align: "center",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.orderId_sw
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.orderId)
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !scope.row.orderId_sw
                                            ? _c("el-input", {
                                                staticStyle: { width: "160px" },
                                                attrs: {
                                                  placeholder: "请输入发货单",
                                                },
                                                model: {
                                                  value: scope.row.orderId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "orderId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.orderId",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          scope.row.message !== "成功"
                                            ? _c("i", {
                                                staticClass: "el-icon-edit",
                                                staticStyle: {
                                                  color: "#409eff",
                                                  "margin-right": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.add_orderId_sw(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2633042453
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "收件人",
                                  prop: "recipient",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "收件地址1",
                                  prop: "detailAddress",
                                  align: "center",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.detailAddress_sw
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.detailAddress
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !scope.row.detailAddress_sw
                                            ? _c("el-input", {
                                                staticStyle: { width: "160px" },
                                                attrs: {
                                                  placeholder:
                                                    "请输入收件地址2",
                                                },
                                                model: {
                                                  value:
                                                    scope.row.detailAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "detailAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.detailAddress",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          scope.row.message !== "成功"
                                            ? _c("i", {
                                                staticClass: "el-icon-edit",
                                                staticStyle: {
                                                  color: "#409eff",
                                                  "margin-right": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.add_detailAddress_sw(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2588937680
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "收件地址2",
                                  prop: "detailAddress2",
                                  align: "center",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.detailAddress2_sw
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.detailAddress2
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !scope.row.detailAddress2_sw
                                            ? _c("el-input", {
                                                staticStyle: { width: "160px" },
                                                attrs: {
                                                  placeholder:
                                                    "请输入收件地址1",
                                                },
                                                model: {
                                                  value:
                                                    scope.row.detailAddress2,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "detailAddress2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.detailAddress2",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          scope.row.message !== "成功"
                                            ? _c("i", {
                                                staticClass: "el-icon-edit",
                                                staticStyle: {
                                                  color: "#409eff",
                                                  "margin-right": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.add_detailAddress2_sw(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  154520161
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "城市",
                                  prop: "city",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "省/州",
                                  prop: "state",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "邮编",
                                  prop: "zipcode",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "电话",
                                  prop: "phone",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "运单号",
                                  prop: "trackingNo",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU信息",
                                  prop: "recipient",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return _vm._l(
                                          scope.row.wsOrderSkuList,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "table_padding",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.wsSku
                                                        ? item.wsSku.sku
                                                        : ""
                                                    ) +
                                                    "*" +
                                                    _vm._s(
                                                      item.quantity
                                                        ? item.quantity
                                                        : ""
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2955774568
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "体积(长*宽*高)",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.wsOrderSkuList[0]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "table_padding",
                                                },
                                                [
                                                  scope.row.wsOrderSkuList[0]
                                                    .length
                                                    ? _c("sapn", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .wsOrderSkuList[0]
                                                              .length
                                                          ) + "* "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.wsOrderSkuList[0]
                                                    .width
                                                    ? _c("sapn", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .wsOrderSkuList[0]
                                                              .width
                                                          ) + "* "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.wsOrderSkuList[0]
                                                    .height
                                                    ? _c("sapn", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .wsOrderSkuList[0]
                                                              .height
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4042250866
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  prop: "remark",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "校验",
                                  prop: "message",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.message === "成功"
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgb(19, 206, 102)",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.message)
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgb(255, 73, 73)",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.message)
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  622317432
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", {
                        staticClass: "form_tab",
                        staticStyle: { width: "100%" },
                        attrs: { label: " " },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.uploadExcel_sw = false
                        },
                      },
                    },
                    [_vm._v("取消\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.btn_loading,
                        disabled: _vm.excel_loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchOrder("uploadExcel_list")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.batch_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "批量查询",
                visible: _vm.batch_sw,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.batch_sw = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 10,
                  placeholder: "请输入订单号",
                },
                model: {
                  value: _vm.batch_text,
                  callback: function ($$v) {
                    _vm.batch_text = $$v
                  },
                  expression: "batch_text",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.batch_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.add_batch_sw("form")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }