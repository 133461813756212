var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "10px" }, attrs: { shadow: "hover" } },
    [
      _c(
        "el-alert",
        {
          staticClass: "warn-content",
          attrs: {
            title: "温馨提示",
            type: "success",
            "show-icon": "",
            closable: false,
          },
        },
        [
          _vm._v("\n             图片存储、视频存储均使用七牛云,详情请查阅"),
          _c(
            "a",
            {
              attrs: {
                href: "https://developer.qiniu.com/kodo",
                target: "_blank",
              },
            },
            [_vm._v("《七牛云官方文档》")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticStyle: { width: "60%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.config,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "accessKey", prop: "accessKey" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.config.accessKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "accessKey", $$v)
                  },
                  expression: "config.accessKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "secretKey", prop: "secretKey" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.config.secretKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "secretKey", $$v)
                  },
                  expression: "config.secretKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片存储区域", prop: "imgZone" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择图片存储区域" },
                  model: {
                    value: _vm.config.imgZone,
                    callback: function ($$v) {
                      _vm.$set(_vm.config, "imgZone", $$v)
                    },
                    expression: "config.imgZone",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "华东", value: "z0" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "华北", value: "z1" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "华南", value: "z2" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "北美", value: "na0" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "东南亚", value: "as0" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片空间名称", prop: "imgBucket" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.imgBucket,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "imgBucket", $$v)
                  },
                  expression: "config.imgBucket",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片下载域名", prop: "imgViewUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.imgViewUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "imgViewUrl", $$v)
                  },
                  expression: "config.imgViewUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "视频存储区域", prop: "videoZone" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择视频存储区域" },
                  model: {
                    value: _vm.config.videoZone,
                    callback: function ($$v) {
                      _vm.$set(_vm.config, "videoZone", $$v)
                    },
                    expression: "config.videoZone",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "华东", value: "z0" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "华北", value: "z1" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "华南", value: "z2" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "北美", value: "na0" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "东南亚", value: "as0" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "视频空间名称", prop: "videoBucket" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.videoBucket,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "videoBucket", $$v)
                  },
                  expression: "config.videoBucket",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "视频下载域名", prop: "videoViewUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.videoViewUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "videoViewUrl", $$v)
                  },
                  expression: "config.videoViewUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateConfig } },
                [_vm._v("更新配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }