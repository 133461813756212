"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _third = require("@/api/third");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'realnameApi',
  data: function data() {
    return {
      config: {},
      loading: true,
      rules: {
        appId: [{
          required: true,
          message: '请填写appId',
          trigger: 'blur'
        }],
        secret: [{
          required: true,
          message: '请填写密钥',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.get();
  },
  methods: {
    get: function get() {
      var _this = this;
      (0, _third.getApproveConfig)().then(function (response) {
        _this.config = response.data;
        if (!_this.config) _this.config = {};
        _this.loading = false;
      });
    },
    updateConfig: function updateConfig() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this2.config);
          formData.addTime = null;
          formData.updTime = null;
          (0, _third.updateApproveConfig)(formData).then(function () {
            _this2.$notify({
              title: '成功',
              message: '更改配置成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;