var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "店铺账号：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入店铺账号",
                            "remote-method": _vm.add_getShopList,
                            loading: _vm.getShopList_loading,
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shopId", $$v)
                            },
                            expression: "listQuery.shopId",
                          },
                        },
                        _vm._l(_vm.envNameOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "订单号：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入订单号" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.search($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderNo", $$v)
                          },
                          expression: "listQuery.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "时间日期：" },
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择开始时间",
                        },
                        model: {
                          value: _vm.listQuery.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "startTime", $$v)
                          },
                          expression: "listQuery.startTime",
                        },
                      }),
                      _vm._v("\n        -\n        "),
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择结束时间",
                        },
                        model: {
                          value: _vm.listQuery.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "endTime", $$v)
                          },
                          expression: "listQuery.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "" },
                    },
                    [
                      _c("span", [_vm._v("类型：")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请选择收入/支出",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.inOut,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "inOut", $$v)
                            },
                            expression: "listQuery.inOut",
                          },
                        },
                        _vm._l(_vm.inOutOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.text, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", size: "mini" },
                },
                [_vm._v("货款余额 $" + _vm._s(_vm.balance))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "info",
                    loading: _vm.downloadLoading,
                    size: "mini",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.handleDownload },
                },
                [_vm._v("导出当前\n    ")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "订单号",
              prop: "orderNo",
              align: "center",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderNo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "收入/支出",
              width: "100",
              align: "center",
              prop: "inOut",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.inOut == "-1" ? "danger" : "success",
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("inOutFilter")(scope.row.inOut)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "资金类型",
              width: "150",
              align: "center",
              prop: "type",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("el-tag", [_vm._v(" 佣金 ")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "收入",
              width: "100",
              align: "center",
              prop: "amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.inOut == "1"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm._f("moneyFormat")(scope.row.amount))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "支出",
              width: "100",
              align: "center",
              prop: "amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.inOut == "-1"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "-" +
                              _vm._s(_vm._f("moneyFormat")(scope.row.amount))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "剩余",
              width: "100",
              align: "center",
              prop: "amountAfter",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm._f("moneyFormat")(scope.row.amountAfter))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作时间",
              width: "150",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "订单" +
                          _vm._s(scope.row.orderNo) +
                          " " +
                          _vm._s(scope.row.remark)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }