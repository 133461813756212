"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "MultipleImageUpload",
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // fileList: [],
      tempFile: [],
      times: 0,
      tempUrl: "",
      uploadUrl: process.env.VUE_APP_BASE_API + "system/upload/US",
      dialogImageUrl: "",
      dialogVisible: false,
      dataObj: {
        token: "",
        key: ""
      }
    };
  },
  created: function created() {
    this.initFileList();
  },
  computed: {
    fileList: {
      get: function get() {
        return this.initFileList();
      },
      set: function set() {}
    }
  },
  methods: {
    //上传前校验方法
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var suffix = file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg";
        var isLt1M = file.size / 1024 / 1024 < 1;
        if (!suffix) {
          _this2.$message({
            message: "上传图片只能是jpg,jpeg,png格式！",
            type: "error"
          });
          return reject(false);
        }
        if (!isLt1M) {
          _this2.$message.error("\u4E0A\u4F20\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 1MB!");
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
        _this2.limitFileWH(600, 600, file).then(function (res) {
          file.isFlag = res;
          if (file.isFlag) {
            return resolve(true);
          } else {
            return reject(false);
          }
        });
      });
    },
    // 上传图片事件限制图片尺寸
    limitFileWH: function limitFileWH(E_width, E_height, file) {
      var _this3 = this;
      var _this = this;
      var imgWidth = "";
      var imgHight = "";
      var isSize = new Promise(function (resolve, reject) {
        var width = E_width;
        var height = E_height;
        var _URL = window.URL || window.webkitURL;
        var img = new Image();
        img.onload = function () {
          imgWidth = img.width;
          imgHight = img.height;
          console.log("图片", img.width, width, img.height, height);
          var valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(function () {
        return true;
      }, function () {
        _this3.$message.error("上传封面图尺寸最小为 600 X 600");
        return false;
      });
      return isSize;
    },
    initFileList: function initFileList() {
      var val = [];
      if (this.value) {
        this.value.forEach(function (item, index) {
          var imgForm = {
            name: index,
            url: item
          };
          val.push(imgForm);
        });
      }
      return val;
    },
    emitInput: function emitInput(val) {
      this.$emit("input", val);
    },
    handleRemove: function handleRemove(file, fileList) {
      var temp = [];
      fileList.forEach(function (item) {
        temp.push(item);
      });
      // this.fileList = temp
      this.emitInput(this.getUrlArray(temp));
    },
    handleImageerror: function handleImageerror(err, file, fileList) {
      this.$message.error("封面图上传失败，请重试！");
    },
    handleImageSuccess: function handleImageSuccess(result, file, fileList) {
      console.log("result, file, fileList", result, file, fileList);
      var curTimes = fileList.length;
      var oldTimes = this.fileList.length;
      var temp = this.fileList;
      file.url = result.data.imgUrl;
      temp.push(file);
      if (curTimes == oldTimes + 1) {
        this.$message({
          message: "封面图上传成功！",
          type: "success"
        });
        this.emitInput(this.getUrlArray(temp));
      }
    },
    getUrlArray: function getUrlArray(fileList) {
      if (fileList && fileList.length > 0) {
        return fileList.map(function (item) {
          return item.url;
        });
      }
    },
    arraysToString: function arraysToString(fileList) {
      if (fileList && fileList.length > 0) {
        var temp = "";
        fileList.forEach(function (item) {
          temp = temp + "|" + item.url;
        });
        return temp.substring(1);
      }
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default2;