var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.skuList.length > 0
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.skuList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _vm._l(_vm.productSkuVariations, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: { align: "center", prop: item.name, label: item.name },
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "SKU名称", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { min: 0 },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.title,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "title", $$v)
                              },
                              expression: "scope.row.title",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  107233772
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "typeSkuName",
                  label: "品类中文名称",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { min: 0 },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.typeSkuName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "typeSkuName", $$v)
                              },
                              expression: "scope.row.typeSkuName",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2067389406
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "imgUrl",
                  label: "图片",
                  align: "center",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("UploadSKU", {
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.imgUrl,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "imgUrl", $$v)
                              },
                              expression: "scope.row.imgUrl",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  834178920
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "价格(美元)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入价格(美元)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.price,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  385115062
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "height", label: "高度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入高度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.height,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "height", $$v)
                              },
                              expression: "scope.row.height",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4122167157
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "width", label: "宽度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入宽度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.width,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "width", $$v)
                              },
                              expression: "scope.row.width",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3485556649
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "length", label: "长度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入长度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.length,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "length", $$v)
                              },
                              expression: "scope.row.length",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2004653265
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "weight", label: "重量(磅)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入重量(磅)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.weight,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "weight", $$v)
                              },
                              expression: "scope.row.weight",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3457839066
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "costPrice",
                  label: "出仓价(美元)",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入一件出仓价(美元)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.costPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "costPrice", $$v)
                              },
                              expression: "scope.row.costPrice",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3337906782
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "buyerFinishRate",
                  label: "佣金 99.00等于99%",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入佣金 99.00等于99%",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: _vm.expressPrice,
                              callback: function ($$v) {
                                _vm.expressPrice = $$v
                              },
                              expression: "expressPrice",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2418403793
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "isDian", label: "属性", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择属性" },
                              on: { input: _vm.add_input },
                              model: {
                                value: scope.row.isDian,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isDian", $$v)
                                },
                                expression: "scope.row.isDian",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "普货", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "带电", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "特货", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2190080464
                ),
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }