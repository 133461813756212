"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _third = require("@/api/third");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'weixinConfig',
  data: function data() {
    return {
      config: {},
      loading: true,
      rules: {
        fiveOriginalId: [{
          required: true,
          message: '请填写五代店小程序原始Id',
          trigger: 'blur'
        }],
        fiveXcxAppId: [{
          required: true,
          message: '请填写五代店小程序AppId',
          trigger: 'blur'
        }],
        fiveXcxSecret: [{
          required: true,
          message: '请填写五代店小程序密钥',
          trigger: 'blur'
        }],
        originalId: [{
          required: true,
          message: '请填写小程序原始Id',
          trigger: 'blur'
        }],
        xcxAppId: [{
          required: true,
          message: '请填写小程序AppId',
          trigger: 'blur'
        }],
        xcxSecret: [{
          required: true,
          message: '请填写小程序密钥',
          trigger: 'blur'
        }],
        kfAppId: [{
          required: true,
          message: '请填写开放平台AppId',
          trigger: 'blur'
        }],
        kfSecret: [{
          required: true,
          message: '请填写开放平台密钥',
          trigger: 'blur'
        }],
        shopKfAppId: [{
          required: true,
          message: '请填写开放平台AppId',
          trigger: 'blur'
        }],
        shopKfSecret: [{
          required: true,
          message: '请填写开放平台密钥',
          trigger: 'blur'
        }],
        gzhAppId: [{
          required: true,
          message: '请填写公众号AppId',
          trigger: 'blur'
        }],
        gzhSecret: [{
          required: true,
          message: '请填写公众号密钥',
          trigger: 'blur'
        }],
        mchId: [{
          required: true,
          message: '请填写微信商户号',
          trigger: 'blur'
        }],
        mchKey: [{
          required: true,
          message: '请填写微信商户密钥',
          trigger: 'blur'
        }],
        payNotifyUrl: [{
          required: true,
          message: '请填写支付回调地址',
          trigger: 'blur'
        }],
        certPath: [{
          required: true,
          message: '请填写API安全证书',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.get();
  },
  methods: {
    get: function get() {
      var _this = this;
      (0, _third.getWeixinConfig)().then(function (response) {
        _this.config = response.data;
        if (!_this.config) _this.config = {};
        _this.loading = false;
      });
    },
    updateConfig: function updateConfig() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this2.config);
          formData.addTime = null;
          formData.updTime = null;
          (0, _third.updateWeixinConfig)(formData).then(function () {
            _this2.$notify({
              title: '成功',
              message: '更改配置成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;