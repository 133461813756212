var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-view" }, [
    _vm.$slots.header
      ? _c("div", { staticClass: "page-view-header" }, [_vm._t("header")], 2)
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "page-view-content" }, [
      _vm.$slots.contentT
        ? _c(
            "div",
            { staticClass: "page-view-content-top" },
            [_vm._t("contentT")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-view-content-table",
          style: {
            paddingTop: _vm.$slots.contentT ? "0px" : "16px",
            paddingBottom:
              _vm.$slots.footerL || _vm.$slots.footer ? "0px" : "16px",
          },
        },
        [_vm._t("default")],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.$slots.footerL || _vm.$slots.footer
      ? _c("div", { staticClass: "page-view-footer" }, [
          _c(
            "div",
            { staticClass: "page-view-footer-left" },
            [_vm._t("footerL")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-view-footer-right" },
            [_vm._t("footer")],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }