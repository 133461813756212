var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/member/list")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-people" },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "peoples",
                      "class-name": "card-panel-icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("\n          会员数量\n        "),
                  ]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.panelData.totalNum,
                      duration: 2600,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/member/list")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-message" },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "people",
                      "class-name": "card-panel-icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("\n          今日注册\n        "),
                  ]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.panelData.todayMemberNum,
                      duration: 2600,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/goods/list")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "active",
                      "class-name": "card-panel-icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("\n          商品\n        "),
                  ]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.panelData.goodsNum,
                      duration: 2600,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/shop/list")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-shopping" },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "register",
                      "class-name": "card-panel-icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("\n          店铺\n        "),
                  ]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.panelData.sellerNum,
                      duration: 3600,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }