"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var vipRouter = {
  path: '/vip',
  component: _layout.default,
  redirect: '/vip/type',
  name: 'vip',
  alwaysShow: true,
  meta: {
    title: 'vip',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vip/type/index'));
      });
    },
    name: 'VipType',
    meta: {
      title: 'vip 配置',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vip/order/index'));
      });
    },
    name: 'VipOrder',
    meta: {
      title: 'vip 订单',
      role: []
    }
  }, {
    path: 'member',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vip/member/index'));
      });
    },
    name: 'VipMember',
    meta: {
      title: 'vip 会员',
      role: []
    }
  }]
};
var _default = vipRouter;
exports.default = _default;