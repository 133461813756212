"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var title = process.env.VUE_APP_TITLE || '';
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}