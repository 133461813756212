var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          staticStyle: { width: "100px" },
          attrs: { size: "mini" },
          on: { click: _vm.chooseImage },
        },
        [_vm._v("素材库选择>>")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择图片",
            visible: _vm.dialogFormVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "分组名称" },
                model: {
                  value: _vm.cate,
                  callback: function ($$v) {
                    _vm.cate = $$v
                  },
                  expression: "cate",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-edit",
                      },
                      on: { click: _vm.addSourceCate },
                    },
                    [_vm._v("\n        添加分组\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              attrs: {
                closable: "",
                type: "border-card",
                "tab-position": "top",
              },
              on: {
                "tab-click": _vm.handleClickTab,
                "tab-remove": _vm.removeTab,
              },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.cateOptions, function (item) {
              return _c(
                "el-tab-pane",
                { attrs: { label: item.name, name: item.id + "" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex f-w" },
                    [
                      _c("MultipleSource", {
                        on: { successCBK: _vm.imageSuccessCBK },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.list, function (item, index) {
                        return _vm.list
                          ? _c(
                              "div",
                              { staticClass: "image-preview flex f-s-0" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "image-preview-wrapper" },
                                  [
                                    _c("el-image", {
                                      key: item.id,
                                      staticStyle: {
                                        width: "148px",
                                        height: "148px",
                                        margin: "10px",
                                        border: "1px dashed #d9d9d9",
                                      },
                                      attrs: {
                                        fit: "scale-down",
                                        src: item.source,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "image-preview-action flex f-j-c f-a-c",
                                        class: item.ischoose ? "on" : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeImage(index)
                                          },
                                        },
                                      },
                                      [
                                        item.ischoose
                                          ? _c("i", {
                                              staticClass: "el-icon-success",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total>0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.listQuery.page,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "page", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }