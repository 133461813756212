"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteRole = deleteRole;
exports.getRoleList = getRoleList;
exports.getRoutes = getRoutes;
exports.updateRole = updateRole;
var _request = _interopRequireDefault(require("@/utils/request"));
function getRoleList(data) {
  return (0, _request.default)({
    url: '/system/adminType/getList',
    method: 'get',
    params: data
  });
}
function getRoutes() {
  return (0, _request.default)({
    url: '/system/menu/getList',
    method: 'get'
  });
}
function updateRole(data) {
  return (0, _request.default)({
    url: 'system/adminType/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteRole(data) {
  return (0, _request.default)({
    url: 'system/adminType/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}