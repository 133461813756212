var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 10px 10px 10px" } },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.config,
            "label-width": "140px",
            "label-position": "right",
          },
        },
        [
          _c("el-divider", [_vm._v("用户端")]),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { column: 1, size: "small", border: "" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        安卓强制更新\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": "1", "inactive-value": "0" },
                    model: {
                      value: _vm.config.android_update_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "android_update_status", $$v)
                      },
                      expression: "config.android_update_status",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        安卓版本号\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.android_version,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "android_version", $$v)
                      },
                      expression: "config.android_version",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        安卓下载链接\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.android_app_download_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "android_app_download_url", $$v)
                      },
                      expression: "config.android_app_download_url",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        安卓更新内容\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.config.android_update_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "android_update_content", $$v)
                      },
                      expression: "config.android_update_content",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("template", { slot: "label" }, [
                _vm._v("\n        IOS强制更新\n       "),
              ]),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "active-value": "1", "inactive-value": "0" },
                model: {
                  value: _vm.config.ios_update_status,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "ios_update_status", $$v)
                  },
                  expression: "config.ios_update_status",
                },
              }),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        IOS版本号\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.ios_version,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "ios_version", $$v)
                      },
                      expression: "config.ios_version",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        IOS下载链接\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.ios_app_download_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "ios_app_download_url", $$v)
                      },
                      expression: "config.ios_app_download_url",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        IOS更新内容\n       "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.config.ios_update_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "ios_update_content", $$v)
                      },
                      expression: "config.ios_update_content",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        操作\n       "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("保存")]
                  ),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }