var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%", "padding-top": "15px" },
      attrs: { data: _vm.list },
    },
    [
      _c("el-table-column", {
        attrs: { label: "订单编号", width: "170" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v("\n      " + _vm._s(scope.row.orderNo) + "\n    ")]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "用户名", width: "195" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v("\n      " + _vm._s(scope.row.memberName) + "\n    "),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "备注", "min-width": "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "span",
                  {
                    style:
                      scope.row.inOut == "-1" ? "color:red;" : "color:green;",
                  },
                  [
                    _vm._v(
                      _vm._s(scope.row.inOut == "-1" ? "[支出]" : "[收入]")
                    ),
                  ]
                ),
                _vm._v("\n      " + _vm._s(scope.row.remark) + "\n    "),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }