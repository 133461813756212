import Layout from '@/layout';
var goodsRouter = {
  path: '/goods',
  component: Layout,
  redirect: '/goods/type',
  name: 'goods',
  alwaysShow: true,
  meta: {
    title: '陪跑营',
    icon: 'report-brand'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/goods/type/index');
    },
    name: 'goodsType',
    meta: {
      title: '商品类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/goods/list/index');
    },
    name: 'goodsList',
    meta: {
      title: '商品列表',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/goods/order/index');
    },
    name: 'goodsOrder',
    meta: {
      title: '陪跑营订单',
      role: []
    }
  }]
};
export default goodsRouter;