"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var logRouter = {
  path: '/log',
  component: _layout.default,
  redirect: 'noredirect',
  name: 'log',
  meta: {
    title: '监控',
    icon: 'message'
  },
  children: [{
    path: 'loginLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/log/login/index'));
      });
    },
    name: 'loginLogTable',
    meta: {
      title: '后台登录日志',
      role: ['admin']
    }
  }, {
    path: 'operationLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/log/operation/index'));
      });
    },
    name: 'operationLogTable',
    meta: {
      title: '后台操作日志',
      role: ['admin']
    }
  }, {
    path: 'messageCode',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/log/messageCode/index'));
      });
    },
    name: 'messageCodeTable',
    meta: {
      title: '验证码记录',
      role: ['admin']
    }
  }]
};
var _default = logRouter;
exports.default = _default;