var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-edit",
                      },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "row-key": "id",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "default-expand-all": false,
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类名称", prop: "cnName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.level == 2
                      ? _c("span", [_vm._v(" ┗")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.level >= 3
                      ? _c("span", [_vm._v(" ┝")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.cnName))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              width: "200px",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "开启状态",
              align: "center",
              width: "150px",
              prop: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function ($event) {
                          return _vm.statusChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "排序",
              width: "150px",
              align: "center",
              prop: "sort",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", min: 1, label: "修改排序" },
                      on: {
                        change: function ($event) {
                          return _vm.sortChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
              width: "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdate(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑" : "新增",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "100%", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.shopCate,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "父ID", prop: "ptid" } },
                [
                  _c("el-cascader-panel", {
                    staticStyle: { width: "60%" },
                    attrs: { options: _vm.shopCateOptions, props: _vm.props },
                    model: {
                      value: _vm.shopCate.ptid,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopCate, "ptid", $$v)
                      },
                      expression: "shopCate.ptid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类中文名称", prop: "cnName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { placeholder: "请输入中文名称" },
                    model: {
                      value: _vm.extractedValue,
                      callback: function ($$v) {
                        _vm.extractedValue = $$v
                      },
                      expression: "extractedValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类英文名称", prop: "enName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { placeholder: "请输入英文名称" },
                    model: {
                      value: _vm.shopCate.enName,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopCate, "enName", $$v)
                      },
                      expression: "shopCate.enName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "200px" },
                    attrs: { min: 1, label: "修改排序" },
                    model: {
                      value: _vm.shopCate.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopCate, "sort", $$v)
                      },
                      expression: "shopCate.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }