import _objectSpread from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSpecialUseDescPage, addSpecialUseDesc, updateSpecialUseDesc, deleteSpecialUseDesc } from '@/api/special/useDesc';
export default {
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      dialogFormVisible: false,
      dialogType: 'add',
      form: {
        title: '',
        config: '',
        flow: '',
        internet: '',
        sort: 0
      },
      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        config: [{
          required: true,
          message: '请输入配置',
          trigger: 'blur'
        }],
        flow: [{
          required: true,
          message: '请输入流量',
          trigger: 'blur'
        }],
        internet: [{
          required: true,
          message: '请输入带宽',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '请输入排序',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;
      getSpecialUseDescPage(this.listQuery).then(function (response) {
        var _response$data = response.data,
          list = _response$data.list,
          total = _response$data.total,
          pageNum = _response$data.pageNum,
          pageSize = _response$data.pageSize;
        _this.list = list;
        _this.total = total;
        _this.listQuery.pageNum = pageNum;
        _this.listQuery.pageSize = pageSize;
      });
    },
    handleCreate: function handleCreate() {
      this.dialogType = 'add';
      this.dialogFormVisible = true;
      this.form = {
        title: '',
        config: '',
        flow: '',
        internet: '',
        sort: 0
      };
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogType = 'edit';
      this.dialogFormVisible = true;
      this.form = _objectSpread({}, row);
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;
      this.$confirm('确认删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteSpecialUseDesc(row.id).then(function () {
          _this2.$message.success('删除成功');
          _this2.fetchList();
        });
      }).catch(function () {
        // do nothing
      });
    },
    confirm: function confirm() {
      var _this3 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          if (_this3.dialogType === 'add') {
            addSpecialUseDesc(_this3.form).then(function () {
              _this3.$message.success('新增成功');
              _this3.dialogFormVisible = false;
              _this3.fetchList();
            });
          } else if (_this3.dialogType === 'edit') {
            updateSpecialUseDesc(_this3.form).then(function () {
              _this3.$message.success('修改成功');
              _this3.dialogFormVisible = false;
              _this3.fetchList();
            });
          }
        }
      });
    },
    parseTime: function parseTime(time) {
      // 解析和格式化时间
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.fetchList();
    },
    handlePageChange: function handlePageChange(val) {
      this.listQuery.pageNum = val;
      this.fetchList();
    }
  }
};