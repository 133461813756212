import Layout from '@/layout';
var vipRouter = {
  path: '/shopvip',
  component: Layout,
  redirect: '/shopvip/type',
  name: 'Shopvip',
  alwaysShow: true,
  meta: {
    title: '子账号会员',
    icon: 'food-list'
  },
  children: [{
    path: 'shopvipmanage',
    component: function component() {
      return import('@/views/shopvip/manage/index');
    },
    name: 'Shopvipmanage',
    meta: {
      title: '子账号管理',
      role: []
    }
  }, {
    path: 'shopviptype',
    component: function component() {
      return import('@/views/shopvip/type/index');
    },
    name: 'ShopvipVipType',
    meta: {
      title: '子账号会员配置',
      role: []
    }
  }, {
    path: 'shopviporder',
    component: function component() {
      return import('@/views/shopvip/order/index');
    },
    name: 'ShopvipOrder',
    meta: {
      title: '子账号会员订单',
      role: []
    }
  }, {
    path: 'shopvipmember',
    component: function component() {
      return import('@/views/shopvip/member/index');
    },
    name: 'Shopvipmember',
    meta: {
      title: '子账号会员',
      role: []
    }
  }, {
    path: 'shopvipaccountInfo',
    component: function component() {
      return import('@/views/shopvip/manage/accountInfo');
    },
    name: 'ShopvipaccountInfo',
    meta: {
      title: '子账号详情',
      role: []
    },
    hidden: true
  }]
};
export default vipRouter;