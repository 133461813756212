"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SingleImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      tempUrl: '',
      uploadUrl: process.env.VUE_APP_BASE_API + "system/upload",
      dataObj: {
        token: '',
        key: ''
      }
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value;
    }
  },
  methods: {
    rmImage: function rmImage() {
      this.emitInput('');
    },
    emitInput: function emitInput(val) {
      this.$emit('input', val);
    },
    handleImageSuccess: function handleImageSuccess(result) {
      this.emitInput(result.data.imgUrl);
    }
  }
};
exports.default = _default;