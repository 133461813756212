"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _list = require("@/api/customer/list");
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Upload: _singleImage.default
  },
  data: function data() {
    return {
      list: [],
      dialogVisible: false,
      form: {
        id: "",
        nickName: "",
        headImg: "",
        wechat: "",
        phone: ""
      },
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      var params = {
        page: this.currentPage,
        pageSize: this.pageSize
      };
      (0, _list.getCustomerServicePage)(params).then(function (response) {
        var _response$data = response.data,
          list = _response$data.list,
          pageNum = _response$data.pageNum,
          pageSize = _response$data.pageSize,
          total = _response$data.total;
        _this.list = list;
        _this.currentPage = pageNum;
        _this.pageSize = pageSize;
        _this.total = total;
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleAdd: function handleAdd() {
      this.form.id = "";
      this.form.nickName = "";
      this.form.headImg = "";
      this.form.wechat = "";
      this.form.phone = "";
      this.dialogVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form.id = row.id;
      this.form.nickName = row.nickName;
      this.form.headImg = row.headImg;
      this.form.wechat = row.wechat;
      this.form.phone = row.phone;
      this.dialogVisible = true;
    },
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      (0, _list.deleteCustomerService)(id).then(function () {
        _this2.fetchData();
        _this2.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    save: function save() {
      var _this3 = this;
      if (this.form.id) {
        // 修改
        (0, _list.updateCustomerService)(this.form).then(function () {
          _this3.fetchData();
          _this3.dialogVisible = false;
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        // 新增
        (0, _list.addCustomerService)(this.form).then(function () {
          _this3.fetchData();
          _this3.dialogVisible = false;
        }).catch(function (error) {
          console.error(error);
        });
      }
    },
    cancel: function cancel() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;