var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v("新增")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", align: "center", width: "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "nickName", label: "昵称", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "headImg", label: "头像", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: { src: scope.row.headImg, alt: "头像" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "wechat", label: "微信号", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "电话号码", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-delete",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.pageSize,
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "编辑客服",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "昵称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nickName", $$v)
                      },
                      expression: "form.nickName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "头像" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.form.headImg,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "headImg", $$v)
                      },
                      expression: "form.headImg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "微信号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.wechat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "wechat", $$v)
                      },
                      expression: "form.wechat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电话号码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }