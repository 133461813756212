"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMemberBlock = addMemberBlock;
exports.addMemberPunishment = addMemberPunishment;
exports.addSystemBlock = addSystemBlock;
exports.deleteMemberBank = deleteMemberBank;
exports.deleteMemberBlock = deleteMemberBlock;
exports.deleteMemberGroup = deleteMemberGroup;
exports.deleteMemberPunishment = deleteMemberPunishment;
exports.deleteMemberRealname = deleteMemberRealname;
exports.deleteOperationLog = deleteOperationLog;
exports.deleteSystemBlock = deleteSystemBlock;
exports.getMember = getMember;
exports.getMemberAccount = getMemberAccount;
exports.getMemberBankList = getMemberBankList;
exports.getMemberBlockList = getMemberBlockList;
exports.getMemberDetail = getMemberDetail;
exports.getMemberGroupList = getMemberGroupList;
exports.getMemberGroupSelector = getMemberGroupSelector;
exports.getMemberList = getMemberList;
exports.getMemberPunishmentList = getMemberPunishmentList;
exports.getMemberRealnameList = getMemberRealnameList;
exports.getOperationLogList = getOperationLogList;
exports.getShopDetail = getShopDetail;
exports.getSjList = getSjList;
exports.getSystemBlockList = getSystemBlockList;
exports.getZtList = getZtList;
exports.importBlock = importBlock;
exports.limitWithdraw = limitWithdraw;
exports.removeSystemBlock = removeSystemBlock;
exports.sendMsg = sendMsg;
exports.transferAmount = transferAmount;
exports.updateInvite = updateInvite;
exports.updateMember = updateMember;
exports.updateMemberAccount = updateMemberAccount;
exports.updateMemberBank = updateMemberBank;
exports.updateMemberGroup = updateMemberGroup;
exports.updateMemberGroupStatus = updateMemberGroupStatus;
exports.updateMemberRealname = updateMemberRealname;
exports.verifyMemberBank = verifyMemberBank;
exports.verifyMemberRealname = verifyMemberRealname;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMemberGroupList(query) {
  return (0, _request.default)({
    url: '/system/memberGroup/getList',
    method: 'get',
    params: query
  });
}
function getMemberGroupSelector(query) {
  return (0, _request.default)({
    url: '/system/memberGroup/getSelector',
    method: 'get',
    params: query
  });
}
function updateMemberGroup(data) {
  return (0, _request.default)({
    url: '/system/memberGroup/editSubmit',
    method: 'post',
    data: data
  });
}
function updateMemberGroupStatus(data) {
  return (0, _request.default)({
    url: '/system/memberGroup/updateStatus',
    method: 'post',
    data: data
  });
}
function deleteMemberGroup(data) {
  return (0, _request.default)({
    url: '/system/memberGroup/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getMemberList(query) {
  return (0, _request.default)({
    url: '/system/member/getList',
    method: 'get',
    params: query
  });
}
function getMember(query) {
  return (0, _request.default)({
    url: '/system/member/get',
    method: 'get',
    params: query
  });
}
function getMemberDetail(query) {
  return (0, _request.default)({
    url: '/system/member/getDetail',
    method: 'get',
    params: query
  });
}
function getShopDetail(query) {
  return (0, _request.default)({
    url: '/system/shop/getDetail',
    method: 'get',
    params: query
  });
}
function updateMember(data) {
  return (0, _request.default)({
    url: '/system/member/editSubmit',
    method: 'post',
    data: data
  });
}
function updateMemberAccount(data) {
  return (0, _request.default)({
    url: '/system/member/updateAccount',
    method: 'post',
    data: data
  });
}
function getMemberRealnameList(query) {
  return (0, _request.default)({
    url: '/system/memberRealname/getList',
    method: 'get',
    params: query
  });
}
function verifyMemberRealname(data) {
  return (0, _request.default)({
    url: '/system/memberRealname/verify',
    method: 'post',
    data: data
  });
}
function updateMemberRealname(data) {
  return (0, _request.default)({
    url: '/system/memberRealname/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteMemberRealname(data) {
  return (0, _request.default)({
    url: '/system/memberRealname/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getMemberBankList(query) {
  return (0, _request.default)({
    url: '/system/memberBank/getList',
    method: 'get',
    params: query
  });
}
function verifyMemberBank(data) {
  return (0, _request.default)({
    url: '/system/memberBank/verify',
    method: 'post',
    data: data
  });
}
function updateMemberBank(data) {
  return (0, _request.default)({
    url: '/system/memberBank/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteMemberBank(data) {
  return (0, _request.default)({
    url: '/system/memberBank/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getMemberBlockList(query) {
  return (0, _request.default)({
    url: '/system/memberBlock/getList',
    method: 'get',
    params: query
  });
}
function addMemberBlock(data) {
  return (0, _request.default)({
    url: '/system/memberBlock/blockin',
    method: 'post',
    data: data
  });
}
function deleteMemberBlock(data) {
  return (0, _request.default)({
    url: '/system/memberBlock/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getSystemBlockList(query) {
  return (0, _request.default)({
    url: '/system/systemBlock/getList',
    method: 'get',
    params: query
  });
}
function addSystemBlock(data) {
  return (0, _request.default)({
    url: '/system/systemBlock/add',
    method: 'post',
    data: data
  });
}
function removeSystemBlock(data) {
  return (0, _request.default)({
    url: '/system/systemBlock/remove',
    method: 'post',
    data: data
  });
}
function deleteSystemBlock(data) {
  return (0, _request.default)({
    url: '/system/systemBlock/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function importBlock(data) {
  return (0, _request.default)({
    url: '/system/systemBlock/importByExcel',
    method: 'post',
    data: data
  });
}
function getOperationLogList(query) {
  return (0, _request.default)({
    url: '/system/memberOperation/getList',
    method: 'get',
    params: query
  });
}
function deleteOperationLog(data) {
  return (0, _request.default)({
    url: '/system/memberOperation/delete',
    method: 'post',
    data: data
  });
}
function getMemberPunishmentList(query) {
  return (0, _request.default)({
    url: '/system/memberPunishment/getList',
    method: 'get',
    params: query
  });
}
function addMemberPunishment(data) {
  return (0, _request.default)({
    url: '/system/memberPunishment/add',
    method: 'post',
    data: data
  });
}
function deleteMemberPunishment(data) {
  return (0, _request.default)({
    url: '/system/memberPunishment/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getZtList(query) {
  return (0, _request.default)({
    url: '/system/member/getZtList',
    method: 'get',
    params: query
  });
}
function getSjList(query) {
  return (0, _request.default)({
    url: '/system/member/getSjList',
    method: 'get',
    params: query
  });
}
function updateInvite(data) {
  return (0, _request.default)({
    url: '/system/member/updateInvite',
    method: 'post',
    data: data
  });
}
function transferAmount(data) {
  return (0, _request.default)({
    url: '/system/member/transferAmount',
    method: 'post',
    data: data
  });
}
function sendMsg(data) {
  return (0, _request.default)({
    url: '/system/member/sendMsg',
    method: 'post',
    data: data
  });
}
function limitWithdraw(data) {
  return (0, _request.default)({
    url: '/system/member/limitWithdraw',
    method: 'post',
    data: data
  });
}
function getMemberAccount(query) {
  return (0, _request.default)({
    url: 'system/getAccount',
    method: 'get',
    params: query
  });
}