var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "goods_zhutu_list", attrs: { id: "goods_zhutu_list" } },
        [
          _vm._l(_vm.new_imgUrls, function (item, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "goods_zhutu_list_son" },
              [
                _c("el-button", {
                  staticClass: "goods_zhutu_list_but goods_zhutu_list_but_2",
                  attrs: { icon: "el-icon-zoom-in", circle: "", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.handlePictureCardPreview(item)
                    },
                  },
                }),
                _vm._v(" "),
                _c("el-button", {
                  staticClass: "goods_zhutu_list_but",
                  attrs: { icon: "el-icon-delete", circle: "", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.delete_img(idx)
                    },
                  },
                }),
                _vm._v(" "),
                _c("el-image", {
                  staticClass: "sort_img",
                  staticStyle: {
                    width: "145px",
                    height: "145px",
                    "margin-left": "20px",
                  },
                  attrs: { src: item, fit: "contain" },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                action: _vm.uploadUrl,
                "list-type": "picture-card",
                "before-upload": _vm.beforeAvatarUpload,
                "on-success": _vm.handleImageSuccess,
                multiple: "",
                "show-file-list": false,
              },
            },
            [
              _c("span", { staticClass: "icon-box" }, [
                _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      display: "inline-block",
                    },
                  },
                  [_vm._v("添加图片")]
                ),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": true },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }