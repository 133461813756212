"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecial = addSpecial;
exports.deleteSpecial = deleteSpecial;
exports.getSpecialAreaList = getSpecialAreaList;
exports.getSpecialDurationList = getSpecialDurationList;
exports.getSpecialList = getSpecialList;
exports.getSpecialPage = getSpecialPage;
exports.getSpecialResourcesList = getSpecialResourcesList;
exports.getSpecialTypeList = getSpecialTypeList;
exports.getSpecialUseDescList = getSpecialUseDescList;
exports.updateSpecial = updateSpecial;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialPage(data) {
  return (0, _requestJson.default)({
    url: '/special/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialList(data) {
  return (0, _requestJson.default)({
    url: '/special/getList',
    method: 'get',
    params: data
  });
}
function addSpecial(data) {
  return (0, _requestJson.default)({
    url: '/special/save',
    method: 'post',
    data: data
  });
}
function updateSpecial(data) {
  return (0, _requestJson.default)({
    url: '/special/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecial(id) {
  return (0, _requestJson.default)({
    url: "/special/deleteById/".concat(id),
    method: 'post'
  });
}
function getSpecialAreaList(data) {
  return (0, _requestJson.default)({
    url: '/special/area/getList',
    method: 'get',
    params: data
  });
}
function getSpecialDurationList(data) {
  return (0, _requestJson.default)({
    url: '/special/duration/getList',
    method: 'get',
    params: data
  });
}
function getSpecialResourcesList(data) {
  return (0, _requestJson.default)({
    url: '/special/resources/getList',
    method: 'get',
    params: data
  });
}
function getSpecialTypeList(data) {
  return (0, _requestJson.default)({
    url: '/special/type/getList',
    method: 'get',
    params: data
  });
}
function getSpecialUseDescList(data) {
  return (0, _requestJson.default)({
    url: '/special/useDesc/getList',
    method: 'get',
    params: data
  });
}