var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
        [_vm._v("新增")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "title", label: "标题" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "config", label: "配置" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "flow", label: "流量" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "internet", label: "带宽" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "sort", label: "排序", width: "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "addTime", label: "添加时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.parseTime(scope.row.addTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "updTime", label: "更新时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.parseTime(scope.row.updTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticStyle: { "margin-top": "20px", "text-align": "right" },
        attrs: {
          "current-page": _vm.listQuery.pageNum,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.listQuery.pageSize,
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handlePageChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            title: _vm.dialogType === "add" ? "新增" : "编辑",
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.dialogFormVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "配置", prop: "config" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入配置" },
                    model: {
                      value: _vm.form.config,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "config", $$v)
                      },
                      expression: "form.config",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "流量", prop: "flow" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入流量" },
                    model: {
                      value: _vm.form.flow,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "flow", $$v)
                      },
                      expression: "form.flow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "互联网", prop: "internet" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入互联网" },
                    model: {
                      value: _vm.form.internet,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "internet", $$v)
                      },
                      expression: "form.internet",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }