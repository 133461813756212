var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "70px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品类名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品类名称", clearable: "" },
                        model: {
                          value: _vm.searchTypeName,
                          callback: function ($$v) {
                            _vm.searchTypeName = $$v
                          },
                          expression: "searchTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品名称", clearable: "" },
                        model: {
                          value: _vm.searchTitle,
                          callback: function ($$v) {
                            _vm.searchTitle = $$v
                          },
                          expression: "searchTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SKU名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入SKU名称", clearable: "" },
                        model: {
                          value: _vm.searchSkuTitle,
                          callback: function ($$v) {
                            _vm.searchSkuTitle = $$v
                          },
                          expression: "searchSkuTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "请选择审核状态",
                            size: "mini",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "auditStatus", $$v)
                            },
                            expression: "listQuery.auditStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "0",
                            attrs: { label: "未申请", value: 0 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "1",
                            attrs: { label: "待审核", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "2",
                            attrs: { label: "已通过", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "3",
                            attrs: { label: "已拒绝", value: 3 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "4",
                            attrs: { label: "待完善", value: 4 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上架状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "请选择上架状态",
                            size: "mini",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "0",
                            attrs: { label: "下架", value: 0 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "1",
                            attrs: { label: "上架", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n            查询\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("\n            新增\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            title: "提示",
                            trigger: "manual",
                            content: "选择产品点击这里进行上下架操作",
                          },
                          model: {
                            value: _vm.highlightBtn1,
                            callback: function ($$v) {
                              _vm.highlightBtn1 = $$v
                            },
                            expression: "highlightBtn1",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                slot: "reference",
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-edit",
                              },
                              on: { click: _vm.handleStatus },
                              slot: "reference",
                            },
                            [_vm._v("\n              上下架\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "input",
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.excel_action,
                            "show-file-list": false,
                            "auto-upload": true,
                            "on-success": _vm.excel_upload_success,
                            type: "file",
                            headers: _vm.headers,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-upload",
                                loading: _vm.excel_loading,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.excel_loading
                                      ? "导入中"
                                      : "导入Excel模版"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleBatchExport },
                        },
                        [_vm._v("\n            导出sku\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downloadFun(
                                "tkBatchCreateProductTemplate_01.xlsx"
                              )
                            },
                          },
                        },
                        [_vm._v("下载模板\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleShare },
                        },
                        [_vm._v("\n            分享链接\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.productList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "45",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "title", label: "产品标题", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "typeName", label: "品类名称", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrls",
              label: "封面图",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "50px", width: "50px" },
                      attrs: {
                        src: scope.row.imgUrls[0],
                        "preview-src-list": [scope.row.imgUrls[0]],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.status ? "上架" : "下架") +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "isSalesPromotion",
              label: "是否促销",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      staticStyle: { display: "block" },
                      attrs: { "active-text": "是", "inactive-text": "否" },
                      on: {
                        change: function (val) {
                          _vm.handleModifyIsSalesPromotion(scope.row, val)
                        },
                      },
                      model: {
                        value: scope.row.isSalesPromotion,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "isSalesPromotion", $$v)
                        },
                        expression: "scope.row.isSalesPromotion",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "auditStatus", label: "审核状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.auditStatus === 1
                      ? _c("span", [_vm._v("待审核")])
                      : scope.row.auditStatus === 2
                      ? _c("span", [_vm._v("已通过")])
                      : scope.row.auditStatus === 3
                      ? _c("span", [_vm._v("已拒绝")])
                      : scope.row.auditStatus === 0
                      ? _c("span", [_vm._v("未申请")])
                      : scope.row.auditStatus === 4
                      ? _c("span", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("待完善"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "auditReason", label: "审核原因", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.auditReason) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "class-name": "small-padding",
              fixed: "right",
              width: "200",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n            编辑\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleApplyAudit(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n            提交审核\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "red" },
                        attrs: {
                          type: "text",
                          icon: "el-icon-delete",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n            删除\n          ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑产品" : "新增产品",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "95%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "productForm",
              attrs: {
                model: _vm.productForm,
                rules: _vm.rules,
                "label-width": "110px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { height: "620px" },
                  attrs: { "tab-position": "left" },
                },
                [
                  _c(
                    "el-tab-pane",
                    { staticClass: "eltabpane", attrs: { label: "基本信息" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品标题", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产品标题" },
                            model: {
                              value: _vm.productForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "title", $$v)
                              },
                              expression: "productForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "sellerSku" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产品名称" },
                            model: {
                              value: _vm.productForm.sellerSku,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "sellerSku", $$v)
                              },
                              expression: "productForm.sellerSku",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品中文名称", prop: "typeName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产品中文名称" },
                            model: {
                              value: _vm.productForm.typeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "typeName", $$v)
                              },
                              expression: "productForm.typeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "封面图", prop: "imgUrls" } },
                        [
                          _vm.SortableUpload_sw
                            ? _c("dragUpload", {
                                attrs: { imgUrls: _vm.productForm.imgUrls },
                                on: { emit_picture: _vm.emit_picture },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品介绍", prop: "intro" } },
                        [
                          _c("WangEnduit", {
                            attrs: { isClear: _vm.isClear },
                            on: {
                              change: _vm.changeEditor,
                              changeClear: function ($event) {
                                return _vm.$emit("changeClear")
                              },
                            },
                            model: {
                              value: _vm.productForm.intro,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "intro", $$v)
                              },
                              expression: "productForm.intro",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否促销",
                            prop: "isSalesPromotion",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.productForm.isSalesPromotion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "isSalesPromotion",
                                  $$v
                                )
                              },
                              expression: "productForm.isSalesPromotion",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地", prop: "place" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产地" },
                            model: {
                              value: _vm.productForm.place,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "place", $$v)
                              },
                              expression: "productForm.place",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "素材链接", prop: "link" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入素材链接" },
                            model: {
                              value: _vm.productForm.link,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "link", $$v)
                              },
                              expression: "productForm.link",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用途", prop: "desc" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入用途" },
                            model: {
                              value: _vm.productForm.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "desc", $$v)
                              },
                              expression: "productForm.desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { staticClass: "eltabpane", attrs: { label: "SKU 信息" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "SKU列表" } },
                        [
                          _vm.Skulist_sw
                            ? _c("Skulist", {
                                attrs: {
                                  variations: _vm.productForm.variations,
                                  dialogType_sw: _vm.dialogType,
                                  productForm_id: _vm.productForm.id,
                                },
                                on: { add_deleteById: _vm.add_deleteById },
                                model: {
                                  value: _vm.productForm.skuList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.productForm, "skuList", $$v)
                                  },
                                  expression: "productForm.skuList",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "eltabpane",
                      attrs: { label: "Tiktok 信息" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "isTiktok" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "确认后后续可推送至tiktok",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-form-item__lable" },
                                        [_vm._v("是否能推送tiktok")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.productForm.isTiktok,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "isTiktok", $$v)
                              },
                              expression: "productForm.isTiktok",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "cateIds",
                            label: "产品类目",
                            rules: [
                              {
                                required: false,
                                message: "请选择",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "60%" },
                            attrs: {
                              props: _vm.props,
                              placeholder: "试试搜索",
                              options: _vm.shopCateOptions,
                              filterable: "",
                            },
                            on: { change: _vm.handleCascaderChange },
                            model: {
                              value: _vm.productForm.shopCateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "shopCateId", $$v)
                              },
                              expression: "productForm.shopCateId",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push("/product/cate")
                                },
                              },
                            },
                            [_vm._v("新建分类")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: { click: _vm.getShopCateList },
                            },
                            [_vm._v("刷新")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "品牌", prop: "brand" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入品牌" },
                            model: {
                              value: _vm.productForm.brand,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "brand", $$v)
                              },
                              expression: "productForm.brand",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.conditionalConfigList,
                        function (field, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: field.fieldName } },
                                [
                                  field.fieldType === 1
                                    ? [
                                        _c("el-input", {
                                          staticStyle: { width: "60%" },
                                          model: {
                                            value: field.value,
                                            callback: function ($$v) {
                                              _vm.$set(field, "value", $$v)
                                            },
                                            expression: "field.value",
                                          },
                                        }),
                                      ]
                                    : field.fieldType === 2
                                    ? [
                                        _c(
                                          "el-radio-group",
                                          {
                                            on: {
                                              input: function (val) {
                                                _vm.add_radio(index, val)
                                              },
                                            },
                                            model: {
                                              value: field.img_pdf_sw,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  field,
                                                  "img_pdf_sw",
                                                  $$v
                                                )
                                              },
                                              expression: "field.img_pdf_sw",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("上传图片")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("上传PDF")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        field.img_pdf_sw == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _vm._l(
                                                  field.value,
                                                  function (item, idx) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: idx,
                                                        staticClass:
                                                          "goods_zhutu_list_son",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          staticClass:
                                                            "goods_zhutu_list_but",
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.delete_img(
                                                                field.value,
                                                                idx
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-image", {
                                                          staticClass:
                                                            "sort_img",
                                                          staticStyle: {
                                                            width: "100px",
                                                            height: "100px",
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                          attrs: {
                                                            src: item,
                                                            fit: "contain",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                field.value.length < 3
                                                  ? _c(
                                                      "el-upload",
                                                      {
                                                        staticClass:
                                                          "avatar-uploader",
                                                        attrs: {
                                                          "before-upload":
                                                            _vm.fileProgress,
                                                          multiple: false,
                                                          "show-file-list": false,
                                                          "on-success":
                                                            _vm.handleImageSuccess,
                                                          action: _vm.uploadUrl,
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.importimg(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-plus avatar-uploader-icon",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        field.img_pdf_sw == 2
                                          ? _c("UploadPdf", {
                                              model: {
                                                value: field.value,
                                                callback: function ($$v) {
                                                  _vm.$set(field, "value", $$v)
                                                },
                                                expression: "field.value",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    : field.fieldType === 3
                                    ? [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "60%" },
                                            attrs: { placeholder: "请选择" },
                                            model: {
                                              value:
                                                _vm.conditionalConfigList[index]
                                                  .value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.conditionalConfigList[
                                                    index
                                                  ],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "conditionalConfigList[index].value",
                                            },
                                          },
                                          _vm._l(
                                            field.options.split(","),
                                            function (option) {
                                              return _c("el-option", {
                                                key: option,
                                                attrs: {
                                                  label: option,
                                                  value: option,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading_01 },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "链接分享",
            visible: _vm.handleShareVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.handleShareVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm._v("\n        " + _vm._s(_vm.handleShareUlr) + "\n      "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("Cropper", {
        ref: "cropper1",
        attrs: {
          show_cropper: _vm.show_cropper,
          id: "avater_id_2",
          img_url: _vm.img_url,
        },
        on: {
          InitCropper: _vm.InitCropper,
          CloseCropper: _vm.CloseCropper,
          SaveCallBack: _vm.SaveCallBack,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }