var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                      },
                      attrs: { label: "重量类型：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择重量类型",
                            size: "mini",
                          },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.queryWeightType,
                            callback: function ($$v) {
                              _vm.queryWeightType = $$v
                            },
                            expression: "queryWeightType",
                          },
                        },
                        _vm._l(_vm.weightTypeList, function (api) {
                          return _c("el-option", {
                            key: api.id,
                            attrs: { value: api.id, label: api.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "重量：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: {
                          oninput: "value=value.replace(/[^\\d]/g, '')",
                          placeholder: "请输入重量",
                          clearable: "",
                        },
                        model: {
                          value: _vm.weight,
                          callback: function ($$v) {
                            _vm.weight = $$v
                          },
                          expression: "weight",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.addHandle },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "id", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.id))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.weight))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重量单位", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.weightType === 0
                      ? _c("span", [_vm._v("OZ")])
                      : scope.row.weightType === 1
                      ? _c("span", [_vm._v("LB")])
                      : scope.row.weightType === 2
                      ? _c("span", [_vm._v("其他")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "预计运费", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.price))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.addOrUpdateRow,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "重量类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "请选择", clearable: true },
                          model: {
                            value: _vm.addOrUpdateRow.weightType,
                            callback: function ($$v) {
                              _vm.$set(_vm.addOrUpdateRow, "weightType", $$v)
                            },
                            expression: "addOrUpdateRow.weightType",
                          },
                        },
                        _vm._l(_vm.weightTypeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预计运费", prop: "declarePrice" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入预计运费",
                          oninput:
                            "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                        },
                        model: {
                          value: _vm.addOrUpdateRow.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrUpdateRow, "price", $$v)
                          },
                          expression: "addOrUpdateRow.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "重量", prop: "declarePrice" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入重量",
                          oninput: "value=value.replace(/[^\\d]/g, '')",
                        },
                        model: {
                          value: _vm.addOrUpdateRow.weight,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrUpdateRow, "weight", $$v)
                          },
                          expression: "addOrUpdateRow.weight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }