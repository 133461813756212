import Layout from '@/layout';
var shipRouter = {
  path: '/usaaddress',
  component: Layout,
  redirect: '/usaaddress',
  name: 'Usaaddress',
  alwaysShow: true,
  meta: {
    title: '美国地址库',
    icon: 'report-brand'
  },
  children: [{
    path: 'state',
    component: function component() {
      return import('@/views/usaaddress/state/index');
    },
    name: 'State',
    meta: {
      title: '州',
      role: []
    }
  }, {
    path: 'city',
    component: function component() {
      return import('@/views/usaaddress/city/index');
    },
    name: 'City',
    meta: {
      title: '城市',
      role: []
    }
  }, {
    path: 'zipcode',
    component: function component() {
      return import('@/views/usaaddress/zipcode/index');
    },
    name: 'Zipcode',
    meta: {
      title: '邮编',
      role: []
    }
  }, {
    path: 'supplier',
    component: function component() {
      return import('@/views/usaaddress/supplier/index');
    },
    name: 'supplier',
    meta: {
      title: '供应商配置',
      role: []
    }
  }]
};
export default shipRouter;