//
//
//
//
//
//
//
//
//
//
//
//
//
//

import goodsDialog from "./goods-dialog";
import linkDialog from "./link-dialog";
export default {
  components: {
    goodsDialog: goodsDialog,
    linkDialog: linkDialog
  },
  data: function data() {
    return {
      goodsFlag: false,
      // 是否商品选择器
      goodsData: [],
      //选择的商品
      linkData: "",
      //选择的链接
      flag: false // modal显隐
    };
  },

  methods: {
    clearGoodsSelected: function clearGoodsSelected() {
      this.goodsData = [];
    },
    // 关闭弹窗
    clickClose: function clickClose() {
      this.$emit("closeFlag", false);
      this.goodsFlag = false;
      this.close();
    },
    // 单选商品
    singleGoods: function singleGoods() {
      var _this = this;
      var timer = setInterval(function () {
        if (_this.$refs.goodsDialog) {
          _this.$refs.goodsDialog.type = "single";
          clearInterval(timer);
        }
      }, 100);
    },
    // 点击确认
    clickOK: function clickOK() {
      if (this.goodsFlag) {
        this.$emit("selectedGoodsData", this.goodsData);
      } else {
        this.$emit("selectedLink", this.linkData);
      }
      this.clickClose();
    },
    // 打开组件方法
    open: function open(type, mutiple) {
      this.flag = true;
      if (type == "goods") {
        this.goodsFlag = true;
        if (mutiple) {
          this.singleGoods();
        }
      } else {
        this.goodsFlag = false;
      }
    },
    // 关闭组件
    close: function close() {
      this.flag = false;
    }
  }
};