import _objectSpread from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSpecialPage, addSpecial, updateSpecial, deleteSpecial, getSpecialAreaList, getSpecialDurationList, getSpecialResourcesList, getSpecialTypeList, getSpecialUseDescList } from '@/api/special/goods';
export default {
  data: function data() {
    return {
      tableData: [],
      // 表格数据
      currentPage: 1,
      // 当前页码
      pageSize: 10,
      // 每页显示条数
      total: 0,
      // 总条目数
      areaList: [],
      // 区域列表
      durationList: [],
      // 时长列表
      resourceList: [],
      // 资源列表
      typeList: [],
      // 类型列表
      useDescList: [],
      // 用途描述列表
      form: {
        // 表单数据
        id: '',
        areaId: '',
        durationId: '',
        resourceId: '',
        typeId: '',
        useDescId: '',
        price: ''
      },
      rules: {
        // 表单验证规则
        areaId: [{
          required: false,
          message: '请选择区域',
          trigger: 'change'
        }],
        durationId: [{
          required: false,
          message: '请选择时长',
          trigger: 'change'
        }],
        resourceId: [{
          required: false,
          message: '请选择资源',
          trigger: 'change'
        }],
        typeId: [{
          required: false,
          message: '请选择类型',
          trigger: 'change'
        }],
        useDescId: [{
          required: false,
          message: '请选择用途描述',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入价格',
          trigger: 'blur'
        }]
      },
      dialogFormVisible: false // 对话框可见性
    };
  },
  mounted: function mounted() {
    this.fetchData();
    this.fetchAreaList();
    this.fetchDurationList();
    this.fetchResourceList();
    this.fetchTypeList();
    this.fetchUseDescList();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      var params = {
        page: this.currentPage,
        pageSize: this.pageSize
      };
      getSpecialPage(params).then(function (response) {
        _this.tableData = response.data.list;
        _this.total = response.data.total;
      }).catch(function (error) {
        console.error('Failed to fetch special page:', error);
      });
    },
    fetchAreaList: function fetchAreaList() {
      var _this2 = this;
      getSpecialAreaList().then(function (response) {
        _this2.areaList = response.data;
      }).catch(function (error) {
        console.error('Failed to fetch area list:', error);
      });
    },
    fetchDurationList: function fetchDurationList() {
      var _this3 = this;
      getSpecialDurationList().then(function (response) {
        _this3.durationList = response.data;
      }).catch(function (error) {
        console.error('Failed to fetch duration list:', error);
      });
    },
    fetchResourceList: function fetchResourceList() {
      var _this4 = this;
      getSpecialResourcesList().then(function (response) {
        _this4.resourceList = response.data;
      }).catch(function (error) {
        console.error('Failed to fetch resource list:', error);
      });
    },
    fetchTypeList: function fetchTypeList() {
      var _this5 = this;
      getSpecialTypeList().then(function (response) {
        _this5.typeList = response.data;
      }).catch(function (error) {
        console.error('Failed to fetch type list:', error);
      });
    },
    fetchUseDescList: function fetchUseDescList() {
      var _this6 = this;
      getSpecialUseDescList().then(function (response) {
        _this6.useDescList = response.data;
      }).catch(function (error) {
        console.error('Failed to fetch useDesc list:', error);
      });
    },
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.form = _objectSpread({}, row);
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;
      var id = row.id;
      deleteSpecial(id).then(function () {
        _this7.$message.success('删除成功');
        _this7.fetchData();
      }).catch(function (error) {
        console.error('Failed to delete special:', error);
      });
    },
    resetForm: function resetForm() {
      this.$refs.dataForm.resetFields();
      this.form = _objectSpread(_objectSpread({}, this.form), {}, {
        id: ''
      });
      this.dialogFormVisible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this8 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          if (_this8.form.id) {
            // 编辑
            updateSpecial(_this8.form).then(function () {
              _this8.$message.success('编辑成功');
              _this8.fetchData();
              _this8.resetForm();
            }).catch(function (error) {
              console.error('Failed to update special:', error);
            });
          } else {
            // 新增
            addSpecial(_this8.form).then(function () {
              _this8.$message.success('新增成功');
              _this8.fetchData();
              _this8.resetForm();
            }).catch(function (error) {
              console.error('Failed to add special:', error);
            });
          }
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    }
  }
};