"use strict";

var _interopRequireWildcard = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("D:\\ideaProject\\tk-admin\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("D:\\ideaProject\\tk-admin\\node_modules\\core-js\\modules\\es6.promise.js");
require("D:\\ideaProject\\tk-admin\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("D:\\ideaProject\\tk-admin\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./permission");
require("./utils/errorLog");
var _socket = _interopRequireDefault(require("./socket"));
var _lemonImui = _interopRequireDefault(require("lemon-imui"));
require("lemon-imui/dist/index.css");
var _request = require("@/utils/request");
var filters = _interopRequireWildcard(require("./filters"));
var _lekDialog = _interopRequireDefault(require("@/views/lek-dialog"));
var _index3 = _interopRequireDefault(require("@/components/PageView/index.vue"));
var _index4 = _interopRequireDefault(require("./components/Space/index.vue"));
var _dateUtil = _interopRequireDefault(require("@/utils/dateUtil.js"));
var _permission2 = _interopRequireDefault(require("@/utils/permission"));
var _permissionCustom = _interopRequireDefault(require("@/utils/permissionCustom"));
var _permissionUser = _interopRequireDefault(require("@/utils/permissionUser"));
// A modern alternative to CSS resets

// global css

// Internationalization
// icon
// permission control
// error log

_vue.default.use(_lemonImui.default);

// global filters

/*挂载全局页面布局组件*/

_vue.default.component("pageView", _index3.default);
_vue.default.component('space', _index4.default);
// sdk处理视频

_vue.default.prototype.$dateUtil = _dateUtil.default;
_vue.default.prototype.download = _request.download;
// 全局按钮判断函数

_vue.default.prototype.$checkPermission = _permission2.default;
_vue.default.prototype.$checkPermissionCustom = _permissionCustom.default;
_vue.default.prototype.$checkPermissionUser = _permissionUser.default;

// import { mockXHR } from '../mock' // simulation data

// mock api in github pages site build
// if (process.env.NODE_ENV === 'production') { mockXHR() }
_vue.default.prototype.socket = _socket.default;
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.component('lekDialog', _lekDialog.default);

// 清除所有console.log
//window.console.log = function () { };

new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});