"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WarehouseList",
  components: {
    Pagination: _index.default
  },
  data: function data() {
    return {
      productName: "",
      // 搜索产品标题
      typeName: "",
      // 搜索产品名称
      skuTitle: "",
      // 搜索SKU名称
      skuTypeName: "",
      // 搜索SKU品类名称
      status: 1,
      //上架
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      warehouseDialogVisible: false,
      warehouseList: [],
      addInventoryDialogVisible: false,
      addForm: {
        skuId: null,
        // 这里将skuId设置为null，待具体行数据时再设置
        warehouseId: null,
        totalInv: null
      },
      warehouseOptions: [],
      // 用来存放仓库列表
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10
      },
      inventoryDialogVisible: false,
      inventoryDialogType: "add",
      // 'add'表示新增库存，'reduce'表示减少库存
      inventoryForm: {
        num: null // 数量字段
        // 其他字段...
      },

      status_arr: [{
        value: 1,
        label: "上架"
      }, {
        value: 0,
        label: "下架"
      }]
    };
  },
  created: function created() {
    this.getList();
    this.loadWarehouseOptions(); // 在created钩子中加载仓库列表
  },

  methods: {
    formatTitle: function formatTitle(title) {
      var maxLength = 100; // Adjust this value as needed
      if (title.length > maxLength) {
        return title.substring(0, maxLength) + "...";
      }
      return title;
    },
    handleSearch: function handleSearch() {
      // 在这里触发搜索操作，调用接口并传递搜索参数
      var params = {};
      this.listQuery.page = 1;
      this.listQuery.pageSize = 10;
      // 调用搜索接口并更新列表数据
      this.getList(params);
    },
    handleAdd: function handleAdd(row) {
      this.inventoryDialogType = "add";
      this.inventoryForm.id = row.id; // 设置需要操作的 SKU
      this.inventoryDialogVisible = true;
    },
    handleReduceInventory: function handleReduceInventory(row) {
      this.inventoryDialogType = "reduce";
      this.inventoryForm.id = row.id; // 设置需要操作的 SKU
      this.inventoryDialogVisible = true;
    },
    handleInventoryConfirm: function handleInventoryConfirm() {
      var _this = this;
      var method = this.inventoryDialogType === "add" ? _warehouse.invAdd : _warehouse.invReduce;
      method(this.inventoryForm).then(function (response) {
        if (response.code === 200) {
          _elementUi.Message.success("操作成功");
          _this.inventoryDialogVisible = false;
          // 刷新库存列表或执行其他操作
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    shenzhouInv: function shenzhouInv() {
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.shenzhouInv)().then(function (response) {
        if (response.code === 200) {
          _elementUi.Message.success("正在同步，请稍后查看库存");
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this2 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.warehouseGetList)().then(function (response) {
        if (response.code === 200) {
          _this2.warehouseOptions = response.data;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    getList: function getList() {
      var _this3 = this;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        productName: this.productName,
        typeName: this.typeName,
        skuTitle: this.skuTitle,
        skuTypeName: this.skuTypeName,
        status: this.status
      };
      this.listLoading = true;
      (0, _warehouse.warehousesInvGetPage)(params).then(function (response) {
        _this3.list = response.data.list;
        _this3.listLoading = false;
        _this3.total = response.data.total;
      });
    },
    handleShowWarehouse: function handleShowWarehouse(id) {
      var _this4 = this;
      this.addForm.skuId = id;
      (0, _warehouse.warehousesInvGetById)(id).then(function (response) {
        _this4.warehouseList = response.data.list;
        _this4.warehouseDialogVisible = true;
      });
    },
    handleAddInventory: function handleAddInventory() {
      // this.addForm.skuId = this.selectedRowId; // 设置skuId为选中行的id
      this.addInventoryDialogVisible = true;
    },
    handleAddInventoryConfirm: function handleAddInventoryConfirm() {
      var _this5 = this;
      // 调用 warehouseInvSave 方法进行新增库存操作
      console.log(this.addForm);
      (0, _warehouse.warehouseInvSave)(this.addForm).then(function (response) {
        if (response.code === 200) {
          _elementUi.Message.success("新增库存成功");
          _this5.addInventoryDialogVisible = false;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    }
  }
};
exports.default = _default;