"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addInformationList = addInformationList;
exports.deleteComment = deleteComment;
exports.deleteInformationList = deleteInformationList;
exports.getComments = getComments;
exports.getInformationList = getInformationList;
exports.getTypeList = getTypeList;
exports.updateCommentAudit = updateCommentAudit;
exports.updateInformationList = updateInformationList;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getInformationList(data) {
  return (0, _requestJson.default)({
    url: '/information/getPage',
    method: 'get',
    params: data
  });
}
function addInformationList(data) {
  return (0, _requestJson.default)({
    url: '/information/save',
    method: 'post',
    data: data
  });
}
function updateInformationList(data) {
  return (0, _requestJson.default)({
    url: '/information/updateById',
    method: 'post',
    data: data
  });
}
function deleteInformationList(id) {
  return (0, _requestJson.default)({
    url: "/information/deleteById/".concat(id),
    method: 'post'
  });
}
// 获取资讯类型typeId
function getTypeList() {
  return (0, _requestJson.default)({
    url: 'information/type/getList',
    method: 'get'
  });
}
function getComments(data) {
  return (0, _requestJson.default)({
    url: '/information/comment/getPage',
    method: 'get',
    params: data
  });
}
function updateCommentAudit(data) {
  return (0, _requestJson.default)({
    url: '/information/comment/audit',
    method: 'post',
    data: data
  });
}
function deleteComment(id) {
  return (0, _requestJson.default)({
    url: "/information/comment/deleteById/".concat(id),
    method: 'post'
  });
}