"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      linkList: [
      // 链接列表
      {
        title: "首页",
        icon: "dashboard",
        ___ptype: "home"
      }, {
        title: "购物车",
        icon: "shop-list",
        ___ptype: "cart"
      }, {
        title: "收藏商品",
        icon: "food-list",
        ___ptype: "collection"
      }, {
        title: "我的订单",
        icon: "order-list",
        ___ptype: "order"
      }, {
        title: "个人中心",
        icon: "user",
        ___ptype: "user"
      }, {
        title: "拼团频道",
        icon: "lock",
        ___ptype: "group"
      }, {
        title: "秒杀频道",
        icon: "list",
        ___ptype: "seckill"
      }, {
        title: "领券中心",
        icon: "promotion-coupon",
        ___ptype: "coupon"
      }, {
        title: "签到",
        icon: "report-brand",
        ___ptype: "sign"
      }, {
        title: "积分商城",
        icon: "example",
        ___type: "point"
      }
      /* {
        title: "小程序直播",
        icon: "ios-videocam",
        ___type: "live",
      },
      {
        title: "砍价",
        icon: "md-share-alt",
        ___type: "kanjia",
      },
      */],

      linkItem: {
        title: "外部链接",
        icon: "link",
        ___ptype: "link",
        url: ""
      },
      linkVisible: false,
      // 是否显示外部链接
      selectedIndex: 9999999 // 已选index
    };
  },

  methods: {
    handleLink: function handleLink(val, index) {
      val = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, val), {}, {
        ___type: "other"
      });
      this.selectedIndex = index;
      this.$emit("selected", [val]);
    }
  }
};
exports.default = _default;