var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleEdit },
            },
            [_vm._v("编辑")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "material-info" }, [
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("ID:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.material.id)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("标题:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.material.title)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("详情:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.material.detail)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("价格:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.material.price)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("创建时间:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.formatTime(_vm.material.addTime))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("修改时间:")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.formatTime(_vm.material.updTime))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "400px" },
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑" : "新增",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.form, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情", prop: "detail" } },
                [
                  _c("WangEnduit", {
                    attrs: { isClear: _vm.isClear },
                    on: {
                      change: _vm.changeEditor,
                      changeClear: function ($event) {
                        return _vm.$emit("changeClear")
                      },
                    },
                    model: {
                      value: _vm.form.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "detail", $$v)
                      },
                      expression: "form.detail",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格", prop: "price" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入价格" },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", _vm._n($$v))
                      },
                      expression: "form.price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }