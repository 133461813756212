"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteMaterialLib = deleteMaterialLib;
exports.getMaterialLibPage = getMaterialLibPage;
exports.saveMaterialLib = saveMaterialLib;
exports.updateMaterialLib = updateMaterialLib;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getMaterialLibPage(data) {
  return (0, _requestJson.default)({
    url: '/material/lib/getPage',
    method: 'get',
    params: data
  });
}
function saveMaterialLib(data) {
  return (0, _requestJson.default)({
    url: '/material/lib/save',
    method: 'post',
    data: data
  });
}
function updateMaterialLib(data) {
  return (0, _requestJson.default)({
    url: '/material/lib/updateById',
    method: 'post',
    data: data
  });
}
function deleteMaterialLib(id) {
  return (0, _requestJson.default)({
    url: "/material/lib/deleteById/".concat(id),
    method: 'post'
  });
}