"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var vipRouter = {
  path: '/shopvip',
  component: _layout.default,
  redirect: '/shopvip/type',
  name: 'Shopvip',
  alwaysShow: true,
  meta: {
    title: '子账号会员',
    icon: 'food-list'
  },
  children: [{
    path: 'shopvipmanage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopvip/manage/index'));
      });
    },
    name: 'Shopvipmanage',
    meta: {
      title: '子账号管理',
      role: []
    }
  }, {
    path: 'shopviptype',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopvip/type/index'));
      });
    },
    name: 'ShopvipVipType',
    meta: {
      title: '子账号会员配置',
      role: []
    }
  }, {
    path: 'shopviporder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopvip/order/index'));
      });
    },
    name: 'ShopvipOrder',
    meta: {
      title: '子账号会员订单',
      role: []
    }
  }, {
    path: 'shopvipmember',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopvip/member/index'));
      });
    },
    name: 'Shopvipmember',
    meta: {
      title: '子账号会员',
      role: []
    }
  }, {
    path: 'shopvipaccountInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shopvip/manage/accountInfo'));
      });
    },
    name: 'ShopvipaccountInfo',
    meta: {
      title: '子账号详情',
      role: []
    },
    hidden: true
  }]
};
var _default = vipRouter;
exports.default = _default;