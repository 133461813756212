import _objectSpread from "/root/workspace/tk-admin_Z7th/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from "@/components/RightPanel";
import { Navbar, Sidebar, AppMain, TagsView, Settings, Message } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: {
    RightPanel: RightPanel,
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain,
    TagsView: TagsView,
    Settings: Settings,
    Message: Message
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  },
  created: function created() {
    var self = this;
    // self.socket.creatSocket();
    // self.$nextTick(() => {
    //   self.socket.setDom(self.$refs.immessage);
    // })
  }
};