import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermissionUser(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = store.getters && store.getters.wsUserAnthor;
    // console.log('roles1111111111', roles);
    var permissionRoles = value;
    if (!roles) {
      return false;
    }
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}