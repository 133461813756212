var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v("新增")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", align: "center", width: "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "path", label: "路径", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "desc", label: "说明", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "content", label: "内容", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "enable", label: "启用状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.enable ? "启用" : "禁用")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-delete",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.pageSize,
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "编辑公告",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "说明" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "路径" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容" } },
                [
                  _c("WangEnduit", {
                    attrs: { isClear: _vm.isClear },
                    on: {
                      change: _vm.changeEditor,
                      changeClear: function ($event) {
                        return _vm.$emit("changeClear")
                      },
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "启用状态" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "启用", "inactive-text": "禁用" },
                    model: {
                      value: _vm.form.enable,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "enable", $$v)
                      },
                      expression: "form.enable",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }