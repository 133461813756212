"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _warehouse = require("@/api/warehouse/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SkuSelect',
  props: {
    title: {
      type: String,
      default: "SKU选择"
    },
    width: {
      type: String,
      default: "700px"
    },
    paramer: {
      type: Object,
      default: function _default() {}
    },
    defaultSelect: {
      type: String | Array,
      default: ''
    },
    idFiled: {
      type: String,
      default: 'wsSkuId'
    }
  },
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      selection: [],
      // 非多个禁用
      multiple: false,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // sku信息表格数据
      skuList: [],
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        // pageNum: 1,
        // pageSize: 10,
        sku: null
      }
    };
  },
  watch: {
    defaultSelect: {
      deep: true,
      handler: function handler(val) {
        console.log("val==>", val);
        this.toggleSelection();
      }
    }
  },
  methods: {
    /** 查询sku信息列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      var par = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), this.paramer);
      (0, _warehouse.getPackingSkuList)(par).then(function (response) {
        _this.skuList = response.data;
        _this.toggleSelection();
        _this.loading = false;
      });
    },
    openDialog: function openDialog() {
      this.getList();
      this.open = true;
    },
    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    // 页面重置
    reset: function reset() {
      this.queryParams = {
        // pageNum: 1,
        // pageSize: 10,
        // status:99,
        sku: null
        // skuCode: null,
      };

      this.resetQuery();
      this.ids = [];
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.wsSkuId;
      });
      this.selection = selection;
      this.multiple = selection.length;
    },
    toggleSelection: function toggleSelection() {
      var _this2 = this;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
      var rows = this.defaultSelect;
      if (rows) {
        rows.forEach(function (row) {
          if (row && _this2.skuList) {
            _this2.skuList.map(function (x) {
              if (row instanceof Object) {
                if (row[_this2.idFiled] == x.wsSkuId) {
                  _this2.$refs.multipleTable.toggleRowSelection(x, true);
                }
              } else {
                if (row == x.wsSkuId) {
                  _this2.$refs.multipleTable.toggleRowSelection(x, true);
                }
              }
            });
          }
        });
      }
    },
    /** 确定按钮操作 */handleOk: function handleOk() {
      var _this3 = this;
      this.cancel();
      var ids = (0, _toConsumableArray2.default)(new Set(this.ids));
      var selection = this.selection.filter(function (item, index) {
        return _this3.selection.findIndex(function (t) {
          return t.wsSkuId === item.wsSkuId;
        }) === index;
      });
      this.$emit("success", ids, selection);
    }
  }
};
exports.default = _default2;