"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encryptData = exports.decryptData = void 0;
require("core-js/modules/es6.regexp.to-string");
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
var key = "0123456789abcdef";

// AES加密函数
var encryptData = function encryptData(data) {
  // 将密钥转换为 WordArray
  var keyWords = _cryptoJs.default.enc.Utf8.parse(key);
  // 将数据转换为 WordArray
  var dataWords = _cryptoJs.default.enc.Utf8.parse(data);

  // 配置加密参数
  var encrypted = _cryptoJs.default.AES.encrypt(dataWords, keyWords, {
    mode: _cryptoJs.default.mode.ECB,
    padding: _cryptoJs.default.pad.Pkcs7
  });

  // 输出为 Base64 字符串
  return encrypted.toString();
};
exports.encryptData = encryptData;
var decryptData = function decryptData(encryptedData) {
  // 解密数据
  var bytes = _cryptoJs.default.AES.decrypt(encryptedData, key);
  var originalText = bytes.toString(_cryptoJs.default.enc.Utf8);
  return originalText;
};
exports.decryptData = decryptData;