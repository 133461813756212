"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var shopRouter = {
  path: '/shop',
  component: _layout.default,
  name: 'shop',
  alwaysShow: true,
  meta: {
    title: '店铺',
    icon: 'shop-list'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/index/index'));
      });
    },
    name: 'shopList',
    meta: {
      title: '店铺列表',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'verify',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/verify/index'));
      });
    },
    name: 'verifyShop',
    meta: {
      title: '新店铺审核',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'data',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/data/index'));
      });
    },
    name: 'shopDataList',
    meta: {
      title: '店铺数据',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'shopService',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/service/index'));
      });
    },
    name: 'shopService',
    meta: {
      title: '服务管理',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'freight',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/freight/index'));
      });
    },
    name: 'freightTemplate',
    meta: {
      title: '运费模板',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/index/detailInfo'));
      });
    },
    name: 'detailInfo',
    meta: {
      title: '店铺详情',
      role: ['admin'],
      activeMenu: '/shop/list'
    },
    hidden: true
  }, {
    path: 'detail/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shop/index/detail'));
      });
    },
    name: 'editMember',
    meta: {
      title: '编辑店铺详情',
      noCache: true,
      role: [],
      activeMenu: '/shop/list'
    },
    hidden: true
  }]
};
var _default = shopRouter;
exports.default = _default;