"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCourseType = addCourseType;
exports.deleteCourseType = deleteCourseType;
exports.getCourseTypeList = getCourseTypeList;
exports.updateCourseType = updateCourseType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getCourseTypeList(data) {
  return (0, _requestJson.default)({
    url: '/course/type/getPage',
    method: 'get',
    params: data
  });
}
function addCourseType(data) {
  return (0, _requestJson.default)({
    url: '/course/type/save',
    method: 'post',
    data: data
  });
}
function updateCourseType(data) {
  return (0, _requestJson.default)({
    url: '/course/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteCourseType(id) {
  return (0, _requestJson.default)({
    url: "/course/type/deleteById/".concat(id),
    method: 'post'
  });
}