"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SingleImageUpload",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tempUrl: "",
      uploadUrl: process.env.VUE_APP_BASE_API + "system/upload/US",
      dataObj: {
        token: "",
        key: ""
      }
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value;
    }
  },
  methods: {
    //图片格式校验
    fileProgress: function fileProgress(file) {
      console.log("校验", file);
      var fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      var whiteList = ["jpg", "png", "jpeg"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "上传图片只能是jpg,jpeg,png格式！",
          type: "error"
        });
        return false;
      }
      var fileSize = parseInt(file.size / 1024) <= 1000;
      if (!fileSize) {
        // 检查文件大小
        console.log("result, file, fileList", file);
        this.$message.error("\u4E0A\u4F20\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 1MB!");
        return false;
      }
    },
    rmImage: function rmImage() {
      this.emitInput("");
    },
    emitInput: function emitInput(val) {
      this.$emit("input", val);
    },
    handleImageSuccess: function handleImageSuccess(result) {
      if (result.code == 200) {
        this.emitInput(result.data.imgUrl);
        this.$message({
          message: "图片上传成功！",
          type: "success"
        });
      } else {
        this.$message.error(result.message);
      }
    }
  }
};
exports.default = _default;