var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "120px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ERPSKU名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: {
                          placeholder: "请输入ERPSKU名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.listQuery.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "sku", $$v)
                          },
                          expression: "listQuery.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "第三方SKU名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: {
                          placeholder: "请输入第三方SKU名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.listQuery.mappingSkuName,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "mappingSkuName", $$v)
                          },
                          expression: "listQuery.mappingSkuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商:" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.listQuery.adminId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "adminId", $$v)
                            },
                            expression: "listQuery.adminId",
                          },
                        },
                        _vm._l(_vm.availableSupplies, function (supply) {
                          return _c("el-option", {
                            key: supply.id,
                            attrs: { label: supply.name, value: supply.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n          查询\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("\n          新增\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ERPSku", prop: "skuName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "第三方Sku",
              prop: "mappingSkuName",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "供应商名字", prop: "adminName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "绑定仓库", width: "200px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.warehouseName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.edit_list(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteDetail(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: { title: _vm.退回库存, visible: _vm.dialogFormVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.refundInfo,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ERPSku", prop: "skuName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入ERPSku" },
                        model: {
                          value: _vm.refundInfo.skuName,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundInfo, "skuName", $$v)
                          },
                          expression: "refundInfo.skuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "第三方SKU", prop: "mappingSkuName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入第三方SKU" },
                        model: {
                          value: _vm.refundInfo.mappingSkuName,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundInfo, "mappingSkuName", $$v)
                          },
                          expression: "refundInfo.mappingSkuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "仓库：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.refundInfo.warehouseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundInfo, "warehouseId", $$v)
                            },
                            expression: "refundInfo.warehouseId",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }