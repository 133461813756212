var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClickTab },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "全部", name: "first" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "待审核", name: "second" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "已通过", name: "third" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "已驳回", name: "four" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "70px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                      },
                      attrs: { label: "关键词：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入关键词" },
                        model: {
                          value: _vm.listQuery.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "keyword", $$v)
                          },
                          expression: "listQuery.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-circle-check",
                          },
                          on: { click: _vm.handleVerify },
                        },
                        [_vm._v("批量审核")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "danger",
                            icon: "el-icon-delete",
                          },
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "用户名",
              prop: "memberName",
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/member/detail?memberId=" + scope.row.memberId,
                        },
                      },
                      [
                        _c("p", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(scope.row.memberName)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "真实姓名",
              prop: "memberName",
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.realname))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "身份证号码",
              prop: "idCard",
              align: "center",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.idCard))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "身份证正反面",
              prop: "idcardFace",
              align: "center",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: {
                        fit: "contain",
                        src: scope.row.idcardFace,
                        "preview-src-list": [
                          scope.row.idcardFace,
                          scope.row.idcardBack,
                          scope.row.idcardFaceHand,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("el-image", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: {
                        fit: "contain",
                        src: scope.row.idcardBack,
                        "preview-src-list": [
                          scope.row.idcardBack,
                          scope.row.idcardFace,
                          scope.row.idcardFaceHand,
                        ],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "手持身份证",
              prop: "idcardFaceHand",
              align: "center",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.idcardFaceHand != null
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function ($event) {
                                return _vm.viewVideo(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看视频")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "申请时间",
              width: "150",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拒绝原因", align: "center", prop: "remark" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.reason))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              prop: "status",
              align: "center",
              width: "70",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.status == 1 ? "success" : "danger",
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("statusFilter")(scope.row.status)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button-group",
                      [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-circle-check",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleVerify(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-edit",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "视频", visible: _vm.dialogVideoVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVideoVisible = $event
            },
          },
        },
        [
          _c(
            "video",
            {
              attrs: {
                id: "playVideos",
                width: "30%",
                height: "100%",
                "webkit-playsinline": "true",
                preload: "auto",
                controls: "controls",
              },
            },
            [_c("source", { attrs: { src: _vm.videoUrl, type: "video/mp4" } })]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.verifyForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.verifyForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "status", $$v)
                        },
                        expression: "verifyForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2, border: "" } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", [_vm._v("拒绝原因")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", prop: "reasonCheck" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.verifyForm,
                            "remark",
                            _vm.verifyForm.reasonCheck
                          )
                        },
                      },
                      model: {
                        value: _vm.verifyForm.reasonCheck,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "reasonCheck", $$v)
                        },
                        expression: "verifyForm.reasonCheck",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: {
                          value:
                            "请查看身份证绑定要求(打开个人中心界面--新手教学--5账号绑定--绑定身份证及要求)",
                          label:
                            "请查看身份证绑定要求(打开个人中心界面--新手教学--5账号绑定--绑定身份证及要求)",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          value: "身份证照片模糊,反光或光线太暗,请重拍",
                          label: "身份证照片模糊,反光或光线太暗,请重拍",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "2",
                        attrs: {
                          value: "身份证拍反了,请重拍",
                          label: "身份证拍反了,请重拍",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "3",
                        attrs: {
                          value: "照片中脸部不能遮挡",
                          label: "照片中脸部不能遮挡",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "4",
                        attrs: {
                          value: "该地区注册人数已达上限, 暂停注册",
                          label: "该地区注册人数已达上限, 暂停注册",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "5",
                        attrs: {
                          value: "身份证姓名与平台注册姓名不一致",
                          label: "身份证姓名与平台注册姓名不一致",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "6",
                        attrs: {
                          value:
                            "年龄不符合注册要求(仅限出生年月1965年1月-1998年12月注册)",
                          label:
                            "年龄不符合注册要求(仅限出生年月1965年1月-1998年12月注册)",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "7",
                        attrs: {
                          value: "该身份证信息已在平台注册过账号,不能重复注册",
                          label: "该身份证信息已在平台注册过账号,不能重复注册",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "8",
                        attrs: {
                          value: "该身份证信息核实不到",
                          label: "该身份证信息核实不到",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "9",
                        attrs: { value: "虚假资料", label: "虚假资料" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "10",
                        attrs: { value: "其他", label: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "填写原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入拒绝理由",
                      rows: 6,
                    },
                    model: {
                      value: _vm.verifyForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "remark", $$v)
                      },
                      expression: "verifyForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.dialogEditVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.editForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "realname" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入真实姓名" },
                    model: {
                      value: _vm.editForm.realname,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "realname", $$v)
                      },
                      expression: "editForm.realname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份证", prop: "idCard" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入身份证" },
                    model: {
                      value: _vm.editForm.idCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "idCard", $$v)
                      },
                      expression: "editForm.idCard",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份证正面", prop: "idcardFace" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.editForm.idcardFace,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "idcardFace", $$v)
                      },
                      expression: "editForm.idcardFace",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份证反面", prop: "idcardBack" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.editForm.idcardBack,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "idcardBack", $$v)
                      },
                      expression: "editForm.idcardBack",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogEditVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }