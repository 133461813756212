var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择审核状态",
                            size: "mini",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "auditStatus", $$v)
                            },
                            expression: "listQuery.auditStatus",
                          },
                        },
                        _vm._l(_vm.status_arr, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "160px" },
                        attrs: {
                          size: "mini",
                          placeholder: "订单号",
                          clearable: "",
                        },
                        on: { clear: _vm.handleSearch },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.orderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderId", $$v)
                          },
                          expression: "listQuery.orderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单时间：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "160px" },
                        attrs: {
                          size: "mini",
                          type: "datetime",
                          placeholder: "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.handleSearch },
                        model: {
                          value: _vm.listQuery.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "startTime", $$v)
                          },
                          expression: "listQuery.startTime",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "160px" },
                        attrs: {
                          size: "mini",
                          type: "datetime",
                          placeholder: "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.handleSearch },
                        model: {
                          value: _vm.listQuery.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "endTime", $$v)
                          },
                          expression: "listQuery.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("查询\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      !_vm.mainFlag
                        ? _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.action,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-change": _vm.importExcel,
                                "on-success": _vm.upload_success,
                                "on-error": _vm.upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.loading_sw,
                                  },
                                },
                                [_vm._v("导入\n          ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "danger",
                            icon: "el-icon-delete",
                          },
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": _vm.rowKey,
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "orderId",
              label: "订单号",
              align: "center",
              width: "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "warehouse",
              label: "仓库编号",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "skuList",
              label: "sku名称",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.skuList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "skuList_calss",
                        style: {
                          borderBottom:
                            index == scope.row.skuList.length - 1
                              ? ""
                              : "1px solid #dfe6ec",
                        },
                      },
                      [_vm._v("\n          " + _vm._s(item.sku) + "\n        ")]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "skuList",
              label: "sku名称",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.skuList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "skuList_calss",
                        style: {
                          borderBottom:
                            index == scope.row.skuList.length - 1
                              ? ""
                              : "1px solid #dfe6ec",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.price) + "\n        "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "skuList",
              label: "数量",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.skuList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "skuList_calss",
                        style: {
                          borderBottom:
                            index == scope.row.skuList.length - 1
                              ? ""
                              : "1px solid #dfe6ec",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.nums) + "\n        "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "city",
              label: "城市",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "zipCode",
              label: "邮编",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "waybillUrl",
              label: "面单地址",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "remarks",
              label: "备注",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "operate",
              label: "结果",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              align: "center",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.status == 1 ? "完成" : "未完成") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "addTime",
              label: "添加时间",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEditClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-top": "5px",
                          "margin-left": "10px",
                        },
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSKUClick(scope.row, scope.$index)
                          },
                        },
                      },
                      [_vm._v("SKU")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-top": "5px",
                          "margin-left": "10px",
                        },
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRetryClick(scope.row.orderId)
                          },
                        },
                      },
                      [_vm._v("重试")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-top": "5px",
                          "margin-left": "10px",
                        },
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteClick(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.edit_dialog_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑",
                visible: _vm.edit_dialog_sw,
                width: "800px",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.edit_dialog_sw = $event
                },
                close: _vm.resetEditAddressDialog,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.editAddressForm,
                    "label-width": "100px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号", prop: "orderId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.orderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "orderId", $$v)
                          },
                          expression: "editAddressForm.orderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库编号", prop: "warehouse" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.warehouse,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "warehouse", $$v)
                          },
                          expression: "editAddressForm.warehouse",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "买家名字" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.buyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "buyName", $$v)
                          },
                          expression: "editAddressForm.buyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址1" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.address1,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "address1", $$v)
                          },
                          expression: "editAddressForm.address1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址2" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.address2,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "address2", $$v)
                          },
                          expression: "editAddressForm.address2",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "城市" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "city", $$v)
                          },
                          expression: "editAddressForm.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "省/州" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "state", $$v)
                          },
                          expression: "editAddressForm.state",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "国家二字码" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "country", $$v)
                          },
                          expression: "editAddressForm.country",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮编" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.zipCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "zipCode", $$v)
                          },
                          expression: "editAddressForm.zipCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "mobile", $$v)
                          },
                          expression: "editAddressForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "phone", $$v)
                          },
                          expression: "editAddressForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "面单地址" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.waybillUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "waybillUrl", $$v)
                          },
                          expression: "editAddressForm.waybillUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "remarks", $$v)
                          },
                          expression: "editAddressForm.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.edit_dialog_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.handleEditAddressConfirm_loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleEditAddressConfirm("ruleForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.handleEditAddressConfirm_loading
                            ? "修改中"
                            : "确认修改"
                        ) + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "650px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.skuList, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "sku名称", align: "center", prop: "content" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入sku名称",
                            clearable: "",
                          },
                          model: {
                            value: scope.row.sku,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sku", $$v)
                            },
                            expression: "scope.row.sku",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", align: "center", prop: "quantity" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "请输入价格" },
                            model: {
                              value: scope.row.price,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price",
                            },
                          },
                          [_c("template", { slot: "append" }, [_vm._v("$")])],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", prop: "quantity" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            placeholder: "请输入数量",
                            clearable: "",
                          },
                          model: {
                            value: scope.row.nums,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "nums", $$v)
                            },
                            expression: "scope.row.nums",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.sku_loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.edit_sku(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }