import { render, staticRenderFns } from "./shops.vue?vue&type=template&id=158f45ea&scoped=true&"
import script from "./shops.vue?vue&type=script&lang=js&"
export * from "./shops.vue?vue&type=script&lang=js&"
import style0 from "./shops.vue?vue&type=style&index=0&id=158f45ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158f45ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tk-admin_Z7th/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('158f45ea')) {
      api.createRecord('158f45ea', component.options)
    } else {
      api.reload('158f45ea', component.options)
    }
    module.hot.accept("./shops.vue?vue&type=template&id=158f45ea&scoped=true&", function () {
      api.rerender('158f45ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lek-dialog/template/shops.vue"
export default component.exports