"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAdmin = deleteAdmin;
exports.getAdmin = getAdmin;
exports.getAdminList = getAdminList;
exports.getKey = getKey;
exports.updateAdmin = updateAdmin;
var _request = _interopRequireDefault(require("@/utils/request"));
function getAdminList(data) {
  return (0, _request.default)({
    url: '/system/admin/getList',
    method: 'get',
    params: data
  });
}
function getAdmin(data) {
  return (0, _request.default)({
    url: '/system/admin/get',
    method: 'get',
    params: data
  });
}
function getKey() {
  return (0, _request.default)({
    url: '/system/admin/getKey',
    method: 'get'
  });
}
function updateAdmin(data) {
  return (0, _request.default)({
    url: 'system/admin/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteAdmin(data) {
  return (0, _request.default)({
    url: 'system/admin/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}