/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var productRouter = {
  path: '/product',
  component: Layout,
  name: 'product',
  alwaysShow: true,
  meta: {
    title: '产品管理',
    icon: 'report-brand'
  },
  children: [{
    path: 'cate',
    component: function component() {
      return import('@/views/product/cate/index');
    },
    name: 'productCate',
    meta: {
      title: '类目管理',
      icon: '',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/product/list/index');
    },
    name: 'productList',
    meta: {
      title: '产品管理',
      icon: '',
      role: []
    }
  }, {
    path: 'audit',
    component: function component() {
      return import('@/views/product/audit/index');
    },
    name: 'productAudit',
    meta: {
      title: '产品审核',
      icon: '',
      role: []
    }
  }, {
    path: 'directory',
    component: function component() {
      return import('@/views/product/directory/index');
    },
    name: 'productDirectory',
    meta: {
      title: '产品目录',
      icon: '',
      role: []
    }
  }, {
    path: 'bind',
    component: function component() {
      return import('@/views/product/bind/index');
    },
    name: 'shopBindSupply',
    meta: {
      title: '推送绑定',
      icon: '',
      role: []
    }
  }, {
    path: 'binshop',
    component: function component() {
      return import('@/views/product/binshop/newindex');
    },
    name: 'Binshop',
    meta: {
      title: '商品回传',
      role: []
    }
  }, {
    path: 'wmssupplier',
    component: function component() {
      return import('@/views/product/wmssupplier/index');
    },
    name: 'Wmssupplier',
    meta: {
      title: 'wms商品添加',
      role: []
    }
  }, {
    path: 'infringementDetection',
    component: function component() {
      return import('@/views/product/infringementDetection/index');
    },
    name: 'infringementDetection',
    meta: {
      title: '产品侵权检测',
      role: []
    }
  }, {
    path: 'bindInv',
    component: function component() {
      return import('@/views/product/bindInv/index');
    },
    name: 'bindInv',
    meta: {
      title: '库存购买锁定',
      role: []
    }
  }, {
    path: 'bindExamineInv',
    component: function component() {
      return import('@/views/product/bindExamineInv/index');
    },
    name: 'bindExamineInv',
    meta: {
      title: '库存购买锁定审核',
      role: []
    }
  }, {
    path: 'mapping',
    component: function component() {
      return import('@/views/product/mapping/index');
    },
    name: 'mapping',
    meta: {
      title: '产品SKU映射',
      role: []
    }
  }]
};
export default productRouter;