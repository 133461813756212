"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shop = require("@/api/shop");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
var _imageSource = _interopRequireDefault(require("@/components/Upload/imageSource"));
var _elementChinaAreaData = require("element-china-area-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Waves directive

// Secondary package based on el-pagination

var statusOptions = [{
  key: 1,
  text: '正常'
}, {
  key: 0,
  text: '停用'
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var statusKeyValue = statusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var typeOptions = [{
  key: 1,
  text: '个人'
}, {
  key: 2,
  text: '企业'
}, {
  key: 3,
  text: '旗舰'
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var typeKeyValue = typeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var _default = {
  name: 'shopDataList',
  components: {
    Pagination: _Pagination.default,
    Upload: _singleImage.default,
    ImageSource: _imageSource.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      return statusKeyValue[status];
    },
    typeFilter: function typeFilter(status) {
      return typeKeyValue[status];
    }
  },
  data: function data() {
    return {
      addressOptions: _elementChinaAreaData.regionData,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      shop: {},
      dialogFormVisible: false,
      dialogType: '',
      multipleSelection: [],
      statusOptions: statusOptions,
      activeName: 'first',
      locationActiveName: 'first',
      typeOptions: typeOptions,
      checkedService: [],
      serviceOptions: null
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _shop.getShopDataList)(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;