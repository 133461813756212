"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goods = require("@/api/business/goods");
var _index = _interopRequireDefault(require("@/components/WangEditor/index"));
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BusinessGoodsPage",
  components: {
    Pagination: _index2.default,
    Upload: _singleImage.default,
    WangEnduit: _index.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      // 列表数据
      total: 0,
      // 总记录数
      pageNum: 1,
      // 当前页码
      pageSize: 10,
      // 每页显示记录数
      listLoading: false,
      // 列表加载状态
      dialogVisible: false,
      // 弹窗可见性
      dialogTitle: "",
      // 弹窗标题
      formData: {
        // 表单数据
        id: null,
        title: "",
        price: null,
        needKnow: "",
        details: "",
        sort: 0,
        img: "",
        addTime: null,
        updTime: null,
        delFlag: false,
        num: 1,
        addBy: 29,
        formId: null
      },
      formRules: {
        // 表单验证规则
        title: [{
          required: true,
          message: "请输入标题",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入价格",
          trigger: "blur"
        }],
        needKnow: [{
          required: true,
          message: "请输入购买须知的内容",
          trigger: "blur"
        }],
        details: [{
          required: true,
          message: "请输入详情内容",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }],
        img: [{
          required: true,
          message: "请上传图片",
          trigger: "change"
        }],
        formId: [{
          required: true,
          message: "请选择所属表单",
          trigger: "change"
        }]
      },
      editorOptions: {
        // 富文本编辑器配置
        // 配置富文本编辑器的选项
      },
      formList: [],
      // 表单列表,
      isClearA: false,
      isClearB: false
    };
  },
  created: function created() {
    this.getList();
    this.getFormList();
  },
  methods: {
    // 富文本内容发生变化时触发
    changeEditorA: function changeEditorA(val) {
      this.form.needKnow = val;
    },
    // 富文本内容发生变化时触发
    changeEditorB: function changeEditorB(val) {
      this.form.details = val;
    },
    // 获取列表数据
    getList: function getList(page) {
      var _this = this;
      console.log("page", page);
      if (page) {
        this.pageNum = page.page;
        this.pageSize = page.limit;
      }
      this.listLoading = true;
      (0, _goods.getBusinessPage)({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 获取表单列表
    getFormList: function getFormList() {
      var _this2 = this;
      (0, _goods.getBusinessFormList)().then(function (response) {
        _this2.formList = response.data;
      });
    },
    // 处理新增按钮点击事件
    handleCreate: function handleCreate() {
      this.dialogTitle = "新增";
      this.dialogVisible = true;
      this.resetForm();
    },
    // 处理编辑按钮点击事件
    handleUpdate: function handleUpdate(row) {
      this.dialogTitle = "编辑";
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, row);
    },
    // 处理删除按钮点击事件
    handleDelete: function handleDelete(row) {
      var _this3 = this;
      // 调用删除接口，根据 row.id 删除数据
      (0, _goods.deleteBusiness)(row.id).then(function () {
        _this3.getList();
        _this3.$message.success("删除成功");
      });
    },
    // 处理表单重置
    resetForm: function resetForm() {
      this.$refs.dataForm.resetFields();
    },
    // 处理表单提交
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            // 调用编辑接口，根据 this.formData.id 更新数据
            (0, _goods.updateBusiness)(_this4.formData).then(function () {
              _this4.getList();
              _this4.dialogVisible = false;
              _this4.$message.success("编辑成功");
            });
          } else {
            // 调用新增接口，传入 this.formData 作为新增的数据
            (0, _goods.addBusiness)(_this4.formData).then(function () {
              _this4.getList();
              _this4.dialogVisible = false;
              _this4.$message.success("新增成功");
            });
          }
        }
      });
    }
  }
};
exports.default = _default;