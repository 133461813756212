"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVipMember = addVipMember;
exports.getVipMember = getVipMember;
exports.getVipOrderList = getVipOrderList;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getVipOrderList(data) {
  return (0, _requestJson.default)({
    url: '/vip/orderPage',
    method: 'get',
    params: data
  });
}
function getVipMember(data) {
  return (0, _requestJson.default)({
    url: '/vip/userVipPage',
    method: 'get',
    params: data
  });
}
function addVipMember(data) {
  return (0, _requestJson.default)({
    url: '/vip/addVip',
    method: 'post',
    data: data
  });
}