"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteArticle = deleteArticle;
exports.deleteArticleCate = deleteArticleCate;
exports.getArticle = getArticle;
exports.getArticleCateList = getArticleCateList;
exports.getArticleCateSelector = getArticleCateSelector;
exports.getArticleList = getArticleList;
exports.updateArticle = updateArticle;
exports.updateArticleCate = updateArticleCate;
var _request = _interopRequireDefault(require("@/utils/request"));
function getArticleList(data) {
  return (0, _request.default)({
    url: '/system/article/getList',
    method: 'get',
    params: data
  });
}
function getArticle(id) {
  return (0, _request.default)({
    url: '/system/article/get',
    method: 'get',
    params: {
      id: id
    }
  });
}
function updateArticle(data) {
  return (0, _request.default)({
    url: 'system/article/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteArticle(data) {
  return (0, _request.default)({
    url: 'system/article/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getArticleCateList(data) {
  return (0, _request.default)({
    url: '/system/articleCate/getList',
    method: 'get',
    params: data
  });
}
function getArticleCateSelector(data) {
  return (0, _request.default)({
    url: '/system/articleCate/getSelector',
    method: 'get',
    params: data
  });
}
function updateArticleCate(data) {
  return (0, _request.default)({
    url: 'system/articleCate/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteArticleCate(data) {
  return (0, _request.default)({
    url: 'system/articleCate/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}