"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

//三方管理路由配置
var thirdRouter = {
  path: '/third',
  component: _layout.default,
  redirect: 'noredirect',
  name: 'third',
  meta: {
    title: '三方',
    icon: 'lock'
  },
  children: [{
    path: 'qiniu',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/qiniu/index'));
      });
    },
    name: 'qiniu',
    meta: {
      title: '七牛云配置',
      role: ['admin']
    }
  }, {
    path: 'sms',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/sms/index'));
      });
    },
    name: 'sms',
    meta: {
      title: '短信配置',
      role: ['admin']
    }
  }, {
    path: 'weixin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/weixin/index'));
      });
    },
    name: 'weixin',
    meta: {
      title: '微信配置',
      role: ['admin']
    }
  }, {
    path: 'weixinTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/weixin/template'));
      });
    },
    name: 'weixinTemplate',
    meta: {
      title: '微信模板消息',
      role: ['admin']
    }
  }, {
    path: 'zfb',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/zfb/index'));
      });
    },
    name: 'zfb',
    meta: {
      title: '支付宝配置',
      role: ['admin']
    }
  }, {
    path: 'realnameApi',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/approve/index'));
      });
    },
    name: 'realnameApi',
    meta: {
      title: '实名认证接口',
      role: ['admin']
    }
  }, {
    path: 'thirdConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/config/index'));
      });
    },
    name: 'thirdConfig',
    meta: {
      title: '接口切换配置',
      role: ['admin']
    }
  }, {
    path: 'interface',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/third/interface/index'));
      });
    },
    name: 'interface',
    meta: {
      title: '第三方接口',
      role: ['admin']
    }
  }]
};
var _default = thirdRouter;
exports.default = _default;