"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SingleVideoUpload",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tempUrl: "",
      isLoading: false,
      uploadUrl: process.env.VUE_APP_BASE_API + "system/uploadVideo",
      dataObj: {
        token: "",
        key: ""
      }
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      // this.value = this.$dateUtil.getPicUrl(this.value);
      // console.log("视频数据", this.value, this.$dateUtil.getPicUrl(this.value));
      return this.value;
    }
  },
  methods: {
    rmImage: function rmImage() {
      this.emitInput("");
    },
    emitInput: function emitInput(val) {
      this.$emit("input", val);
    },
    handleUploading: function handleUploading(event, file, fileList) {
      this.isLoading = true;
    },
    handleImageSuccess: function handleImageSuccess(result) {
      this.isLoading = false;
      this.emitInput(result.data.videoUrl);
    }
  }
};
exports.default = _default;