"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audit = audit;
exports.count = count;
exports.deleteById = deleteById;
exports.deleteTypeById = deleteTypeById;
exports.exportExcel = exportExcel;
exports.getAuditPage = getAuditPage;
exports.getListByTypeId = getListByTypeId;
exports.getOrderPage = getOrderPage;
exports.getShipOrderPage = getShipOrderPage;
exports.getTypePage = getTypePage;
exports.shopgetShopList = shopgetShopList;
exports.skuDeleteById = skuDeleteById;
exports.skuOrderDelete = skuOrderDelete;
exports.skuOrderRetry = skuOrderRetry;
exports.skuOrderUpdate = skuOrderUpdate;
exports.skuSave = skuSave;
exports.skuUpdateById = skuUpdateById;
exports.smallSkuOrder = smallSkuOrder;
exports.typeSave = typeSave;
exports.typeUpdateById = typeUpdateById;
exports.uploadExcel = uploadExcel;
exports.uploadSkuOrder = uploadSkuOrder;
exports.uploadType = uploadType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
var _request = _interopRequireDefault(require("@/utils/request"));
function getOrderPage(data) {
  return (0, _requestJson.default)({
    url: '/ship/page',
    method: 'get',
    params: data
  });
}
function getAuditPage(data) {
  return (0, _requestJson.default)({
    url: '/ship/audit/page',
    method: 'get',
    params: data
  });
}
function getTypePage(data) {
  return (0, _requestJson.default)({
    url: '/ship/type/page',
    method: 'get',
    params: data
  });
}
function uploadType(data) {
  return (0, _requestJson.default)({
    url: '/ship/uploadType',
    method: 'post',
    data: data
  });
}
function audit(data) {
  return (0, _requestJson.default)({
    url: '/ship/audit',
    method: 'post',
    data: data
  });
}
function uploadExcel(data) {
  return (0, _requestJson.default)({
    url: '/ship/uploadExcel',
    method: 'post',
    data: data
  });
}
function exportExcel(data) {
  return fetch({
    url: '/ship/exportExcel',
    method: 'get',
    timeout: '120000',
    responseType: 'blob',
    data: data
  });
}
function typeSave(data) {
  return (0, _requestJson.default)({
    url: '/ship/type/save',
    method: 'post',
    data: data
  });
}
function typeUpdateById(data) {
  return (0, _requestJson.default)({
    url: '/ship/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteTypeById(id) {
  return (0, _requestJson.default)({
    url: "/ship/type/deleteById/".concat(id),
    method: 'post'
  });
}
function deleteById(id) {
  return (0, _requestJson.default)({
    url: "/ship/deleteById/".concat(id),
    method: 'post'
  });
}
function getListByTypeId(id) {
  return (0, _requestJson.default)({
    url: "/ship/sku/getListByTypeId/".concat(id),
    method: 'get'
  });
}
function skuSave(data) {
  return (0, _requestJson.default)({
    url: '/ship/sku/save',
    method: 'post',
    data: data
  });
}
function skuUpdateById(data) {
  return (0, _requestJson.default)({
    url: '/ship/sku/updateById',
    method: 'post',
    data: data
  });
}
function skuDeleteById(id) {
  return (0, _requestJson.default)({
    url: "/ship/sku/deleteById/".concat(id),
    method: 'post'
  });
}
function count() {
  return (0, _requestJson.default)({
    url: "/ship/count",
    method: 'get'
  });
}
function getShipOrderPage(data) {
  return (0, _requestJson.default)({
    url: "/ship/getShipOrderPage",
    method: 'get',
    params: data
  });
}
function shopgetShopList() {
  return (0, _requestJson.default)({
    url: 'shopSupply/getShopList',
    method: 'get'
  });
}
function smallSkuOrder(data) {
  return (0, _requestJson.default)({
    url: "/ship/pageSkuOrder",
    method: 'get',
    params: data
  });
}
function uploadSkuOrder(data) {
  return (0, _request.default)({
    url: '/ship/uploadSkuOrderExcel',
    method: 'post',
    data: data
  });
}
function skuOrderDelete(data) {
  return (0, _request.default)({
    url: "/ship/skuOrderDelete",
    method: 'post',
    params: {
      ids: data
    }
  });
}
function skuOrderUpdate(data) {
  return (0, _requestJson.default)({
    url: "/ship/skuOrderUpdate",
    method: 'post',
    data: data
  });
}
function skuOrderRetry(ids) {
  return (0, _request.default)({
    url: "/ship/skuOrderRetry?ids=" + ids,
    method: 'post'
  });
}

// export function exportShipInfo(orderIds) {
//   return request({
//     url: '/small/exportShipInfo?orderIds=' + orderIds,
//     method: 'get'
//   })
// }