"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _form = require("@/api/business/form");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BusinessForm',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 10
      },
      form: {},
      dialogFormVisible: false,
      dialogType: '',
      rules: {
        title: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }],
        typeId: [{
          required: true,
          message: '类型不能为空',
          trigger: 'blur'
        }]
      },
      typeList: [] // 存储类型列表
    };
  },
  created: function created() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _form.getBusinessFormList)(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    getTypeList: function getTypeList() {
      var _this2 = this;
      (0, _form.getBusinessTypeList)().then(function (response) {
        _this2.typeList = response.data.list;
      });
    },
    getTypeTitle: function getTypeTitle(typeId) {
      var type = this.typeList.find(function (item) {
        return item.id === typeId;
      });
      return type ? type.title : '';
    },
    formatDate: function formatDate(time) {
      return (0, _utils.parseTime)(time, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    handleCreate: function handleCreate() {
      var _this3 = this;
      this.dialogType = 'add';
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.form = (0, _objectSpread2.default)({}, row);
      this.dialogType = 'edit';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    confirm: function confirm() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var formData = (0, _objectSpread2.default)({}, _this5.form);
          if (_this5.dialogType === 'add') {
            (0, _form.addBusinessForm)(formData).then(function () {
              _this5.getList();
              _this5.dialogFormVisible = false;
              _this5.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success',
                duration: 2000
              });
            });
          } else if (_this5.dialogType === 'edit') {
            (0, _form.updateBusinessForm)(formData).then(function () {
              _this5.getList();
              _this5.dialogFormVisible = false;
              _this5.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success',
                duration: 2000
              });
            });
          }
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('确认删除该项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _form.deleteBusinessForm)(row.id).then(function () {
          _this6.getList();
          _this6.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        });
      }).catch(function () {
        // do nothing
      });
    }
  }
};
exports.default = _default;