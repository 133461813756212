"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countUnReadNum = countUnReadNum;
exports.deleteChatSession = deleteChatSession;
exports.getChatList = getChatList;
exports.getChatSessionList = getChatSessionList;
exports.readBySession = readBySession;
exports.sendMsg = sendMsg;
var _request = _interopRequireDefault(require("@/utils/request"));
function getChatSessionList(data) {
  return (0, _request.default)({
    url: 'system/chatSession/getList',
    method: 'get',
    params: data
  });
}
function deleteChatSession(data) {
  return (0, _request.default)({
    url: 'system/chatSession/delete',
    method: 'post',
    data: data
  });
}
function getChatList(data) {
  return (0, _request.default)({
    url: 'system/chat/getList',
    method: 'get',
    params: data
  });
}
function countUnReadNum(data) {
  return (0, _request.default)({
    url: 'system/chat/countUnReadNum',
    method: 'get',
    params: data
  });
}
function sendMsg(data) {
  return (0, _request.default)({
    url: 'system/chat/send',
    method: 'post',
    data: data
  });
}
function readBySession(data) {
  return (0, _request.default)({
    url: 'system/chat/read',
    method: 'post',
    data: data
  });
}