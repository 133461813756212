"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _balance = require("@/api/userBalance/balance");
var _supplierIndex = _interopRequireDefault(require("@/components/Rollin/supplierIndex.vue"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Balance",
  components: {
    Pagination: _Pagination.default,
    SupplierIndex: _supplierIndex.default
  },
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 会员账户表格数据
      balanceList: [],
      // 弹出层标题

      // 查询参数
      queryParams: {
        page: 1,
        pageSize: 10,
        userType: '1',
        username: null,
        pusername: null
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 查询会员账户列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _balance.supplierList)(this.queryParams).then(function (response) {
        _this.balanceList = response.data.list;
        _this.total = response.data.total;
        _this.loading = false;
      });
    },
    checkDataFun: function checkDataFun(row) {
      var i = [(row.topup || 0) * 100, (row.rollin || 0) * 100, -(row.rollout || 0) * 100, -(row.consume || 0) * 100, -(row.freeze || 0) * 100, -(row.balance || 0) * 100];
      var sum = 0;
      i.map(function (x) {
        sum += Math.round(x);
      });
      return (sum / 100).toFixed(2);
    },
    goLogList: function goLogList(row) {
      this.$router.push({
        path: "/association/associationfund",
        query: {
          memberId: row.id,
          userType: this.queryParams.userType
        }
      });
    },
    goSupplierIndex: function goSupplierIndex(row) {
      this.$refs.supplierIndexRef.open(row.id);
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 多选框选中数据
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('userBalance/balance/supplierExport', (0, _objectSpread2.default)({}, this.queryParams), "\u4F9B\u5E94\u5546\u4F59\u989D_".concat(new Date().getTime(), ".xlsx"));
    }
  }
};
exports.default = _default;