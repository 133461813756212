"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deletePhonebook = deletePhonebook;
exports.getPhonebookList = getPhonebookList;
exports.getPhonebookLogList = getPhonebookLogList;
exports.getPhonebookMsgList = getPhonebookMsgList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPhonebookList(data) {
  return (0, _request.default)({
    url: '/system/phonebook/getList',
    method: 'get',
    params: data
  });
}
function deletePhonebook(data) {
  return (0, _request.default)({
    url: 'system/phonebook/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getPhonebookLogList(data) {
  return (0, _request.default)({
    url: '/system/phonebookLog/getList',
    method: 'get',
    params: data
  });
}
function getPhonebookMsgList(data) {
  return (0, _request.default)({
    url: '/system/phonebookMessage/getList',
    method: 'get',
    params: data
  });
}