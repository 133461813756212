import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API_Goods from "@/api/goods";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      type: "multiple",
      //单选或者多选 single  multiple
      skuList: [],
      // 商品sku列表
      total: 0,
      // 商品总数
      goodsParams: {
        // 商品请求参数
        page: 1,
        pageSize: 20,
        goodsName: "",
        sn: "",
        categoryPath: "",
        marketEnable: "UPPER",
        authFlag: "PASS"
      },
      category: [],
      // 分类
      goodsData: [],
      // 商品数据
      empty: false,
      // 空数据
      loading: false // 加载状态
    };
  },

  props: {
    selectedWay: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    }
  },
  watch: {
    category: function category(val) {
      this.goodsParams.categoryPath = val[2];
    },
    selectedWay: {
      handler: function handler() {
        this.$emit("selected", this.selectedWay);
      },
      deep: true,
      immediate: true
    },
    "goodsParams.categoryPath": {
      handler: function handler() {
        this.goodsData = [];
        this.goodsParams.page = 0, this.getQueryGoodsList();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    // 触底加载更多方法
    handleReachBottom: function handleReachBottom() {
      var _this = this;
      setTimeout(function () {
        if (_this.goodsParams.page * _this.goodsParams.pageSize <= _this.total) {
          _this.goodsParams.page++;
          _this.getQueryGoodsList();
        }
      }, 1500);
    },
    // 获取商品列表
    getQueryGoodsList: function getQueryGoodsList() {
      var _this2 = this;
      API_Goods.getGoodsList(this.goodsParams).then(function (res) {
        _this2.initGoods(res);
      });
    },
    // 获取列表方法
    initGoods: function initGoods(res) {
      var _this3 = this;
      if (res.data.list.length != 0) {
        res.data.list.forEach(function (item) {
          item.selected = false;
          item.___type = "goods"; //设置为goods让pc wap知道标识
          _this3.selectedWay.forEach(function (e) {
            if (e.id && e.id === item.id) {
              item.selected = true;
            }
          });
        });
        /**
         * 解决数据请求中，滚动栏会一直上下跳动
         */
        this.total = res.data.total;
        this.goodsData = res.data.list;
        // this.goodsData.push(...res.data.list);
      } else {
        this.empty = true;
      }
    },
    // 查询商品
    init: function init() {
      var _this4 = this;
      API_Goods.getGoodsList(this.goodsParams).then(function (res) {
        // 商品
        _this4.initGoods(res);
      });
      /* if (localStorage.getItem('category')) {
        this.deepGroup(JSON.parse(localStorage.getItem('category')))
      } else {
        setTimeout(() => {
          this.deepGroup(JSON.parse(localStorage.getItem('category')))
        },3000)
      } */
    },
    deepGroup: function deepGroup(val) {
      var _this5 = this;
      val.forEach(function (item) {
        var childWay = []; //第二级
        // 第二层
        if (item.children) {
          item.children.forEach(function (child) {
            // // 第三层
            if (child.children) {
              child.children.forEach(function (grandson, index, arr) {
                arr[index] = {
                  value: grandson.id,
                  label: grandson.name,
                  children: ""
                };
              });
            }
            var children = {
              value: child.id,
              label: child.name,
              children: child.children
            };
            childWay.push(children);
          });
        }
        // 第一层
        var way = {
          value: item.id,
          label: item.name,
          children: childWay
        };
        _this5.skuList.push(way);
      });
    },
    /**
     * 点击商品
     */
    checkedGoods: function checkedGoods(val, index) {
      // 如果单选的话
      if (this.type != "multiple") {
        this.goodsData.forEach(function (item) {
          item.selected = false;
        });
        this.selectedWay = [];
        val.selected = true;
        this.selectedWay.push(val);
        return false;
      }
      if (val.selected == false) {
        val.selected = true;
        this.selectedWay.push(val);
      } else {
        val.selected = false;
        for (var i = 0; i < this.selectedWay.length; i++) {
          if (this.selectedWay[i].id === val.id) {
            this.selectedWay.splice(i, 1);
            break;
          }
        }
      }
    }
  }
};