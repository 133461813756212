"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var state = {
  webName: '',
  logo: '',
  brandList: []
};
var mutations = {
  SET_WEB_NAME: function SET_WEB_NAME(state, webName) {
    state.webName = webName;
  },
  SET_LOGO: function SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_BRAND_LIST: function SET_BRAND_LIST(state, brandList) {
    state.brandList = brandList;
  }
};
var actions = {
  // 获取系统信息
  getWebInfo: function getWebInfo(_ref) {
    var commit = _ref.commit,
      state = _ref.state;
    return new Promise(function (resolve, reject) {
      (0, _system.getWebInfo)().then(function (response) {
        var data = response.data;
        commit('SET_WEB_NAME', data.webName);
        commit('SET_LOGO', data.logo);
        commit('SET_BRAND_LIST', data.brandList);
        //commit('SET_INTRODUCTION', data.introduction)
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;