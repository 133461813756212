"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/api/index");
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var _LineChart = _interopRequireDefault(require("./components/LineChart"));
var _RaddarChart = _interopRequireDefault(require("./components/RaddarChart"));
var _PieChart = _interopRequireDefault(require("./components/PieChart"));
var _BarChart = _interopRequireDefault(require("./components/BarChart"));
var _TransactionTable = _interopRequireDefault(require("./components/TransactionTable"));
var _BoxCard = _interopRequireDefault(require("./components/BoxCard"));
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Chart from './components/mixChart'

var interval;
var exceptionInterval;
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    LineChart: _LineChart.default,
    TransactionTable: _TransactionTable.default,
    BoxCard: _BoxCard.default,
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: "最近一周",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近一个月",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近三个月",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      queryTime: [new Date(), new Date()],
      lineChartName: {},
      lineChartData: {},
      panelData: {},
      numData: {},
      amountData: {},
      pieData: {},
      barData: {},
      mixData: [],
      loginData: {},
      platformIncome: 0,
      shopData: [],
      goodsData: []
    };
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    getInfo: function getInfo() {
      var _this = this;
      (0, _index.getLoginInfo)().then(function (response) {
        var result = response.data;
        _this.loginData = result;
      });

      // countMember().then(response => {
      // 	const result = response.data
      //   this.panelData = result
      // })

      // getOrderAddList().then(response => {
      // 	const result = response.data
      //   this.lineChartData = result
      // })

      // countNumber().then(response => {
      // 	const result = response.data
      //   this.numData = result
      // })

      (0, _index.countAmount)().then(function (response) {
        var result = response.data;
        _this.amountData = result;
      });

      // getConversionReport().then(response => {
      // 	const result = response.data
      //   this.mixData = result
      // })

      // getShopSellList().then(response => {
      // 	const result = response.data
      //   this.shopData = result
      // })

      // getGoodsSellList().then(response => {
      // 	const result = response.data
      //   this.goodsData = result
      // })
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;