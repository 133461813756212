"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _order = require("@/api/material/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: [],
      listLoading: false,
      tableKey: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      form: {
        id: null,
        link: ""
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.getMaterialOrderPage)({
        page: this.pageNum,
        pageSize: this.pageSize
      }).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.total;
        _this.tableKey += 1;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    getPaymentWayLabel: function getPaymentWayLabel(paymentWay) {
      if (paymentWay === 1) {
        return "微信支付";
      } else {
        return "其他支付";
      }
    },
    getStatusLabel: function getStatusLabel(status) {
      if (status === 1) {
        return "待处理";
      } else if (status === 2) {
        return "已处理";
      } else if (status === 3) {
        return "已完成";
      } else {
        return "未知状态";
      }
    },
    formatDate: function formatDate(timestamp) {
      var date = new Date(timestamp);
      return date.toLocaleString();
    },
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getList();
    },
    handleEditLink: function handleEditLink(row) {
      this.form.id = row.id;
      this.form.link = row.link;
      this.dialogVisible = true;
    },
    handleEditConfirm: function handleEditConfirm() {
      var _this2 = this;
      var _this$form = this.form,
        id = _this$form.id,
        link = _this$form.link;
      if (id && link) {
        (0, _order.updateMaterial)({
          id: id,
          link: link
        }).then(function () {
          _this2.dialogVisible = false;
          _this2.getList();
        }).catch(function (error) {
          console.log("编辑链接失败", error);
        });
      }
    },
    handleUpload: function handleUpload(row) {
      // TODO: 处理上传操作
      console.log("上传链接", row.link);
    }
  }
};
exports.default = _default;