var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      staticClass: "avatar-uploader",
      attrs: {
        data: _vm.dataObj,
        multiple: false,
        "show-file-list": false,
        "on-success": _vm.handleImageSuccess,
        action: _vm.uploadUrl,
      },
    },
    [
      _vm.imageUrl && _vm.imageUrl != "NVL" && _vm.imageUrl.length > 1
        ? _c("el-image", {
            staticClass: "avatar",
            attrs: { src: _vm.imageUrl, fit: "contain" },
          })
        : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }