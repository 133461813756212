"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _account = require("@/api/account");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    remarkFilter: function remarkFilter(str) {
      return str.substring(0, 30);
    }
  },
  data: function data() {
    return {
      list: null
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      var queryForm = {
        page: 1,
        pageSize: 9
      };
      (0, _account.getAccountLog)(queryForm).then(function (response) {
        _this.list = response.data.items;
      });
    }
  }
};
exports.default = _default;