"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRouter = createRouter;
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.routeFilterByPath = routeFilterByPath;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

/**
 * 根据权限匹配路由
 * @param {array} permission 权限列表（菜单列表）
 * @param {array} asyncRouter 异步路由对象
 */
function createRouter(item) {
  var accessedRoutes = Object.assign({}, routeFilterByPath(1, item.url));
  if (item.children && item.children.length > 0) {
    var r2 = [];
    item.children.forEach(function (item2) {
      var accessedRoutes2 = routeFilterByPath(2, item2.url);
      if (accessedRoutes2 && accessedRoutes2.path) {
        if (item2.children && item2.children.length > 0) {
          var r3 = [];
          item2.children.forEach(function (item3) {
            var accessedRoutes3 = routeFilterByPath(3, item3.url);
            r3.push(accessedRoutes3);
          });
          accessedRoutes2.children = r3;
        }
        r2.push(accessedRoutes2);
      }
    });
    accessedRoutes.children = r2;
  }
  return accessedRoutes;
}
function routeFilterByPath(type, path) {
  var accessedRoute = {};
  if (type == 1) {
    for (var i = 0; i < _router.asyncRoutes.length; i++) {
      var route = Object.assign({}, _router.asyncRoutes[i]);
      if (route.path == path) {
        route.children = [];
        return route;
      }
    }
  } else if (type == 2) {
    var isreturn = false;
    for (var i = 0; i < _router.asyncRoutes.length; i++) {
      if (isreturn) return accessedRoute;
      if (_router.asyncRoutes[i].children && _router.asyncRoutes[i].children.length > 0) {
        for (var j = 0; j < _router.asyncRoutes[i].children.length; j++) {
          if (isreturn) return accessedRoute;
          var route2 = Object.assign({}, _router.asyncRoutes[i].children[j]);
          if (path == _router.asyncRoutes[i].path + '/' + route2.path) {
            route2.children = [];
            accessedRoute = route2;
            isreturn = true;
          }
        }
      }
    }
  } else if (type == 3) {
    var _isreturn = false;
    for (var i = 0; i < _router.asyncRoutes.length; i++) {
      if (_isreturn) return accessedRoute;
      if (_router.asyncRoutes[i].children && _router.asyncRoutes[i].children.length > 0) {
        var route1 = Object.assign({}, _router.asyncRoutes[i]);
        for (var j = 0; j < route1.children.length; j++) {
          if (_isreturn) return accessedRoute;
          var _route = Object.assign({}, route1.children[j]);
          if (_route.children && _route.children.length > 0) {
            for (var k = 0; k < _route.children.length; k++) {
              if (_isreturn) return accessedRoute;
              var route3 = Object.assign({}, _route.children[k]);
              if (path == route1.path + '/' + _route.path + '/' + route3.path) {
                route3.children = [];
                accessedRoute = route3;
                _isreturn = true;
              }
            }
          }
        }
      }
    }
  }
  return accessedRoute;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, menusRoles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var roles = menusRoles.roles,
        menus = menusRoles.menus;
      var accessedRoutes = [];
      menus.forEach(function (item) {
        var router = createRouter(item);
        if (router && router.path && router.path != '') {
          accessedRoutes.push(router);
        }
      });
      accessedRoutes.push({
        path: '*',
        redirect: '/404',
        hidden: true
      });
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;