"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _third = require("@/api/third");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'qiniuConfig',
  data: function data() {
    return {
      config: {},
      loading: true,
      rules: {
        accessKey: [{
          required: true,
          message: '请填写accessKey',
          trigger: 'blur'
        }],
        secretKey: [{
          required: true,
          message: '请填写secretKey',
          trigger: 'blur'
        }],
        imgZone: [{
          required: true,
          message: '请填写图片存储区域',
          trigger: 'change'
        }],
        imgBucket: [{
          required: true,
          message: '请填写图片空间名称',
          trigger: 'blur'
        }],
        imgViewUrl: [{
          required: true,
          message: '请填写图片下载域名',
          trigger: 'blur'
        }],
        videoZone: [{
          required: true,
          message: '请填写视频存储区域',
          trigger: 'change'
        }],
        videoBucket: [{
          required: true,
          message: '请填写视频空间名称',
          trigger: 'blur'
        }],
        videoViewUrl: [{
          required: true,
          message: '请填写视频下载域名',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.get();
  },
  methods: {
    get: function get() {
      var _this = this;
      (0, _third.getQiniuConfig)().then(function (response) {
        _this.config = response.data;
        _this.loading = false;
      });
    },
    updateConfig: function updateConfig() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var formData = {
            id: _this2.config.id,
            accessKey: _this2.config.accessKey,
            secretKey: _this2.config.secretKey,
            imgZone: _this2.config.imgZone,
            imgBucket: _this2.config.imgBucket,
            imgViewUrl: _this2.config.imgViewUrl,
            videoZone: _this2.config.videoZone,
            videoBucket: _this2.config.videoBucket,
            videoViewUrl: _this2.config.videoViewUrl
          };
          (0, _third.updateQiniuConfig)(formData).then(function () {
            _this2.$notify({
              title: '成功',
              message: '更改配置成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;