"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _warehouse = require("@/api/warehouse/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listQuery: {
        page: 1,
        pageSize: 20
      },
      tableData: [],
      total: 0,
      ruleForm: {
        id: '',
        adminId: '',
        warehouseId: '',
        amount: ''
      },
      rules: {
        amount: [{
          required: true,
          message: '请输入充值/扣减余额',
          trigger: 'blur'
        }]
      },
      dialogType: 'add',
      dialogFormVisible: false,
      amount: '',
      supplierShow: false,
      warehouseShow: false
    };
  },
  created: function created() {
    this.list_url();
    // this.getBindWarehouse_url();
  },

  methods: {
    // 编辑
    edit_list: function edit_list(row) {
      this.dialogType = 'edit';
      this.dialogFormVisible = true;
      this.ruleForm = row;
    },
    // 保存
    confirm: function confirm() {
      var _this = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this.ruleForm);
          formData.amount = _this.amount;
          (0, _warehouse.updateWsSupplier)(formData).then(function () {
            _this.list_url();
            _this.dialogFormVisible = false;
            _this.$message({
              message: '编辑成功',
              type: 'success'
            });
          });
        }
      });
    },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1;
      this.listQuery.pageSize = 20;
      this.list_url();
    },
    // 列表数据
    list_url: function list_url() {
      var _this2 = this;
      console.log('this.listQuery', this.listQuery);
      (0, _warehouse.pageWsSupplier)(this.listQuery).then(function (response) {
        console.log('列表数据', response);
        _this2.tableData = response.data.list;
        _this2.total = response.data.total;
        var list = response.data.list;
        if (list.length > 0) {
          if (list[0].userType === 'admin') {
            _this2.supplierShow = true;
            _this2.warehouseShow = true;
          }
          if (list[0].userType === 'supplier') {
            _this2.supplierShow = false;
            _this2.warehouseShow = true;
          }
          if (list[0].userType === 'warehouse') {
            _this2.supplierShow = true;
            _this2.warehouseShow = false;
          }
        }
      });
    }
  }
};
exports.default = _default;