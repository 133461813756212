var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库名称：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择仓库" },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.warehouseId,
                            callback: function ($$v) {
                              _vm.warehouseId = $$v
                            },
                            expression: "warehouseId",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (warehouse) {
                          return _c("el-option", {
                            key: warehouse.id,
                            attrs: {
                              value: warehouse.id,
                              label: warehouse.name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品标题：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入产品标题" },
                        on: { input: _vm.handleSearch },
                        model: {
                          value: _vm.productName,
                          callback: function ($$v) {
                            _vm.productName = $$v
                          },
                          expression: "productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入产品名称" },
                        on: { input: _vm.handleSearch },
                        model: {
                          value: _vm.typeName,
                          callback: function ($$v) {
                            _vm.typeName = $$v
                          },
                          expression: "typeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SKU名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入SKU名称" },
                        on: { input: _vm.handleSearch },
                        model: {
                          value: _vm.skuTitle,
                          callback: function ($$v) {
                            _vm.skuTitle = $$v
                          },
                          expression: "skuTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("span", { staticClass: "el-form-item__label" }, [
                        _vm._v("SKU品类名称："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入SKU品类名称" },
                        on: { input: _vm.handleSearch },
                        model: {
                          value: _vm.skuTypeName,
                          callback: function ($$v) {
                            _vm.skuTypeName = $$v
                          },
                          expression: "skuTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.id))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "产品标题", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.productTitle) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "产品名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.typeName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "产品图片", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticClass: "product-image",
                      attrs: { src: scope.row.imgUrl, alt: "Product Image" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.skuTitle))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU品类名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.typeSkuName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "价格", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.price))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "总库存", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.totalInv != null
                          ? scope.row.totalInv
                          : "未关联仓库"
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "总销售数量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.useInv != null
                          ? scope.row.useInv
                          : "未关联仓库"
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "剩余库存", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.residueNum != null
                          ? scope.row.residueNum
                          : "未关联仓库"
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "供应商", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.supplyName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "240" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApplyStorage(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          新增库存\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApplyStorageA(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          减少库存\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.add_cancellation(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          取消库存\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请入库",
            visible: _vm.applyStorageDialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.applyStorageDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "applyStorageForm",
              attrs: { model: _vm.applyStorageData, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新增数量" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.applyStorageData.addInv,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "addInv", _vm._n($$v))
                      },
                      expression: "applyStorageData.addInv",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "入库说明" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.applyStorageData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "content", $$v)
                      },
                      expression: "applyStorageData.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "入库凭证" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.applyStorageData.imgUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "imgUrl", $$v)
                      },
                      expression: "applyStorageData.imgUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.applyStorageDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleApplyStorageConfirm },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请入库",
            visible: _vm.applyStorageDialogVisibleA,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.applyStorageDialogVisibleA = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "applyStorageForm",
              attrs: { model: _vm.applyStorageData, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "减少数量" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.applyStorageData.decInv,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "decInv", _vm._n($$v))
                      },
                      expression: "applyStorageData.decInv",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出库说明" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.applyStorageData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "content", $$v)
                      },
                      expression: "applyStorageData.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出库凭证" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.applyStorageData.imgUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyStorageData, "imgUrl", $$v)
                      },
                      expression: "applyStorageData.imgUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.applyStorageDialogVisibleA = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleApplyStorageConfirm },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }