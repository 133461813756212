import Layout from '@/layout';
var supplyDemandRouter = {
  path: '/supplyDemand',
  component: Layout,
  redirect: '/supplyDemand/supplyDemand',
  meta: {
    title: '合作管理',
    icon: 'food-list'
  },
  alwaysShow: true,
  children: [{
    path: 'supplyDemand',
    component: function component() {
      return import('@/views/supplyDemand/list/index');
    },
    name: 'supplyDemand',
    meta: {
      title: '合作管理',
      role: []
    }
  }]
};
export default supplyDemandRouter;