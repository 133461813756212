"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _third = require("@/api/third");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var configOptions = [{
  key: 'jdwx',
  value: '京东万象',
  docUrl: 'https://wx.jdcloud.com/'
}, {
  key: 'taofake',
  value: '淘发客',
  docUrl: 'https://www.taofake.com/apistore/'
}];
var _default = {
  name: 'interface',
  data: function data() {
    return {
      config: {},
      params: {},
      loading: true,
      rules: {
        appId: [{
          required: true,
          message: '请填写appId',
          trigger: 'blur'
        }],
        secret: [{
          required: true,
          message: '请填写密钥',
          trigger: 'blur'
        }],
        apiUrl: [{
          required: true,
          message: '请填写请求链接',
          trigger: 'blur'
        }]
      },
      activeName: 'jdwx',
      configName: '',
      docUrl: ''
    };
  },
  created: function created() {
    this.get();
  },
  methods: {
    getOption: function getOption(key) {
      var option;
      configOptions.forEach(function (item) {
        if (item.key == key) {
          option = item;
        }
      });
      return option;
    },
    get: function get() {
      var _this = this;
      (0, _third.getInterfaceConfig)({
        nid: this.activeName
      }).then(function (response) {
        _this.config = response.data;
        if (!_this.config) {
          _this.config = {};
          _this.params = {};
        } else {
          _this.params = JSON.parse(_this.config.content);
        }
        var configOption = _this.getOption(_this.activeName);
        _this.configName = configOption.value;
        _this.docUrl = configOption.docUrl;
        _this.loading = false;
      });
    },
    updateConfig: function updateConfig() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var formData = {
            id: _this2.config.id,
            nid: _this2.activeName,
            content: JSON.stringify(_this2.params)
          };
          (0, _third.updateInterfaceConfig)(formData).then(function () {
            _this2.$notify({
              title: '成功',
              message: '更改配置成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleClickTab: function handleClickTab(tab, event) {
      this.activeName = tab.name;
      this.get();
    }
  }
};
exports.default = _default;