var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "pdf-uploader",
          attrs: {
            data: _vm.dataObj,
            multiple: false,
            "show-file-list": false,
            "on-success": _vm.handleFileSuccess,
            action: _vm.uploadUrl,
            accept: "application/pdf",
          },
        },
        [
          !_vm.imgUrl
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary", disabled: "false" },
                },
                [_vm._v("\n      选择PDF文件\n    ")]
              )
            : _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary", disabled: "false" },
                  on: { click: _vm.previewPDF },
                },
                [_vm._v("\n      预览PDF\n    ")]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.imgUrl
        ? _c("div", { staticClass: "pdf-preview" }, [
            _c("iframe", {
              attrs: { src: _vm.imgUrl, width: "100%", height: "500px" },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }