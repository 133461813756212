var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "100px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "子账号名称：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          placeholder: "请输入子账号名称",
                        },
                        model: {
                          value: _vm.listQuery.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "keyword", $$v)
                          },
                          expression: "listQuery.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("搜索\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", align: "center", width: "55" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "账号名称",
              width: "200",
              align: "center",
              fixed: "left",
              prop: "code",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/shopvip/shopvipaccountInfo?accountId=" +
                            scope.row.id,
                        },
                      },
                      [
                        _c("span", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(scope.row.username)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "姓名",
              width: "150",
              align: "center",
              prop: "code",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/shopvip/shopvipaccountInfo?accountId=" +
                            scope.row.id,
                        },
                      },
                      [
                        _c("span", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(scope.row.realname)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == "-3"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "17px",
                              color: "rgb(64, 158, 255)",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("待审批")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "-4"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "17px",
                              color: "rgb(245, 108, 108)",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("已拒绝")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "1"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "17px",
                              color: "rgb(64, 158, 255)",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("正常")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "-1"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "17px",
                              color: "rgb(245, 108, 108)",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("停用")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleModifyStatus(scope.row, "-1")
                              },
                            },
                          },
                          [_vm._v("\n          停用\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "-1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleModifyStatus(scope.row, "1")
                              },
                            },
                          },
                          [_vm._v("\n          启用\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "当前角色",
              width: "200",
              align: "center",
              prop: "deptName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.deptName) +
                          "-" +
                          _vm._s(scope.row.roleName)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "环境名称",
              width: "120",
              align: "center",
              prop: "deptName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.envName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "是否同步",
              width: "120",
              align: "center",
              prop: "deptName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.isAsync == true ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "注册时间",
              width: "150",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "注册IP",
              width: "150",
              align: "center",
              prop: "registerIp",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.registerIp))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最后登录IP",
              width: "150",
              align: "center",
              prop: "lastLoginIp",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.lastLoginIp))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              "min-width": "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        scope.row.status != "-3"
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/shopvip/shopvipaccountInfo?accountId=" +
                                    scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      display: "block",
                                      "margin-top": "5px",
                                      "margin-left": "5px",
                                    },
                                    attrs: {
                                      icon: "el-icon-view",
                                      size: "mini",
                                      type: "primary",
                                    },
                                  },
                                  [_vm._v("\n              详情\n            ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "-3"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  display: "block",
                                  "margin-top": "5px",
                                  "margin-left": "5px",
                                },
                                attrs: {
                                  icon: "el-icon-document",
                                  size: "mini",
                                  type: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.add_dialogVisible(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            审核\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "800px" },
          attrs: { title: "注册", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.registerForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "realname" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.registerForm.realname,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "realname", $$v)
                      },
                      expression: "registerForm.realname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号" },
                    model: {
                      value: _vm.registerForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "username", $$v)
                      },
                      expression: "registerForm.username",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "tips" }, [
                    _vm._v(
                      '\n          * 账号前面默认会添加"主账号:", 如"13444555666:客服小美"\n        '
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机" },
                    model: {
                      value: _vm.registerForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "phone", $$v)
                      },
                      expression: "registerForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密码" },
                    model: {
                      value: _vm.registerForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "password", $$v)
                      },
                      expression: "registerForm.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "环境名称", prop: "envName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入环境名称" },
                    model: {
                      value: _vm.registerForm.envName,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "envName", $$v)
                      },
                      expression: "registerForm.envName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否同步", prop: "isAsync" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.registerForm.isAsync,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "isAsync", $$v)
                        },
                        expression: "registerForm.isAsync",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.register } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "账号审核",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "560px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号审核", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "status", $$v)
                            },
                            expression: "ruleForm.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: -4 } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      key: "3",
                      attrs: {
                        label: "原因",
                        prop: "freezeReason",
                        rules:
                          _vm.ruleForm.status == -4
                            ? [
                                {
                                  required: true,
                                  message: "请输入原因",
                                  trigger: "blur",
                                },
                              ]
                            : [],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          size: "small",
                          maxlength: "100",
                          "show-word-limit": "",
                          type: "textarea",
                          rows: 8,
                        },
                        model: {
                          value: _vm.ruleForm.freezeReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "freezeReason", $$v)
                          },
                          expression: "ruleForm.freezeReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }