var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "10px" }, attrs: { shadow: "hover" } },
    [
      _c(
        "el-alert",
        {
          staticClass: "warn-content",
          attrs: {
            title: "温馨提示",
            type: "success",
            "show-icon": "",
            closable: false,
          },
        },
        [
          _vm._v("\n             本接口使用万维易源, 详情请查阅"),
          _c(
            "a",
            { attrs: { href: "https://www.showapi.com", target: "_blank" } },
            [_vm._v("《万维易源相关文档》")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticStyle: { width: "60%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.config,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "appId", prop: "appId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "appId", $$v)
                  },
                  expression: "config.appId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密钥", prop: "secret" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.config.secret,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "secret", $$v)
                  },
                  expression: "config.secret",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateConfig } },
                [_vm._v("更新配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }