import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import memberRouter from "./modules/member";
import vipRouter from "./modules/vip";
import courseRouter from "./modules/course";
import specialRouter from "./modules/special";
import systemRouter from "./modules/system";
import informationRouter from "./modules/information";
import thirdRouter from "./modules/third";
import logRouter from "./modules/log";
import shopRouter from "./modules/shop";
import businessRouter from '@/router/modules/business';
import goodsRouter from '@/router/modules/goods';
import materialRouter from '@/router/modules/material';
import noticeRouter from '@/router/modules/notice';
import customerRouter from '@/router/modules/customer';
import slideshowRouter from '@/router/modules/slideshow';
import shipRouter from "@/router/modules/ship";
import productRouter from "@/router/modules/product";
import warehouseRouter from "@/router/modules/warehouse";
import wswarehouseRouter from "@/router/modules/wswarehouse";
import fundRouter from "@/router/modules/fund";
// import bindingshop from "@/router/modules/bindingshop";
import shopvip from "@/router/modules/shopvip";
import usaaddress from "@/router/modules/usaaddress";
import association from "@/router/modules/association";
import supplier from "@/router/modules/supplier";
import supplyDemand from "@/router/modules/supplyDemand";

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
 **/
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/payservice/serviceAccount',
  component: function component() {
    return import('@/views/payservice/serviceAccount/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '/imgEdit',
  redirect: '/imgEdit/imgAiEdit',
  component: function component() {
    return import('@/views/imgEditModule/layout/index.vue');
  },
  children: [{
    path: 'imgAiEdit',
    component: function component() {
      return import('@/views/imgEditModule/page/imgAiEdit/imgAiEditPage.vue');
    },
    name: 'imgAiEdit',
    meta: {
      title: '图片AI处理'
    }
  }, {
    path: 'imgSearch',
    component: function component() {
      return import('@/views/imgEditModule/page/imgSearch/index.vue');
    },
    name: 'imgSearch',
    meta: {
      title: '图片搜索'
    }
  }],
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}];
export var asyncRoutes = [shopRouter, memberRouter, vipRouter, courseRouter, specialRouter, systemRouter, informationRouter, businessRouter, goodsRouter, logRouter, noticeRouter, customerRouter, slideshowRouter, thirdRouter, materialRouter, shipRouter, productRouter, warehouseRouter, wswarehouseRouter, fundRouter, shopvip, association,
// bindingshop,
usaaddress, supplier, supplyDemand, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;