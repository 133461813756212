"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Linediagram/index.vue"));
var _warehouse = require("@/api/warehouse/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    lineTu: _index.default
  },
  data: function data() {
    return {
      list: [],
      header: []
    };
  },
  methods: {
    list_url: function list_url() {
      var _this = this;
      (0, _warehouse.todayData)().then(function (response) {
        _this.list = response.data.warehouseOrderResults;
        var headerData = {
          todayOrderNum: response.data.todayDataResult.todayOrderNum,
          todayOrderSkuNum: response.data.todayDataResult.todayOrderSkuNum,
          todayOrderSkuCost: response.data.todayDataResult.todayOrderSkuCost,
          currentExpressFee: response.data.currentMonthDataResult.currentExpressFee,
          currentExpressProcessFee: response.data.currentMonthDataResult.currentExpressProcessFee,
          currentOrderNum: response.data.currentMonthDataResult.currentOrderNum,
          currentOrderSkuNum: response.data.currentMonthDataResult.currentOrderSkuNum,
          currentOrderSkuCost: response.data.currentMonthDataResult.currentOrderSkuCost
        };
        _this.header.push(headerData);
      });
    }
  },
  created: function created() {
    this.list_url();
  }
};
exports.default = _default;