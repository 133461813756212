var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "90px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "" },
                    },
                    [
                      _c("span", [_vm._v("店铺名称：")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "请输入店铺名称进行绑定",
                          clearable: "",
                        },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "0px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.add_save },
                        },
                        [_vm._v("绑定店铺\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.refresh_listShopCatalog_url(1)
                            },
                          },
                        },
                        [_vm._v("\n            目录详情\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺账号", prop: "username" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最后一次拉取开始时间",
              prop: "lastPullingStartTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最后一次拉取结束时间",
              prop: "lastPullingEndTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-download\n",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.detaillist_url(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n            拉取产品目录\n          ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailDialogVisible,
            title: "目录明细",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                size: "mini",
                "label-width": "90px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-bottom": "20px",
                    "font-size": "16px",
                  },
                  attrs: { label: "" },
                },
                [
                  _c("span", [_vm._v("名称：")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入名称", clearable: "" },
                    model: {
                      value: _vm.details_obj.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.details_obj, "title", $$v)
                      },
                      expression: "details_obj.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-right": "0px",
                    "margin-bottom": "20px",
                    "font-size": "16px",
                  },
                  attrs: { label: "" },
                },
                [
                  _c("span", [_vm._v("状态：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px", "margin-right": "20px" },
                      attrs: {
                        placeholder: "请选择状态",
                        size: "small",
                        clearable: "",
                      },
                      model: {
                        value: _vm.details_obj.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.details_obj, "status", $$v)
                        },
                        expression: "details_obj.status",
                      },
                    },
                    _vm._l(_vm.details_status, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.listShopCatalog_url },
                    },
                    [_vm._v("查询\n          ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refresh_listShopCatalog_url(2)
                        },
                      },
                    },
                    [_vm._v("刷新\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.batch_pullProducts_url },
                    },
                    [_vm._v("批量拉取\n          ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "orderTable",
              attrs: { data: _vm.detaillist, border: "", "max-height": 500 },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "id", prop: "id", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "username", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品ID",
                  prop: "tkProductId",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "产品名称", prop: "title", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拉取产品状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                scope.row.status == 0
                                  ? "#409EFF"
                                  : scope.row.status == 1
                                  ? "#e64242"
                                  : "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 0
                                  ? "未拉取"
                                  : scope.row.status == 1
                                  ? "拉取中"
                                  : "已完成"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: scope.row.status == 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.add_getProductFromTK(scope.row)
                              },
                            },
                          },
                          [_vm._v("拉取产品")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.details_total > 0,
                expression: "details_total > 0",
              },
            ],
            staticStyle: { width: "90%" },
            attrs: {
              total: _vm.details_total,
              page: _vm.details_obj.page,
              limit: _vm.details_obj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.details_obj, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.details_obj, "pageSize", $event)
              },
              pagination: _vm.listShopCatalog_url,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }