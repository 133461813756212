var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            size: "mini",
                            "label-width": "85px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "SKU名称：" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入SKU名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "sku", $$v)
                                  },
                                  expression: "listQuery.sku",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "中文名称：" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入中文名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.cnTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "cnTitle", $$v)
                                  },
                                  expression: "listQuery.cnTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.warehouseId",
                                  },
                                },
                                _vm._l(_vm.warehouseOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v("\n              查询\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$checkPermission(["warehouseLocationManage"]),
                      expression:
                        "$checkPermission(['warehouseLocationManage'])",
                    },
                  ],
                  staticClass: "filter-item",
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-edit",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("添加")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "仓库", prop: "warehouseName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "库区", prop: "name", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "库位",
              prop: "locationList.name",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.locationList, function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$checkPermission([
                              "warehouseLocationManage",
                            ]),
                            expression:
                              "$checkPermission(['warehouseLocationManage'])",
                          },
                        ],
                        staticClass: "filter-item",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.edit_list(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑库位\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$checkPermission([
                              "warehouseLocationManage",
                            ]),
                            expression:
                              "$checkPermission(['warehouseLocationManage'])",
                          },
                        ],
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑库位" : "新增",
                visible: _vm.dialogFormVisible,
                width: "970px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: {
                    width: "900px",
                    "margin-left": "30px",
                    "margin-top": "10px",
                  },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "110px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库区名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入库区名称",
                          oninput: "value=value.replace(/-/g, '')",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库位名称" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.ruleForm.locationList,
                            border: "",
                            "max-height": "450",
                            "header-cell-style": {
                              background: "#eef1f6",
                              color: "#606266",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "库位名字", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "locationList." +
                                              scope.$index +
                                              ".name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入库位名字",
                                              clearable: "",
                                              oninput:
                                                "value=value.replace(/-/g, '')",
                                            },
                                            model: {
                                              value: scope.row.name,
                                              callback: function ($$v) {
                                                _vm.$set(scope.row, "name", $$v)
                                              },
                                              expression: "scope.row.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2647831049
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            loading: _vm.sku_loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.add_sku(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("添加")]
                                      ),
                                      _vm._v(" "),
                                      _vm.ruleForm.locationList.length > 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delete_sku(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3698937039
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }