var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vip-member-page" },
    [
      _c(
        "el-button",
        {
          staticClass: "filter-item",
          attrs: { size: "mini", type: "primary" },
          on: { click: _vm.handleCreateMember },
        },
        [_vm._v("新增会员")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "手机号", prop: "phone" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "会员配置", prop: "vipTitle" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "开始时间", prop: "beginDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDate(row.beginDate)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "结束时间", prop: "endDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDate(row.endDate)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.createMemberDialogVisible,
            title: "新增会员",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.createMemberDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.createMemberForm, "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户ID" },
                    model: {
                      value: _vm.createMemberForm.uid,
                      callback: function ($$v) {
                        _vm.$set(_vm.createMemberForm, "uid", $$v)
                      },
                      expression: "createMemberForm.uid",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.selectUserDialogVisible = true
                        },
                      },
                    },
                    [_vm._v("查询用户")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员配置" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择会员配置" },
                      model: {
                        value: _vm.createMemberForm.vipConfigId,
                        callback: function ($$v) {
                          _vm.$set(_vm.createMemberForm, "vipConfigId", $$v)
                        },
                        expression: "createMemberForm.vipConfigId",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.title },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.createMemberDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAddMember },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.selectUserDialogVisible,
            title: "选择用户",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectUserDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.selectUserForm, "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.selectUserForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectUserForm, "phone", $$v)
                      },
                      expression: "selectUserForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "昵称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入昵称" },
                    model: {
                      value: _vm.selectUserForm.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectUserForm, "nickname", $$v)
                      },
                      expression: "selectUserForm.nickname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名" },
                    model: {
                      value: _vm.selectUserForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectUserForm, "code", $$v)
                      },
                      expression: "selectUserForm.code",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectUserDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSearchUser },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.selectUserLoading,
                  expression: "selectUserLoading",
                },
              ],
              key: _vm.selectUserTableKey,
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.selectUserList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "用户昵称", prop: "nickname" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", prop: "phone" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户名", prop: "code" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectUser(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }