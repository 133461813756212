"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSlideshow = addSlideshow;
exports.addSlideshowType = addSlideshowType;
exports.deleteSlideshow = deleteSlideshow;
exports.deleteSlideshowType = deleteSlideshowType;
exports.getSlideshowPage = getSlideshowPage;
exports.getSlideshowTypeList = getSlideshowTypeList;
exports.getSlideshowTypePage = getSlideshowTypePage;
exports.updateSlideshow = updateSlideshow;
exports.updateSlideshowType = updateSlideshowType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSlideshowTypePage(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/type/getPage',
    method: 'get',
    params: data
  });
}
function addSlideshowType(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/type/save',
    method: 'post',
    data: data
  });
}
function updateSlideshowType(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteSlideshowType(id) {
  return (0, _requestJson.default)({
    url: "/slideshow/type/deleteById/".concat(id),
    method: 'post'
  });
}
function getSlideshowTypeList(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/type/getList',
    method: 'get',
    params: data
  });
}
function getSlideshowPage(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/getPage',
    method: 'get',
    params: data
  });
}
function addSlideshow(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/save',
    method: 'post',
    data: data
  });
}
function updateSlideshow(data) {
  return (0, _requestJson.default)({
    url: '/slideshow/updateById',
    method: 'post',
    data: data
  });
}
function deleteSlideshow(id) {
  return (0, _requestJson.default)({
    url: "/slideshow/deleteById/".concat(id),
    method: 'post'
  });
}