"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var specialRouter = {
  path: '/special',
  component: _layout.default,
  redirect: '/special/type',
  name: 'special',
  alwaysShow: true,
  meta: {
    title: 'TikTok专线',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/type/index'));
      });
    },
    name: 'SpecialType',
    meta: {
      title: '专线类型',
      role: []
    }
  }, {
    path: 'resources',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/resources/index'));
      });
    },
    name: 'SpecialResources',
    meta: {
      title: '专线标准资源',
      role: []
    }
  }, {
    path: 'duration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/duration/index'));
      });
    },
    name: 'SpecialDuration',
    meta: {
      title: '专线时长',
      role: []
    }
  }, {
    path: 'useDesc',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/useDesc/index'));
      });
    },
    name: 'SpecialUseDesc',
    meta: {
      title: '专线使用方式',
      role: []
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/goods/index'));
      });
    },
    name: 'SpecialGoods',
    meta: {
      title: '专线商品',
      role: []
    }
  }, {
    path: 'area',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/special/area/index'));
      });
    },
    name: 'SpecialArea',
    meta: {
      title: '专线地区',
      role: []
    }
  }]
};
var _default = specialRouter;
exports.default = _default;