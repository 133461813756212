"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));
var _socialsignin = _interopRequireDefault(require("./socialsignin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    LangSelect: _LangSelect.default,
    SocialSign: _socialsignin.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('请输入正确的用户名'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 5) {
        callback(new Error('密码不能小于5位'));
      } else {
        callback();
      }
    };
    var validateBrandId = function validateBrandId(rule, value, callback) {
      if (value == null) {
        callback(new Error('请填写商户名称'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        checked: true
      },
      loginRules: {
        brandName: [{
          required: true,
          trigger: 'blur',
          message: '请填写商户名称'
        }],
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      redirect: undefined,
      title: process.env.VUE_APP_TITLE,
      hello: '早上好！'
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  mounted: function mounted() {
    var time = new Date();
    if (time.getHours() >= 0 && time.getHours() <= 10) {
      this.hello = '早上好！';
    } else if (time.getHours() >= 11 && time.getHours() <= 14) {
      this.hello = '中午好！';
    } else if (time.getHours() >= 15 && time.getHours() <= 18) {
      this.hello = '下午好！';
    } else if (time.getHours() >= 19 && time.getHours() <= 24) {
      this.hello = '晚上好！';
    }
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$store.dispatch('user/login', _this2.loginForm).then(function (res) {
            _this2.loading = false;
            _this2.$router.push({
              path: _this2.redirect || '/'
            });
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    afterQRScan: function afterQRScan() {
      // const hash = window.location.hash.slice(1)
      // const hashObj = getQueryObject(hash)
      // const originUrl = window.location.origin
      // history.replaceState({}, '', originUrl)
      // const codeMap = {
      //   wechat: 'code',
      //   tencent: 'code'
      // }
      // const codeName = hashObj[codeMap[this.auth_type]]
      // if (!codeName) {
      //   alert('第三方登录失败')
      // } else {
      //   this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
      //     this.$router.push({ path: '/' })
      //   })
      // }
    }
  }
};
exports.default = _default;