"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admin = require("@/api/admin");
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var statusOptions = [{
  key: "1",
  text: "正常"
}, {
  key: "-1",
  text: "锁定"
}];
var _default = {
  name: "adminCenter",
  components: {
    Upload: _singleImage.default
  },
  data: function data() {
    return {
      admin: {},
      loading: true,
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }]
      },
      statusOptions: statusOptions
    };
  },
  created: function created() {
    this.get();
  },
  methods: {
    get: function get() {
      var _this = this;
      (0, _admin.getAdmin)().then(function (response) {
        _this.admin = response.data;
        console.log("this.admin===>", _this.admin);
        _this.loading = false;
      });
    },
    getSKey: function getSKey() {
      var _this2 = this;
      (0, _admin.getKey)().then(function (res) {
        console.log(res);
        _this2.$alert(res.data, '请妥善保管您的秘钥', {
          confirmButtonText: '确定'
        });
      });
    },
    updateAdmin: function updateAdmin() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this3.admin);
          formData.addTime = null;
          formData.updTime = null;
          (0, _admin.updateAdmin)(formData).then(function (res) {
            _this3.$store.state.user.name = 111111;
            // this.$store.state.user = res;
            _this3.$notify({
              title: "成功",
              message: "个人信息修改成功",
              type: "success",
              duration: 2000
            });
            _this3.$router.go(0);
          });
        }
      });
    }
  }
};
exports.default = _default;