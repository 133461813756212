var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "10px" }, attrs: { shadow: "hover" } },
    [
      _c(
        "el-alert",
        {
          staticClass: "warn-content",
          attrs: {
            title: "温馨提示",
            type: "success",
            "show-icon": "",
            closable: false,
          },
        },
        [
          _vm._v("\n             本系统使用到\n             "),
          _c(
            "a",
            { attrs: { href: "https://mp.weixin.qq.com/", target: "_blank" } },
            [_vm._v("《微信公众平台-小程序和公众号》")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "https://open.weixin.qq.com/", target: "_blank" },
            },
            [_vm._v("《微信开放平台-APP》")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://pay.weixin.qq.com/index.php",
                target: "_blank",
              },
            },
            [_vm._v("《微信商户平台-微信支付》")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticStyle: { width: "60%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.config,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公众号AppId", prop: "gzhAppId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.gzhAppId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "gzhAppId", $$v)
                  },
                  expression: "config.gzhAppId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "公众号Secret", prop: "gzhSecret" } },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "off" },
                model: {
                  value: _vm.config.gzhSecret,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "gzhSecret", $$v)
                  },
                  expression: "config.gzhSecret",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "小程序AppId", prop: "xcxAppId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.xcxAppId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "xcxAppId", $$v)
                  },
                  expression: "config.xcxAppId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "小程序Secret", prop: "xcxSecret" } },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "off" },
                model: {
                  value: _vm.config.xcxSecret,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "xcxSecret", $$v)
                  },
                  expression: "config.xcxSecret",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "微信商户号", prop: "mchId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.mchId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "mchId", $$v)
                  },
                  expression: "config.mchId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "微信商户密钥", prop: "mchKey" } },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "off" },
                model: {
                  value: _vm.config.mchKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "mchKey", $$v)
                  },
                  expression: "config.mchKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "支付回调", prop: "payNotifyUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.payNotifyUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "payNotifyUrl", $$v)
                  },
                  expression: "config.payNotifyUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "API安全证书", prop: "certPath" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.certPath,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "certPath", $$v)
                  },
                  expression: "config.certPath",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateConfig } },
                [_vm._v("更新配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }