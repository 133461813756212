"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WarehouseList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      addOrUpdateRow: {},
      listLoading: true,
      total: 0,
      loading_01: false,
      uploadExcel: process.env.VUE_APP_BASE_API + 'ws/zone/uploadExcel',
      headers: {},
      weightTypeList: [{
        id: 0,
        name: "OZ"
      }, {
        id: 1,
        name: "LB"
      }, {
        id: 2,
        name: "其他"
      }],
      queryWeightType: '',
      weight: '',
      dialogType: "add",
      dialogFormVisible: false
    };
  },
  created: function created() {
    this.getList();
    this.headers = {
      Authorization: 'Bearer ' + (0, _auth.getToken)()
    };
  },
  methods: {
    handleEdit: function handleEdit(row) {
      this.addOrUpdateRow = (0, _objectSpread2.default)({}, row);
      this.dialogFormVisible = true;
      this.dialogType = "edit";
    },
    handleDelete: function handleDelete(id) {
      var _this = this;
      (0, _warehouse.withholdDel)(id).then(function () {
        _this.getList();
        _elementUi.Message.success("删除成功");
      }).catch(function (error) {
        console.error("删除失败", error);
        _elementUi.Message.error("删除失败");
      });
    },
    confirm: function confirm() {
      var _this2 = this;
      if (this.dialogType === "edit") {
        (0, _warehouse.saveOrUpdate)(this.addOrUpdateRow).then(function () {
          _this2.getList();
          _this2.dialogFormVisible = false;
          _this2.$message({
            message: "编辑成功",
            type: "success"
          });
        });
      } else {
        (0, _warehouse.saveOrUpdate)(this.addOrUpdateRow).then(function () {
          _this2.getList();
          _this2.dialogFormVisible = false;
          _this2.$message({
            message: "新增成功",
            type: "success"
          });
        });
      }
    },
    // 导入
    importExcel: function importExcel(val) {
      console.log("导入", val.message);
    },
    upload_success: function upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    // 上传失败回调
    upload_error: function upload_error(val) {
      this.$notify({
        title: "导入失败",
        message: "数据导入失败" + val.message,
        type: "error",
        duration: 2000
      });
      console.log("上传失败回调", val.message);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      this.loading_01 = true;
    },
    handleSearch: function handleSearch() {
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      var params = {
        weightType: this.queryWeightType,
        weight: this.weight
      };
      (0, _warehouse.getWithholdList)(params).then(function (response) {
        _this3.list = response.data;
        _this3.listLoading = false;
      });
    },
    addHandle: function addHandle() {
      this.dialogFormVisible = true;
      this.dialogType = "add";
    }
  }
};
exports.default = _default;