"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBindPage = getBindPage;
exports.getProductFromTK = getProductFromTK;
exports.getShopAccountListBySupplyId = getShopAccountListBySupplyId;
exports.getShopList = getShopList;
exports.save = save;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getShopAccountListBySupplyId(shopId, pageSize, page) {
  return (0, _requestJson.default)({
    // url: '/system/getShopAccountListBySupplyId?supplyId=' + supplyId,
    url: 'system/getShopAccountListBySupplyId/' + shopId + '?pageSize=' + pageSize + '&page=' + page,
    method: 'get'
  });
}
function getBindPage(supplyId, page, pageSize) {
  return (0, _requestJson.default)({
    url: 'shopSupply/getBindPage?supplyId=' + supplyId + '&page=' + page + '&pageSize=' + pageSize,
    method: 'get'
  });
}
function getShopList() {
  return (0, _requestJson.default)({
    url: 'shopSupply/getShopList',
    method: 'get'
  });
}
function save(data) {
  return (0, _requestJson.default)({
    url: 'shopSupply/save',
    method: 'post',
    data: data
  });
}
function getProductFromTK(shopAccountId) {
  return (0, _requestJson.default)({
    url: 'product/getProductFromTK/' + shopAccountId,
    method: 'get'
  });
}