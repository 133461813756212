"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCourseDetail = addCourseDetail;
exports.addCourseDetailInfo = addCourseDetailInfo;
exports.deleteCourseDetail = deleteCourseDetail;
exports.deleteCourseDetailInfo = deleteCourseDetailInfo;
exports.getCourseDetailInfo = getCourseDetailInfo;
exports.getCourseDetailList = getCourseDetailList;
exports.getListOfCourseType = getListOfCourseType;
exports.updateCourseDetail = updateCourseDetail;
exports.updateCourseDetailInfo = updateCourseDetailInfo;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getCourseDetailList(data) {
  return (0, _requestJson.default)({
    url: '/course/getHeaderPage',
    method: 'get',
    params: data
  });
}
function updateCourseDetail(data) {
  return (0, _requestJson.default)({
    url: 'course/updateById',
    method: 'post',
    data: data
  });
}
function addCourseDetail(data) {
  return (0, _requestJson.default)({
    url: '/course/save',
    method: 'post',
    data: data
  });
}
function getCourseDetailInfo(id) {
  return (0, _requestJson.default)({
    url: "/course/getLineListByHeaderId/".concat(id),
    method: 'get'
  });
}
function addCourseDetailInfo(data) {
  return (0, _requestJson.default)({
    url: '/course/saveLine',
    method: 'post',
    data: data
  });
}
function updateCourseDetailInfo(data) {
  return (0, _requestJson.default)({
    url: '/course/updateLineById',
    method: 'post',
    data: data
  });
}
function deleteCourseDetail(id) {
  return (0, _requestJson.default)({
    url: "course/deleteById/".concat(id),
    method: 'post'
  });
}
function deleteCourseDetailInfo(id) {
  return (0, _requestJson.default)({
    url: "course/deleteLineById/".concat(id),
    method: 'post'
  });
}
// 获取课程类型typeId
function getListOfCourseType() {
  return (0, _requestJson.default)({
    url: '/course/type/getList',
    method: 'get'
  });
}