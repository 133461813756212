"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCustomerService = addCustomerService;
exports.deleteCustomerService = deleteCustomerService;
exports.getCustomerServicePage = getCustomerServicePage;
exports.updateCustomerService = updateCustomerService;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getCustomerServicePage(data) {
  return (0, _requestJson.default)({
    url: '/customerService/getPage',
    method: 'get',
    params: data
  });
}
function addCustomerService(data) {
  return (0, _requestJson.default)({
    url: '/customerService/save',
    method: 'post',
    data: data
  });
}
function updateCustomerService(data) {
  return (0, _requestJson.default)({
    url: '/customerService/updateById',
    method: 'post',
    data: data
  });
}
function deleteCustomerService(id) {
  return (0, _requestJson.default)({
    url: "/customerService/deleteById/".concat(id),
    method: 'post'
  });
}