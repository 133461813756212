"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/goods/order");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "GoodsOrderPage",
  components: {
    Pagination: _index.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      // 列表数据
      total: 0,
      // 总记录数
      pageNum: 1,
      // 当前页码
      pageSize: 10,
      // 每页显示的记录数
      listLoading: false // 列表加载状态
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 格式化时间戳为日期字符串
    formatDate: function formatDate(timestamp) {
      var date = new Date(timestamp);
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      var seconds = ("0" + date.getSeconds()).slice(-2);
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    // 获取订单列表数据
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.getGoodsOrderPage)({
        page: this.pageNum,
        pageSize: this.pageSize
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code === 200) {
          _this.list = data.list;
          _this.total = data.total;
          _this.tableKey += 1;
        }
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    // 获取支付方式对应的标签
    getPaymentWayLabel: function getPaymentWayLabel(paymentWay) {
      // 自行添加支付方式与标签的对应关系
      if (paymentWay === 1) {
        return "微信支付";
      } else if (paymentWay === 2) {
        return "支付宝支付";
      } else {
        return "未知支付方式";
      }
    },
    getIsPay: function getIsPay(paymentWay) {
      // 自行添加支付方式与标签的对应关系
      if (paymentWay == false) {
        return "未支付";
      } else if (paymentWay == true) {
        return "已支付";
      } else {
        return "未知支付方式";
      }
    },
    // 获取订单状态对应的标签
    getStatusLabel: function getStatusLabel(status) {
      // 自行添加订单状态与标签的对应关系
      if (status === 1) {
        return "待处理";
      } else if (status === 2) {
        return "已处理";
      } else {
        return "未知状态";
      }
    }
  }
};
exports.default = _default;