"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTKWarehouseAddress = addTKWarehouseAddress;
exports.apiBandDel = apiBandDel;
exports.apiBandUpdateOrAdd = apiBandUpdateOrAdd;
exports.apiBandingPage = apiBandingPage;
exports.applyOrder = applyOrder;
exports.applyStorage = applyStorage;
exports.areaDel = areaDel;
exports.areaPageList = areaPageList;
exports.areaSaveOrUpdate = areaSaveOrUpdate;
exports.cancel = cancel;
exports.createOrderSelfLabel = createOrderSelfLabel;
exports.createorderSelf = createorderSelf;
exports.del = del;
exports.delNew = delNew;
exports.deleteTKWarehouseAddress = deleteTKWarehouseAddress;
exports.flushAnthor = flushAnthor;
exports.getApiByWarehouseId = getApiByWarehouseId;
exports.getInvLogPage = getInvLogPage;
exports.getOrderLabelNum = getOrderLabelNum;
exports.getOrderStatusNum = getOrderStatusNum;
exports.getPackingGetPage = getPackingGetPage;
exports.getPackingNewGetPage = getPackingNewGetPage;
exports.getPackingSkuList = getPackingSkuList;
exports.getPackingWarehouseList = getPackingWarehouseList;
exports.getPageByWarehouseId = getPageByWarehouseId;
exports.getStoragePage = getStoragePage;
exports.getSupplier = getSupplier;
exports.getWithholdList = getWithholdList;
exports.getWsAreaLocation = getWsAreaLocation;
exports.getWsWarehouseAreaList = getWsWarehouseAreaList;
exports.invAdd = invAdd;
exports.invReduce = invReduce;
exports.invgetPage = invgetPage;
exports.invsave = invsave;
exports.invupdate = invupdate;
exports.listTKWarehouseAddress = listTKWarehouseAddress;
exports.listUp = listUp;
exports.merchantGetList = merchantGetList;
exports.ordergetPage = ordergetPage;
exports.packingCreateOrder = packingCreateOrder;
exports.packingNewCreateOrder = packingNewCreateOrder;
exports.packingNewUpdateOrder = packingNewUpdateOrder;
exports.packingNeworderwarehousing = packingNeworderwarehousing;
exports.packingSign = packingSign;
exports.packingUpdateOrder = packingUpdateOrder;
exports.packingorderwarehousing = packingorderwarehousing;
exports.pageWsSupplier = pageWsSupplier;
exports.pageWsSupplierBill = pageWsSupplierBill;
exports.releaseOrder = releaseOrder;
exports.saveOrUpdate = saveOrUpdate;
exports.shenzhouInv = shenzhouInv;
exports.shipMethodOrder = shipMethodOrder;
exports.shipmentBatchPage = shipmentBatchPage;
exports.shipmentBatchUpdate = shipmentBatchUpdate;
exports.skuWarehousing = skuWarehousing;
exports.skuWarehousingNew = skuWarehousingNew;
exports.skulnvAndConfig = skulnvAndConfig;
exports.supplierGetPage = supplierGetPage;
exports.supplierSave = supplierSave;
exports.supplierUpdate = supplierUpdate;
exports.syncWsSkuInv = syncWsSkuInv;
exports.todayData = todayData;
exports.updateAmount = updateAmount;
exports.updateBreakAmount = updateBreakAmount;
exports.updateLabel = updateLabel;
exports.updateStatus = updateStatus;
exports.updateTKWarehouseAddress = updateTKWarehouseAddress;
exports.updateWarehouseById = updateWarehouseById;
exports.updateWsSupplier = updateWsSupplier;
exports.uploadExcelInvBatchSave = uploadExcelInvBatchSave;
exports.warehouseDelete = warehouseDelete;
exports.warehouseGetList = warehouseGetList;
exports.warehouseGetPage = warehouseGetPage;
exports.warehouseInvDelete = warehouseInvDelete;
exports.warehouseInvGetPage = warehouseInvGetPage;
exports.warehouseInvSave = warehouseInvSave;
exports.warehouseInvUpdate = warehouseInvUpdate;
exports.warehouseSave = warehouseSave;
exports.warehouseSaveInfo = warehouseSaveInfo;
exports.warehouseUpdate = warehouseUpdate;
exports.warehousegetWarehouseInfo = warehousegetWarehouseInfo;
exports.warehousesInvGetById = warehousesInvGetById;
exports.warehousesInvGetPage = warehousesInvGetPage;
exports.withholdDel = withholdDel;
exports.wsApiList = wsApiList;
exports.wsWarehouseGetPage = wsWarehouseGetPage;
exports.wsWarehouseList = wsWarehouseList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function warehouseSave(data) {
  return (0, _request.default)({
    url: '/warehouse/save',
    method: 'post',
    data: data
  });
}
function updateWarehouseById(data) {
  return (0, _request.default)({
    url: '/warehouse/updateWarehouseById',
    method: 'post',
    data: data
  });
}
function warehouseSaveInfo(data) {
  return (0, _request.default)({
    url: '/warehouse/warehouseSaveInfo',
    method: 'post',
    data: data
  });
}
function warehouseUpdate(data) {
  return (0, _request.default)({
    url: '/warehouse/updateById',
    method: 'post',
    data: data
  });
}
function warehouseDelete(id) {
  return (0, _request.default)({
    url: "/warehouse/deleteById/".concat(id),
    method: 'post'
  });
}
function warehouseGetPage(data) {
  return (0, _request.default)({
    url: '/warehouse/getPage',
    method: 'get',
    params: data
  });
}
function wsWarehouseGetPage(data) {
  return (0, _request.default)({
    url: '/warehouse/wsGetPage',
    method: 'get',
    params: data
  });
}
function warehouseGetList(type) {
  return (0, _request.default)({
    url: '/warehouse/getList' + (type ? '?type=' + type : ''),
    method: 'get'
  });
}
function merchantGetList() {
  return (0, _request.default)({
    url: '/supplier/getMerchantList',
    method: 'get'
  });
}
function warehouseInvSave(data) {
  return (0, _request.default)({
    url: '/warehouse/inv/save',
    method: 'post',
    data: data
  });
}
function warehouseInvUpdate(data) {
  return (0, _request.default)({
    url: '/warehouse/inv/updateById',
    method: 'post',
    data: data
  });
}
function warehouseInvDelete(id) {
  return (0, _request.default)({
    url: "/warehouse/inv/deleteById/".concat(id),
    method: 'post'
  });
}
function warehouseInvGetPage(data) {
  return (0, _request.default)({
    url: '/warehouse/inv/getPage',
    method: 'get',
    params: data
  });
}
function warehousesInvGetPage(data) {
  return (0, _request.default)({
    url: '/warehouse/warehouseInv/getPage',
    method: 'get',
    params: data
  });
}
function getPageByWarehouseId(data) {
  return (0, _request.default)({
    url: '/warehouse/warehouseInv/getPageByWarehouseId',
    method: 'get',
    params: data
  });
}
function warehousesInvGetById(id) {
  return (0, _request.default)({
    url: "/warehouse/inv/getList?skuId=" + id,
    method: 'get'
  });
}
function supplierSave(data) {
  return (0, _request.default)({
    url: '/supplier/save',
    method: 'post',
    data: data
  });
}
function supplierUpdate(data) {
  return (0, _request.default)({
    url: '/supplier/updateById',
    method: 'post',
    data: data
  });
}
function supplierGetPage(data) {
  return (0, _request.default)({
    url: '/supplier/page',
    method: 'get',
    params: data
  });
}
function invAdd(data) {
  return (0, _request.default)({
    url: '/warehouse/inv/add',
    method: 'post',
    data: data
  });
}
function invReduce(data) {
  return (0, _request.default)({
    url: '/warehouse/inv/reduce',
    method: 'post',
    data: data
  });
}
function applyStorage(data) {
  return (0, _request.default)({
    url: '/warehouse/apply/storage',
    method: 'post',
    data: data
  });
}
function getStoragePage(data) {
  return (0, _request.default)({
    url: '/warehouse/getStoragePage',
    method: 'get',
    params: data
  });
}
function cancel(data) {
  return (0, _request.default)({
    url: '/warehouse/cancel',
    method: 'post',
    data: data
  });
}
function listTKWarehouseAddress(pageSize, page, addressor) {
  return (0, _request.default)({
    url: '/warehouse/listTKWarehouseAddress?pageSize=' + pageSize + '&page=' + page + '&addressor=' + addressor,
    method: 'get'
  });
}
function addTKWarehouseAddress(data) {
  return (0, _requestJson.default)({
    url: '/warehouse/addTKWarehouseAddress',
    method: 'post',
    data: data
  });
}
function updateTKWarehouseAddress(data) {
  return (0, _requestJson.default)({
    url: '/warehouse/updateTKWarehouseAddress',
    method: 'post',
    data: data
  });
}
function deleteTKWarehouseAddress(tkWarehouseAddressId) {
  return (0, _requestJson.default)({
    url: '/warehouse/deleteTKWarehouseAddress/' + tkWarehouseAddressId,
    method: 'get'
  });
}
function warehousegetWarehouseInfo(id) {
  return (0, _request.default)({
    url: "/warehouse/getWarehouseInfo?id=" + id,
    method: 'get'
  });
}
function invgetPage(data) {
  return (0, _request.default)({
    url: '/ws/inv/getPage',
    method: 'get',
    params: data
  });
}
function getWsWarehouseAreaList(data) {
  return (0, _request.default)({
    url: '/ws/inv/getWsWarehouseAreaList',
    method: 'get',
    params: data
  });
}
function invupdate(data) {
  return (0, _requestJson.default)({
    url: '/ws/inv/updateLocationId',
    method: 'post',
    data: data
  });
}
function invsave(data) {
  return (0, _requestJson.default)({
    url: '/ws/inv/save',
    method: 'post',
    data: data
  });
}
function getInvLogPage(data) {
  return (0, _request.default)({
    url: '/ws/inv/getInvLogPage',
    method: 'get',
    params: data
  });
}
function areaPageList(data) {
  return (0, _request.default)({
    url: 'ws/warehouse/areaPageList',
    method: 'get',
    params: data
  });
}
function areaSaveOrUpdate(data) {
  return (0, _requestJson.default)({
    url: 'ws/warehouse/areaSaveOrUpdate',
    method: 'post',
    data: data
  });
}
function areaDel(data) {
  return (0, _requestJson.default)({
    url: 'ws/warehouse/areaDel?ids=' + data,
    method: 'post'
  });
}
function ordergetPage(data) {
  return (0, _request.default)({
    url: '/ws/order/getPage',
    method: 'get',
    params: data
  });
}
function getOrderStatusNum(data) {
  return (0, _request.default)({
    url: '/ws/order/getOrderStatusNum',
    method: 'get',
    params: data
  });
}
function getOrderLabelNum(data) {
  return (0, _request.default)({
    url: '/ws/order/getOrderLabelNum',
    method: 'get',
    params: data
  });
}
function skulnvAndConfig(data) {
  return (0, _requestJson.default)({
    url: 'ws/skuInvAndConfig',
    method: 'post',
    data: data
  });
}
function getApiByWarehouseId(data) {
  return (0, _request.default)({
    url: 'ws/getApiByWarehouseId',
    method: 'post',
    data: data
  });
}
function createorderSelf(data) {
  return (0, _requestJson.default)({
    url: 'ws/createOrderSelf',
    method: 'post',
    data: data
  });
}
function createOrderSelfLabel(data) {
  return (0, _requestJson.default)({
    url: 'ws/order/createOrderSelfLabel',
    method: 'post',
    data: data
  });
}
function updateAmount(data) {
  return (0, _requestJson.default)({
    url: 'ws/inv/updateAmount',
    method: 'post',
    data: data
  });
}
function updateBreakAmount(data) {
  return (0, _requestJson.default)({
    url: 'ws/inv/updateBreakAmount',
    method: 'post',
    data: data
  });
}
function shipmentBatchPage(data) {
  return (0, _request.default)({
    url: '/ws/shipmentBatchPage',
    method: 'post',
    data: data
  });
}
function updateStatus(id, status) {
  return (0, _request.default)({
    url: '/ws/updateStatus?id=' + id + '&status=' + status,
    method: 'post'
  });
}
function shipmentBatchUpdate(data) {
  return (0, _requestJson.default)({
    url: '/ws/shipmentBatchUpdate',
    method: 'post',
    data: data
  });
}
function releaseOrder(id, orderId) {
  return (0, _request.default)({
    url: '/ws/releaseOrder',
    method: 'post',
    data: {
      id: id,
      orderId: orderId
    }
  });
}
function listUp(data) {
  return (0, _request.default)({
    url: '/ws/zone/list?apiId=' + data,
    method: 'get'
  });
}
function getWithholdList(data) {
  return (0, _request.default)({
    url: '/ws/zone/getWithholdList',
    method: 'get',
    params: data
  });
}
function saveOrUpdate(data) {
  return (0, _requestJson.default)({
    url: '/ws/zone/saveOrUpdate',
    method: 'post',
    data: data
  });
}
function withholdDel(data) {
  return (0, _request.default)({
    url: '/ws/zone/del?id=' + data,
    method: 'post'
  });
}
function apiBandingPage(data) {
  return (0, _request.default)({
    url: '/ws/apiBandingPage',
    method: 'post',
    data: data
  });
}
function wsWarehouseList() {
  return (0, _request.default)({
    url: '/ws/wsWarehouseList',
    method: 'post'
  });
}
function wsApiList() {
  return (0, _request.default)({
    url: '/ws/wsApiList',
    method: 'post'
  });
}
function apiBandUpdateOrAdd(data) {
  return (0, _requestJson.default)({
    url: '/ws/apiBandUpdateOrAdd',
    method: 'post',
    data: data
  });
}
function apiBandDel(data) {
  return (0, _request.default)({
    url: '/ws/apiBandDel',
    method: 'post',
    data: {
      id: data
    }
  });
}
function flushAnthor() {
  return (0, _request.default)({
    url: '/ws/flushAnthor',
    method: 'post'
  });
}
function pageWsSupplier(data) {
  return (0, _request.default)({
    url: '/ws/supplier/getAmountPage',
    method: 'get',
    params: data
  });
}
function updateWsSupplier(data) {
  return (0, _requestJson.default)({
    url: '/ws/supplier/update',
    method: 'put',
    data: data
  });
}
function pageWsSupplierBill(data) {
  return (0, _request.default)({
    url: '/ws/supplier/getBillPage',
    method: 'get',
    params: data
  });
}
function updateLabel(data) {
  return (0, _requestJson.default)({
    url: '/ws/order/updateLabel',
    method: 'post',
    data: data
  });
}
function uploadExcelInvBatchSave(data) {
  return (0, _requestJson.default)({
    url: '/ws/inv/uploadExcelBatchSave',
    method: 'post',
    data: data
  });
}
function getPackingWarehouseList() {
  return (0, _request.default)({
    url: '/ws/packing/order/getWarehouseList',
    method: 'get'
  });
}
function getPackingSkuList(data) {
  return (0, _request.default)({
    url: '/ws/packing/order/getSkuList',
    method: 'get',
    params: data
  });
}
function getPackingGetPage(data) {
  return (0, _request.default)({
    url: '/ws/packing/order/getPage',
    method: 'get',
    params: data
  });
}
function packingUpdateOrder(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/order/updateOrder',
    method: 'post',
    data: data
  });
}
function del(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/order/del?id=' + data,
    method: 'get'
  });
}
function packingCreateOrder(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/order/createOrder',
    method: 'post',
    data: data
  });
}
function packingorderwarehousing(id, location) {
  return (0, _request.default)({
    url: '/ws/packing/order/warehousing?id=' + id + '&location=' + location,
    method: 'get'
  });
}
function skuWarehousing(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/order/skuWarehousing',
    method: 'post',
    data: data
  });
}
function getPackingNewGetPage(data) {
  return (0, _request.default)({
    url: '/ws/packing/orderNew/getPage',
    method: 'get',
    params: data
  });
}
function packingNewUpdateOrder(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/orderNew/updateOrder',
    method: 'post',
    data: data
  });
}
function delNew(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/orderNew/del?id=' + data,
    method: 'get'
  });
}
function packingNewCreateOrder(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/orderNew/createOrder',
    method: 'post',
    data: data
  });
}
function packingNeworderwarehousing(id, location) {
  return (0, _request.default)({
    url: '/ws/packing/orderNew/warehousing?id=' + id + '&location=' + location,
    method: 'get'
  });
}
function skuWarehousingNew(data) {
  return (0, _requestJson.default)({
    url: '/ws/packing/orderNew/skuWarehousing',
    method: 'post',
    data: data
  });
}
function packingSign(data) {
  return (0, _request.default)({
    url: '/ws/packing/order/signIn?id=' + data,
    method: 'get'
  });
}
function syncWsSkuInv(data, type) {
  return (0, _request.default)({
    url: '/ws/inv/syncWsSkuInv?warehouseId=' + data + '&type=' + type,
    method: 'get'
  });
}
function getSupplier() {
  return (0, _request.default)({
    url: '/ws/getSupplier',
    method: 'post'
  });
}
function todayData() {
  return (0, _request.default)({
    url: '/ws/order/todayData',
    method: 'post'
  });
}
function getWsAreaLocation() {
  return (0, _request.default)({
    url: '/ws/inv/getWsAreaLocation',
    method: 'get'
  });
}
function shenzhouInv() {
  return (0, _request.default)({
    url: '/warehouse/warehouseInv/Inv',
    method: 'get'
  });
}
function applyOrder(data) {
  return (0, _requestJson.default)({
    url: 'ws/applyOrder',
    method: 'post',
    data: data
  });
}
function shipMethodOrder(data) {
  return (0, _requestJson.default)({
    url: 'ws/shipMethodOrder',
    method: 'post',
    data: data
  });
}