"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteMenu = deleteMenu;
exports.getMenuList = getMenuList;
exports.updateMenu = updateMenu;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMenuList(data) {
  return (0, _request.default)({
    url: '/system/orgMenu/getList',
    method: 'get',
    params: data
  });
}
function updateMenu(data) {
  return (0, _request.default)({
    url: '/system/orgMenu/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteMenu(data) {
  return (0, _request.default)({
    url: '/system/orgMenu/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}