var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "调用接口名称", width: "200px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "调用接口标识", width: "180px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.nid))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "接口切换" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.value,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "value", $$v)
                          },
                          expression: "scope.row.value",
                        },
                      },
                      [
                        scope.row.platforms.indexOf("wwyw") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "wwyw", border: "" } },
                              [_vm._v("万维易网")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("aliapprove") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "aliapprove", border: "" } },
                              [_vm._v("阿里认证")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("qiniu") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "qiniu", border: "" } },
                              [_vm._v("七牛云")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("local") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "local", border: "" } },
                              [_vm._v("本地储存")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("alioss") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "alioss", border: "" } },
                              [_vm._v("阿里OSS")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("jdwx") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "jdwx", border: "" } },
                              [_vm._v("京东万象")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platforms.indexOf("tfk") >= 0
                          ? _c(
                              "el-radio",
                              { attrs: { label: "tfk", border: "" } },
                              [_vm._v("淘发客")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }