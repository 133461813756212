"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApproveConfig = getApproveConfig;
exports.getDeliveryConfig = getDeliveryConfig;
exports.getGiftConfig = getGiftConfig;
exports.getGiftLeft = getGiftLeft;
exports.getInterfaceConfig = getInterfaceConfig;
exports.getKuaidiConfig = getKuaidiConfig;
exports.getQiniuConfig = getQiniuConfig;
exports.getSmsConfig = getSmsConfig;
exports.getTaofakeConfig = getTaofakeConfig;
exports.getTaofakeLeft = getTaofakeLeft;
exports.getTbAssistantConfig = getTbAssistantConfig;
exports.getTbAssistantLeft = getTbAssistantLeft;
exports.getThirdConfigList = getThirdConfigList;
exports.getVrabbitConfig = getVrabbitConfig;
exports.getVrabbitLeft = getVrabbitLeft;
exports.getWeixinConfig = getWeixinConfig;
exports.getWeixinTemplate = getWeixinTemplate;
exports.getXiaofeixiangConfig = getXiaofeixiangConfig;
exports.getXiaofeixiangLeft = getXiaofeixiangLeft;
exports.getZfbConfig = getZfbConfig;
exports.updateApproveConfig = updateApproveConfig;
exports.updateDeliveryConfig = updateDeliveryConfig;
exports.updateGiftConfig = updateGiftConfig;
exports.updateInterfaceConfig = updateInterfaceConfig;
exports.updateKuaidiConfig = updateKuaidiConfig;
exports.updateQiniuConfig = updateQiniuConfig;
exports.updateSmsConfig = updateSmsConfig;
exports.updateTaofakeConfig = updateTaofakeConfig;
exports.updateTbAssistantConfig = updateTbAssistantConfig;
exports.updateThirdConfig = updateThirdConfig;
exports.updateVrabbitConfig = updateVrabbitConfig;
exports.updateWeixinConfig = updateWeixinConfig;
exports.updateWeixinTemplate = updateWeixinTemplate;
exports.updateXiaofeixiangConfig = updateXiaofeixiangConfig;
exports.updateZfbConfig = updateZfbConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
function getKuaidiConfig() {
  return (0, _request.default)({
    url: '/system/kuaidi/get',
    method: 'get'
  });
}
function updateKuaidiConfig(data) {
  return (0, _request.default)({
    url: '/system/kuaidi/editSubmit',
    method: 'post',
    data: data
  });
}
function getQiniuConfig() {
  return (0, _request.default)({
    url: '/system/qiniu/get',
    method: 'get'
  });
}
function updateQiniuConfig(data) {
  return (0, _request.default)({
    url: '/system/qiniu/editSubmit',
    method: 'post',
    data: data
  });
}
function getSmsConfig() {
  return (0, _request.default)({
    url: '/system/sms/get',
    method: 'get'
  });
}
function updateSmsConfig(data) {
  return (0, _request.default)({
    url: '/system/sms/editSubmit',
    method: 'post',
    data: data
  });
}
function getWeixinConfig() {
  return (0, _request.default)({
    url: '/system/weixin/get',
    method: 'get'
  });
}
function updateWeixinConfig(data) {
  return (0, _request.default)({
    url: '/system/weixin/editSubmit',
    method: 'post',
    data: data
  });
}
function getZfbConfig() {
  return (0, _request.default)({
    url: '/system/zfb/get',
    method: 'get'
  });
}
function updateZfbConfig(data) {
  return (0, _request.default)({
    url: '/system/zfb/editSubmit',
    method: 'post',
    data: data
  });
}
function getDeliveryConfig() {
  return (0, _request.default)({
    url: '/system/deliveryConfig/get',
    method: 'get'
  });
}
function updateDeliveryConfig(data) {
  return (0, _request.default)({
    url: '/system/deliveryConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getWeixinTemplate(data) {
  return (0, _request.default)({
    url: '/system/weixinTemplate/getList',
    method: 'get',
    params: data
  });
}
function updateWeixinTemplate(data) {
  return (0, _request.default)({
    url: '/system/weixinTemplate/editSubmit',
    method: 'post',
    data: data
  });
}
function getApproveConfig() {
  return (0, _request.default)({
    url: '/system/approveConfig/get',
    method: 'get'
  });
}
function updateApproveConfig(data) {
  return (0, _request.default)({
    url: '/system/approveConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getTaofakeConfig() {
  return (0, _request.default)({
    url: '/system/taofakeConfig/get',
    method: 'get'
  });
}
function updateTaofakeConfig(data) {
  return (0, _request.default)({
    url: '/system/taofakeConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getTaofakeLeft() {
  return (0, _request.default)({
    url: '/system/taofakeConfig/getLeftNum',
    method: 'get'
  });
}
function getVrabbitConfig() {
  return (0, _request.default)({
    url: '/system/vrabbitConfig/get',
    method: 'get'
  });
}
function updateVrabbitConfig(data) {
  return (0, _request.default)({
    url: '/system/vrabbitConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getVrabbitLeft() {
  return (0, _request.default)({
    url: '/system/vrabbitConfig/getLeftNum',
    method: 'get'
  });
}
function getXiaofeixiangConfig() {
  return (0, _request.default)({
    url: '/system/xiaofeixiangConfig/get',
    method: 'get'
  });
}
function updateXiaofeixiangConfig(data) {
  return (0, _request.default)({
    url: '/system/xiaofeixiangConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getXiaofeixiangLeft() {
  return (0, _request.default)({
    url: '/system/xiaofeixiangConfig/getLeftNum',
    method: 'get'
  });
}
function getGiftConfig() {
  return (0, _request.default)({
    url: '/system/giftConfig/get',
    method: 'get'
  });
}
function updateGiftConfig(data) {
  return (0, _request.default)({
    url: '/system/giftConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getGiftLeft() {
  return (0, _request.default)({
    url: '/system/giftConfig/getLeftNum',
    method: 'get'
  });
}
function getTbAssistantConfig() {
  return (0, _request.default)({
    url: '/system/tbAssistantConfig/get',
    method: 'get'
  });
}
function updateTbAssistantConfig(data) {
  return (0, _request.default)({
    url: '/system/tbAssistantConfig/editSubmit',
    method: 'post',
    data: data
  });
}
function getTbAssistantLeft() {
  return (0, _request.default)({
    url: '/system/tbAssistantConfig/getLeftNum',
    method: 'get'
  });
}
function getInterfaceConfig(data) {
  return (0, _request.default)({
    url: '/system/interface/get',
    method: 'get',
    params: data
  });
}
function updateInterfaceConfig(data) {
  return (0, _request.default)({
    url: '/system/interface/editSubmit',
    method: 'post',
    data: data
  });
}
function getThirdConfigList(data) {
  return (0, _request.default)({
    url: '/system/thirdConfig/getList',
    method: 'get',
    params: data
  });
}
function updateThirdConfig(data) {
  return (0, _request.default)({
    url: '/system/thirdConfig/editSubmit',
    method: 'post',
    data: data
  });
}