//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime, exportMethod } from "@/utils";
import Pagination from "@/components/Pagination";
import { Message } from "element-ui";
import store from "@/store";
import { getOrderPage } from "@/api/ship/ship";
import { warehouseGetList } from "@/api/warehouse/warehouse";
import { getShopList as _getShopList } from "@/api/product/product";
export default {
  name: "ShipList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      showStatusDialog: false,
      // 控制修改推送状态对话框的显示
      orderStatus: "",
      // 用于绑定下拉框选中值
      selectedOrderId: "",
      // 用于存储当前选中的产品ID
      syncLogDialogVisible: false,
      syncLogList: [],
      logTotal: 0,
      logQuery: {
        page: 1,
        pageSize: 10
      },
      cancelDialogVisible: false,
      selectedOrderToCancel: null,
      detailDialogVisible: false,
      currentDetail: {},
      detailLoading: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        warehouseId: "",
        status: "",
        startTime: "",
        endTime: "",
        phone: "",
        title: "",
        orderNo: "",
        shopId: ""
      },
      form: {},
      dialogFormVisible: false,
      dialogType: "",
      typeOptions: [],
      isClear: false,
      timePickerDialogVisible: false,
      // 控制时间选择器弹框的显示与隐藏
      timeRangeForm: {
        startTime: "",
        // 选择的开始时间
        endTime: "" // 选择的结束时间
      },

      manualShipDialogVisible: false,
      manualShipForm: {
        shipNo: "",
        warehouseId: "",
        carrier: "",
        carrierServiceName: ""
      },
      warehouseOptions: [],
      envNameOptions: [],
      editAddressDialogVisible: false,
      // 编辑地址对话框的显示与隐藏
      editAddressForm: {
        recipient: "",
        phone: "",
        city: "",
        state: "",
        detailAddress: "",
        zipcode: "",
        additionalAddressInformation: ""
      },
      selectedAddressOrder: null,
      // 存储选中的订单对象
      selectedOrder: null,
      // 用于存储选中的订单对象
      updateStatusForm: {
        orderId: "",
        status: ""
      },
      addmoneydialogVisible: false,
      selectedOrders: null,
      showSyncShipNoDialog: false,
      headers: {},
      token: "",
      action: "https://www.qixunetwork.com/cjdniwo/small/uploadCsv",
      handleSyncShipNo_loading: false,
      getShopList_loading: false,
      multipleSelection: []
    };
  },
  created: function created() {
    if (this.$route.query.status) {
      this.listQuery.status = this.$route.query.status;
      // console.log("值", this.listQuery.status);
    }

    this.getList();
    this.loadWarehouseOptions();
    // this.getShopList();
    this.token = store.getters.token;
    this.headers = {
      Authorization: "Bearer" + this.token
    };
    // console.log("store", store.getters.token);
  },

  methods: {
    // 点击复制
    copyname: function copyname(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      console.log("类型", file);
      var isJPG = file.type === "csv";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    // 导入
    importExcel: function importExcel(val) {
      console.log("导入", val);
    },
    // 上传成功回调
    upload_success: function upload_success(val) {
      if (val.code == 200) {
        this.getList();
        this.$notify({
          title: "成功",
          message: "导入成功",
          type: "success",
          duration: 2000
        });
      } else {
        this.$notify({
          title: "导入失败",
          message: val.message,
          type: "error",
          duration: 2000
        });
      }
      console.log("上传成功回调", val);
    },
    // 上传失败回调
    upload_error: function upload_error(val) {
      this.$notify({
        title: "导入失败",
        message: "数据导入失败",
        type: "error",
        duration: 2000
      });
      console.log("上传失败回调", val);
    },
    showPushStatusDialog: function showPushStatusDialog(row) {
      this.selectedOrderId = row.orderNo; // 存储当前选中的产品ID
      this.orderStatus = ""; // 清空下拉框选中值
      this.showStatusDialog = true; // 显示修改推送状态对话框
    },
    handleSyncShipNo: function handleSyncShipNo(row) {
      this.selectedOrder = row;
      this.orderStatus = ""; // 清空下拉框选中值
      this.showSyncShipNoDialog = true; // 显示修改推送状态对话框
    },
    updatePushStatus: function updatePushStatus() {
      var _this = this;
      // 检查是否选择了推送状态
      if (this.orderStatus === "") {
        this.$message.warning("请选择状态");
        return;
      }
      this.updateStatusForm.orderId = this.selectedOrderId;
      this.updateStatusForm.status = this.orderStatus;
      // 调用 updateStatus 接口传递选中的产品ID和推送状态
      updateStatus(this.updateStatusForm).then(function () {
        _this.getList(); // 刷新列表，你可能需要根据你的代码结构修改这部分
        _this.showStatusDialog = false; // 关闭对话框
        _this.$message.success("状态修改成功");
      }).catch(function (error) {
        console.error(error);
        _this.$message.error("状态修改失败");
      });
    },
    getShopList: function getShopList() {
      var _this2 = this;
      _getShopList().then(function (resp) {
        _this2.envNameOptions = resp.data;
      });
    },
    add_getShopList: function add_getShopList(val) {
      var _this3 = this;
      if (val != "") {
        this.getShopList_loading = true;
        _getShopList(val).then(function (resp) {
          _this3.envNameOptions = resp.data.list;
          _this3.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }

      // console.log("输入了什么", val);
    },
    getSyncLogList: function getSyncLogList() {
      var _this4 = this;
      getLogPage(this.logQuery).then(function (response) {
        _this4.syncLogList = response.data.list;
        _this4.logTotal = response.data.total;
      }).catch(function (error) {
        console.error("获取同步日志失败", error);
      });
    },
    // 打开同步日志弹框并获取日志列表
    handleViewSyncLog: function handleViewSyncLog() {
      this.syncLogDialogVisible = true;
      this.getSyncLogList();
    },
    // 获取同步状态的描述
    // getStatusDescription(syncStatus) {
    //   // 根据传入的 syncStatus 返回相应的描述文本
    //   // 假设 1 表示同步成功，2 表示同步失败
    //   return syncStatus === 1 ? "同步成功" : "同步失败";
    // },
    // 格式化时间戳为可读格式
    formatTime: function formatTime(timestamp) {
      // 实现时间格式化的方法，可以使用自己项目中的时间处理工具函数
      // 假设 parseTime 是一个时间格式化函数
      return parseTime(timestamp, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    showCancelDialog: function showCancelDialog(order) {
      this.selectedOrderToCancel = order;
      this.cancelDialogVisible = true;
    },
    resetCancelDialog: function resetCancelDialog() {
      this.selectedOrderToCancel = null;
      this.cancelDialogVisible = false;
    },
    // 取消订单确认按钮点击事件
    cancelOrderConfirm: function cancelOrderConfirm() {
      var _this5 = this;
      if (this.selectedOrderToCancel) {
        // 调用取消订单接口，传递订单ID
        cancelOrder(this.selectedOrderToCancel.orderNo).then(function () {
          _this5.$message.success("订单已成功取消");
          _this5.getList(); // 刷新列表
          _this5.resetCancelDialog(); // 关闭弹出框
        }).catch(function (error) {
          console.error("取消订单失败", error);
          _this5.$message.error("取消订单失败");
        });
      }
    },
    handleEditAddress: function handleEditAddress(row) {
      this.selectedAddressOrder = row; // 存储选中的订单对象
      // 将选中订单的地址信息赋值给编辑表单
      this.editAddressForm = {
        recipient: row.recipient,
        phone: row.phone,
        city: row.city,
        state: row.state,
        detailAddress: row.detailAddress,
        zipcode: row.zipcode,
        additionalAddressInformation: row.additionalAddressInformation
      };
      this.editAddressDialogVisible = true; // 打开编辑地址对话框
    },
    // 确认修改地址按钮点击事件
    handleEditAddressConfirm: function handleEditAddressConfirm() {
      var _this6 = this;
      // 调用 updateAddress 接口，传递修改后的地址信息
      updateAddress({
        orderId: this.selectedAddressOrder.orderNo,
        // 假设订单有一个ID字段
        recipient: this.editAddressForm.recipient,
        phone: this.editAddressForm.phone,
        city: this.editAddressForm.city,
        state: this.editAddressForm.state,
        detailAddress: this.editAddressForm.detailAddress,
        zipcode: this.editAddressForm.zipcode,
        additionalAddressInformation: this.editAddressForm.additionalAddressInformation
      }).then(function () {
        _this6.$message.success("地址修改成功");
        _this6.getList(); // 刷新列表
        _this6.editAddressDialogVisible = false; // 关闭编辑地址对话框
      }).catch(function (error) {
        console.error("地址修改失败", error);
        _this6.$message.error("地址修改失败");
      });
    },
    // 重置编辑地址对话框数据
    resetEditAddressDialog: function resetEditAddressDialog() {
      this.selectedAddressOrder = null;
      this.editAddressForm = {
        recipient: "",
        phone: "",
        city: "",
        state: "",
        detailAddress: "",
        zipcode: ""
      };
    },
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this7 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      warehouseGetList().then(function (response) {
        if (response.code === 200) {
          _this7.warehouseOptions = response.data;
        } else {
          Message.error(response.message);
        }
      });
    },
    handleExceptionSearch: function handleExceptionSearch() {
      this.listQuery.page = 1; // 查询时重置页码
      this.listQuery.type = 1;
      this.getList();
    },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1; // 查询时重置页码
      this.getList();
    },
    // 新添加的方法用于获取状态描述
    getStatusDescription: function getStatusDescription(row) {
      if (row.cnName === "") {
        return "待维护品类信息";
      } else {
        switch (row.status) {
          case 0:
            return "待发货";
          case 1:
            return "待回传物流单号";
          case 2:
            return "待同步物流单号";
          case 3:
            return "已同步物流单号";
          case 9:
            return "订单已取消";
          case 8:
            return "申请取消订单";
          case 7:
            return "订单取消已被拒绝";
          default:
            return "未知状态";
        }
      }
    },
    // 手动发货按钮点击事件
    handleManualShip: function handleManualShip(row) {
      this.selectedOrder = row; // 存储选中的订单对象
      if (this.selectedOrder.shipNo) {
        this.manualShipForm.shipNo = this.selectedOrder.shipNo;
      }
      this.manualShipDialogVisible = true; // 打开弹出框
    },
    // 确认发货按钮点击事件
    handleManualShipConfirm: function handleManualShipConfirm() {
      var _this8 = this;
      // 确保仓库和运单号都填写了
      if (!this.manualShipForm.warehouseId || !this.manualShipForm.shipNo) {
        this.$message.warning("请填写仓库和运单号");
        return;
      }

      // 调用 toShip 接口，传递订单ID和运单号
      toShip({
        orderId: this.selectedOrder.orderNo,
        warehouseId: this.manualShipForm.warehouseId,
        shipNo: this.manualShipForm.shipNo,
        carrier: this.manualShipForm.carrier,
        carrierServiceName: this.manualShipForm.carrierServiceName
      }).then(function () {
        _this8.$message.success("手动发货成功");
        _this8.getList(); // 刷新列表
        _this8.manualShipDialogVisible = false; // 关闭弹出框
      }).catch(function (error) {
        console.error("手动发货失败", error);
        _this8.$message.error("手动发货失败");
      });
    },
    handleSyncShipNoApi: function handleSyncShipNoApi() {
      var _this9 = this;
      // 确保仓库和运单号都填写了
      if (!this.manualShipForm.warehouseId) {
        this.$message.warning("请填写仓库");
        return;
      }
      this.handleSyncShipNo_loading = true;
      // 调用 toShip 接口，传递订单ID和运单号
      syncShipNo({
        orderId: this.selectedOrder.orderNo,
        warehouseId: this.manualShipForm.warehouseId
      }).then(function () {
        _this9.$message.success("同步运单号成功");
        _this9.getList(); // 刷新列表
        _this9.manualShipDialogVisible = false; // 关闭弹出框
        _this9.handleSyncShipNo_loading = false;
      }).catch(function (error) {
        _this9.handleSyncShipNo_loading = false;
        console.error("同步运单号失败", error);
        _this9.$message.error("同步运单号失败");
      });
    },
    // 重置弹出框数据
    resetManualShipDialog: function resetManualShipDialog() {
      this.manualShipForm.shipNo = "";
      this.selectedOrder = null;
    },
    rowKey: function rowKey(row) {
      return row.id;
    },
    // 批量发货按钮点击事件
    handleBatchShip: function handleBatchShip() {
      var selectedOrders = this.$refs["orderTable"].selection; // 获取选中的订单对象数组
      if (selectedOrders.length === 0) {
        this.$message.warning("请选择要批量发货的订单");
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderNo;
      }); // 提取选中订单的ID数组
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      console.log("选中的", selectedOrders);
      this.$router.push({
        path: "goods",
        query: {
          obj: JSON.stringify(selectedOrders)
        }
      });
    },
    // 物流单号同步按钮点击事件
    handleLogisticsSync: function handleLogisticsSync() {
      var _this10 = this;
      var selectedOrders = this.$refs["orderTable"].selection; // 获取选中的订单对象数组
      if (selectedOrders.length === 0) {
        this.$message.warning("请选择要同步运单号的订单");
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderNo;
      }); // 提取选中订单的ID数组
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      // 调用物流单号同步接口
      shipNoSync(orderIdsString).then(function () {
        _this10.$message.success("成功发起物流单号同步");
      }).catch(function (error) {
        console.error("物流单号同步失败", error);
        _this10.$message.error("物流单号同步发起失败");
      });
    },
    handleShowDetail: function handleShowDetail(row) {
      this.currentDetail = row;
      this.detailLoading = true;
      this.detailDialogVisible = true;
      this.detailLoading = false;
    },
    handleDelete: function handleDelete() {
      var _this11 = this;
      var selectedOrders = this.$refs["orderTable"].selection; // 获取选中的订单对象数组
      if (selectedOrders.length === 0) {
        this.$message.warning("请选择要删除的订单");
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderNo;
      }); // 提取选中订单的ID数组
      // 调用 API 删除选中的订单
      deleteById(selectedOrderIds).then(function () {
        _this11.$refs["orderTable"].clearSelection();
        _this11.getList(); // 成功删除后刷新列表
        _this11.$message.success("成功删除订单");
      }).catch(function (error) {
        console.error("删除订单失败", error);
        _this11.$message.error("删除订单失败");
      });
    },
    handleAsync: function handleAsync() {
      var _this12 = this;
      // 调用 API 删除选中的订单
      asyncOrder().then(function () {
        _this12.$message.success("成功发起订单同步，同步订单需要一定的时间，请耐心等待");
      }).catch(function (error) {
        console.error("删除订单失败", error);
        _this12.$message.error("订单同步发起失败");
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleTimePickerConfirm: function handleTimePickerConfirm() {
      // if (!this.listQuery.startTime || !this.listQuery.endTime) {
      //   this.$message.warning("请选择开始时间和结束时间");
      //   return;
      // }
      var selectedOrders = this.multipleSelection;
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderNo;
      }); // 提取选中订单的ID数组
      localStorage.setItem("selectedOrderIds", JSON.stringify(selectedOrders));
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      var myObj = {
        method: "get",
        url: "ship/exportExcel?startTime=" + this.listQuery.startTime + "&endTime=" + this.listQuery.endTime + "&warehouseId=" + this.listQuery.warehouseId + "&status=" + this.listQuery.status + "&phone=" + this.listQuery.phone + "&title=" + this.listQuery.title + "&orderNo=" + orderIdsString + "&shopId=" + this.listQuery.shopId,
        fileName: "shipOrder"
      };
      exportMethod(myObj);
    },
    handleTimePickerCancel: function handleTimePickerCancel() {
      this.timePickerDialogVisible = false;
    },
    handleExportClick: function handleExportClick() {
      this.timeRangeForm.startTime = ""; // 清空上次选择的开始时间
      this.timeRangeForm.endTime = ""; // 清空上次选择的结束时间
      this.timePickerDialogVisible = true; // 显示时间选择器弹框
    },
    getList: function getList() {
      var _this13 = this;
      this.listLoading = true;
      getOrderPage(this.listQuery).then(function (response) {
        _this13.list = response.data.list;
        _this13.total = response.data.total;
        _this13.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      var _this14 = this;
      this.dialogStatus = "add";
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this14.$refs["dataForm"].clearValidate();
      });
    },
    // 点击上传按钮的事件处理
    handleUploadClick: function handleUploadClick() {
      this.$refs.fileInput.click(); // 触发文件输入的点击事件
    },
    // 文件输入发生改变时的事件处理
    handleFileChange: function handleFileChange(event) {
      var _this15 = this;
      var file = event.target.files[0]; // 获取选择的文件
      if (file) {
        var formData = new FormData();
        formData.append("file", file); // 将文件添加到FormData对象

        var loadingMessage = Message.info({
          message: "文件上传处理中，请稍候...",
          duration: 0 // 不自动关闭
        });

        console.log("文件", file, formData);
        // 调用上传接口
        uploadExcel(formData).then(function (response) {
          // 处理上传成功后的逻辑
          // 可能是显示上传成功的消息，刷新列表等操作
          loadingMessage.close(); // 关闭处理中的提示
          _this15.getList();
          _this15.$notify({
            title: "成功",
            message: "导入成功",
            type: "success",
            duration: 2000
          });
        }).catch(function (error) {
          // 处理上传失败的逻辑
          // 可能是显示上传失败的消息，处理错误等操作

          loadingMessage.close(); // 关闭处理中的提示
          _this15.getList();
          _this15.$notify({
            title: "导入失败",
            message: "数据导入失败",
            type: "error",
            duration: 2000
          });
        });
      }
    }
  }
};