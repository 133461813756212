"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  menus: function menus(state) {
    return state.user.menus;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  webName: function webName(state) {
    return state.system.webName;
  },
  logo: function logo(state) {
    return state.system.logo;
  },
  wsAnthor: function wsAnthor(state) {
    return state.user.wsAnthor;
  },
  id: function id(state) {
    return state.user.ID;
  },
  wsUserAnthor: function wsUserAnthor(state) {
    return state.user.wsUserAnthor;
  }
};
var _default = getters;
exports.default = _default;