/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var fundRouter = {
  path: '/fund',
  component: Layout,
  redirect: 'noredirect',
  name: 'fund',
  meta: {
    title: '财务',
    icon: 'money'
  },
  children: [{
    path: 'withdraw',
    component: function component() {
      return import('@/views/fund/withdraw/index');
    },
    name: 'withdraw',
    meta: {
      title: '提现记录',
      role: ['admin']
    }
  }, {
    path: 'mission',
    component: function component() {
      return import('@/views/fund/mission/index');
    },
    name: 'mission',
    meta: {
      title: '结算出入账',
      role: ['admin']
    }
  }, {
    path: 'suppliermission',
    component: function component() {
      return import('@/views/fund/suppliermission/index');
    },
    name: 'suppliermission',
    meta: {
      title: '供应商结算出入账',
      role: ['admin']
    }
  }, {
    path: 'supplieramount',
    component: function component() {
      return import('@/views/fund/supplieramount/index');
    },
    name: 'supplieramount',
    meta: {
      title: '供应商额度结算',
      role: ['admin']
    }
  }, {
    path: 'bank',
    component: function component() {
      return import('@/views/fund/bank/index');
    },
    name: 'bank',
    meta: {
      title: '提现账户',
      role: ['admin']
    }
  }, {
    path: 'withdrawa',
    component: function component() {
      return import('@/views/fund/withdrawa/index');
    },
    name: 'withdrawa',
    meta: {
      title: '提现管理',
      role: ['admin']
    }
  }, {
    path: 'recharge',
    component: function component() {
      return import('@/views/fund/recharge/index');
    },
    name: 'recharge',
    meta: {
      title: '充值记录',
      role: ['admin']
    }
  }, {
    path: 'account',
    component: function component() {
      return import('@/views/fund/account/index');
    },
    name: 'account',
    meta: {
      title: '资金明细',
      role: ['admin']
    }
  }, {
    path: 'balance',
    component: function component() {
      return import('@/views/fund/balance/index');
    },
    name: 'balance',
    meta: {
      title: '用户余额',
      role: []
    }
  }, {
    path: 'supplierBalance',
    component: function component() {
      return import('@/views/fund/supplierBalance/index');
    },
    name: 'supplierBalance',
    meta: {
      title: '供应商余额',
      role: ['admin']
    }
  }]
};
export default fundRouter;