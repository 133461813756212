var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-row",
        {
          staticStyle: {
            "background-color": "#fff",
            padding: "20px 20px 0 20px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "SKU名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入SKU名称", clearable: "" },
                        model: {
                          value: _vm.listQuery.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "sku", $$v)
                          },
                          expression: "listQuery.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "中文名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入中文名称", clearable: "" },
                        model: {
                          value: _vm.listQuery.cnTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "cnTitle", $$v)
                          },
                          expression: "listQuery.cnTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "仓库：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.listQuery.warehouseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "warehouseId", $$v)
                            },
                            expression: "listQuery.warehouseId",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n            查询\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "tableWrapper",
          staticClass: "table-wrapper",
          staticStyle: {
            padding: "20px",
            "background-color": "#fff",
            "margin-top": "20px",
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { size: "mini", type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                size: "mini",
                type: "primary",
                icon: "el-icon-document-copy",
              },
              on: {
                click: function ($event) {
                  return _vm.inv_list()
                },
              },
            },
            [_vm._v("\n      同步商品\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                fit: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
                "max-height": _vm.tableH,
                height: "85%",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "ID",
                  prop: "id",
                  width: "50",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Sku编码", prop: "sku", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "中文名称", prop: "cnTitle", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品重量（LB）",
                  prop: "weight",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品长度（IN）",
                  prop: "length",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品宽度（IN）",
                  prop: "width",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品高度（IN）",
                  prop: "height",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "安全库存",
                  prop: "safetyAmount",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "价值（$）",
                  prop: "declarePrice",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存", prop: "amount", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "锁定库存",
                  prop: "lockedAmount",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "有效库存",
                  prop: "effectiveAmount",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "绑定仓库", width: "200px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.warehouseName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "reason", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_list(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticStyle: { width: "90%" },
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.list_url,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku编码", prop: "sku" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku编码" },
                        model: {
                          value: _vm.ruleForm.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sku", $$v)
                          },
                          expression: "ruleForm.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku自编码", prop: "selfCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku自编码" },
                        model: {
                          value: _vm.ruleForm.selfCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "selfCode", $$v)
                          },
                          expression: "ruleForm.selfCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中文名称", prop: "cnTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入中文名称" },
                        model: {
                          value: _vm.ruleForm.cnTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "cnTitle", $$v)
                          },
                          expression: "ruleForm.cnTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "英文名称", prop: "enTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入英文名称" },
                        model: {
                          value: _vm.ruleForm.enTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "enTitle", $$v)
                          },
                          expression: "ruleForm.enTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品重量", prop: "weight" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入商品重量" },
                          model: {
                            value: _vm.ruleForm.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "weight", $$v)
                            },
                            expression: "ruleForm.weight",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateWeight },
                              slot: "append",
                              model: {
                                value: _vm.currentWeight,
                                callback: function ($$v) {
                                  _vm.currentWeight = $$v
                                },
                                expression: "currentWeight",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "LB", value: "LB" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "KG", value: "KG" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品长度", prop: "length" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入商品长度" },
                          model: {
                            value: _vm.ruleForm.length,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "length", $$v)
                            },
                            expression: "ruleForm.length",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateLength },
                              slot: "append",
                              model: {
                                value: _vm.currentLength,
                                callback: function ($$v) {
                                  _vm.currentLength = $$v
                                },
                                expression: "currentLength",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品宽度", prop: "width" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入商品宽度" },
                          model: {
                            value: _vm.ruleForm.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "width", $$v)
                            },
                            expression: "ruleForm.width",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateWidth },
                              slot: "append",
                              model: {
                                value: _vm.currentWidth,
                                callback: function ($$v) {
                                  _vm.currentWidth = $$v
                                },
                                expression: "currentWidth",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品高度", prop: "height" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入商品高度" },
                          model: {
                            value: _vm.ruleForm.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "height", $$v)
                            },
                            expression: "ruleForm.height",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateHeight },
                              slot: "append",
                              model: {
                                value: _vm.currentHeight,
                                callback: function ($$v) {
                                  _vm.currentHeight = $$v
                                },
                                expression: "currentHeight",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "价钱", prop: "declarePrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入商品价钱" },
                          model: {
                            value: _vm.ruleForm.declarePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "declarePrice", $$v)
                            },
                            expression: "ruleForm.declarePrice",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("$")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "安全库存", prop: "safetyAmount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品安全库存" },
                        model: {
                          value: _vm.ruleForm.safetyAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "safetyAmount", $$v)
                          },
                          expression: "ruleForm.safetyAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dialogType == "add"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "绑定仓库", prop: "warehouseIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.warehouseIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "warehouseIds", $$v)
                                },
                                expression: "ruleForm.warehouseIds",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "绑定仓库", prop: "warehouseId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: { disabled: true, placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "warehouseId", $$v)
                                },
                                expression: "ruleForm.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }