"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecialType = addSpecialType;
exports.deleteSpecialType = deleteSpecialType;
exports.getSpecialTypeList = getSpecialTypeList;
exports.getSpecialTypePage = getSpecialTypePage;
exports.updateSpecialType = updateSpecialType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialTypePage(data) {
  return (0, _requestJson.default)({
    url: '/special/type/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialTypeList(data) {
  return (0, _requestJson.default)({
    url: '/special/type/getList',
    method: 'get',
    params: data
  });
}
function addSpecialType(data) {
  return (0, _requestJson.default)({
    url: '/special/type/save',
    method: 'post',
    data: data
  });
}
function updateSpecialType(data) {
  return (0, _requestJson.default)({
    url: '/special/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecialType(id) {
  return (0, _requestJson.default)({
    url: "/special/type/deleteById/".concat(id),
    method: 'post'
  });
}