"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteMenu = deleteMenu;
exports.getMenu = getMenu;
exports.getMenuList = getMenuList;
exports.saveMenu = saveMenu;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMenuList() {
  return (0, _request.default)({
    url: '/system/menu/getList',
    method: 'get'
  });
}
function getMenu(data) {
  return (0, _request.default)({
    url: '/system/menu/get',
    method: 'get',
    params: {
      id: data
    }
  });
}
function saveMenu(data) {
  return (0, _request.default)({
    url: '/system/menu/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteMenu(data) {
  return (0, _request.default)({
    url: 'system/menu/delete',
    method: 'post',
    params: {
      id: data
    }
  });
}