var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            size: "mini",
                            "label-width": "85px",
                            "label-position": "right",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "0px",
                                "margin-bottom": "0px",
                                "font-size": "16px",
                              },
                              attrs: { label: "SKU名称：" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入SKU名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "sku", $$v)
                                  },
                                  expression: "listQuery.sku",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "0px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { label: "中文名称：" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入中文名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.cnTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "cnTitle", $$v)
                                  },
                                  expression: "listQuery.cnTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "0px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { label: "英文名称：" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入中文名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.enTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "enTitle", $$v)
                                  },
                                  expression: "listQuery.enTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "0px",
                                "margin-bottom": "10px",
                                "font-size": "16px",
                              },
                              attrs: { label: "供应商：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.adminId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "adminId", $$v)
                                    },
                                    expression: "listQuery.adminId",
                                  },
                                },
                                _vm._l(_vm.supplierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v("\n              查询\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _vm.$checkPermission(["skuListUpAdd"])
                        ? _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-edit",
                              },
                              on: { click: _vm.handleCreate },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            loading: _vm.btn_loading,
                          },
                          on: { click: _vm.handleBatchSku },
                        },
                        [_vm._v("\n          导出sku\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.add_uploadExcel },
                        },
                        [_vm._v("导入模版\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                            loading: _vm.handleDownloadTag_loading,
                          },
                          on: { click: _vm.handleSelectSize },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.handleDownloadTag_loading
                                ? "下载中"
                                : "下载标签"
                            ) + "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1 } },
                    [
                      _c("el-button", {
                        attrs: {
                          icon: "el-icon-more-outline",
                          title: "同步ws仓库商品",
                          circle: "",
                        },
                        on: { click: _vm.wsSync },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "10%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "45", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "供应商/供货商",
              prop: "adminName",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticClass: "avatar",
                      staticStyle: { height: "50px", width: "50px" },
                      attrs: { src: scope.row.imageUrl },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Sku编码", prop: "sku", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "自编码", prop: "selfCode", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "英文名称", prop: "enTitle", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中文名称", prop: "cnTitle", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品重量（LB）", prop: "weight", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品长度（IN）", prop: "length", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品宽度（IN）", prop: "width", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品高度（IN）", prop: "height", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "安全库存", prop: "safetyAmount", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "出仓价", prop: "declarePrice", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "每箱数量", prop: "qty", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "属性", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isDian === 0
                      ? _c("span", [_vm._v("普货")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.isDian === 1
                      ? _c("span", [_vm._v("液体")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.isDian === 2
                      ? _c("span", [_vm._v("带电")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "reason", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      [
                        _vm.$checkPermission(["skuListUpAdd"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit_list(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _vm.$checkPermission(["inventoryManage"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addInvSkuWindow(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            新增库存\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _vm.$checkPermission(["wsSkuDel"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.del_list(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            删除\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
                "destroy-on-close": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$checkPermission(["wsOrderSkuSupplier"]),
                          expression:
                            "$checkPermission(['wsOrderSkuSupplier'])",
                        },
                      ],
                      attrs: { label: "供货商：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "请选择", clearable: true },
                          model: {
                            value: _vm.ruleForm.adminId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "adminId", $$v)
                            },
                            expression: "ruleForm.adminId",
                          },
                        },
                        _vm._l(_vm.supplierOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku编码", prop: "sku" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku编码" },
                        model: {
                          value: _vm.ruleForm.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sku", $$v)
                          },
                          expression: "ruleForm.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sku自编码", prop: "selfCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入Sku自编码" },
                        model: {
                          value: _vm.ruleForm.selfCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "selfCode", $$v)
                          },
                          expression: "ruleForm.selfCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中文名称", prop: "cnTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入中文名称" },
                        model: {
                          value: _vm.ruleForm.cnTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "cnTitle", $$v)
                          },
                          expression: "ruleForm.cnTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "英文名称", prop: "enTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入英文名称" },
                        model: {
                          value: _vm.ruleForm.enTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "enTitle", $$v)
                          },
                          expression: "ruleForm.enTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "min-width": "800px" },
                      attrs: { label: "图片", prop: "headImg" },
                    },
                    [
                      _c("Upload", {
                        model: {
                          value: _vm.ruleForm.imageUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "imageUrl", $$v)
                          },
                          expression: "ruleForm.imageUrl",
                        },
                      }),
                      _vm._v(" "),
                      _c("ImageSource", {
                        attrs: { mul: false },
                        model: {
                          value: _vm.ruleForm.imageUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "imageUrl", $$v)
                          },
                          expression: "ruleForm.imageUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品重量", prop: "weight" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入商品重量",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          model: {
                            value: _vm.ruleForm.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "weight", $$v)
                            },
                            expression: "ruleForm.weight",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateWeight },
                              slot: "append",
                              model: {
                                value: _vm.currentWeight,
                                callback: function ($$v) {
                                  _vm.currentWeight = $$v
                                },
                                expression: "currentWeight",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "LB", value: "LB" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "KG", value: "KG" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品长度", prop: "length" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入商品长度",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          model: {
                            value: _vm.ruleForm.length,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "length", $$v)
                            },
                            expression: "ruleForm.length",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateLength },
                              slot: "append",
                              model: {
                                value: _vm.currentLength,
                                callback: function ($$v) {
                                  _vm.currentLength = $$v
                                },
                                expression: "currentLength",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品宽度", prop: "width" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入商品宽度",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          model: {
                            value: _vm.ruleForm.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "width", $$v)
                            },
                            expression: "ruleForm.width",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateWidth },
                              slot: "append",
                              model: {
                                value: _vm.currentWidth,
                                callback: function ($$v) {
                                  _vm.currentWidth = $$v
                                },
                                expression: "currentWidth",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品高度", prop: "height" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入商品高度",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          model: {
                            value: _vm.ruleForm.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "height", $$v)
                            },
                            expression: "ruleForm.height",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              on: { change: _vm.updateHeight },
                              slot: "append",
                              model: {
                                value: _vm.currentHeight,
                                callback: function ($$v) {
                                  _vm.currentHeight = $$v
                                },
                                expression: "currentHeight",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IN", value: "IN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CM", value: "CM" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "安全库存", prop: "safetyAmount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入商品安全库存",
                          oninput: "value=value.replace(/[^0-9]/g,'')",
                        },
                        model: {
                          value: _vm.ruleForm.safetyAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "safetyAmount", $$v)
                          },
                          expression: "ruleForm.safetyAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "每箱数量", prop: "qty" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入每箱数量",
                          oninput: "value=value.replace(/[^0-9]/g,'')",
                        },
                        model: {
                          value: _vm.ruleForm.qty,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "qty", $$v)
                          },
                          expression: "ruleForm.qty",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出仓价", prop: "declarePrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入出仓价",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          model: {
                            value: _vm.ruleForm.declarePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "declarePrice", $$v)
                            },
                            expression: "ruleForm.declarePrice",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("$")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "属性", prop: "isDian" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择属性" },
                          model: {
                            value: _vm.ruleForm.isDian,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isDian", $$v)
                            },
                            expression: "ruleForm.isDian",
                          },
                        },
                        _vm._l(_vm.isDian_arr, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "reason", $$v)
                          },
                          expression: "ruleForm.reason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sizeDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "选择尺寸",
                visible: _vm.sizeDialog,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.sizeDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.sizeTypeForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "尺寸", prop: "sizeType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.sizeTypeForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.sizeTypeForm, "type", $$v)
                            },
                            expression: "sizeTypeForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "4：6" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "2：1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "7：2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "5：2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.sizeDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleDownloadTag("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadExcel_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "导入模版",
                visible: _vm.uploadExcel_sw,
                "close-on-click-modal": false,
                width: "85%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadExcel_sw = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$checkPermission([
                                "wsOrderSkuSupplier",
                              ]),
                              expression:
                                "$checkPermission(['wsOrderSkuSupplier'])",
                            },
                          ],
                          attrs: { label: "供货商：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择", clearable: true },
                              model: {
                                value: _vm.uploadSupplierId,
                                callback: function ($$v) {
                                  _vm.uploadSupplierId = $$v
                                },
                                expression: "uploadSupplierId",
                              },
                            },
                            _vm._l(_vm.supplierOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传模版", required: true } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                action: _vm.excel_action,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-progress": _vm.excel_progress,
                                "on-change": _vm.excel_importExcel,
                                "on-success": _vm.excel_upload_success,
                                "on-error": _vm.excel_upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.excel_loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.excel_loading
                                          ? "导入中"
                                          : "导入Excel模板"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btns",
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFun(
                                        "wsBatchUploadSkuTemplate_01.xlsx"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载模板\n            ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: {
                                data: _vm.excel_list,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                              on: { "selection-change": _vm.add_selection },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  selectable: _vm.checkSelectable,
                                  type: "selection",
                                  width: "45",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "sku",
                                  prop: "sku",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "sku自编码",
                                  prop: "selfCode",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "中文名",
                                  prop: "cnTitle",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "英文名",
                                  prop: "enTitle",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "重量",
                                  prop: "weight",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "长度",
                                  prop: "length",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "宽",
                                  prop: "width",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "高",
                                  prop: "height",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "安全库存",
                                  prop: "safetyAmount",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "每箱数量",
                                  prop: "qty",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "货物类型",
                                  prop: "isDian",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "货物类型", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.isDian === 0
                                                    ? "普货"
                                                    : scope.row.isDian === 1
                                                    ? "液体"
                                                    : scope.row.isDian === 2
                                                    ? "带电"
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3278147022
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  prop: "reason",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "校验信息",
                                  prop: "verificationInfo",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "校验结果", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.verification === 1
                                                    ? "通过"
                                                    : scope.row.verification ===
                                                      0
                                                    ? "未通过"
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1805636397
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "上传状态",
                                  prop: "uploadStatus",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "上传原因",
                                  prop: "remark",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", {
                        staticClass: "form_tab",
                        staticStyle: { width: "100%" },
                        attrs: { label: " " },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.uploadExcel_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.btn_loading,
                        disabled: _vm.excel_loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchOrder()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.invSkuFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.新增库存,
                visible: _vm.invSkuFormVisible,
                width: "650px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.invSkuFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.addInvRules,
                    model: _vm.addInvParam,
                    "label-width": "100px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "库区-库位", prop: "areaAndLocation" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", clearable: true },
                          model: {
                            value: _vm.addInvParam.areaAndLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.addInvParam, "areaAndLocation", $$v)
                            },
                            expression: "addInvParam.areaAndLocation",
                          },
                        },
                        _vm._l(_vm.areaLocationOptions, function (item) {
                          return _c("el-option", {
                            key: item.area + "-" + item.location,
                            attrs: {
                              label: item.area + "-" + item.location,
                              value: item.area + "-" + item.location,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存数量", prop: "amount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入库存数量" },
                        model: {
                          value: _vm.addInvParam.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.addInvParam, "amount", $$v)
                          },
                          expression: "addInvParam.amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.addInvParam.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addInvParam, "remark", $$v)
                          },
                          expression: "addInvParam.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.invSkuFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addInvConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "800px" },
          attrs: { title: "是否要仓库ID", visible: _vm.select_warhouse },
          on: {
            "update:visible": function ($event) {
              _vm.select_warhouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: { "label-width": "80px", "label-position": "right" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "保留仓库ID", prop: "warehouseIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.warehouseId_flag,
                        callback: function ($$v) {
                          _vm.warehouseId_flag = $$v
                        },
                        expression: "warehouseId_flag",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "保留", value: "0" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "不保留", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.select_warhouse = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.syncWmsConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }