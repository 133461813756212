var render, staticRenderFns
import script from "./lemon-message-goods.vue?vue&type=script&lang=js&"
export * from "./lemon-message-goods.vue?vue&type=script&lang=js&"
import style0 from "./lemon-message-goods.vue?vue&type=style&index=0&id=0ea2ed91&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea2ed91",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tk-admin_Z7th/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ea2ed91')) {
      api.createRecord('0ea2ed91', component.options)
    } else {
      api.reload('0ea2ed91', component.options)
    }
    
  }
}
component.options.__file = "src/layout/components/Message/lemon-message-goods.vue"
export default component.exports