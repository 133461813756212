"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page404',
  computed: {
    message: function message() {
      return '该页面不存在或已被删除......';
    }
  }
};
exports.default = _default;