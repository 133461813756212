import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WangEnduit from "@/components/WangEditor/index";
import { parseTime } from '@/utils';
import waves from '@/directive/waves';
import Pagination from '@/components/Pagination';
import { getGoodsList, addGoods, updateGoods, deleteGoods, getTypeList as _getTypeList } from '@/api/goods/list';
import Upload from "@/components/Upload/singleImage";
export default {
  name: 'GoodsManagement',
  components: {
    Pagination: Pagination,
    WangEnduit: WangEnduit,
    Upload: Upload
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      form: {},
      dialogFormVisible: false,
      dialogType: '',
      rules: {
        typeId: [{
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入价格',
          trigger: 'blur'
        }]
      },
      typeList: [],
      editorOptions: {
        placeholder: '请输入内容',
        theme: 'snow'
      },
      isClear: false
    };
  },
  created: function created() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    changeEditor: function changeEditor(val) {
      this.form.content = val;
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      getGoodsList(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function (error) {
        console.error(error);
      });
    },
    getTypeList: function getTypeList() {
      var _this2 = this;
      _getTypeList().then(function (response) {
        _this2.typeList = response.data;
      }).catch(function (error) {
        console.error(error);
      });
    },
    getTypeName: function getTypeName(typeId) {
      var type = this.typeList.find(function (item) {
        return item.id === typeId;
      });
      return type ? type.type : '';
    },
    handleCreate: function handleCreate() {
      var _this3 = this;
      this.dialogType = 'add';
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.form = Object.assign({}, row);
      this.dialogType = 'edit';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    confirm: function confirm() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this5.form);
          if (_this5.dialogType === 'add') {
            addGoods(formData).then(function () {
              _this5.getList();
              _this5.dialogFormVisible = false;
              _this5.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success',
                duration: 2000
              });
            }).catch(function (error) {
              console.error(error);
            });
          } else if (_this5.dialogType === 'edit') {
            updateGoods(formData).then(function () {
              _this5.getList();
              _this5.dialogFormVisible = false;
              _this5.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
            }).catch(function (error) {
              console.error(error);
            });
          }
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('确认删除该商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteGoods(row.id).then(function () {
          _this6.getList();
          _this6.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        }).catch(function (error) {
          console.error(error);
        });
      }).catch(function () {});
    }
  },
  filters: {
    parseTime: parseTime
  }
};