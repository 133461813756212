var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SKU名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入SKU名称", clearable: "" },
                        model: {
                          value: _vm.listQuery.sku,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "sku", $$v)
                          },
                          expression: "listQuery.sku",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n          查询\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "85%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Sku编码", prop: "sku", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "供应商名字", prop: "adminName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "锁定总数量", prop: "totalInv", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "单价", prop: "price", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "总价", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.price * scope.row.totalInv)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "子账号名字", prop: "shopName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核状态", width: "200px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("div", [_vm._v("待审核")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c("div", [_vm._v("审核成功")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("div", [_vm._v("拒绝审核")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "绑定仓库", width: "200px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.warehouseName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              width: "170px",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "修改时间",
              width: "170px",
              align: "center",
              prop: "updTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.updTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_list(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          审核\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "800px" },
              attrs: { title: "退回库存", visible: _vm.dialogFormVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.refundInfo,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.refundInfo.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundInfo, "status", $$v)
                            },
                            expression: "refundInfo.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1, border: "" } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2, border: "" } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "原因", prop: "amount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入原因" },
                        model: {
                          value: _vm.refundInfo.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundInfo, "remark", $$v)
                          },
                          expression: "refundInfo.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }