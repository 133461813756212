import Layout from '@/layout';
var noticeRouter = {
  path: '/notice',
  component: Layout,
  redirect: '/notice/list',
  name: 'notice',
  alwaysShow: true,
  meta: {
    title: '公告',
    icon: 'food-list'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/notice/list/index');
    },
    name: 'noticeList',
    meta: {
      title: '公告列表',
      role: []
    }
  }]
};
export default noticeRouter;