"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'supplierIndexComponent',
  components: {},
  props: {},
  data: function data() {
    return {
      memberId: "",
      show: false,
      rules: {
        supplierAmount: [{
          required: true,
          message: '请填写金额',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请填写备注',
          trigger: 'blur'
        }]
      },
      accountForm: {},
      loading: false
    };
  },
  methods: {
    updateMemberAccount: function updateMemberAccount() {
      var _this = this;
      if (this.loading) {
        return;
      }
      if (!this.memberId) {
        return;
      }
      this.accountForm.memberId = this.memberId;
      this.loading = true;
      (0, _member.updateMemberAccount)(this.accountForm).then(function (response) {
        _this.colse();
        _this.loading = false;
      }).catch(function (error) {
        _this.loading = false;
        alert("保存失败！");
      });
    },
    open: function open(memberId) {
      if (!memberId) {
        return;
      }
      this.memberId = memberId;
      this.accountForm = {};
      this.show = true;
    },
    colse: function colse() {
      this.show = false;
      this.accountForm = {};
      this.$notify({
        title: "成功",
        message: "保存成功",
        type: "success",
        duration: 2000
      });
      this.$emit("change");
    }
  }
};
exports.default = _default;