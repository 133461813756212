"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVip = addVip;
exports.deleteVip = deleteVip;
exports.getTypeList = getTypeList;
exports.getVipTypeList = getVipTypeList;
exports.updateVip = updateVip;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getVipTypeList(data) {
  return (0, _requestJson.default)({
    url: '/vip/configPage',
    method: 'get',
    params: data
  });
}
function addVip(data) {
  return (0, _requestJson.default)({
    url: '/vip/config/save',
    method: 'post',
    data: data
  });
}
function updateVip(data) {
  return (0, _requestJson.default)({
    url: '/vip/config/updateById',
    method: 'post',
    data: data
  });
}
function deleteVip(id) {
  return (0, _requestJson.default)({
    url: "/vip/config/removeById/".concat(id),
    method: 'post'
  });
}
function getTypeList(data) {
  return (0, _requestJson.default)({
    url: '/vip/configGetList',
    method: 'get',
    params: data
  });
}