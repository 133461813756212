import Layout from '@/layout';
var vipRouter = {
  path: '/association',
  component: Layout,
  redirect: '/associationlist',
  name: 'Association',
  alwaysShow: true,
  meta: {
    title: '供应商与主账号关联',
    icon: 'food-list'
  },
  children: [{
    path: 'associationlist',
    component: function component() {
      return import('@/views/association/associationlist');
    },
    name: 'Associationlist',
    meta: {
      title: '供应商与主账号',
      role: []
    }
  }, {
    path: 'associationfund',
    component: function component() {
      return import('@/views/association/associationfund');
    },
    name: 'Associationfund',
    meta: {
      title: '供应商资金明细',
      role: []
    }
  }]
};
export default vipRouter;