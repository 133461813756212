"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var informationRouter = {
  path: '/information',
  component: _layout.default,
  redirect: '/information/type',
  name: 'information',
  alwaysShow: true,
  meta: {
    title: '资讯',
    icon: 'report-brand'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/information/type/index'));
      });
    },
    name: 'InformationType',
    meta: {
      title: '资讯类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/information/list/index'));
      });
    },
    name: 'InformationArticle',
    meta: {
      title: '资讯列表',
      role: []
    }
  }, {
    path: 'comments/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/information/list/comments'));
      });
    },
    name: 'InformationComments',
    meta: {
      title: '查看评论列表',
      role: [],
      activeMenu: '/course/detail'
    },
    hidden: true
  }]
};
var _default = informationRouter;
exports.default = _default;