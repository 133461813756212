"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShopCouponList = getShopCouponList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getShopCouponList(data) {
  return (0, _request.default)({
    url: 'system/coupon/getList',
    method: 'get',
    params: data
  });
}