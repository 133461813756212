"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBankSelector = getBankSelector;
exports.getDeliveryTypeSelector = getDeliveryTypeSelector;
exports.getWebInfo = getWebInfo;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function getWebInfo() {
  return (0, _request.default)({
    url: '/system/getWebInfo',
    method: 'get'
  });
}
function getBankSelector() {
  return (0, _request.default)({
    url: '/system/getBankSelector',
    method: 'get'
  });
}
function getDeliveryTypeSelector() {
  return (0, _request.default)({
    url: '/system/deliveryType/getSelector',
    method: 'get'
  });
}
function upload(data) {
  return (0, _request.default)({
    url: '/system/upload',
    method: 'post',
    data: data
  });
}