var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
        [_vm._v("新增")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "area", label: "地区" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "imgUrl", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "40px", height: "40px" },
                      attrs: { src: scope.row.imgUrl, alt: "" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "addTime", label: "添加时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.parseTime(scope.row.addTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "updTime", label: "更新时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.parseTime(scope.row.updTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        attrs: {
          total: _vm.total,
          "current-page": _vm.listQuery.pageNum,
          "page-size": _vm.listQuery.pageSize,
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNum", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNum", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          pagination: _vm.fetchList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            title: _vm.dialogType === "add" ? "新增" : "编辑",
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.dialogFormVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "地区", prop: "area" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地区" },
                    model: {
                      value: _vm.form.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "area", $$v)
                      },
                      expression: "form.area",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图片", prop: "imgUrl" } },
                [
                  _c("Upload", {
                    attrs: { placeholder: "请输入图片URL" },
                    model: {
                      value: _vm.form.imgUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "imgUrl", $$v)
                      },
                      expression: "form.imgUrl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }