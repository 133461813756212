"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var vipRouter = {
  path: '/association',
  component: _layout.default,
  redirect: '/associationlist',
  name: 'Association',
  alwaysShow: true,
  meta: {
    title: '供应商与主账号关联',
    icon: 'food-list'
  },
  children: [{
    path: 'associationlist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/association/associationlist'));
      });
    },
    name: 'Associationlist',
    meta: {
      title: '供应商与主账号',
      role: []
    }
  }, {
    path: 'associationfund',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/association/associationfund'));
      });
    },
    name: 'Associationfund',
    meta: {
      title: '供应商资金明细',
      role: []
    }
  }]
};
var _default = vipRouter;
exports.default = _default;