var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "money_box" }, [
      _c(
        "div",
        { staticClass: "money_container" },
        _vm._l(_vm.money_data, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "money_son",
              on: {
                click: function ($event) {
                  return _vm.add_product(item.name)
                },
              },
            },
            [
              _c("div", { staticClass: "money_son_left" }, [
                _c("img", {
                  style: { width: item.width, height: item.height },
                  attrs: { src: item.image, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "money_son_right" }, [
                _c(
                  "div",
                  {
                    staticClass: "money_right_number",
                    style: { color: item.color },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.number) + "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "money_right_name" }, [
                  _vm._v("\n            " + _vm._s(item.name) + "\n          "),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "big_box" }, [
      _c("div", { staticClass: "list_box", style: _vm.left_box_style }, [
        _c("div", { staticClass: "data_ttitle" }, [
          _vm._v("\n        订单管理\n      "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list_content" },
          _vm._l(_vm.list_data, function (item, index) {
            return _c("div", { key: index, staticClass: "list_for" }, [
              _c("div", { staticClass: "list_img" }, [
                _c("img", {
                  style: { width: item.width, height: item.height },
                  attrs: { src: item.image, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list_number", style: { color: item.color } },
                [
                  _vm._v(
                    "\n            " + _vm._s(item.number) + "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "list_name" }, [
                _vm._v("\n            " + _vm._s(item.name) + "\n          "),
              ]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "icon_box", style: _vm.right_box_style }, [
        _c("div", { staticClass: "data_ttitle" }, [
          _vm._v("\n        快捷方式\n      "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "icon_content" },
          _vm._l(_vm.icon_data, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "icon_for",
                on: {
                  click: function ($event) {
                    return _vm.add_router(item.router)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon_img_box",
                    style: { background: item.color },
                  },
                  [
                    _c("img", {
                      style: { width: item.width, height: item.height },
                      attrs: { src: item.image, alt: "" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "icon_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "line_box", style: _vm.left_box_style },
        [
          _c("div", { staticClass: "data_ttitle" }, [
            _vm._v("\n        总账台\n      "),
          ]),
          _vm._v(" "),
          _vm.lineTu_sw
            ? _c(
                "div",
                { staticClass: "line_tu" },
                [
                  _c("lineTu", {
                    attrs: {
                      number: _vm.data_arr.order_price,
                      time: _vm.data_arr.date,
                      visitor: _vm.data_arr.visitor,
                      colour: _vm.colour_arr,
                      legend: _vm.legend,
                      title: _vm.title,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.lineTu_sw
            ? _c("el-empty", { attrs: { description: "暂无数据" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "order_box", style: _vm.right_box_style }, [
        _c("div", { staticClass: "data_ttitle" }, [
          _vm._v("\n        财务管理\n      "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "order_content" },
          _vm._l(_vm.order_data, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "order_for",
                on: {
                  click: function ($event) {
                    return _vm.add_order(item.name)
                  },
                },
              },
              [
                _c("div", { staticClass: "order_img" }, [
                  _c("img", {
                    style: { width: item.width, height: item.height },
                    attrs: { src: item.image, alt: "" },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order_number", style: { color: item.color } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.number) + "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "order_name" }, [
                  _vm._v("\n            " + _vm._s(item.name) + "\n          "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }