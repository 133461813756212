"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shopvip = require("@/api/shopvip/shopvip.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'accoutIndexComponent',
  components: {},
  props: {},
  data: function data() {
    return {
      accountId: "",
      show: false,
      shopAccount: 0,
      accountForm: {},
      loading: false,
      rules: {
        amount: [{
          required: true,
          message: '请填写金额',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请填写备注',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    getMember: function getMember() {
      var _this = this;
      if (!this.accountId) {
        return;
      }
      var par = {
        accountId: this.accountId
      };
      (0, _shopvip.getShopAccountDetail)(par).then(function (response) {
        var result = response.data;
        if (result && result.member) {
          _this.shopAccount = result.memberAccount;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    updateMemberAccount: function updateMemberAccount() {
      var _this2 = this;
      if (this.loading) {
        return;
      }
      if (!this.accountId) {
        return;
      }
      this.accountForm.memberId = this.accountId;
      this.loading = true;
      (0, _shopvip.updateMemberAccount)(this.accountForm).then(function (response) {
        _this2.getMember();
        _this2.colse();
        _this2.loading = false;
      }).catch(function (error) {
        _this2.loading = false;
        alert("保存失败！");
      });
    },
    open: function open(accountId) {
      if (!accountId) {
        return;
      }
      this.accountId = accountId;
      this.accountForm = {};
      this.getMember();
      this.show = true;
    },
    colse: function colse() {
      this.show = false;
      this.accountForm = {};
      this.$notify({
        title: "成功",
        message: "保存成功",
        type: "success",
        duration: 2000
      });
      this.$emit("change");
    }
  }
};
exports.default = _default;