import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadSKU from "@/components/Upload/singleImageUsSKU.vue";
import { skuSave, skuUpdateById, skuDeleteById, getProductSku } from "@/api/product/product";
export default {
  props: ["value", "variations", "dialogType_sw", "productForm_id"],
  //   computed: {
  //     skuList() {
  //       console.log("父数据的值", this.value);
  //       return this.value;
  //     }
  //   },
  components: {
    UploadSKU: UploadSKU
  },
  data: function data() {
    return {
      main: false,
      //是否有图片
      name_select: [{
        value: "Color",
        label: "Color"
      }],
      ruleForm: {
        productSkuVariations:
        // [
        //   {
        //     name: "",
        //     domains: [
        //       {
        //         value: ""
        //       }
        //     ]
        //   },
        //   {
        //     name: "",
        //     domains: [
        //       {
        //         value: ""
        //       }
        //     ]
        //   }
        // ]
        [
          // {
          //   name: "color",
          //   domains: [
          //     {
          //       value: "color1"
          //     },
          //     {
          //       value: "color2"
          //     },
          //     {
          //       value: "color3"
          //     }
          //   ]
          // },
          // {
          //   name: "seze",
          //   domains: [
          //     {
          //       value: "seze1"
          //     },
          //     {
          //       value: "seze2"
          //     },
          //     {
          //       value: "seze3"
          //     }
          //   ]
          // },
          // {
          //   name: "big",
          //   domains: [
          //     {
          //       value: "big1"
          //     },
          //     {
          //       value: "big2"
          //     },
          //     {
          //       value: "big3"
          //     }
          //   ]
          // }
        ]
      },
      rules: {
        name: [{
          required: true,
          message: "请选择或输入规则名称",
          trigger: "change"
        }]
      },
      skuList: [],
      dialogType: "add",
      expressPrice: "",
      codeType_list: [{
        value: "GTIN",
        label: "GTIN规则:14位，仅限数字"
      }, {
        value: "EAN",
        label: "EAN规则:8、13、14位，仅限数字"
      }, {
        value: "UPC",
        label: "UPC规则:12位，仅限数字"
      }, {
        value: "ISBN",
        label: "ISBN规则:13位，最后一位支持输入X（大写）"
      }],
      packageSku_sw: false,
      props_obj: {},
      searchtitle: ""
    };
  },
  methods: {
    add_package: function add_package(row) {
      console.log("row", row);
      this.props_obj = row;
      this.searchtitle = "";
      this.packageSku_sw = true;
    },
    add_title: function add_title() {
      var _this = this;
      if (this.props_obj.packageType == 1) {
        var title = this.searchtitle;
        getProductSku(title).then(function (res) {
          _this.props_obj.shipSkuList = res.data;
          _this.props_obj.shipSkuList.forEach(function (val) {
            val.quantity = "";
          });
          console.log("绑定sku", res);
        });
      } else {
        val.packageSkuList = [];
      }
    },
    getRowClass: function getRowClass(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
    } // 把每一行的索引放进row
    // row.index = rowIndex;
    // // 判断当前行是否有子数据
    // if (
    //   row.packageSkuList === null ||
    //   row.packageSkuList === undefined ||
    //   row.packageSkuList.length === 0 ||
    //   row.packageSkuList === [] ||
    //   row.shipSkuList === null ||
    //   row.shipSkuList === undefined ||
    //   row.shipSkuList.length === 0 ||
    //   row.shipSkuList === []
    // ) {
    //   // console.log("row11111", row);
    //   return "row-hidden-expand-icon";
    // } else {
    //   // console.log("row22222", row);
    //   return "row-show-icon";
    // }
    ,
    // 删除
    delete_list: function delete_list(val, index, valchildren) {
      var _this2 = this;
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        val.splice(index, 1);
        console.log("数据", val, index, valchildren);
        _this2.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    // 选择skuId
    add_skuId: function add_skuId(item) {
      console.log(item);
      // return;
      if (item.quantity != "") {
        console.log("this.skuList", this.skuList);
        var obj = {
          productSkuTitle: item.title,
          quantity: item.quantity
        };
        if (this.props_obj.packageSkuList) {
          this.props_obj.packageSkuList.push(obj);
        } else {
          this.props_obj.packageSkuList = [];
          this.props_obj.packageSkuList.push(obj);
        }
        this.$forceUpdate();

        // this.skuList.splice();
        this.add_input();
        this.$message({
          type: "success",
          message: "添加成功"
        });
      } else {
        this.$message.error("请输入发货SKU数量");
      }
    },
    // 点击是否是套餐
    add_packageType: function add_packageType(val) {
      if (val.packageType == 1) {
        var title = "";
        getProductSku(title).then(function (res) {
          val.shipSkuList = res.data;
          val.shipSkuList.forEach(function (val) {
            val.quantity = "";
          });
          console.log("绑定sku", res);
        });
      } else {
        val.packageSkuList = [];
      }
    },
    shuffleArray: function shuffleArray(array) {
      // Fisher-Yates shuffle algorithm
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var _ref2 = [array[j], array[i]];
        array[i] = _ref2[0];
        array[j] = _ref2[1];
      }
      return array;
    },
    // EAN:8、13、14位，仅限数字；
    generateRandomString: function generateRandomString(length) {
      var numbers = Array.from({
        length: 10
      }, function (_, i) {
        return i.toString();
      });
      var shuffledNumbers = this.shuffleArray(numbers);
      var randomString = shuffledNumbers.slice(0, length).join("");
      return randomString;
    },
    generateRandomUniqueString: function generateRandomUniqueString() {
      var lengthOptions = [8, 13, 14];
      var randomLength = lengthOptions[Math.floor(Math.random() * lengthOptions.length)];
      return this.generateRandomString(randomLength);
    },
    // ISBN:13位，最后一位支持输入X（大写）
    generateRandomStringWithX: function generateRandomStringWithX() {
      var numbers = "0123456789";
      var result = "";
      // Generate 12 random digits
      for (var i = 0; i < 12; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }
      // Append X at the end
      result += "X";
      return result;
    },
    // 点击商品编码类型
    add_codeType: function add_codeType(val, index) {
      this.$set(this.skuList[index], "productIdentifierCode", "");
      if (val.value == "GTIN") {
        this.skuList[index].productIdentifierCode = Math.random().toString().slice(2, 16);
      } else if (val.value == "EAN") {
        this.skuList[index].productIdentifierCode = this.generateRandomUniqueString();
      } else if (val.value == "UPC") {
        this.skuList[index].productIdentifierCode = Math.floor(100000000000 + Math.random() * 900000000000).toString();
      } else if (val.value == "ISBN") {
        this.skuList[index].productIdentifierCode = this.generateRandomStringWithX();
      }

      // this.$forceUpdate();
      this.skuList.splice();
      this.add_input();
      console.log("val", val, index);
    },
    //input变化时执行
    add_input: function add_input() {
      this.$emit("input", this.skuList);
      console.log("this.skuList", this.skuList);
    },
    // 列表删除规则
    handleDeleteSku: function handleDeleteSku(row, index) {
      console.log("index", row, index);
      if (this.dialogType === "add") {
        this.skuList.splice(index, 1);
      } else {
        this.skuList.splice(index, 1);
        console.log("index", index);
        // skuDeleteById(index.id)
        //   .then(() => {
        //     this.$emit("add_deleteById");
        //     this.$notify({
        //       title: "成功",
        //       message: "删除成功",
        //       type: "success",
        //       duration: 2000
        //     });
        //   })
        //   .catch(error => {
        //     console.error(error);
        //   });
      }
    },
    // 保存规则
    handleSaveSku: function handleSaveSku(sku) {
      var _this3 = this;
      console.log("sku", sku);
      sku.productId = this.productForm_id;
      // If SKU has no ID, it's a new SKU, so call the save API
      if (!sku.id) {
        skuSave(sku).then(function () {
          _this3.$message.success("SKU保存成功");
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        skuUpdateById(sku).then(function () {
          _this3.$message.success("SKU更新成功");
        }).catch(function (error) {
          console.error(error);
        });
      }
    },
    // 删除规则
    remove_productSkuVariations: function remove_productSkuVariations(big_index) {
      this.ruleForm.productSkuVariations.splice(big_index, 1);
    },
    // 添加规则
    add_productSkuVariations: function add_productSkuVariations() {
      var obj = {
        name: "",
        domains: [{
          value: ""
        }]
      };
      this.ruleForm.productSkuVariations.push(obj);
    },
    // 删除
    removeDomain: function removeDomain(big_index, item) {
      var index = this.ruleForm.productSkuVariations[big_index].domains.indexOf(item);
      if (index !== -1) {
        this.ruleForm.productSkuVariations[big_index].domains.splice(index, 1);
      }
    },
    // 添加
    addDomain: function addDomain(big_index) {
      this.ruleForm.productSkuVariations[big_index].domains.push({
        value: "",
        key: Date.now()
      });
    },
    //保存
    submitForm: function submitForm(ruleForm) {
      var _this4 = this;
      //   return;
      console.log("验证", this.ruleForm.productSkuVariations, this.$refs[ruleForm]);
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          if (_this4.ruleForm.productSkuVariations.length == 1) {
            _this4.productSkuVariations_length_1();
          } else if (_this4.ruleForm.productSkuVariations.length == 2) {
            _this4.productSkuVariations_length_2();
          } else if (_this4.ruleForm.productSkuVariations.length == 3) {
            _this4.productSkuVariations_length_3();
          }
          console.log("数据", _this4.ruleForm.productSkuVariations);
        } else {
          _this4.$message.error("规格名称、规则数值不能为空！");
          console.log("error submit!!");
          return false;
        }
      });
    },
    productSkuVariations_length_1: function productSkuVariations_length_1() {
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        var key1 = obj[0].name;
        var combination = {
          title: "",
          skuTitleType: 1,
          inv: 0,
          price: 0.0,
          packageSkuList: [],
          shipSkuList: [],
          searchtitle: "",
          productSkuVariations: [{
            name: key1,
            value: colorDomain.value,
            main: true
            //   imgUrl: this.main ? colorDomain.imgUrl : ""
          }]
        };

        combination[key1] = colorDomain.value;
        result.push(combination);
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    productSkuVariations_length_2: function productSkuVariations_length_2() {
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        obj[1].domains.forEach(function (sizeDomain) {
          var key1 = obj[0].name;
          var key2 = obj[1].name;
          var combination = {
            title: "",
            inv: 0,
            price: 0.0,
            skuTitleType: 1,
            packageSkuList: [],
            shipSkuList: [],
            searchtitle: "",
            productSkuVariations: [{
              name: key1,
              value: colorDomain.value,
              main: true
              // imgUrl: this.main ? colorDomain.imgUrl : ""
            }, {
              name: key2,
              value: sizeDomain.value,
              main: false
            }]
          };
          combination[key1] = colorDomain.value;
          combination[key2] = sizeDomain.value;
          result.push(combination);
        });
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    productSkuVariations_length_3: function productSkuVariations_length_3() {
      console.log("数据", this.ruleForm.productSkuVariations, this.skuList);
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        obj[1].domains.forEach(function (sizeDomain) {
          obj[2].domains.forEach(function (bigDomain) {
            var key1 = obj[0].name;
            var key2 = obj[1].name;
            var key3 = obj[2].name;
            var combination = {
              title: "",
              inv: 0,
              price: 0.0,
              skuTitleType: 1,
              packageSkuList: [],
              shipSkuList: [],
              searchtitle: "",
              productSkuVariations: [{
                name: key1,
                value: colorDomain.value,
                main: true
                //   imgUrl: this.main ? colorDomain.imgUrl : ""
              }, {
                name: key2,
                value: sizeDomain.value,
                main: false
              }, {
                name: key3,
                value: bigDomain.value,
                main: false
              }]
            };
            combination[key1] = colorDomain.value;
            combination[key2] = sizeDomain.value;
            combination[key3] = bigDomain.value;
            result.push(combination);
          });
        });
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    add_result: function add_result(result) {
      var _this5 = this;
      console.log("item1111", result);
      this.skuList.forEach(function (item) {
        console.log("item222", item);
        result.forEach(function (val, index) {
          if (_this5.arraysAreEqual(val.productSkuVariations, item.productSkuVariations)) {
            _this5.$set(result, index, item);
          } else {
            console.log("aaa", val);
          }
        });
      });
      this.skuList = result;
    },
    arraysAreEqual: function arraysAreEqual(arr1, arr2) {
      // 判断两个数组是否相同
      console.log("arr1", arr1, arr2);
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    }
  },
  created: function created() {
    var _this6 = this;
    this.dialogType = this.dialogType_sw;
    console.log("父数据", this.dialogType_sw, this.value);
    if (this.dialogType == "edit") {
      this.ruleForm.productSkuVariations = [];
      if (this.variations.length > 0) {
        this.variations.forEach(function (val) {
          //   if (val.url != "") {
          //     this.main = true;
          //   }
          var obj = {
            name: val.name,
            main: val.main,
            domains: []
          };
          val.values.forEach(function (item) {
            var obj_value = {
              value: item.value
              //   imgUrl: item.url
            };

            obj.domains.push(obj_value);
          });
          console.log("val", val);
          _this6.ruleForm.productSkuVariations.push(obj);
        });
        this.ruleForm.productSkuVariations = this.ruleForm.productSkuVariations.slice().sort(function (a, b) {
          return (b.main === true) - (a.main === true);
        });
      }
      if (this.value) {
        this.skuList = this.value;
        this.skuList.forEach(function (val) {
          val.productSkuVariations.forEach(function (item) {
            var name = item.name;
            val[name] = item.value;
          });
        });
        console.log("最终数据", this.skuLis);
      }
      console.log("SKU子数据", this.value, this.variations, this.ruleForm);
    }
  },
  directives: {
    "numeric-14": {
      bind: function bind(el, val) {
        console.log("input", el, val);
        el.handler = function (event) {
          var input = event.target;
          var max = val.value.maxval;
          // 限制输入长度为14位
          if (input.value.length > max) {
            input.value = input.value.slice(0, max);
            input.dispatchEvent(new Event("input"));
          }
          // 只允许输入数字
          var regex = /^[0-9]*$/;
          if (!regex.test(input.value)) {
            input.value = input.value.replace(/[^0-9]/g, "");
            input.dispatchEvent(new Event("input"));
          }
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function unbind(el) {
        el.removeEventListener("input", el.handler);
      }
    },
    "numeric-length": {
      bind: function bind(el) {
        el.handler = function (event) {
          var input = event.target;
          var value = input.value.trim();
          // 只允许输入数字
          var regex = /^[0-9]*$/;
          if (!regex.test(value)) {
            input.value = value.replace(/[^0-9]/g, "");
            input.dispatchEvent(new Event("input"));
          }
          // 检查输入值长度是否为8、13或14
          if (value.length !== 8 && value.length !== 13 && value.length !== 14) {
            input.value = value.slice(0, 14); // 截取前14位
            input.dispatchEvent(new Event("input"));
          }
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function unbind(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  }
};