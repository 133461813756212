var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "contentT",
          fn: function () {
            return [
              _c("input", {
                ref: "fileInput",
                staticStyle: { display: "none" },
                attrs: { type: "file" },
                on: { change: _vm.handleFileChange },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    size: "mini",
                    type: "danger",
                    icon: "el-icon-delete",
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删除")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "55",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客户单号", align: "center", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.orderNo) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "跟踪号", align: "center", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.shipNo) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请店铺", align: "center", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.username) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货仓库", align: "center", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.warehouse) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.status == 1
                            ? "发货成功"
                            : scope.row.status == 8
                            ? "申请取消中"
                            : "订单已取消"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "申请时间",
              align: "center",
              prop: "cancelTime",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.cancelTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.add_SKU(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          SKU明细\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleEditAddress(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看地址")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApprovalClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          审批\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "60%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.detailsskuList,
                border: "",
                fit: "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品标题",
                  align: "center",
                  prop: "productName",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.productName)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.productName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: "center",
                  prop: "cnName",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.sellerSku)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "品类名称",
                  align: "center",
                  prop: "cnName",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(
                            _vm._s(
                              scope.row.cnName ? scope.row.cnName : "未关联品类"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "SKU名称", align: "center", prop: "cnName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.variation)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.variation))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "配货备注", align: "center", prop: "content" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.content)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", prop: "quantity" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.quantity)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "净重", align: "center", prop: "weight" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.weight)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "价值",
                  align: "center",
                  prop: "originalPrice",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.originalPrice)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用途", align: "center", prop: "desc" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.desc)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "SKU类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isDian === 0
                          ? _c("span", [_vm._v("普通")])
                          : scope.row.isDian === 2
                          ? _c("span", [_vm._v("液体")])
                          : scope.row.isDian === 1
                          ? _c("span", [_vm._v("带电")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看地址",
            visible: _vm.editAddressDialogVisible,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editAddressDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editAddressForm",
              attrs: {
                model: _vm.editAddressForm,
                "label-width": "80px",
                disabled: true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "recipient" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.recipient,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "recipient", $$v)
                      },
                      expression: "editAddressForm.recipient",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收件人电话", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "phone", $$v)
                      },
                      expression: "editAddressForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "城市", prop: "city" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "city", $$v)
                      },
                      expression: "editAddressForm.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "州/省", prop: "state" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "state", $$v)
                      },
                      expression: "editAddressForm.state",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "detailAddress" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.detailAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "detailAddress", $$v)
                      },
                      expression: "editAddressForm.detailAddress",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门牌地址",
                    prop: "additionalAddressInformation",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.additionalAddressInformation,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editAddressForm,
                          "additionalAddressInformation",
                          $$v
                        )
                      },
                      expression:
                        "editAddressForm.additionalAddressInformation",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮编", prop: "zipcode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.zipcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "zipcode", $$v)
                      },
                      expression: "editAddressForm.zipcode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审批订单",
            visible: _vm.approvalDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.approvalDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.approvalForm, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.approvalForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.approvalForm, "status", $$v)
                        },
                        expression: "approvalForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 9 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 7 } }, [_vm._v("拒绝")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审批原因" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入审批原因" },
                    model: {
                      value: _vm.approvalForm.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.approvalForm, "reason", $$v)
                      },
                      expression: "approvalForm.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleApprovalCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleApprovalConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择订单创建时间范围",
            visible: _vm.timePickerDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.timePickerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.timeRangeForm, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间",
                    },
                    model: {
                      value: _vm.timeRangeForm.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.timeRangeForm, "startTime", $$v)
                      },
                      expression: "timeRangeForm.startTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择结束时间",
                    },
                    model: {
                      value: _vm.timeRangeForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.timeRangeForm, "endTime", $$v)
                      },
                      expression: "timeRangeForm.endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleTimePickerCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleTimePickerConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }