var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "仓库名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.filterData },
                          model: {
                            value: _vm.selectedWarehouse,
                            callback: function ($$v) {
                              _vm.selectedWarehouse = $$v
                            },
                            expression: "selectedWarehouse",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.warehouseOptions, function (warehouse) {
                            return _c("el-option", {
                              key: warehouse,
                              attrs: {
                                label: warehouse.name,
                                value: warehouse.id,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100px" },
                          attrs: { value: "SKU" },
                          on: {
                            input: function (value) {
                              return (_vm.currentSearchType = value)
                            },
                          },
                          model: {
                            value: _vm.currentSearchType,
                            callback: function ($$v) {
                              _vm.currentSearchType = $$v
                            },
                            expression: "currentSearchType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "商品SKU", value: "SKU" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "订单号", value: "OrderId" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入" },
                        on: { change: _vm.filterData },
                        model: {
                          value: _vm.searchKeyword,
                          callback: function ($$v) {
                            _vm.searchKeyword = $$v
                          },
                          expression: "searchKeyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "操作类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.filterData },
                          model: {
                            value: _vm.selectedOperationType,
                            callback: function ($$v) {
                              _vm.selectedOperationType = $$v
                            },
                            expression: "selectedOperationType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "入库", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "出库", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "操作时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.filterData },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.filterData },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.exportData },
                        },
                        [_vm._v("导出日志")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.inventoryList.size,
                  "page-sizes": [20, 50, 100, 200],
                  "page-size": _vm.inventoryList.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            stripe: "",
            border: "",
            data: _vm.inventoryList,
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "仓库名称", prop: "warehouseName" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "商品SKU", prop: "sku" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "cnTitle" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作类型", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("入库"),
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("出库"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v("\n    >\n    "),
          _c("el-table-column", { attrs: { label: "数量", prop: "num" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "剩余数量", prop: "surplusNum" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人", prop: "addByName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作时间", prop: "addTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单号", prop: "orderId" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "备注", prop: "reamark" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }