"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countRiderAccount = countRiderAccount;
exports.countRiderBail = countRiderBail;
exports.countRiderWithdraw = countRiderWithdraw;
exports.doRefundBail = doRefundBail;
exports.getMemberAccountLog = getMemberAccountLog;
exports.getMemberRechargeLog = getMemberRechargeLog;
exports.getRiderAccountLog = getRiderAccountLog;
exports.getRiderBailDetailLog = getRiderBailDetailLog;
exports.getRiderBailLog = getRiderBailLog;
exports.getRiderWithdrawList = getRiderWithdrawList;
exports.verifyRiderWithdraw = verifyRiderWithdraw;
var _request = _interopRequireDefault(require("@/utils/request"));
function getRiderAccountLog(data) {
  return (0, _request.default)({
    url: '/system/riderAccount/getList',
    method: 'get',
    params: data
  });
}
function countRiderAccount(data) {
  return (0, _request.default)({
    url: '/system/riderAccount/count',
    method: 'get',
    params: data
  });
}
function getRiderBailLog(data) {
  return (0, _request.default)({
    url: '/system/riderBail/getList',
    method: 'get',
    params: data
  });
}
function getRiderBailDetailLog(data) {
  return (0, _request.default)({
    url: '/system/riderBail/getDetailList',
    method: 'get',
    params: data
  });
}
function countRiderBail(data) {
  return (0, _request.default)({
    url: '/system/riderBail/count',
    method: 'get',
    params: data
  });
}
function getMemberAccountLog(data) {
  return (0, _request.default)({
    url: '/system/memberAccount/getList',
    method: 'get',
    params: data
  });
}
function getMemberRechargeLog(data) {
  return (0, _request.default)({
    url: '/system/memberRecharge/getList',
    method: 'get',
    params: data
  });
}
function getRiderWithdrawList(data) {
  return (0, _request.default)({
    url: '/system/riderWithdraw/getList',
    method: 'get',
    params: data
  });
}
function countRiderWithdraw(data) {
  return (0, _request.default)({
    url: '/system/riderWithdraw/count',
    method: 'get',
    params: data
  });
}
function verifyRiderWithdraw(data) {
  return (0, _request.default)({
    url: 'system/riderWithdraw/verifyEditSubmit',
    method: 'post',
    data: data
  });
}
function doRefundBail(data) {
  return (0, _request.default)({
    url: 'system/riderBail/doRefund',
    method: 'post',
    data: data
  });
}