"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countAmount = countAmount;
exports.countMember = countMember;
exports.countNumber = countNumber;
exports.countPlatformIncome = countPlatformIncome;
exports.countVerifyNum = countVerifyNum;
exports.getConversionReport = getConversionReport;
exports.getGoodsSellList = getGoodsSellList;
exports.getLoginInfo = getLoginInfo;
exports.getOrderAddList = getOrderAddList;
exports.getShopSellList = getShopSellList;
var _request = _interopRequireDefault(require("@/utils/request"));
function countMember() {
  return (0, _request.default)({
    url: '/system/countMember',
    method: 'get'
  });
}
function getOrderAddList() {
  return (0, _request.default)({
    url: '/system/getMemberAddList',
    method: 'get'
  });
}
function countNumber(data) {
  return (0, _request.default)({
    url: '/system/countNumber',
    method: 'get',
    params: data
  });
}
function countVerifyNum(data) {
  return (0, _request.default)({
    url: '/system/countVerifyNum',
    method: 'get',
    params: data
  });
}
function countAmount(data) {
  return (0, _request.default)({
    url: '/system/countAmount',
    method: 'get',
    params: data
  });
}
function getLoginInfo() {
  return (0, _request.default)({
    url: '/system/getLoginInfo',
    method: 'get'
  });
}
function countPlatformIncome(data) {
  return (0, _request.default)({
    url: '/system/countPlatformIncome',
    method: 'get',
    params: data
  });
}
function getShopSellList(data) {
  return (0, _request.default)({
    url: 'system/getShopSellList',
    method: 'get',
    params: data
  });
}
function getGoodsSellList(data) {
  return (0, _request.default)({
    url: 'system/getGoodsSellList',
    method: 'get',
    params: data
  });
}
function getConversionReport() {
  return (0, _request.default)({
    url: 'system/getConversionReport',
    method: 'get'
  });
}