var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClickLocationTab },
                  model: {
                    value: _vm.locationActiveName,
                    callback: function ($$v) {
                      _vm.locationActiveName = $$v
                    },
                    expression: "locationActiveName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "企业", name: "third" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "店铺名称：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入店铺名称" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                              },
                              on: { click: _vm.handleDelete },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺LOGO", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "30px" },
                      attrs: {
                        src: scope.row.logo,
                        "preview-src-list": [scope.row.logo],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺类型", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(_vm._s(_vm._f("typeFilter")(scope.row.type))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属用户", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.memberName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "省市", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.province) +
                          _vm._s(scope.row.city) +
                          _vm._s(scope.row.area)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "服务费", align: "center", prop: "addTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticStyle: { width: "90px" },
                      attrs: {
                        size: "mini",
                        min: 1,
                        label: "修改子账号数量上限",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.sortChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.serviceFee,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "serviceFee", $$v)
                        },
                        expression: "scope.row.serviceFee",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "子账号购买VIP返佣比例",
              align: "center",
              prop: "addTime",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "110px" },
                        attrs: {
                          size: "mini",
                          min: 1,
                          label: "修改子账号购买VIP返佣比例",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.sortChange(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.shopAccountVipRebate,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "shopAccountVipRebate", $$v)
                          },
                          expression: "scope.row.shopAccountVipRebate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "子账号数量上限",
              align: "center",
              prop: "addTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticStyle: { width: "90px" },
                      attrs: {
                        size: "mini",
                        min: 1,
                        label: "修改子账号数量上限",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.sortChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.limitSubordinateNum,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "limitSubordinateNum", $$v)
                        },
                        expression: "scope.row.limitSubordinateNum",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "平台类型", align: "center", prop: "platformType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "90px" },
                        attrs: { size: "mini", label: "修改平台类型" },
                        on: {
                          change: function ($event) {
                            return _vm.sortChange(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.platformType,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "platformType", $$v)
                          },
                          expression: "scope.row.platformType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "merchant", value: 1 },
                        }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { label: "shop", value: 2 } }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "order", value: 3 },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", prop: "addTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排序", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", min: 1, label: "修改排序" },
                      on: {
                        change: function ($event) {
                          return _vm.sortChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/shop/detail?uid=" + scope.row.memberId },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-view",
                              size: "mini",
                              type: "primary",
                            },
                          },
                          [_vm._v("\n            详情\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "100%", "margin-left": "50px" },
              attrs: {
                model: _vm.shop,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属会员",
                    prop: "memberName",
                    rules: [
                      {
                        required: true,
                        message: "请填写所属会员",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.shop.memberName,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "memberName", $$v)
                      },
                      expression: "shop.memberName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请填写店铺名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    model: {
                      value: _vm.shop.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "name", $$v)
                      },
                      expression: "shop.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "描述",
                    prop: "produce",
                    rules: [
                      {
                        required: true,
                        message: "请填写描述",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.shop.produce,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "produce", $$v)
                      },
                      expression: "shop.produce",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺LOGO",
                    prop: "logo",
                    rules: [
                      {
                        required: true,
                        message: "请上传LOGO",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.shop.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "logo", $$v)
                      },
                      expression: "shop.logo",
                    },
                  }),
                  _vm._v(" "),
                  _c("ImageSource", {
                    attrs: { mul: false },
                    model: {
                      value: _vm.shop.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "logo", $$v)
                      },
                      expression: "shop.logo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺简介",
                    prop: "remark",
                    rules: [
                      {
                        required: true,
                        message: "请填写店铺简介",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.shop.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "remark", $$v)
                      },
                      expression: "shop.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "省市",
                    prop: "pca",
                    rules: [
                      {
                        required: true,
                        message: "请选择省市",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "60%" },
                    attrs: {
                      size: "small",
                      options: _vm.addressOptions,
                      clearable: "",
                    },
                    model: {
                      value: _vm.shop.pca,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "pca", $$v)
                      },
                      expression: "shop.pca",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排序",
                    prop: "sort",
                    rules: [
                      { required: true, message: "必填", trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "200px" },
                    attrs: { min: 1, label: "修改排序" },
                    model: {
                      value: _vm.shop.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.shop, "sort", $$v)
                      },
                      expression: "shop.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    prop: "status",
                    rules: [
                      { required: true, message: "必填", trigger: "blur" },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "60%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.shop.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.shop, "status", $$v)
                        },
                        expression: "shop.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.text, value: item.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }