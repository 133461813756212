"use strict";

var _interopRequireWildcard = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _warehouse = require("@/api/warehouse/warehouse");
var XLSX = _interopRequireWildcard(require("xlsx"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listQuery: {
        page: 1,
        pageSize: 20
      },
      tableData: [],
      total: 0,
      ruleForm: {
        id: '',
        adminId: '',
        warehouseId: '',
        amount: ''
      },
      rules: {
        amount: [{
          required: true,
          message: '请输入充值/扣减余额',
          trigger: 'blur'
        }]
      },
      dialogType: 'add',
      dialogFormVisible: false,
      amount: '',
      supplierShow: false,
      warehouseShow: false
    };
  },
  created: function created() {
    this.list_url();
    // this.getBindWarehouse_url();
  },

  methods: {
    // 编辑
    edit_list: function edit_list(row) {
      this.dialogType = 'edit';
      this.dialogFormVisible = true;
      this.ruleForm = row;
    },
    // 保存
    confirm: function confirm() {
      var _this = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this.ruleForm);
          formData.amount = _this.amount;
          (0, _warehouse.updateWsSupplier)(formData).then(function () {
            _this.list_url();
            _this.dialogFormVisible = false;
            _this.$message({
              message: '编辑成功',
              type: 'success'
            });
          });
        }
      });
    },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1;
      this.listQuery.pageSize = 20;
      this.list_url();
    },
    // 列表数据
    list_url: function list_url() {
      var _this2 = this;
      this.listQuery.dateFrom = (0, _utils.renderTime)(this.listQuery.startTime);
      this.listQuery.dateTo = (0, _utils.renderTime)(this.listQuery.endTime);
      (0, _warehouse.pageWsSupplierBill)(this.listQuery).then(function (response) {
        console.log('列表数据', response);
        _this2.tableData = response.data.list;
        _this2.total = response.data.total;
        var list = response.data.list;
        if (list.length > 0) {
          if (list[0].userType === 'admin') {
            _this2.supplierShow = true;
            _this2.warehouseShow = true;
          }
          if (list[0].userType === 'supplier') {
            _this2.supplierShow = false;
            _this2.warehouseShow = true;
          }
          if (list[0].userType === 'warehouse') {
            _this2.supplierShow = true;
            _this2.warehouseShow = false;
          }
        }
      });
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      var _this3 = this;
      // 设置Excel文件的标题行
      var hed = [];
      if (this.warehouseShow) {
        hed.push("仓库");
      }
      if (this.supplierShow) {
        hed.push("供应商");
      }
      var headers = ['序号'].concat(hed, ['操作类型', '费用', '订单号', '备注', '时间']);

      // 使用 map 函数并添加序号计数器
      var index = 1; // 初始化序号计数器
      var data = this.tableData.map(function (item) {
        var typename = "";
        switch (item.type) {
          case 0:
            typename = '物流费扣除';
            break;
          case 1:
            typename = '充值';
            break;
          case 2:
            typename = '操作费扣除';
            break;
          case 3:
            typename = '取消订单退费';
            break;
        }
        // 在每个迭代中增加序号计数器
        var hedVal = [];
        if (_this3.warehouseShow) {
          hedVal.push(item.warehouse.name);
        }
        if (_this3.supplierShow) {
          hedVal.push(item.admin.name);
        }
        return [index++].concat(hedVal, [typename, item.amount, item.orderId, item.reason, (0, _utils.parseTime)(item.addTime, "{y}-{m}-{d} {h}:{i}:{s}")]);
      });

      // 创建工作簿
      var wb = XLSX.utils.book_new();

      // 将数据转换为工作表
      var ws = XLSX.utils.aoa_to_sheet([headers].concat((0, _toConsumableArray2.default)(data)));

      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // 导出Excel文件
      XLSX.writeFile(wb, '账单导出.xlsx');
    }
  }
};
exports.default = _default;