var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
        [_vm._v("新增")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "areaName", label: "区域" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "durationName", label: "时长" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "resourceName", label: "资源" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "typeName", label: "类型" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "useDescName", label: "用途描述" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "price", label: "价格" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.pageSize,
          total: _vm.total,
          layout: "sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增/编辑特价商品",
            visible: _vm.dialogFormVisible,
            width: "30%",
          },
          on: { close: _vm.resetForm },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
                autocomplete: "off",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "区域", prop: "areaId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区域" },
                      model: {
                        value: _vm.form.areaId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "areaId", $$v)
                        },
                        expression: "form.areaId",
                      },
                    },
                    _vm._l(_vm.areaList, function (area) {
                      return _c("el-option", {
                        key: area.id,
                        attrs: { label: area.area, value: area.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时长", prop: "durationId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择时长" },
                      model: {
                        value: _vm.form.durationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "durationId", $$v)
                        },
                        expression: "form.durationId",
                      },
                    },
                    _vm._l(_vm.durationList, function (duration) {
                      return _c("el-option", {
                        key: duration.id,
                        attrs: { label: duration.duration, value: duration.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "资源", prop: "resourceId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择资源" },
                      model: {
                        value: _vm.form.resourceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "resourceId", $$v)
                        },
                        expression: "form.resourceId",
                      },
                    },
                    _vm._l(_vm.resourceList, function (resource) {
                      return _c("el-option", {
                        key: resource.id,
                        attrs: { label: resource.resource, value: resource.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "typeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择类型" },
                      model: {
                        value: _vm.form.typeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "typeId", $$v)
                        },
                        expression: "form.typeId",
                      },
                    },
                    _vm._l(_vm.typeList, function (type) {
                      return _c("el-option", {
                        key: type.id,
                        attrs: { label: type.type, value: type.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用途描述", prop: "useDescId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用途描述" },
                      model: {
                        value: _vm.form.useDescId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "useDescId", $$v)
                        },
                        expression: "form.useDescId",
                      },
                    },
                    _vm._l(_vm.useDescList, function (useDesc) {
                      return _c("el-option", {
                        key: useDesc.id,
                        attrs: { label: useDesc.title, value: useDesc.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格", prop: "price" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", _vm._n($$v))
                      },
                      expression: "form.price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }