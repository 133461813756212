"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _params = require("@/api/params");
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _imageSource = _interopRequireDefault(require("@/components/Upload/imageSource"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Waves directive

// Secondary package based on el-pagination

var languageOptions = [{
  key: "all",
  text: "用户自主选择"
}, {
  key: "folowSystem",
  text: "跟随手机系统"
}, {
  key: "zh_CN",
  text: "简体中文"
}, {
  key: "zh_TW",
  text: "繁体中文"
}, {
  key: "en_US",
  text: "英文"
}];
var _default = {
  name: "configTable",
  components: {
    Pagination: _Pagination.default,
    Upload: _singleImage.default,
    ImageSource: _imageSource.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      config: {},
      dialogFormVisible: false,
      dialogType: "",
      multipleSelection: [],
      dialogImageUrl: "",
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_API + "/system/uploadSyjapk",
      languageOptions: languageOptions
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _params.getConfigList)(this.listQuery).then(function (response) {
        _this.config = response.data;
        console.log("数据", _this.config);
        _this.listLoading = false;
      });
    },
    confirm: function confirm() {
      var _this2 = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this2.config);
          formData.addTime = null;
          formData.updTime = null;
          (0, _params.updateConfig)(formData).then(function () {
            _this2.$notify({
              title: "成功",
              message: "参数保存成功",
              type: "success",
              duration: 2000
            });
          });
        }
      });
    },
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    },
    uploadSus: function uploadSus(response, file, fileList) {
      var res = response.data;
      if (res.code !== 200) {
        this.$notify({
          title: "失败",
          message: "上传失败",
          type: "error",
          duration: 2000
        });
      } else {
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
          duration: 2000
        });
      }
    },
    uploadFail: function uploadFail(response, file, fileList) {
      var res = response.data;
      if (res.code !== 200) {
        this.$notify({
          title: "失败",
          message: "上传失败",
          type: "error",
          duration: 2000
        });
      } else {
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
          duration: 2000
        });
      }
    }
  }
};
exports.default = _default;