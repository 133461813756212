"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _third = require("@/api/third");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var _default = {
  name: 'thirdConfig',
  directives: {
    waves: _waves.default
  },
  filters: {
    typeFilter: function typeFilter(status) {
      return typeKeyValue[status];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _third.getThirdConfigList)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      });
    },
    changeValue: function changeValue(row) {
      var _this2 = this;
      var formData = {
        id: row.id,
        value: row.value
      };
      (0, _third.updateThirdConfig)(formData).then(function (response) {
        _this2.$notify({
          title: '成功',
          message: '切换成功',
          type: 'success',
          duration: 2000
        });
      });
    }
  }
};
exports.default = _default;