//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addNotice, updateNotice, deleteNotice, getNoticePage } from "@/api/notice/list";
import WangEnduit from "@/components/WangEditor/index";
export default {
  components: {
    WangEnduit: WangEnduit
  },
  data: function data() {
    return {
      isClear: false,
      list: [],
      dialogVisible: false,
      form: {
        id: "",
        path: "",
        content: "",
        enable: false
      },
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    changeEditor: function changeEditor(val) {
      this.form.content = val;
    },
    fetchData: function fetchData() {
      var _this = this;
      var params = {
        page: this.currentPage,
        pageSize: this.pageSize
      };
      getNoticePage(params).then(function (response) {
        var _response$data = response.data,
          list = _response$data.list,
          pageNum = _response$data.pageNum,
          pageSize = _response$data.pageSize,
          total = _response$data.total;
        _this.list = list;
        _this.currentPage = pageNum;
        _this.pageSize = pageSize;
        _this.total = total;
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleAdd: function handleAdd() {
      this.form.id = "";
      this.form.path = "";
      this.form.content = "";
      this.form.desc = "";
      this.form.enable = false;
      this.dialogVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form.id = row.id;
      this.form.path = row.path;
      this.form.desc = row.desc;
      this.form.content = row.content;
      this.form.enable = row.enable;
      this.dialogVisible = true;
    },
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      deleteNotice(id).then(function () {
        _this2.fetchData();
        _this2.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    save: function save() {
      var _this3 = this;
      if (this.form.id) {
        // 修改
        updateNotice(this.form).then(function () {
          _this3.fetchData();
          _this3.dialogVisible = false;
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        // 新增
        addNotice(this.form).then(function () {
          _this3.fetchData();
          _this3.dialogVisible = false;
        }).catch(function (error) {
          console.error(error);
        });
      }
    },
    cancel: function cancel() {
      this.dialogVisible = false;
    }
  }
};