import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getWeixinTemplate, updateWeixinTemplate } from '@/api/third';
import waves from '@/directive/waves'; // Waves directive
import { parseTime, renderTime } from '@/utils';
var typeOptions = [{
  key: '1',
  text: '派单成功通知'
}, {
  key: '2',
  text: '订单失败通知'
}, {
  key: '3',
  text: '催收货通知'
}, {
  key: '4',
  text: '催评价通知'
}, {
  key: '5',
  text: '评价审核不成功'
}, {
  key: '6',
  text: '未付款订单即将关闭提醒'
}, {
  key: '7',
  text: '平台处罚通知'
}, {
  key: '8',
  text: '提现成功通知'
}, {
  key: '9',
  text: '提现失败通知'
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var typeKeyValue = typeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
export default {
  name: 'weixinTemplateTable',
  directives: {
    waves: waves
  },
  filters: {
    typeFilter: function typeFilter(status) {
      return typeKeyValue[status];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {},
      template: {},
      dialogFormVisible: false,
      dialogType: '',
      multipleSelection: [],
      rules: {
        cate: [{
          required: true,
          message: '请选择提醒类型',
          trigger: 'change'
        }],
        templateId: [{
          required: true,
          message: '模板ID不能为空',
          trigger: 'blur'
        }],
        key1: [{
          required: true,
          message: '订阅消息KEY值不能为空',
          trigger: 'blur'
        }],
        key2: [{
          required: true,
          message: '订阅消息KEY值不能为空',
          trigger: 'blur'
        }],
        key3: [{
          required: true,
          message: '订阅消息KEY值不能为空',
          trigger: 'blur'
        }],
        key4: [{
          required: true,
          message: '订阅消息KEY值不能为空',
          trigger: 'blur'
        }],
        key5: [{
          required: true,
          message: '订阅消息KEY值不能为空',
          trigger: 'blur'
        }]
      },
      activeName: 'first',
      typeOptions: typeOptions
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      getWeixinTemplate(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this2 = this;
      this.template = {};
      this.template = Object.assign({}, row); // copy obj
      if (this.template.content != null) {
        var keyArr = this.template.content.split(",");
        this.$set(this.template, "key1", keyArr.length > 0 ? keyArr[0] : "");
        this.$set(this.template, "key2", keyArr.length > 1 ? keyArr[1] : "");
        this.$set(this.template, "key3", keyArr.length > 2 ? keyArr[2] : "");
        this.$set(this.template, "key4", keyArr.length > 3 ? keyArr[3] : "");
        this.$set(this.template, "key5", keyArr.length > 4 ? keyArr[4] : "");
      }
      this.dialogStatus = 'edit';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    confirm: function confirm() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this3.template);
          if (formData.cate == '1') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4 + "," + formData.key5;
          } else if (formData.cate == '2') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4;
          } else if (formData.cate == '3') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4;
          } else if (formData.cate == '4') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4;
          } else if (formData.cate == '5') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4 + "," + formData.key5;
          } else if (formData.cate == '6') {
            formData.content = formData.key1 + "," + formData.key2;
          } else if (formData.cate == '7') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4;
          } else if (formData.cate == '8') {
            formData.content = formData.key1 + "," + formData.key2;
          } else if (formData.cate == '9') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3;
          } else if (formData.cate == '12') {
            formData.content = formData.key1 + "," + formData.key2 + "," + formData.key3 + "," + formData.key4 + "," + formData.key5;
          }
          formData.addTime = null;
          formData.updTime = null;
          updateWeixinTemplate(formData).then(function () {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.$notify({
              title: '成功',
              message: '模板消息更新成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};