"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var warehouseRouter = {
  path: '/warehouse',
  component: _layout.default,
  redirect: '/warehouse/order',
  name: 'warehouse',
  alwaysShow: true,
  meta: {
    title: '仓库管理',
    icon: 'report-brand'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/index/index'));
      });
    },
    name: 'warehouseList',
    meta: {
      title: '仓库列表',
      role: []
    }
  }, {
    path: 'wsindex',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/wsindex/index'));
      });
    },
    name: 'wsWarehouseList',
    meta: {
      title: '仓库管理',
      role: []
    }
  }, {
    path: 'apiconfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/apiconfig/index'));
      });
    },
    name: 'wsApiConfig',
    meta: {
      title: '物流渠道',
      role: []
    }
  }, {
    path: 'inv',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/inv/index'));
      });
    },
    name: 'warehouseInv',
    meta: {
      title: '库存管理',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/list/index'));
      });
    },
    name: 'WarehouseList',
    meta: {
      title: '仓库库存列表',
      role: []
    }
  }, {
    path: 'inventory',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/inventory/index'));
      });
    },
    name: 'WarehouseInventory',
    meta: {
      title: '库存管理',
      role: []
    }
  }, {
    path: 'locator',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/locator/index'));
      });
    },
    name: 'WarehouseLocator',
    meta: {
      title: '库位管理',
      role: []
    }
  }, {
    path: 'deliverynote',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/deliverynote/index'));
      });
    },
    name: 'WarehouseDeliverynote',
    meta: {
      title: '发货单管理',
      role: []
    }
  }, {
    path: 'batchadmin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/batchadmin/index'));
      });
    },
    name: 'WarehouseBatchadmin',
    meta: {
      title: '批次管理',
      role: []
    }
  }, {
    path: 'supplier',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/supplier/index'));
      });
    },
    name: 'warehouseSupplier',
    meta: {
      title: '供应商管理',
      role: []
    }
  }, {
    path: 'record',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/record/index'));
      });
    },
    name: 'warehouserecord',
    meta: {
      title: '出入库记录',
      role: []
    }
  }, {
    path: 'address',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/address/index'));
      });
    },
    name: 'Address',
    meta: {
      title: '仓库地址',
      role: []
    }
  }, {
    path: 'supplierBilling',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/supplierBilling/index'));
      });
    },
    name: 'supplierBilling',
    meta: {
      title: '充值',
      role: []
    }
  }, {
    path: 'supplierBillList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/supplierBillList/index'));
      });
    },
    name: 'supplierBillList',
    meta: {
      title: '账单',
      role: []
    }
  }, {
    path: 'packingOrderList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/packingOrder/index'));
      });
    },
    name: 'packingOrderList',
    meta: {
      title: '装箱单管理',
      role: []
    }
  }, {
    path: 'packingOrderNewList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/packingOrderNew/index'));
      });
    },
    name: 'packingOrderNewList',
    meta: {
      title: '装箱单(新)管理',
      role: []
    }
  }]
};
var _default = warehouseRouter;
exports.default = _default;