import Layout from '@/layout';
var warehouseRouter = {
  path: '/supplier',
  component: Layout,
  redirect: '/supplier',
  name: 'Supplier',
  alwaysShow: true,
  meta: {
    title: '供应商管理',
    icon: 'report-brand',
    roles: ['supplier']
  },
  children: [{
    path: 'managelist',
    component: function component() {
      return import('@/views/supplier/managelist');
    },
    name: 'Managelist',
    meta: {
      title: '供应商关联列表',
      role: ['supplier:managelist']
    }
  }]
};
export default warehouseRouter;