var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "10px" }, attrs: { shadow: "hover" } },
    [
      _c(
        "el-alert",
        {
          staticClass: "warn-content",
          attrs: {
            title: "温馨提示",
            type: "success",
            "show-icon": "",
            closable: false,
          },
        },
        [
          _vm._v(
            "\n             短信服务使用阿里云短信服务通道,详细细心请查阅"
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.aliyun.com/product/sms",
                target: "_blank",
              },
            },
            [_vm._v("《阿里云短信服务官方文档》")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticStyle: { width: "60%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.config,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "账号", prop: "account" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "account", $$v)
                  },
                  expression: "config.account",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.config.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "password", $$v)
                  },
                  expression: "config.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "请求URL", prop: "sendUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.sendUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "sendUrl", $$v)
                  },
                  expression: "config.sendUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "端口号", prop: "serverPort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.serverPort,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "serverPort", $$v)
                  },
                  expression: "config.serverPort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "短信签名", prop: "signName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.signName,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "signName", $$v)
                  },
                  expression: "config.signName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "通用模板ID", prop: "commonTemplateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.commonTemplateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "commonTemplateId", $$v)
                  },
                  expression: "config.commonTemplateId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "注册模板ID", prop: "registerTemplateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.registerTemplateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "registerTemplateId", $$v)
                  },
                  expression: "config.registerTemplateId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "绑定模板ID", prop: "bindTemplateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.bindTemplateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "bindTemplateId", $$v)
                  },
                  expression: "config.bindTemplateId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "忘记密码模板ID", prop: "forgetTemplateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.forgetTemplateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "forgetTemplateId", $$v)
                  },
                  expression: "config.forgetTemplateId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码通知模板ID", prop: "passwordTemplateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.config.passwordTemplateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "passwordTemplateId", $$v)
                  },
                  expression: "config.passwordTemplateId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateConfig } },
                [_vm._v("更新配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }