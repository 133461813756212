"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var courseRouter = {
  path: '/course',
  component: _layout.default,
  redirect: '/course/type',
  name: 'course',
  alwaysShow: true,
  meta: {
    title: '课程',
    icon: 'table'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/course/type/index'));
      });
    },
    name: 'CourseType',
    meta: {
      title: '课程类型',
      role: []
    }
  }, {
    path: 'detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/course/detail/index'));
      });
    },
    name: 'CourseDetail',
    meta: {
      title: '课程详情',
      role: []
    }
  }, {
    path: 'detailInfo/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/course/detail/detailInfo'));
      });
    },
    name: 'CourseDetailInfo',
    meta: {
      title: '查看课程详情',
      role: [],
      activeMenu: '/course/detail'
    },
    hidden: true
  }]
};
var _default = courseRouter;
exports.default = _default;