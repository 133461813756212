"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _shop = require("@/api/shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      // 加载状态
      total: 0,
      // 总数
      params: {
        // 请求参数
        page: 1,
        pageSize: 10,
        name: ""
      },
      shopsData: [],
      // 店铺数据
      selected: 9999999999 //设置一个不可能选中的index
    };
  },

  watch: {},
  created: function created() {
    this.init();
  },
  methods: {
    handleReachBottom: function handleReachBottom() {
      var _this = this;
      setTimeout(function () {
        if (_this.params.page * _this.params.pageSize <= _this.total) {
          _this.params.page++;
          _this.init();
        }
      }, 1500);
    },
    init: function init() {
      var _this2 = this;
      this.loading = true;
      (0, _shop.getShopList)(this.params).then(function (res) {
        if (res.code == 200) {
          /**
           * 解决数据请求中，滚动栏会一直上下跳动
           */
          _this2.total = res.data.total;
          _this2.shopsData = res.data.list;

          // this.shopsData.push(...res.data.list);

          _this2.loading = false;
        }
      });
    },
    clickShop: function clickShop(val, i) {
      this.selected = i;
      val = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, val), {}, {
        ___type: "shops"
      });
      this.$emit("selected", [val]);
    }
  }
};
exports.default = _default;