var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "api：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择仓库", size: "mini" },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.queryApiId,
                            callback: function ($$v) {
                              _vm.queryApiId = $$v
                            },
                            expression: "queryApiId",
                          },
                        },
                        _vm._l(_vm.apiList, function (api) {
                          return _c("el-option", {
                            key: api.id,
                            attrs: { value: api.id, label: api.description },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action:
                              _vm.uploadExcel + "?apiId=" + _vm.queryApiId,
                            "show-file-list": false,
                            "auto-upload": true,
                            "on-change": _vm.importExcel,
                            "on-success": _vm.upload_success,
                            "on-error": _vm.upload_error,
                            headers: _vm.headers,
                            "before-upload": _vm.handleBeforeUpload,
                            type: "file",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-upload",
                                loading: _vm.loading_01,
                              },
                            },
                            [_vm._v("导入\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "api名字", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.apiName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重量", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.weight))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重量单位", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.weightType))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 1", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[0].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[0].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 2", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[1].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[1].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 3", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[2].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[2].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 4", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[3].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[3].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 5", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[4].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[4].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 6", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[5].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[5].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 7", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[6].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[6].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 8", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[7].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[7].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ZONE 9", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amountList[8].amount
                      ? _c("el-tag", [
                          _vm._v(" " + _vm._s(scope.row.amountList[8].amount)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }