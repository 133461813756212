import request from '@/utils/request';
import requsetJson from '@/utils/requestJson';
export function loginByUsername(username, password, brandId) {
  var data = {
    username: username,
    password: password,
    brandId: brandId
  };
  return request({
    url: '/system/common/login',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/system/common/logout',
    method: 'post'
  });
}
export function getUserInfo(token) {
  return request({
    url: '/system/info/get',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function refreshToken() {
  return request({
    url: '/system/common/refreshToken',
    method: 'post'
  });
}
export function sendNotify(data) {
  return requsetJson({
    url: '/system/sendNotic',
    method: 'post',
    data: data
  });
}