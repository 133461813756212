"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoodsType = addGoodsType;
exports.deleteGoodsType = deleteGoodsType;
exports.getGoodsTypeList = getGoodsTypeList;
exports.updateGoodsType = updateGoodsType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getGoodsTypeList(data) {
  return (0, _requestJson.default)({
    url: '/goods/type/getPage',
    method: 'get',
    params: data
  });
}
function addGoodsType(data) {
  return (0, _requestJson.default)({
    url: '/goods/type/save',
    method: 'post',
    data: data
  });
}
function updateGoodsType(data) {
  return (0, _requestJson.default)({
    url: '/goods/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteGoodsType(id) {
  return (0, _requestJson.default)({
    url: "/goods/type/deleteById/".concat(id),
    method: 'post'
  });
}