"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

//系统管理路由配置
var systemRouter = {
  path: '/system',
  component: _layout.default,
  redirect: '/system/admin',
  name: 'system',
  alwaysShow: true,
  meta: {
    title: '系统',
    icon: 'system'
  },
  children: [{
    path: 'admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/admin/index'));
      });
    },
    name: 'adminTable',
    meta: {
      title: '管理员',
      role: ['admin']
    }
  }, {
    path: 'adminType',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/adminType/index'));
      });
    },
    name: 'adminTypeTable',
    meta: {
      title: '角色管理',
      role: ['admin']
    }
  }, {
    path: 'params',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/params/index'));
      });
    },
    name: 'params',
    meta: {
      title: '系统参数',
      role: ['admin']
    }
  }, {
    path: 'configuration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/configuration/index'));
      });
    },
    name: 'configuration',
    meta: {
      title: '萤火配置',
      role: ['admin']
    }
  }, {
    path: 'modParams',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/params/modParams'));
      });
    },
    name: 'modParams',
    meta: {
      title: 'APP更新',
      role: ['admin']
    }
  }, {
    path: 'menuList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/index'));
      });
    },
    name: 'menuList',
    meta: {
      title: '后台菜单',
      role: ['admin']
    }
  }, {
    path: 'shopMenuList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/shopMenu/index'));
      });
    },
    name: 'shopMenuList',
    meta: {
      title: '商家菜单',
      role: ['admin']
    }
  }, {
    path: 'center',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/center/index'));
      });
    },
    name: 'adminCenter',
    meta: {
      title: '个人中心',
      noCache: true
    }
    // hidden: true
  }]
};
var _default = systemRouter;
exports.default = _default;