var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card-component",
      staticStyle: { "margin-left": "8px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "box-card-header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("img", {
            attrs: {
              src: "https://wpimg.wallstcn.com/e7d23d71-cf19-4b90-a1cc-f56af8c0903d.png",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("pan-thumb", {
            staticClass: "panThumb",
            attrs: { image: _vm.avatar },
          }),
          _vm._v(" "),
          _c("mallki", {
            attrs: { "class-name": "mallki-text", text: _vm.name },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "progress-item",
            staticStyle: { "padding-top": "35px" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "progress-item" }),
          _vm._v(" "),
          _c("div", { staticClass: "progress-item" }),
          _vm._v(" "),
          _c("div", { staticClass: "progress-item" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }