import _defineProperty from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.split";
var _methods;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMember, updateMember as _updateMember, updateMemberAccount as _updateMemberAccount, getMemberGroupSelector as _getMemberGroupSelector, getMemberDetail, getMemberBankList, verifyMemberBank, updateMemberBank, deleteMemberBank, addSystemBlock, removeSystemBlock, updateInvite as _updateInvite, transferAmount, sendMsg, limitWithdraw as _limitWithdraw } from "@/api/member";
import { getWithdrawList as _getWithdrawList, countWithdraw, verifyWithdraw, setDoingWithdraw as _setDoingWithdraw, getRechargeLog, verifyRecharge, getLastWithdraw, applyWithdraw } from "@/api/fund";
import { getConfigMissionSelector as _getConfigMissionSelector, getSecretSelector as _getSecretSelector, getShopCateSelector } from "@/api/config";
import waves from "@/directive/waves"; // Waves directive
import { parseTime, renderTime } from "@/utils";
import Upload from "@/components/Upload/singleImage";
import ImageSource from "@/components/Upload/imageSource";
import { provinceAndCityDataPlus, regionData, CodeToText } from "element-china-area-data";
var statusOptions = [{
  key: "0",
  text: "待审核"
}, {
  key: "1",
  text: "通过"
}, {
  key: "2",
  text: "不通过"
}, {
  key: "3",
  text: "处理中"
}];
var typeOptions = [{
  key: 1,
  text: "银行"
}, {
  key: 2,
  text: "支付宝"
}, {
  key: 3,
  text: "微信"
}];
// arr to obj ,such as { CN : "China", US : "USA" }
var statusKeyValue = statusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var typeKeyValue = typeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var rechargeStatusOptions = [{
  key: "0",
  text: "待审核"
}, {
  key: "1",
  text: "通过"
}, {
  key: "2",
  text: "不通过"
}, {
  key: "3",
  text: "已取消"
}];
var rechargeTypeOptions = [{
  key: 1,
  text: "支付宝"
}, {
  key: 2,
  text: "微信"
}, {
  key: 3,
  text: "财付通"
}, {
  key: 4,
  text: "网银"
}];
// arr to obj ,such as { CN : "China", US : "USA" }
var rechargeStatusKeyValue = rechargeStatusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var rechargeTypeKeyValue = rechargeTypeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var buyerStatusOptions = [{
  key: 0,
  text: "待审核"
}, {
  key: 1,
  text: "已通过"
}, {
  key: 2,
  text: "已驳回"
}, {
  key: 3,
  text: "已锁定"
}, {
  key: 4,
  text: "已停用"
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var buyerStatusKeyValue = buyerStatusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var ageOptions = [{
  key: 1,
  text: "20岁以下"
}, {
  key: 2,
  text: "21-25岁"
}, {
  key: 3,
  text: "26-32岁"
}, {
  key: 4,
  text: "33-40岁"
}, {
  key: 5,
  text: "41岁以上"
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var ageKeyValue = ageOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var memberStatusOptions = [{
  key: "1",
  text: "正常"
}, {
  key: "-1",
  text: "停用"
}, {
  key: "-2",
  text: "注销"
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var memberStatusKeyValue = memberStatusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var bankOptions = [{
  key: "ICBC",
  text: "中国工商银行"
}, {
  key: "ABC",
  text: "中国农业银行"
}, {
  key: "BOC",
  text: "中国银行"
}, {
  key: "CCB",
  text: "中国建设银行"
}, {
  key: "CIB",
  text: "兴业银行"
}, {
  key: "SPDB",
  text: "浦东发展银行"
}, {
  key: "BCM",
  text: "交通银行"
}, {
  key: "ECITIC",
  text: "中信银行"
}, {
  key: "CEB",
  text: "中国光大银行"
}, {
  key: "CMB",
  text: "招商银行"
}, {
  key: "GDB",
  text: "广发银行"
}, {
  key: "PSBC",
  text: "中国邮政储蓄银行"
}, {
  key: "PAB",
  text: "平安银行"
}, {
  key: "CMSB",
  text: "民生银行"
}];
var bankKeyValue = bankOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var orderLimitOptions = [{
  key: 0,
  text: "无限制"
}, {
  key: 1,
  text: "仅派浏览"
}, {
  key: 2,
  text: "仅派垫付单"
}, {
  key: 3,
  text: "垫付限金额"
}, {
  key: 4,
  text: "禁止派单"
}];
var orderLimitKeyValue = orderLimitOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var publishLimitOptions = [{
  key: "0",
  text: "无限制"
}, {
  key: "1",
  text: "关闭浏览"
}, {
  key: "2",
  text: "关闭黑科技"
}, {
  key: "3",
  text: "关闭特别任务"
}, {
  key: "4",
  text: "关闭抖音淘宝"
}, {
  key: "5",
  text: "垫付限金额"
}, {
  key: "6",
  text: "禁止发单"
}];
var publishLimitKeyValue = publishLimitOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var taoAgeOptions = [{
  key: 1,
  text: "1年"
}, {
  key: 2,
  text: "2年"
}, {
  key: 3,
  text: "3年"
}, {
  key: 4,
  text: "3年以上"
}];
export default {
  name: "MemberDetailInfo",
  directives: {
    waves: waves
  },
  components: {
    Upload: Upload,
    ImageSource: ImageSource
  },
  filters: {
    statusFilter: function statusFilter(status) {
      return statusKeyValue[status];
    },
    typeFilter: function typeFilter(status) {
      return typeKeyValue[status];
    },
    rechargeStatusFilter: function rechargeStatusFilter(status) {
      return rechargeStatusKeyValue[status];
    },
    rechargeTypeFilter: function rechargeTypeFilter(status) {
      return rechargeTypeKeyValue[status];
    },
    buyerStatusFilter: function buyerStatusFilter(status) {
      return buyerStatusKeyValue[status];
    },
    ageFilter: function ageFilter(status) {
      return ageKeyValue[status];
    },
    memberStatusFilter: function memberStatusFilter(status) {
      return memberStatusKeyValue[status];
    },
    bankFilter: function bankFilter(status) {
      return bankKeyValue[status];
    },
    orderLimitFilter: function orderLimitFilter(status) {
      return orderLimitKeyValue[status];
    },
    publishLimitFilter: function publishLimitFilter(status) {
      return publishLimitKeyValue[status];
    }
  },
  data: function data() {
    return {
      memberId: null,
      addressOptions: regionData,
      searchParams: {},
      memberDetail: {},
      memberRealname: {},
      memberAccount: {},
      accountForm: {},
      currentActive: "1",
      groupOptions: [],
      missionGroupOptions: [],
      secretOptions: [],
      visible: false,
      withdrawTableKey: 0,
      withdrawList: null,
      withdrawTotal: 0,
      withdrawListLoading: false,
      withdrawListQuery: {
        page: 1,
        pageSize: 20
      },
      dialogWithdrawVisible: false,
      withdraw: {},
      withdrawRules: {
        status: [{
          required: true,
          message: "状态不能为空",
          trigger: "change"
        }]
      },
      bankTableKey: 1,
      bankList: null,
      bankTotal: 0,
      bankListLoading: false,
      bankListQuery: {
        page: 1,
        pageSize: 20
      },
      rechargeTableKey: 2,
      rechargeList: null,
      rechargeTotal: 0,
      rechargeListLoading: false,
      rechargeListQuery: {
        page: 1,
        pageSize: 5
      },
      buyerTableKey: 3,
      buyerList: null,
      buyerTotal: 0,
      buyerListLoading: false,
      buyerListQuery: {
        page: 1,
        pageSize: 20
      },
      sellerTableKey: 4,
      sellerList: null,
      sellerTotal: 0,
      sellerListLoading: false,
      sellerListQuery: {
        page: 1,
        pageSize: 20
      },
      dialogBankVisible: false,
      dialogEditBankVisible: false,
      verifyForm: {},
      editBankForm: {},
      bankRules: {
        status: [{
          required: true,
          message: "状态不能为空",
          trigger: "change"
        }]
      },
      bankOptions: bankOptions,
      dialogBuyerVisible: false,
      dialogEditBuyerVisible: false,
      editBuyerForm: {},
      buyerRules: {
        status: [{
          required: true,
          message: "状态不能为空",
          trigger: "change"
        }]
      },
      shopCateOptions: [],
      ageOptions: ageOptions,
      dialogSameAddressVisible: false,
      areaCode: null,
      shopCateId: null,
      phone: null,
      dialogSellerVisible: false,
      dialogEditSellerVisible: false,
      dialogTransferSellerVisible: false,
      editSellerForm: {},
      sellerRules: {
        status: [{
          required: true,
          message: "状态不能为空",
          trigger: "change"
        }]
      },
      dialogAccountVisible: false,
      dialogApplyWithdrawVisible: false,
      applyWithdrawRules: {
        accountName: [{
          required: true,
          message: "收款姓名不能为空",
          trigger: "blur"
        }],
        bank: [{
          required: true,
          message: "提现银行不能为空",
          trigger: "blur"
        }],
        bankAccount: [{
          required: true,
          message: "银行卡号不能为空",
          trigger: "blur"
        }],
        amount: [{
          required: true,
          message: "提现金额不能为空",
          trigger: "blur"
        }]
      },
      applyWithdraw: {},
      orderLimitOptions: orderLimitOptions,
      dialogTransferVisible: false,
      transferRules: {
        uid: [{
          required: true,
          message: "收款人ID不能为空",
          trigger: "blur"
        }],
        amount: [{
          required: true,
          message: "转账金额不能为空",
          trigger: "blur"
        }]
      },
      transferForm: {},
      dialogMsgVisible: false,
      msgRules: {
        title: [{
          required: true,
          message: "标题不能为空",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "内容不能为空",
          trigger: "blur"
        }]
      },
      msgForm: {},
      dialogFreezeVisible: false,
      freezeRules: {
        freezeReason: [{
          required: true,
          message: "理由不能为空",
          trigger: "blur"
        }]
      },
      freezeForm: {},
      publishLimitOptions: publishLimitOptions,
      publishLimitArr: [],
      taoAgeOptions: taoAgeOptions,
      platform: process.env.VUE_APP_TITLE
    };
  },
  created: function created() {
    var uid = this.$route.query && this.$route.query.uid;
    if (uid) {
      this.searchParams.uid = uid;
    }
    var memberId = this.$route.query && this.$route.query.memberId;
    if (memberId) {
      this.searchParams.memberId = memberId;
    }
    this.getMember(true);
    this.getMemberGroupSelector();
    this.getConfigMissionSelector();
    this.getSecretSelector();
  },
  methods: (_methods = {
    getMemberGroupSelector: function getMemberGroupSelector() {
      var _this = this;
      _getMemberGroupSelector().then(function (response) {
        _this.groupOptions = response.data;
      });
    },
    getConfigMissionSelector: function getConfigMissionSelector() {
      var _this2 = this;
      _getConfigMissionSelector().then(function (response) {
        _this2.missionGroupOptions = response.data;
      });
    },
    getSecretSelector: function getSecretSelector() {
      var _this3 = this;
      _getSecretSelector().then(function (response) {
        _this3.secretOptions = response.data;
      });
    },
    getMember: function getMember(isAllRefresh) {
      var _this4 = this;
      getMemberDetail(this.searchParams).then(function (response) {
        var result = response.data;
        if (result && result.member) {
          _this4.memberDetail = result.member;
          _this4.searchParams.uid = _this4.memberDetail.uid;
          _this4.memberId = _this4.memberDetail.id;
          _this4.memberDetail.password = null;
          _this4.memberDetail.payPassword = null;
          _this4.memberRealname = result.realname;
          if (_this4.memberDetail.publishLimit) {
            _this4.publishLimitArr = _this4.memberDetail.publishLimit.split(",");
          }
          if (!_this4.memberRealname) {
            _this4.memberRealname = {};
          }
          _this4.memberAccount = result.memberAccount;
          if (isAllRefresh) {
            _this4.getWithdrawList(_this4.memberDetail.id);
            _this4.getBankList(_this4.memberDetail.id);
            _this4.getRechargeList(_this4.memberDetail.id);
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    updateMemberInfo: function updateMemberInfo() {
      var _this5 = this;
      this.memberDetail.addTime = null;
      this.memberDetail.updTime = null;
      this.memberDetail.birthday = renderTime(this.memberDetail.birthday);
      _updateMember(this.memberDetail).then(function (response) {
        _this5.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000
        });
      });
    },
    updateMemberAccount: function updateMemberAccount() {
      var _this6 = this;
      this.accountForm.memberId = this.memberDetail.id;
      _updateMemberAccount(this.accountForm).then(function (response) {
        _this6.memberAccount = response.data;
        _this6.dialogAccountVisible = false;
        _this6.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000
        });
      });
    },
    getWithdrawList: function getWithdrawList(memberId) {
      var _this7 = this;
      this.withdrawListLoading = true;
      this.withdrawListQuery.memberId = memberId;
      _getWithdrawList(this.withdrawListQuery).then(function (response) {
        _this7.withdrawList = response.data.list;
        _this7.withdrawTotal = response.data.total;
        _this7.withdrawListLoading = false;
      });
    },
    getBankList: function getBankList(memberId) {
      var _this8 = this;
      this.bankListLoading = true;
      this.bankListQuery.memberId = memberId;
      getMemberBankList(this.bankListQuery).then(function (response) {
        _this8.bankList = response.data.list;
        _this8.bankTotal = response.data.total;
        _this8.bankListLoading = false;
      });
    },
    getRechargeList: function getRechargeList(memberId) {
      var _this9 = this;
      this.rechargeListLoading = true;
      this.rechargeListQuery.memberId = memberId;
      getRechargeLog(this.rechargeListQuery).then(function (response) {
        _this9.rechargeList = response.data.list;
        _this9.rechargeTotal = response.data.total;
        _this9.rechargeListLoading = false;
      });
    },
    setDoingWithdraw: function setDoingWithdraw(row) {
      var _this10 = this;
      var id = row.id;
      var formData = {
        ids: id
      };
      _setDoingWithdraw(formData).then(function (response) {
        _this10.getWithdrawList(_this10.memberId);
        if (response.description && response.description != "") {
          var message = response.description.replaceAll("|", "\n");
          _this10.$notify({
            title: "失败",
            message: message,
            type: "error",
            duration: 2000
          });
        }
        _this10.$notify({
          title: "成功",
          message: "处理成功",
          type: "success",
          duration: 2000
        });
      });
    },
    handleVerifyWithdraw: function handleVerifyWithdraw(row) {
      var _this11 = this;
      this.withdraw = {
        status: "1",
        ids: row.id
      }; // copy obj
      this.dialogWithdrawVisible = true;
      this.$nextTick(function () {
        _this11.$refs["withdrawForm"].clearValidate();
      });
    },
    confirmWithdraw: function confirmWithdraw() {
      var _this12 = this;
      this.$refs["withdrawForm"].validate(function (valid) {
        if (valid) {
          var formData = {
            ids: _this12.withdraw.ids,
            status: _this12.withdraw.status,
            remark: _this12.withdraw.remark
          };
          verifyWithdraw(formData).then(function (response) {
            _this12.getWithdrawList(_this12.memberId);
            _this12.dialogWithdrawVisible = false;
            if (response.description && response.description != "") {
              var message = response.description.replaceAll("|", "\n");
              _this12.$notify({
                title: "失败",
                message: message,
                type: "error",
                duration: 2000
              });
              return;
            }
            _this12.$notify({
              title: "成功",
              message: "审核提现成功",
              type: "success",
              duration: 2000
            });
          });
        }
      });
    },
    handleVerifyBank: function handleVerifyBank(row) {
      var _this13 = this;
      this.verifyForm = {
        status: 1,
        ids: row.id
      }; // copy obj
      this.dialogBankVisible = true;
      this.$nextTick(function () {
        _this13.$refs["bankForm"].clearValidate();
      });
    },
    confirmBank: function confirmBank() {
      var _this14 = this;
      this.$refs["bankForm"].validate(function (valid) {
        if (valid) {
          var formData = {
            ids: _this14.verifyForm.ids,
            status: _this14.verifyForm.status,
            remark: _this14.verifyForm.remark
          };
          verifyMemberBank(formData).then(function (response) {
            _this14.getBankList(_this14.memberId);
            _this14.dialogBankVisible = false;
            if (response.description && response.description != "") {
              _this14.$notify({
                title: "失败",
                message: response.description,
                type: "error",
                duration: 2000
              });
            }
            _this14.$notify({
              title: "成功",
              message: "审核成功",
              type: "success",
              duration: 2000
            });
          });
        }
      });
    },
    handleUpdateBank: function handleUpdateBank(row) {
      var _this15 = this;
      this.dialogEditBankVisible = true;
      this.editBankForm = Object.assign({}, row); // copy obj
      this.editBankForm.pca = [this.editBankForm.provinceCode, this.editBankForm.cityCode, this.editBankForm.areaCode];
      this.$nextTick(function () {
        _this15.$refs["editBankForm"].clearValidate();
      });
    },
    editSubmitBank: function editSubmitBank() {
      var _this16 = this;
      this.$refs["editBankForm"].validate(function (valid) {
        if (valid) {
          var formData = Object.assign({}, _this16.editBankForm);
          if (_this16.editBankForm.pca && _this16.editBankForm.pca.length > 0) {
            formData.provinceCode = _this16.editBankForm.pca[0];
            formData.cityCode = _this16.editBankForm.pca[1];
            formData.areaCode = _this16.editBankForm.pca[2];
            formData.province = CodeToText[formData.provinceCode];
            formData.city = CodeToText[formData.cityCode];
            formData.area = CodeToText[formData.areaCode];
          }
          formData.addTime = null;
          formData.updTime = null;
          updateMemberBank(formData).then(function () {
            _this16.getBankList(_this16.memberId);
            _this16.dialogEditBankVisible = false;
            _this16.$notify({
              title: "成功",
              message: "保存成功",
              type: "success",
              duration: 2000
            });
          });
        }
      });
    }
  }, _defineProperty(_methods, "updateMemberInfo", function updateMemberInfo() {
    var _this17 = this;
    this.memberDetail.addTime = null;
    this.memberDetail.updTime = null;
    this.memberDetail.birthday = renderTime(this.memberDetail.birthday);
    _updateMember(this.memberDetail).then(function (response) {
      _this17.$notify({
        title: "成功",
        message: "保存成功",
        type: "success",
        duration: 2000
      });
    });
  }), _defineProperty(_methods, "updateMemberAccount", function updateMemberAccount() {
    var _this18 = this;
    this.accountForm.memberId = this.memberDetail.id;
    _updateMemberAccount(this.accountForm).then(function (response) {
      _this18.getMember(false);
      _this18.dialogAccountVisible = false;
      _this18.$notify({
        title: "成功",
        message: "保存成功",
        type: "success",
        duration: 2000
      });
    });
  }), _defineProperty(_methods, "initPassword", function initPassword() {
    this.updateMember({
      id: this.memberId,
      password: "123456"
    }, "重置成功");
  }), _defineProperty(_methods, "updateMember", function updateMember(form, msg) {
    var _this19 = this;
    _updateMember(form).then(function (response) {
      _this19.$notify({
        title: "成功",
        message: msg,
        type: "success",
        duration: 2000
      });
      _this19.getMember(false);
    });
  }), _defineProperty(_methods, "handleAccount", function handleAccount() {
    var _this20 = this;
    this.accountForm = {};
    this.dialogAccountVisible = true;
    this.$nextTick(function () {
      _this20.$refs["accountForm"].clearValidate();
    });
  }), _defineProperty(_methods, "updateInvite", function updateInvite() {
    var _this21 = this;
    _updateInvite({
      memberId: this.memberId,
      inviteUid: this.memberDetail.tjrUid
    }).then(function (response) {
      _this21.$notify({
        title: "成功",
        message: "修改成功",
        type: "success",
        duration: 2000
      });
      _this21.getMember(false);
    });
  }), _defineProperty(_methods, "handleApplyWithdraw", function handleApplyWithdraw() {
    var _this22 = this;
    this.applyWithdraw = {};
    getLastWithdraw({
      type: 1,
      memberId: this.memberId
    }).then(function (result) {
      var response = result.data;
      if (response) {
        _this22.applyWithdraw = {
          accountName: response.realname,
          bank: response.bank,
          bankAccount: response.bankAccount
        };
      }
    });
    this.dialogApplyWithdrawVisible = true;
    this.$nextTick(function () {
      _this22.$refs["applyWithdrawForm"].clearValidate();
    });
  }), _defineProperty(_methods, "confirmApplyWithdraw", function confirmApplyWithdraw() {
    var _this23 = this;
    this.$refs["applyWithdrawForm"].validate(function (valid) {
      if (valid) {
        var formData = Object.assign({}, _this23.applyWithdraw);
        formData.memberId = _this23.memberId;
        formData.addTime = null;
        formData.updTime = null;
        applyWithdraw(formData).then(function () {
          _this23.getMember(true);
          _this23.dialogApplyWithdrawVisible = false;
          _this23.$notify({
            title: "成功",
            message: "申请成功, 请等待平台审核打款",
            type: "success",
            duration: 2000
          });
        });
      }
    });
  }), _defineProperty(_methods, "handleTransfer", function handleTransfer() {
    var _this24 = this;
    this.transferForm = {};
    this.dialogTransferVisible = true;
    this.$nextTick(function () {
      _this24.$refs["transferForm"].clearValidate();
    });
  }), _defineProperty(_methods, "confirmTransfer", function confirmTransfer() {
    var _this25 = this;
    this.$refs["transferForm"].validate(function (valid) {
      if (valid) {
        var formData = Object.assign({}, _this25.transferForm);
        formData.memberId = _this25.memberId;
        formData.addTime = null;
        formData.updTime = null;
        transferAmount(formData).then(function () {
          _this25.getMember(true);
          _this25.dialogTransferVisible = false;
          _this25.$notify({
            title: "成功",
            message: "转账成功",
            type: "success",
            duration: 2000
          });
        });
      }
    });
  }), _defineProperty(_methods, "handleSendMsg", function handleSendMsg() {
    var _this26 = this;
    this.msgForm = {};
    this.dialogMsgVisible = true;
    this.$nextTick(function () {
      _this26.$refs["msgForm"].clearValidate();
    });
  }), _defineProperty(_methods, "confirmSendMsg", function confirmSendMsg() {
    var _this27 = this;
    this.$refs["msgForm"].validate(function (valid) {
      if (valid) {
        var formData = Object.assign({}, _this27.msgForm);
        formData.memberId = _this27.memberId;
        formData.addTime = null;
        formData.updTime = null;
        sendMsg(formData).then(function () {
          _this27.getMember(true);
          _this27.dialogMsgVisible = false;
          _this27.$notify({
            title: "成功",
            message: "发送成功",
            type: "success",
            duration: 2000
          });
        });
      }
    });
  }), _defineProperty(_methods, "limitWithdraw", function limitWithdraw(form, msg) {
    var _this28 = this;
    _limitWithdraw(form).then(function (response) {
      _this28.$notify({
        title: "成功",
        message: msg,
        type: "success",
        duration: 2000
      });
      _this28.getMember(false);
    });
  }), _methods)
};