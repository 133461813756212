"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecialDuration = addSpecialDuration;
exports.deleteSpecialDuration = deleteSpecialDuration;
exports.getSpecialDurationList = getSpecialDurationList;
exports.getSpecialDurationPage = getSpecialDurationPage;
exports.updateSpecialDuration = updateSpecialDuration;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialDurationPage(data) {
  return (0, _requestJson.default)({
    url: '/special/duration/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialDurationList(data) {
  return (0, _requestJson.default)({
    url: '/special/duration/getList',
    method: 'get',
    params: data
  });
}
function addSpecialDuration(data) {
  return (0, _requestJson.default)({
    url: '/special/duration/save',
    method: 'post',
    data: data
  });
}
function updateSpecialDuration(data) {
  return (0, _requestJson.default)({
    url: '/special/duration/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecialDuration(id) {
  return (0, _requestJson.default)({
    url: "/special/duration/deleteById/".concat(id),
    method: 'post'
  });
}