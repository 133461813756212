"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SingleImageUpload",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tempUrl: "",
      uploadUrl: process.env.VUE_APP_BASE_API + "system/upload/US",
      dataObj: {
        token: "",
        key: ""
      }
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value;
    }
  },
  methods: {
    //上传前校验方法
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var suffix = file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg";
        var isLt1M = file.size / 1024 / 1024 < 1;
        if (!suffix) {
          _this2.$message({
            message: "上传图片只能是jpg,jpeg,png格式！",
            type: "error"
          });
          return reject(false);
        }
        if (!isLt1M) {
          _this2.$message.error("\u4E0A\u4F20\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 1MB!");
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
        _this2.limitFileWH(600, 600, file).then(function (res) {
          file.isFlag = res;
          if (file.isFlag) {
            _this2.$message({
              message: "图片上传中...",
              type: "success"
            });
            return resolve(true);
          } else {
            return reject(false);
          }
        });
      });
    },
    // 上传图片事件限制图片尺寸
    limitFileWH: function limitFileWH(E_width, E_height, file) {
      var _this3 = this;
      var _this = this;
      var imgWidth = "";
      var imgHight = "";
      var isSize = new Promise(function (resolve, reject) {
        var width = E_width;
        var height = E_height;
        var _URL = window.URL || window.webkitURL;
        var img = new Image();
        img.onload = function () {
          imgWidth = img.width;
          imgHight = img.height;
          console.log("图片", img.width, width, img.height, height);
          var valid = img.width >= width && img.height >= height && img.width <= 1200 && img.height <= 1200 && img.width == img.height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(function () {
        return true;
      }, function () {
        _this3.$message.error("上传SKU图片尺寸最小为 600 X 600 最大为1200 X 1200的正方形图片");
        return false;
      });
      return isSize;
    },
    rmImage: function rmImage() {
      this.emitInput("");
    },
    emitInput: function emitInput(val) {
      this.$emit("input", val);
    },
    handleImageSuccess: function handleImageSuccess(result) {
      if (result.code == 200) {
        this.$message({
          message: "图片上传成功！",
          type: "success"
        });
        this.emitInput(result.data.imgUrl);
      } else {
        this.$message.error("上传图片失败，请重新上传");
      }
    }
  }
};
exports.default = _default;