"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SupplierList",
  components: {
    Pagination: _index.default
  },
  data: function data() {
    return {
      list: [],
      listLoading: true,
      obj: {
        productTitle: "",
        supplyName: "",
        skuTitle: "",
        page: 1,
        pageSize: 10
      },
      total: 0,
      tableH: 0
    };
  },
  mounted: function mounted() {
    this.fetTableHeight();
  },
  methods: {
    // 重置table高度
    resetHeight: function resetHeight() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        _this.tableH = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight: function fetTableHeight() {
      var _this2 = this;
      this.resetHeight().then(function (res) {
        // this.tableH =
        //   this.$refs.tableWrapper.getBoundingClientRect().height - 10;
        _this2.tableH = _this2.$refs.tableWrapper.getBoundingClientRect().height - 35;
      });
      console.log("this.$refs.tableWrapper", this.$refs.tableWrapper.getBoundingClientRect().height);
    },
    formatTitle: function formatTitle(title) {
      var maxLength = 100; // Adjust this value as needed
      if (title.length > maxLength) {
        return title.substring(0, maxLength) + "...";
      }
      return title;
    },
    // 点击查询
    addquery: function addquery() {},
    handleSearch: function handleSearch() {
      // 在这里触发搜索操作，调用接口并传递搜索参数
      var params = {};
      this.obj.page = 1;
      this.obj.pageSize = 10;
      // 调用搜索接口并更新列表数据
      this.getList();
    },
    // 列表接口
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _warehouse.getStoragePage)(this.obj).then(function (response) {
        // console.log("数据", response);
        if (response.code === 200) {
          _this3.list = response.data.list;
          _this3.total = response.data.total;
          // console.log("执行了嘛", this.total);
        } else {
          _elementUi.Message.error(response.message);
        }
        _this3.listLoading = false;
      });
    }
  },
  created: function created() {
    this.getList();
  }
};
exports.default = _default;