var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "买家组别", name: "first" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "卖家组别", name: "second" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-edit",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    size: "mini",
                    type: "danger",
                    icon: "el-icon-delete",
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "组别类型", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(scope.row.type == 1 ? "买家组别" : "卖家组别")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "组别名称", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.defaultStatus == 1
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("默认"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "组别图标", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.icon
                      ? _c("el-image", {
                          staticStyle: { height: "20px" },
                          attrs: { src: scope.row.icon, fit: "scale-down" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "等级", width: "100px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.level))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "初始金额", width: "100px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("moneyFormat")(scope.row.initAmount))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "开启状态", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function ($event) {
                          return _vm.statusChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑" : "新增",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "100%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.memberGroup,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "组别类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.memberGroup.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberGroup, "type", $$v)
                        },
                        expression: "memberGroup.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("买家组别"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("卖家组别"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组别名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分组名称" },
                    model: {
                      value: _vm.memberGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "name", $$v)
                      },
                      expression: "memberGroup.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组别icon", prop: "icon" } },
                [
                  _c("Upload", {
                    model: {
                      value: _vm.memberGroup.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "icon", $$v)
                      },
                      expression: "memberGroup.icon",
                    },
                  }),
                  _vm._v(" "),
                  _c("ImageSource", {
                    attrs: { mul: false },
                    model: {
                      value: _vm.memberGroup.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "icon", $$v)
                      },
                      expression: "memberGroup.icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否默认", prop: "defaultStatus" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.memberGroup.defaultStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "defaultStatus", $$v)
                      },
                      expression: "memberGroup.defaultStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "级别", prop: "level" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入级别" },
                    model: {
                      value: _vm.memberGroup.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "level", $$v)
                      },
                      expression: "memberGroup.level",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "初始金额", prop: "initAmount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入初始金额" },
                    model: {
                      value: _vm.memberGroup.initAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "initAmount", $$v)
                      },
                      expression: "memberGroup.initAmount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "200px" },
                    attrs: { min: 1, label: "修改排序" },
                    model: {
                      value: _vm.memberGroup.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "sort", $$v)
                      },
                      expression: "memberGroup.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开启关闭", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.memberGroup.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberGroup, "status", $$v)
                      },
                      expression: "memberGroup.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }