var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _c(
      "div",
      { staticClass: " clearfix" },
      [
        _c("pan-thumb", {
          staticStyle: { float: "left" },
          attrs: { image: _vm.avatar },
        }),
        _vm._v(" "),
        _c("github-corner", {
          staticStyle: {
            position: "absolute",
            top: "0px",
            border: "0",
            right: "0",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "info-container" }, [
          _c("span", { staticClass: "display_name" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c("img", { staticClass: "emptyGif", attrs: { src: _vm.emptyGif } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }