"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecialResources = addSpecialResources;
exports.deleteSpecialResources = deleteSpecialResources;
exports.getSpecialResourcesList = getSpecialResourcesList;
exports.getSpecialResourcesPage = getSpecialResourcesPage;
exports.updateSpecialResources = updateSpecialResources;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialResourcesPage(data) {
  return (0, _requestJson.default)({
    url: '/special/resources/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialResourcesList(data) {
  return (0, _requestJson.default)({
    url: '/special/resources/getList',
    method: 'get',
    params: data
  });
}
function addSpecialResources(data) {
  return (0, _requestJson.default)({
    url: '/special/resources/save',
    method: 'post',
    data: data
  });
}
function updateSpecialResources(data) {
  return (0, _requestJson.default)({
    url: '/special/resources/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecialResources(id) {
  return (0, _requestJson.default)({
    url: "/special/resources/deleteById/".concat(id),
    method: 'post'
  });
}