"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserInfo = getUserInfo;
exports.loginByUsername = loginByUsername;
exports.logout = logout;
exports.refreshToken = refreshToken;
exports.sendNotify = sendNotify;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function loginByUsername(username, password, brandId) {
  var data = {
    username: username,
    password: password,
    brandId: brandId
  };
  return (0, _request.default)({
    url: '/system/common/login',
    method: 'post',
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: '/system/common/logout',
    method: 'post'
  });
}
function getUserInfo(token) {
  return (0, _request.default)({
    url: '/system/info/get',
    method: 'get',
    params: {
      token: token
    }
  });
}
function refreshToken() {
  return (0, _request.default)({
    url: '/system/common/refreshToken',
    method: 'post'
  });
}
function sendNotify(data) {
  return (0, _requestJson.default)({
    url: '/system/sendNotic',
    method: 'post',
    data: data
  });
}