"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/api/log");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Waves directive

// Secondary package based on el-pagination

var statusOptions = [{
  key: "0",
  text: "未发送"
}, {
  key: "1",
  text: "已发送"
}, {
  key: "2",
  text: "发送失败"
}];
var typeOptions = [{
  key: "1",
  text: "手机"
}, {
  key: "2",
  text: "邮箱"
}];
// arr to obj ,such as { CN : "China", US : "USA" }
var statusKeyValue = statusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var typeKeyValue = typeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
var _default = {
  name: "messageLogTable",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      return statusKeyValue[status];
    },
    typeFilter: function typeFilter(status) {
      return typeKeyValue[status];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      statusOptions: statusOptions,
      typeOptions: typeOptions,
      checkAll: true,
      checkedStatus: ["0", "1", "2"],
      isIndeterminate: false,
      statusList: ["0", "1", "2"],
      tableH: 0
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {
    this.fetTableHeight();
  },
  methods: {
    // 重置table高度
    resetHeight: function resetHeight() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        _this.tableH = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight: function fetTableHeight() {
      var _this2 = this;
      this.resetHeight().then(function (res) {
        // this.tableH =
        //   this.$refs.tableWrapper.getBoundingClientRect().height - 10;
        _this2.tableH = _this2.$refs.tableWrapper.getBoundingClientRect().height - 35;
      });
      console.log("this.$refs.tableWrapper", this.$refs.tableWrapper.getBoundingClientRect().height);
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedStatus = val ? ["0", "1", "2"] : [];
      this.isIndeterminate = false;
    },
    handleCheckedStatusChange: function handleCheckedStatusChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.statusList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.statusList.length;
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      if (this.checkedStatus && this.checkedStatus.length > 0) {
        this.listQuery.statusArr = this.checkedStatus.join(",");
      }
      (0, _log.getverifyCodeLog)(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.total;
        _this3.listLoading = false;
      });
    }
  }
};
exports.default = _default;