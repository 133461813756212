import Layout from '@/layout';
var vipRouter = {
  path: '/vip',
  component: Layout,
  redirect: '/vip/type',
  name: 'vip',
  alwaysShow: true,
  meta: {
    title: 'vip',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/vip/type/index');
    },
    name: 'VipType',
    meta: {
      title: 'vip 配置',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/vip/order/index');
    },
    name: 'VipOrder',
    meta: {
      title: 'vip 订单',
      role: []
    }
  }, {
    path: 'member',
    component: function component() {
      return import('@/views/vip/member/index');
    },
    name: 'VipMember',
    meta: {
      title: 'vip 会员',
      role: []
    }
  }]
};
export default vipRouter;