"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditshipOrder = auditshipOrder;
exports.deleteShop = deleteShop;
exports.deleteShopFreight = deleteShopFreight;
exports.deleteShopInfo = deleteShopInfo;
exports.deleteShopService = deleteShopService;
exports.getShipOrder = getShipOrder;
exports.getShopDataList = getShopDataList;
exports.getShopFreightList = getShopFreightList;
exports.getShopInfoList = getShopInfoList;
exports.getShopList = getShopList;
exports.getShopServiceList = getShopServiceList;
exports.getShopServiceSelector = getShopServiceSelector;
exports.updateShop = updateShop;
exports.updateShopService = updateShopService;
exports.verifyShopInfo = verifyShopInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
function getShopList(data) {
  return (0, _request.default)({
    url: '/system/shop/getList',
    method: 'get',
    params: data
  });
}
function updateShop(data) {
  return (0, _request.default)({
    url: 'system/shop/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteShop(data) {
  return (0, _request.default)({
    url: 'system/shop/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getShopServiceSelector(data) {
  return (0, _request.default)({
    url: '/system/shopService/getSelector',
    method: 'get',
    params: data
  });
}
function getShopServiceList(data) {
  return (0, _request.default)({
    url: '/system/shopService/getList',
    method: 'get',
    params: data
  });
}
function updateShopService(data) {
  return (0, _request.default)({
    url: 'system/shopService/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteShopService(data) {
  return (0, _request.default)({
    url: 'system/shopService/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getShopFreightList(data) {
  return (0, _request.default)({
    url: '/system/shopFreight/getList',
    method: 'get',
    params: data
  });
}
function deleteShopFreight(data) {
  return (0, _request.default)({
    url: 'system/shopFreight/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getShopInfoList(data) {
  return (0, _request.default)({
    url: '/system/shopInfo/getList',
    method: 'get',
    params: data
  });
}
function verifyShopInfo(data) {
  return (0, _request.default)({
    url: 'system/shopInfo/verify',
    method: 'post',
    data: data
  });
}
function deleteShopInfo(data) {
  return (0, _request.default)({
    url: 'system/shopInfo/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getShopDataList(data) {
  return (0, _request.default)({
    url: '/system/shop/getDataList',
    method: 'get',
    params: data
  });
}
function getShipOrder(data) {
  return (0, _request.default)({
    url: "/ship/getShipOrder",
    method: 'get',
    params: data
  });
}
function auditshipOrder(data) {
  return (0, _request.default)({
    url: "/ship/audit/shipOrder",
    method: 'post',
    data: data
  });
}