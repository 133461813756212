"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _supplier = require("@/api/supplier/supplier");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ShipList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      tableData: [],
      editAddressDialogVisible: false,
      dialogAccountVisible: false,
      accountForm: {
        supplierAmount: "",
        remark: ""
      },
      rules: {
        supplierAmount: [{
          required: true,
          message: "请填写充值的金额",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "请填写备注",
          trigger: "blur"
        }]
      },
      memberAccount: {},
      obj: {
        page: 1,
        pageSize: 20
      },
      tableData_total: 0
    };
  },
  methods: {
    add_recharge: function add_recharge(row) {
      console.log("row", row);
      this.accountForm.memberId = row.memberId;
      this.accountForm.supplierAmount = "";
      this.accountForm.remark = "";
      this.dialogAccountVisible = true;
    },
    // 保存充值
    add_updateMemberAccount: function add_updateMemberAccount(ruleForm) {
      var _this = this;
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          (0, _supplier.memberupdateAccount)(_this.accountForm).then(function (response) {
            _this.dialogAccountVisible = false;
            _this.getList();
            _this.$message({
              message: "充值成功",
              type: "success"
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 确定新增
    submitForm: function submitForm(editAddressForm) {
      var _this2 = this;
      this.$refs[editAddressForm].validate(function (valid) {
        if (valid) {
          var url = "";
          if (_this2.text == "新增") {
            url = uscitysave;
          } else {
            url = uscityupdateById;
          }
          url(_this2.editAddressForm).then(function (res) {
            _this2.$message({
              message: _this2.text + "成功！",
              type: "success"
            });
            _this2.editAddressDialogVisible = false;
            _this2.getList();
            //   console.log("新增成功吗", res);
          }).catch(function (error) {});
        } else {}
      });
    },
    //点击分野
    get_tableData: function get_tableData() {
      var _this3 = this;
      console.log("分页");
      (0, _supplier.getShopSubAccount)(this.obj).then(function (res) {
        _this3.tableData = res.data.list;
        _this3.tableData_total = res.data.total;
        console.log("点击主账号列表数据", res);
      });
    },
    // 编辑州
    edit_state: function edit_state(val) {
      var _this4 = this;
      console.log("点击主账号列表", val);
      this.obj.shopId = val.shopId;
      this.obj.page = 1;
      this.obj.pageSize = 10;
      (0, _supplier.getShopSubAccount)(this.obj).then(function (res) {
        console.log("点击主账号列表数据", res);
        _this4.tableData = res.data.list;
        _this4.tableData_total = res.data.total;
      });
      this.editAddressDialogVisible = true;
    },
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      (0, _supplier.getShopRelationPage)(this.listQuery).then(function (response) {
        _this5.list = response.data.list;
        _this5.total = response.data.total;
        _this5.listLoading = false;
        console.log("列表数据", _this5.list);
      }).catch(function (error) {
        _this5.listLoading = false;
      });
    }
  },
  created: function created() {
    this.getList();
  }
};
exports.default = _default;