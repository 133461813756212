import Layout from '@/layout';
var wswarehouseRouter = {
  path: '/warehouseModule',
  component: Layout,
  redirect: '/warehouseModule/order',
  name: 'warehouse',
  alwaysShow: true,
  meta: {
    title: '仓库系统',
    icon: 'report-brand'
  },
  children: [{
    path: 'wsindex',
    component: function component() {
      return import('@/views/warehouse/wsindex/index');
    },
    name: 'wsWarehouseList',
    meta: {
      title: '仓库管理',
      role: []
    }
  }, {
    path: 'apiconfig',
    component: function component() {
      return import('@/views/warehouse/apiconfig/index');
    },
    name: 'wsApiConfig',
    meta: {
      title: '物流渠道',
      role: []
    }
  }, {
    path: 'weightZone',
    component: function component() {
      return import('@/views/warehouse/weightZone/index');
    },
    name: 'weightZone',
    meta: {
      title: '运费上浮表',
      role: []
    }
  }, {
    path: 'withholdFreight',
    component: function component() {
      return import('@/views/warehouse/weightZone/withholdFreightindex');
    },
    name: 'withholdFreight',
    meta: {
      title: '预计运费表',
      role: []
    }
  }, {
    path: 'inventory',
    component: function component() {
      return import('@/views/warehouse/inventory/index');
    },
    name: 'WarehouseInventory',
    meta: {
      title: '库存管理',
      role: []
    }
  }, {
    path: 'locator',
    component: function component() {
      return import('@/views/warehouse/locator/index');
    },
    name: 'WarehouseLocator',
    meta: {
      title: '库位管理',
      role: []
    }
  }, {
    path: 'deliverynote',
    component: function component() {
      return import('@/views/warehouse/deliverynote/index');
    },
    name: 'WarehouseDeliverynote',
    meta: {
      title: '发货单管理',
      role: []
    }
  }, {
    path: 'batchadmin',
    component: function component() {
      return import('@/views/warehouse/batchadmin/index');
    },
    name: 'WarehouseBatchadmin',
    meta: {
      title: '批次管理',
      role: []
    }
  }, {
    path: 'supplier',
    component: function component() {
      return import('@/views/warehouse/supplier/index');
    },
    name: 'warehouseSupplier',
    meta: {
      title: '供应商管理',
      role: []
    }
  }, {
    path: 'wssku',
    component: function component() {
      return import('@/views/warehouse/wssku/index');
    },
    name: 'warehouseWssku',
    meta: {
      title: '商品管理',
      role: []
    }
  }, {
    path: 'bindWarehouse',
    component: function component() {
      return import('@/views/warehouse/bindWarehouse/index');
    },
    name: 'warehouseBindWarehouse',
    meta: {
      title: '仓库账号',
      role: []
    }
  }, {
    path: 'bulletin',
    component: function component() {
      return import('@/views/warehouse/bulletin/index');
    },
    name: 'warehouseBulletin',
    meta: {
      title: '公示板',
      role: []
    }
  }, {
    path: 'inventoryLog',
    component: function component() {
      return import('@/views/warehouse/inventory/inventoryLogs.vue');
    },
    name: 'warehouseInventoryLog',
    meta: {
      title: '库存日志',
      role: []
    }
  }]
};
export default wswarehouseRouter;