import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/root/workspace/tk-admin_Z7th/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/workspace/tk-admin_Z7th/node_modules/core-js/modules/es6.promise.js";
import "/root/workspace/tk-admin_Z7th/node_modules/core-js/modules/es6.object.assign.js";
import "/root/workspace/tk-admin_Z7th/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // Internationalization
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/errorLog"; // error log
import socket from "./socket";
import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
import { download } from '@/utils/request';
Vue.use(LemonIMUI);
import * as filters from "./filters"; // global filters
import lekDialog from '@/views/lek-dialog';
/*挂载全局页面布局组件*/
import pageView from "@/components/PageView/index.vue";
Vue.component("pageView", pageView);
import Space from "./components/Space/index.vue";
Vue.component('space', Space);
// sdk处理视频
import dateUtil from '@/utils/dateUtil.js';
Vue.prototype.$dateUtil = dateUtil;
Vue.prototype.download = download;
// 全局按钮判断函数
import checkPermission from "@/utils/permission";
Vue.prototype.$checkPermission = checkPermission;
import checkPermissionCustom from "@/utils/permissionCustom";
Vue.prototype.$checkPermissionCustom = checkPermissionCustom;
import checkPermissionUser from "@/utils/permissionUser";
Vue.prototype.$checkPermissionUser = checkPermissionUser;

// import { mockXHR } from '../mock' // simulation data

// mock api in github pages site build
// if (process.env.NODE_ENV === 'production') { mockXHR() }
Vue.prototype.socket = socket;
Vue.use(Element, {
  size: Cookies.get('size') || 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.component('lekDialog', lekDialog);

// 清除所有console.log
// window.console.log = function () { };

new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});