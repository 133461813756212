var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-input",
        {
          staticClass: "filter-item",
          staticStyle: { width: "250px" },
          attrs: { clearable: "", placeholder: "" },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.getMember(true)
            },
          },
          model: {
            value: _vm.searchParams.uid,
            callback: function ($$v) {
              _vm.$set(_vm.searchParams, "uid", $$v)
            },
            expression: "searchParams.uid",
          },
        },
        [_c("template", { slot: "prepend" }, [_vm._v("用户ID")])],
        2
      ),
      _vm._v(" "),
      _c(
        "el-input",
        {
          staticClass: "filter-item",
          staticStyle: { width: "250px" },
          attrs: { clearable: "", placeholder: "" },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.getMember(true)
            },
          },
          model: {
            value: _vm.searchParams.phone,
            callback: function ($$v) {
              _vm.$set(_vm.searchParams, "phone", $$v)
            },
            expression: "searchParams.phone",
          },
        },
        [_c("template", { slot: "prepend" }, [_vm._v("手机号码")])],
        2
      ),
      _vm._v(" "),
      _c(
        "el-input",
        {
          staticClass: "filter-item",
          staticStyle: { width: "250px" },
          attrs: { clearable: "", placeholder: "" },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.getMember(true)
            },
          },
          model: {
            value: _vm.searchParams.inviteCode,
            callback: function ($$v) {
              _vm.$set(_vm.searchParams, "inviteCode", $$v)
            },
            expression: "searchParams.inviteCode",
          },
        },
        [_c("template", { slot: "prepend" }, [_vm._v("邀请码")])],
        2
      ),
      _vm._v(" "),
      _c("el-button", {
        directives: [{ name: "waves", rawName: "v-waves" }],
        staticClass: "filter-item",
        attrs: { type: "primary", icon: "el-icon-search", circle: "" },
        on: {
          click: function ($event) {
            return _vm.getMember(true)
          },
        },
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("基本信息")])]),
      _vm._v(" "),
      _c("div", { staticClass: "contain" }, [
        _c("div", { staticClass: "bg-color-w b-color-4 wrap-info-table" }, [
          _c("div", { staticClass: "flex f-a-c h-50 f-j-c bg-color-4" }, [
            _vm.memberDetail.type == 1
              ? _c("span", [_vm._v("买家")])
              : _vm._e(),
            _vm._v("\n          \n\n        "),
            _vm.memberDetail.type == 2
              ? _c("span", [_vm._v("商家")])
              : _vm._e(),
            _vm._v("\n          \n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            头像\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _c("el-avatar", {
                    attrs: { shape: "square", src: _vm.memberDetail.headImg },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户ID\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.memberDetail.uid) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户名\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.memberDetail.code) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            会员组\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "请选择组别",
                        clearable: "",
                        filterable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.updateMember(
                            {
                              id: _vm.memberDetail.id,
                              groupId: _vm.memberDetail.groupId,
                            },
                            "修改成功"
                          )
                        },
                      },
                      model: {
                        value: _vm.memberDetail.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberDetail, "groupId", $$v)
                        },
                        expression: "memberDetail.groupId",
                      },
                    },
                    _vm._l(_vm.groupOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            身份证\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.memberRealname.idCard) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            姓名\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("span", [_vm._v(_vm._s(_vm.memberRealname.realname))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            身份认证状态\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.memberDetail.realnameStatus == 0 ? "未认证" : "已认证"
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            用户状态\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("memberStatusFilter")(_vm.memberDetail.status)
                    )
                ),
                _vm.memberDetail.freezeReason
                  ? _c("span", [
                      _vm._v("/" + _vm._s(_vm.memberDetail.freezeReason)),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            手机号码\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1  f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateMember(
                          {
                            id: _vm.memberDetail.id,
                            phone: _vm.memberDetail.phone,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "phone", $$v)
                      },
                      expression: "memberDetail.phone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 f-s-0 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            QQ号\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { "margin-left": "10px", width: "90%" },
                    attrs: { placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateMember(
                          {
                            id: _vm.memberDetail.id,
                            phone: _vm.memberDetail.qq,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.qq,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "qq", $$v)
                      },
                      expression: "memberDetail.qq",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            积分\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.memberAccount.point) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            设备号\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("span", [_vm._v(_vm._s(_vm.memberDetail.imeId))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            注册时间\n          ")]
              ),
              _vm._v(" "),
              _vm.memberDetail.addTime
                ? _c(
                    "div",
                    { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("parseTime")(
                              _vm.memberDetail.addTime,
                              "{y}-{m}-{d} {h}:{i}:{s}"
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            注册IP\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("span", [_vm._v(_vm._s(_vm.memberDetail.registerIp))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            最后登录时间\n          ")]
              ),
              _vm._v(" "),
              _vm.memberDetail.lastLoginTime
                ? _c(
                    "div",
                    { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("parseTime")(
                              _vm.memberDetail.lastLoginTime,
                              "{y}-{m}-{d} {h}:{i}:{s}"
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            最后登录IP\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("span", [_vm._v(_vm._s(_vm.memberDetail.lastLoginIp))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            邀请人ID\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _c("el-input", {
                    staticStyle: { "margin-left": "10px", width: "90%" },
                    attrs: { placeholder: "" },
                    on: { change: _vm.updateInvite },
                    model: {
                      value: _vm.memberDetail.tjrUid,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "tjrUid", $$v)
                      },
                      expression: "memberDetail.tjrUid",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            银行卡认证状态\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("statusFilter")(_vm.memberDetail.bankStatus)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            邀请码\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm.memberDetail.inviteStatus == 1
                  ? _c("span", [_vm._v(_vm._s(_vm.memberDetail.inviteCode))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.inviteStatus == 0
                  ? _c("span", [_vm._v("无邀请资格")])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
              },
              [_vm._v("\n          操作\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex flex-1 bg-color-w f-w",
                staticStyle: { padding: "0 0px 10px 10px" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "10px 10px 0 0" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.handleAccount },
                  },
                  [_vm._v("充值/扣钱")]
                ),
                _vm._v(" "),
                _vm.memberDetail.status == "1"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定冻结用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            _vm.dialogFreezeVisible = true
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("冻结用户")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.status == "-1" ||
                _vm.memberDetail.status == "-2"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定解冻用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateMember(
                              { id: _vm.memberDetail.id, status: "1" },
                              "解冻成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("解冻用户")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.inviteStatus == 1
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定删除邀请资格？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateMember(
                              { id: _vm.memberDetail.id, inviteStatus: 0 },
                              "删除成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("删除邀请资格")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.inviteStatus == 0
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定恢复邀请资格？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateMember(
                              { id: _vm.memberDetail.id, inviteStatus: 1 },
                              "恢复成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("恢复邀请资格")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.status != "-2"
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定注销用户？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.updateMember(
                              { id: _vm.memberDetail.id, status: "-2" },
                              "注销成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("注销用户")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "10px 10px 0 0" },
                    attrs: {
                      slot: "reference",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.handleApplyWithdraw },
                    slot: "reference",
                  },
                  [_vm._v("用户提现")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "10px 10px 0 0" },
                    attrs: {
                      slot: "reference",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.handleTransfer },
                    slot: "reference",
                  },
                  [_vm._v("本金互转")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "10px 10px 0 0" },
                    attrs: {
                      slot: "reference",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.handleSendMsg },
                    slot: "reference",
                  },
                  [_vm._v("私信")]
                ),
                _vm._v(" "),
                _vm.memberDetail.withdrawLimit == 0
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title:
                            "确定限制该用户提现, 限制后正在申请的提现会被驳回？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.limitWithdraw(
                              { memberId: _vm.memberDetail.id, status: 1 },
                              "限制成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("限制提现")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.memberDetail.withdrawLimit == 1
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "不用了",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "确定解除用户限制提现？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.limitWithdraw(
                              { memberId: _vm.memberDetail.id, status: 0 },
                              "解除成功"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 10px 0 0" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                            },
                            slot: "reference",
                          },
                          [_vm._v("解除限制提现")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            备注\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c " },
                [
                  _c("el-input", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "mini", type: "textarea", placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.updateMember(
                          {
                            id: _vm.memberDetail.id,
                            remark: _vm.memberDetail.remark,
                          },
                          "修改成功"
                        )
                      },
                    },
                    model: {
                      value: _vm.memberDetail.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberDetail, "remark", $$v)
                      },
                      expression: "memberDetail.remark",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c("div", {
                staticClass:
                  "flex f-s-0 f-a-c f-j-c w-120 padding-10  border-r",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "初始密码为123456",
                        placement: "top-start",
                      },
                    },
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            "confirm-button-text": "确定",
                            "cancel-button-text": "不用了",
                            icon: "el-icon-info",
                            "icon-color": "red",
                            title: "确定将用户重置初始密码, 初始密码为123456？",
                          },
                          on: { confirm: _vm.initPassword },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                size: "small",
                                type: "text",
                              },
                              slot: "reference",
                            },
                            [_vm._v("初始化登录密码")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "充值扣钱",
            visible: _vm.dialogAccountVisible,
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAccountVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "accountForm",
              attrs: {
                model: _vm.accountForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账户资金", prop: "amount" } },
                [
                  _c("code", [
                    _c("span", [
                      _vm._v("账户余额:$" + _vm._s(_vm.memberAccount.amount)),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("账户积分:$" + _vm._s(_vm.memberAccount.point)),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "推广佣金:$" + _vm._s(_vm.memberAccount.spmission)
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "增减余额", prop: "amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写金额" },
                    model: {
                      value: _vm.accountForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "amount", $$v)
                      },
                      expression: "accountForm.amount",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*增加金额请填写正数,减少金额请填写负数"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "增减积分", prop: "point" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写积分" },
                    model: {
                      value: _vm.accountForm.point,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "point", $$v)
                      },
                      expression: "accountForm.point",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*增加积分请填写正数,减少积分请填写负数"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "增减推广佣金", prop: "spmission" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写金额" },
                    model: {
                      value: _vm.accountForm.spmission,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "spmission", $$v)
                      },
                      expression: "accountForm.spmission",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*增加推广佣金请填写正数,减少推广佣金请填写负数"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea", placeholder: "备注" },
                    model: {
                      value: _vm.accountForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "remark", $$v)
                      },
                      expression: "accountForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogAccountVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateMemberAccount },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请提现",
            visible: _vm.dialogApplyWithdrawVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogApplyWithdrawVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "applyWithdrawForm",
              attrs: {
                rules: _vm.applyWithdrawRules,
                model: _vm.applyWithdraw,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "可用余额", prop: "amount" } },
                [
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("$" + _vm._s(_vm.memberAccount.amount)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收款姓名", prop: "accountName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收款姓名" },
                    model: {
                      value: _vm.applyWithdraw.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyWithdraw, "accountName", $$v)
                      },
                      expression: "applyWithdraw.accountName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "提现银行", prop: "bank" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入提现银行" },
                    model: {
                      value: _vm.applyWithdraw.bank,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyWithdraw, "bank", $$v)
                      },
                      expression: "applyWithdraw.bank",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "银行卡号", prop: "bankAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入银行卡号" },
                    model: {
                      value: _vm.applyWithdraw.bankAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyWithdraw, "bankAccount", $$v)
                      },
                      expression: "applyWithdraw.bankAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "提现金额", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入提现金额" },
                    model: {
                      value: _vm.applyWithdraw.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyWithdraw, "amount", $$v)
                      },
                      expression: "applyWithdraw.amount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogApplyWithdrawVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmApplyWithdraw },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "本金互转",
            visible: _vm.dialogTransferVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTransferVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "transferForm",
              attrs: {
                rules: _vm.transferRules,
                model: _vm.transferForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "可用余额", prop: "" } }, [
                _c("span", { staticClass: "tips" }, [
                  _vm._v("$" + _vm._s(_vm.memberAccount.amount)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收款人ID", prop: "uid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收款人ID" },
                    model: {
                      value: _vm.transferForm.uid,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferForm, "uid", $$v)
                      },
                      expression: "transferForm.uid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转账金额", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入转账金额" },
                    model: {
                      value: _vm.transferForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferForm, "amount", $$v)
                      },
                      expression: "transferForm.amount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogTransferVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmTransfer },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "私信",
            visible: _vm.dialogMsgVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMsgVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "msgForm",
              attrs: {
                rules: _vm.msgRules,
                model: _vm.msgForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "收件人", prop: "" } }, [
                _c("span", { staticClass: "tips" }, [
                  _vm._v(_vm._s(_vm.memberDetail.code)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.msgForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.msgForm, "title", $$v)
                      },
                      expression: "msgForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.msgForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.msgForm, "content", $$v)
                      },
                      expression: "msgForm.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogMsgVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSendMsg },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "冻结用户",
            visible: _vm.dialogFreezeVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFreezeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "freezeForm",
              attrs: {
                rules: _vm.freezeRules,
                model: _vm.freezeForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "冻结理由", prop: "freezeReason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.freezeForm.freezeReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.freezeForm, "freezeReason", $$v)
                      },
                      expression: "freezeForm.freezeReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFreezeVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.updateMember(
                        {
                          id: _vm.memberDetail.id,
                          status: "-1",
                          freezeReason: _vm.freezeForm.freezeReason,
                        },
                        "冻结成功"
                      )
                      _vm.dialogFreezeVisible = false
                    },
                  },
                },
                [_vm._v("确认冻结")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("资金账户信息")])]),
      _vm._v(" "),
      _c("div", { staticClass: "contain" }, [
        _c("div", { staticClass: "bg-color-w b-color-4 wrap-info-table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            余额账户\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                [
                  _vm._v(
                    "\n            $ " +
                      _vm._s(_vm._f("moneyFormat")(_vm.memberAccount.amount)) +
                      "\n               \n            "
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/fund/account?memberName=" + _vm.memberDetail.code,
                      },
                    },
                    [
                      _c("span", { staticClass: "link-type" }, [
                        _vm._v("查看流水"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            冻结余额\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            $ " +
                    _vm._s(
                      _vm._f("moneyFormat")(_vm.memberAccount.freezeAmount)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            推广佣金账户\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-1 f-j-c f-a-c border-r" },
                  [
                    _vm._v(
                      "\n              $ " +
                        _vm._s(
                          _vm._f("moneyFormat")(_vm.memberAccount.spmission)
                        ) +
                        "\n              "
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/fund/spmission?memberName=" +
                            _vm.memberDetail.code,
                        },
                      },
                      [
                        _vm._v("\n                   \n                "),
                        _c("span", { staticClass: "link-type" }, [
                          _vm._v("查看流水"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            今日总收入\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            $ " +
                    _vm._s(
                      _vm._f("moneyFormat")(_vm.memberAccount.todayIncome)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex border-t" }, [
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            总收入\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            $ " +
                    _vm._s(
                      _vm._f("moneyFormat")(_vm.memberAccount.totalIncome)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            总支出\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                  _vm._v(
                    "\n              $ " +
                      _vm._s(
                        _vm._f("moneyFormat")(_vm.memberAccount.totalOutCome)
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            总充值金额\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            $ " +
                    _vm._s(
                      _vm._f("moneyFormat")(_vm.memberAccount.totalRecharge)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-1 h-50" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
                },
                [_vm._v("\n            总提现金额\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-1 f-j-c f-a-c border-r" }, [
                _vm._v(
                  "\n            $ " +
                    _vm._s(
                      _vm._f("moneyFormat")(_vm.memberAccount.totalWithdraw)
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("最近提现记录")])]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.withdrawListLoading,
              expression: "withdrawListLoading",
            },
          ],
          key: _vm.withdrawTableKey,
          ref: "withdrawTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.withdrawList,
            border: "",
            fit: "",
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { label: "提现记录", prop: "", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _vm._v("\n        提现记录\n        "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/fund/withdraw?memberName=" +
                              _vm.memberDetail.code,
                          },
                        },
                        [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v("查看更多"),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderNo", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orderNo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户名", align: "center", prop: "memberName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.memberName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "出款类型", align: "center", prop: "method" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              scope.row.method == 1 ? "账户余额" : "佣金余额"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "提现方式", align: "center", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(_vm._s(_vm._f("typeFilter")(scope.row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "银行卡信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "开户银行", align: "center", prop: "bank" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.type == 1
                              ? _c("p", [_vm._v(_vm._s(scope.row.bank))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开户账号",
                      align: "center",
                      prop: "bankAccount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.type == 1
                              ? _c("p", [_vm._v(_vm._s(scope.row.bankAccount))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "户名", align: "center", prop: "realname" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.type == 1
                              ? _c("p", [_vm._v(_vm._s(scope.row.realname))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付宝账号",
                  align: "center",
                  prop: "bankAccount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 2 && scope.row.bankAccount
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.bankAccount) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "二维码",
                  align: "center",
                  prop: "bankAccount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 3 && scope.row.bankAccount
                          ? _c("el-image", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                src: scope.row.bankAccount,
                                "preview-src-list": [scope.row.bankAccount],
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "申请提现金额($)",
                  align: "center",
                  prop: "totalAmount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm._f("moneyFormat")(scope.row.totalAmount))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "提现手续费", align: "center", prop: "fee" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm._f("moneyFormat")(scope.row.fee))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实际到账金额(￥)",
                  align: "center",
                  prop: "amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm._f("moneyFormat")(scope.row.amount))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "申请/审核时间",
                  align: "center",
                  prop: "addTime",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.addTime
                          ? _c("span", [
                              _vm._v(
                                "申请时间:\n            " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.addTime,
                                      "{y}-{m}-{d} {h}:{i}:{s}"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        scope.row.updTime && scope.row.status == "1"
                          ? _c("span", [
                              _vm._v(
                                "审核时间:\n            " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.updTime,
                                      "{y}-{m}-{d} {h}:{i}:{s}"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tag", [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.actions"),
                  "class-name": "small-padding",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "0"
                          ? _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  "confirm-button-text": "确定",
                                  "cancel-button-text": "不用了",
                                  icon: "el-icon-info",
                                  "icon-color": "red",
                                  title: "确定将该笔提现设为已处理？",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.setDoingWithdraw(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("处理中")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "3"
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleVerifyWithdraw(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核提现", visible: _vm.dialogWithdrawVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogWithdrawVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "withdrawForm",
              attrs: {
                rules: _vm.withdrawRules,
                model: _vm.withdraw,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.withdraw.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.withdraw, "status", $$v)
                        },
                        expression: "withdraw.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1", border: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2", border: "" } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", [_vm._v("拒绝原因")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", prop: "reasonCheck" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.withdraw,
                            "remark",
                            _vm.withdraw.reasonCheck
                          )
                        },
                      },
                      model: {
                        value: _vm.withdraw.reasonCheck,
                        callback: function ($$v) {
                          _vm.$set(_vm.withdraw, "reasonCheck", $$v)
                        },
                        expression: "withdraw.reasonCheck",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: {
                          value: "银行卡填写错误",
                          label: "银行卡填写错误",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "10",
                        attrs: { value: "其他", label: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "填写原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入拒绝理由",
                      rows: 6,
                    },
                    model: {
                      value: _vm.withdraw.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.withdraw, "remark", $$v)
                      },
                      expression: "withdraw.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogWithdrawVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmWithdraw },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("绑定银行账户信息")])]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.bankListLoading,
              expression: "bankListLoading",
            },
          ],
          key: _vm.bankTableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.bankList,
            border: "",
            fit: "",
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { label: "银行卡列表", prop: "", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _vm._v("\n        银行卡列表\n        "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/member/bank?memberName=" +
                              _vm.memberDetail.code,
                          },
                        },
                        [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v("查看更多"),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户名", prop: "memberName", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("p", [_vm._v(_vm._s(scope.row.memberName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "真实姓名",
                  prop: "accountName",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("p", [_vm._v(_vm._s(scope.row.accountName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "身份证号码", prop: "idCard", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.idCard))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "开户行", prop: "bank", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.bank) + _vm._s(scope.row.bankSub)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "银行卡号",
                  prop: "bankAccount",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.bankAccount))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "地区", prop: "address", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.province) +
                              _vm._s(scope.row.city) +
                              _vm._s(scope.row.area)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "申请时间", align: "center", prop: "addTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.addTime,
                                "{y}-{m}-{d} {h}:{i}:{s}"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "status", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.status == 1 ? "success" : "danger",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("statusFilter")(scope.row.status))
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拒绝原因", align: "center", prop: "remark" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.actions"),
                  "class-name": "small-padding",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            scope.row.status == 0
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleVerifyBank(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdateBank(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.dialogBankVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogBankVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "bankForm",
              attrs: {
                rules: _vm.bankRules,
                model: _vm.verifyForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.verifyForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "status", $$v)
                        },
                        expression: "verifyForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2, border: "" } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", [_vm._v("拒绝原因")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", prop: "reasonCheck" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.verifyForm,
                            "remark",
                            _vm.verifyForm.reasonCheck
                          )
                        },
                      },
                      model: {
                        value: _vm.verifyForm.reasonCheck,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "reasonCheck", $$v)
                        },
                        expression: "verifyForm.reasonCheck",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: {
                          value: "此卡被没收,请于发卡方联系",
                          label: "此卡被没收,请于发卡方联系",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          value: "持卡人认证失败",
                          label: "持卡人认证失败",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "2",
                        attrs: { value: "无效卡号", label: "无效卡号" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "3",
                        attrs: {
                          value: "此卡无对应发卡方",
                          label: "此卡无对应发卡方",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "4",
                        attrs: {
                          value: "该卡未初始化或睡眠卡",
                          label: "该卡未初始化或睡眠卡",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "5",
                        attrs: { value: "作弊卡,吞卡", label: "作弊卡,吞卡" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "6",
                        attrs: {
                          value: "发卡方不支持的交易",
                          label: "发卡方不支持的交易",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "7",
                        attrs: { value: "此卡已经挂失", label: "此卡已经挂失" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "8",
                        attrs: {
                          value: "持卡人信息有误",
                          label: "持卡人信息有误",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "9",
                        attrs: {
                          value: "银行卡号码有误",
                          label: "银行卡号码有误",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "10",
                        attrs: { value: "其他", label: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "填写原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入拒绝理由",
                      rows: 6,
                    },
                    model: {
                      value: _vm.verifyForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "remark", $$v)
                      },
                      expression: "verifyForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogBankVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmBank } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.dialogEditBankVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditBankVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editBankForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.bankRules,
                model: _vm.editBankForm,
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "accountName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入真实姓名" },
                    model: {
                      value: _vm.editBankForm.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "accountName", $$v)
                      },
                      expression: "editBankForm.accountName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份证", prop: "idCard" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入身份证" },
                    model: {
                      value: _vm.editBankForm.idCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "idCard", $$v)
                      },
                      expression: "editBankForm.idCard",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "pca", label: "开户地址" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "small",
                      options: _vm.addressOptions,
                      clearable: "",
                    },
                    model: {
                      value: _vm.editBankForm.pca,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "pca", $$v)
                      },
                      expression: "editBankForm.pca",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开户银行", prop: "bank" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        placeholder: "银行列表",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.editBankForm.bank,
                        callback: function ($$v) {
                          _vm.$set(_vm.editBankForm, "bank", $$v)
                        },
                        expression: "editBankForm.bank",
                      },
                    },
                    _vm._l(_vm.bankOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.text, value: item.text },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "支行名称", prop: "bankSub" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入支行名称" },
                    model: {
                      value: _vm.editBankForm.bankSub,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "bankSub", $$v)
                      },
                      expression: "editBankForm.bankSub",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "银行卡号", prop: "bankAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入银行卡号" },
                    model: {
                      value: _vm.editBankForm.bankAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "bankAccount", $$v)
                      },
                      expression: "editBankForm.bankAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.editBankForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.editBankForm, "status", $$v)
                        },
                        expression: "editBankForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入拒绝理由" },
                    model: {
                      value: _vm.editBankForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editBankForm, "remark", $$v)
                      },
                      expression: "editBankForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogEditBankVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editSubmitBank },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("el-divider", [_c("h3", [_vm._v("充值记录(展示最近5条记录)")])]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.rechargeListLoading,
              expression: "rechargeListLoading",
            },
          ],
          key: _vm.rechargeTableKey,
          ref: "rechargeTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.rechargeList,
            border: "",
            fit: "",
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { label: "商家充值记录", prop: "", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _vm._v("\n        充值记录\n        "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/fund/recharge?memberName=" +
                              _vm.memberDetail.code,
                          },
                        },
                        [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v("查看更多"),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderNo", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orderNo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户名", align: "center", prop: "realname" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.memberName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "支付方式",
                  align: "center",
                  prop: "paymethod",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("rechargeTypeFilter")(scope.row.paymethod)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "充值金额", align: "center", prop: "amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm._f("moneyFormat")(scope.row.amount))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "卡号后四位",
                  align: "center",
                  prop: "remitAccount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v("卡号后四位:\n            "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.remitAccount)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "打款人",
                  align: "center",
                  prop: "remitRealname",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.remitRealname))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "打款银行",
                  align: "center",
                  prop: "remitBank",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.remitBank))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "充值时间", align: "center", prop: "addTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.addTime
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("parseTime")(
                                    scope.row.addTime,
                                    "{y}-{m}-{d} {h}:{i}:{s}"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "0"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("rechargeStatusFilter")(
                                    scope.row.status
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "1"
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("rechargeStatusFilter")(
                                    scope.row.status
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("rechargeStatusFilter")(
                                    scope.row.status
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "3"
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("rechargeStatusFilter")(
                                    scope.row.status
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-1 h-50" }, [
      _c("div", {
        staticClass:
          "flex f-s-0 f-a-c f-j-c w-120 padding-10 bg-color-4 border-r",
      }),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-1 f-j-c f-a-c" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex f-a-c h-50 f-j-c bg-color-4" }, [
      _c("span", [_vm._v("资金账户")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }