var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            size: "mini",
                            "label-width": "85px",
                            "label-position": "right",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "margin-right": "0px",
                                "margin-bottom": "0px",
                                "font-size": "16px",
                              },
                              attrs: { label: "SKU名称：" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入SKU名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "sku", $$v)
                                  },
                                  expression: "listQuery.sku",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "中文名称：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入中文名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.cnTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "cnTitle", $$v)
                                  },
                                  expression: "listQuery.cnTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.warehouseId",
                                  },
                                },
                                _vm._l(_vm.warehouseOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "供应商：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: true,
                                  },
                                  model: {
                                    value: _vm.listQuery.adminId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "adminId", $$v)
                                    },
                                    expression: "listQuery.adminId",
                                  },
                                },
                                _vm._l(_vm.supplierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v("\n              查询\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$checkPermission(["inventoryManage"]),
                              expression:
                                "$checkPermission(['inventoryManage'])",
                            },
                          ],
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                          },
                          on: { click: _vm.ExportInventory },
                        },
                        [_vm._v("导出库存\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-refresh",
                            loading: _vm.handleLogisticsSync_loading,
                          },
                          on: { click: _vm.handleInventorySync },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.handleLogisticsSync_loading
                                ? "库存同步中"
                                : "库存同步"
                            ) + "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.add_uploadExcel },
                        },
                        [_vm._v("导入模版\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                            loading: _vm.handleDownloadTag_loading,
                          },
                          on: { click: _vm.handleDownloadTag },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.handleDownloadTag_loading
                                ? "下载中"
                                : "下载标签"
                            ) + "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1 } },
                    [
                      _c("el-button", {
                        attrs: {
                          icon: "el-icon-more-outline",
                          title: "同步ws库存",
                          circle: "",
                        },
                        on: { click: _vm.syncWmsInv },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.list_url,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "45", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU", prop: "sku", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_padding" }, [
                      _vm._v(_vm._s(scope.row.sku)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中文名", prop: "cnTitle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table_padding" }, [
                      _vm._v(_vm._s(scope.row.cnTitle)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "供应商/供货商",
              prop: "adminName",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "图片", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticClass: "avatar",
                      staticStyle: { height: "50px", width: "50px" },
                      attrs: { src: scope.row.imageUrl },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "现有库存", prop: "amountSum", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "每箱数量", prop: "qty", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "当前锁定库存",
              prop: "lockedAmountSum",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "当前有效库存",
              prop: "effectiveAmountSum",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            staticClass: "inv_box",
            attrs: { align: "center", width: "500" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v("库存详情")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab_inv" }, [
                      _c("div", [_vm._v("仓库")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("库位")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("库存")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("破损库存")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("箱数")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("操作")]),
                    ]),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.wsSkuInvVoList,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "skuList_calss",
                          style: {
                            borderBottom:
                              index == scope.row.wsSkuInvVoList.length - 1
                                ? ""
                                : "1px solid #dfe6ec",
                          },
                        },
                        [
                          _c("div", { staticClass: "skuList_calss_son" }, [
                            _c("div", { staticClass: "calss_son" }, [
                              _vm._v(_vm._s(item.warehouse.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "calss_son" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.wsWarehouseArea.name) +
                                  "-" +
                                  _vm._s(item.wsWarehouseLocation.name) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "calss_son" }, [
                              _vm._v(_vm._s(item.amount)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "calss_son" }, [
                              _vm._v(_vm._s(item.breakAmount)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "calss_son" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    Math.floor(item.amount / scope.row.qty)
                                  ) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$checkPermission([
                                          "inventoryManage",
                                        ]),
                                        expression:
                                          "$checkPermission(['inventoryManage'])",
                                      },
                                    ],
                                    staticStyle: {
                                      padding: "0",
                                      width: "100%",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.add_getWsWarehouseAreaList(
                                          scope.row,
                                          item.id,
                                          item.wsWarehouseArea.id,
                                          item.wsWarehouseLocation.id,
                                          item.wsSkuId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("移库位")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$checkPermission([
                                          "inventoryManage",
                                        ]),
                                        expression:
                                          "$checkPermission(['inventoryManage'])",
                                      },
                                    ],
                                    staticStyle: { margin: "0", width: "100%" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleBreakAmount(item)
                                      },
                                    },
                                  },
                                  [_vm._v("转破损")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$checkPermission([
                                          "inventoryManage",
                                        ]),
                                        expression:
                                          "$checkPermission(['inventoryManage'])",
                                      },
                                    ],
                                    staticClass: "filter-item",
                                    staticStyle: {
                                      margin: "0",
                                      width: "100%",
                                      "text-align": "center",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit_list(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                调整库存\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$checkPermission([
                                          "inventoryManage",
                                        ]),
                                        expression:
                                          "$checkPermission(['inventoryManage'])",
                                      },
                                    ],
                                    staticClass: "filter-item",
                                    staticStyle: {
                                      margin: "0",
                                      width: "100%",
                                      "text-align": "center",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.add_inventoryLogrouter(
                                          scope.row,
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                出入库记录\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "价值", prop: "declarePrice", align: "center" },
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "库存体积", align: "center" } },
            [[_vm._v("\n        暂无\n      ")]],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
                width: "650px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "100px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "库区-库位", prop: "areaAndLocation" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", clearable: true },
                          model: {
                            value: _vm.ruleForm.areaAndLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "areaAndLocation", $$v)
                            },
                            expression: "ruleForm.areaAndLocation",
                          },
                        },
                        _vm._l(_vm.areaLocationOptions, function (item) {
                          return _c("el-option", {
                            key: item.area + "-" + item.location,
                            attrs: {
                              label: item.area + "-" + item.location,
                              value: item.area + "-" + item.location,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "商品sku", prop: "sku" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请输入sku",
                            clearable: true,
                            "reserve-keyword": "",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.getWsSkuList,
                          },
                          model: {
                            value: _vm.ruleForm.sku,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "sku", $$v)
                            },
                            expression: "ruleForm.sku",
                          },
                        },
                        _vm._l(_vm.skuListOptions, function (item) {
                          return _c("el-option", {
                            key: item.sku,
                            attrs: { label: item.sku, value: item.sku },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存数量", prop: "amount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入库存数量" },
                        model: {
                          value: _vm.ruleForm.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "amount", $$v)
                          },
                          expression: "ruleForm.amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogFormVisibleEdit
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑",
                visible: _vm.dialogFormVisibleEdit,
                width: "650px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisibleEdit = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "100px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存数量", prop: "amount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入库存数量" },
                        model: {
                          value: _vm.ruleForm.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "amount", $$v)
                          },
                          expression: "ruleForm.amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisibleEdit = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmEdit()
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.wswarehouseareaList_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "移库位",
                visible: _vm.wswarehouseareaList_sw,
                width: "450px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.wswarehouseareaList_sw = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.invruleForm,
                    "label-width": "90px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库区/库位", prop: "value" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.wswarehouseareaList,
                          props: {
                            expandTrigger: "hover",
                            value: "id",
                            label: "name",
                            children: "wsWarehouseLocationList",
                          },
                        },
                        model: {
                          value: _vm.invruleForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.invruleForm, "value", $$v)
                          },
                          expression: "invruleForm.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.wswarehouseareaList_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.add_invupdate },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.wswarehouseareaList_break
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "转破损",
                visible: _vm.wswarehouseareaList_break,
                width: "650px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.wswarehouseareaList_break = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.ruleForm,
                    "label-width": "100px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "破损库存", prop: "breakAmount" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入破损库存" },
                        model: {
                          value: _vm.ruleForm.breakAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "breakAmount", $$v)
                          },
                          expression: "ruleForm.breakAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.wswarehouseareaList_break = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmBreak },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadExcel_sw
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$checkPermission(["inventoryManage"]),
                  expression: "$checkPermission(['inventoryManage'])",
                },
              ],
              attrs: {
                title: "导入模版",
                visible: _vm.uploadExcel_sw,
                "close-on-click-modal": false,
                width: "85%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadExcel_sw = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传模板", required: true } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "input",
                              staticClass: "upload-demo",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                action: _vm.excel_action,
                                "show-file-list": false,
                                "auto-upload": true,
                                "on-progress": _vm.excel_progress,
                                "on-change": _vm.excel_importExcel,
                                "on-success": _vm.excel_upload_success,
                                "on-error": _vm.excel_upload_error,
                                type: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                    loading: _vm.excel_loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.excel_loading
                                          ? "导入中"
                                          : "导入Excel模版"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btns",
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFun("wsSkuInv_01.xlsx")
                                    },
                                  },
                                },
                                [_vm._v("下载模版\n            ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: {
                                data: _vm.excel_list,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                              on: { "selection-change": _vm.add_selection },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  selectable: _vm.checkSelectable,
                                  type: "selection",
                                  width: "45",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "sku",
                                  prop: "sku",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "库区-库位",
                                  prop: "areaLocation",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "数量",
                                  prop: "qty",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "校验信息",
                                  prop: "verificationInfo",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "校验结果", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.verification === 1
                                                    ? "通过"
                                                    : scope.row.verification ===
                                                      0
                                                    ? "未通过"
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          scope.row.beforeAmount &&
                                          scope.row.afterAmount
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  原库存：" +
                                                    _vm._s(
                                                      scope.row.beforeAmount
                                                    ) +
                                                    ",变更后库存：" +
                                                    _vm._s(
                                                      scope.row.afterAmount
                                                    ) +
                                                    "\n                "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  717581112
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "上传状态",
                                  prop: "uploadStatus",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "上传原因",
                                  prop: "remark",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", {
                        staticClass: "form_tab",
                        staticStyle: { width: "100%" },
                        attrs: { label: " " },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.uploadExcel_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.btn_loading,
                        disabled: _vm.excel_loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchOrder()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "800px" },
          attrs: { title: "选择仓库", visible: _vm.select_warhouse },
          on: {
            "update:visible": function ($event) {
              _vm.select_warhouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.admin,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "绑定仓库", prop: "warehouseIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.admin.warehouseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.admin, "warehouseId", $$v)
                        },
                        expression: "admin.warehouseId",
                      },
                    },
                    _vm._l(_vm.warehouseOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "保留仓库ID", prop: "warehouseIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.admin.warehouseId_flag,
                        callback: function ($$v) {
                          _vm.$set(_vm.admin, "warehouseId_flag", $$v)
                        },
                        expression: "admin.warehouseId_flag",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "保留", value: "0" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "不保留", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.select_warhouse = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.syncWmsInvConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }