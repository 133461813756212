var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "createPost-container",
      staticStyle: {
        position: "relative",
        left: "-100px",
        width: "1000px",
        margin: "20px auto",
        padding: "30px 100px 50px 70px",
        border: "1px solid #f4f4f4",
      },
    },
    [
      _c("el-alert", {
        staticStyle: { height: "60px" },
        attrs: {
          title: "修改个人信息",
          type: "info",
          "show-icon": "",
          closable: false,
        },
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.admin,
            "label-width": "100px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "min-width": "800px" },
              attrs: { label: "头像", prop: "headImg" },
            },
            [
              _c("Upload", {
                model: {
                  value: _vm.admin.headImg,
                  callback: function ($$v) {
                    _vm.$set(_vm.admin, "headImg", $$v)
                  },
                  expression: "admin.headImg",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "账号", prop: "code" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入账号", disabled: "" },
                model: {
                  value: _vm.admin.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.admin, "code", $$v)
                  },
                  expression: "admin.code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.admin.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.admin, "name", $$v)
                  },
                  expression: "admin.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入密码", type: "password" },
                model: {
                  value: _vm.admin.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.admin, "password", $$v)
                  },
                  expression: "admin.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { disabled: "", placeholder: "请选择" },
                  model: {
                    value: _vm.admin.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.admin, "status", $$v)
                    },
                    expression: "admin.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.text, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色", prop: "typeName" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.admin.typeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.admin, "typeName", $$v)
                  },
                  expression: "admin.typeName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateAdmin } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _vm.admin.typeId == 8 || _vm.admin.typeId == 15
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.getSKey } },
                    [_vm._v("获取secretKey")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }