"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.supplierShopRelationdelete = supplierShopRelationdelete;
exports.supplierShopRelationpage = supplierShopRelationpage;
exports.supplierShopRelationsave = supplierShopRelationsave;
exports.supplieraccount = supplieraccount;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function supplierShopRelationpage(data) {
  return (0, _requestJson.default)({
    url: 'supplierShopRelation/page',
    method: 'get',
    params: data
  });
}
function supplierShopRelationsave(data) {
  return (0, _requestJson.default)({
    url: '/supplierShopRelation/save',
    method: 'post',
    data: data
  });
}
function supplierShopRelationdelete(data) {
  return (0, _requestJson.default)({
    url: '/supplierShopRelation/delete',
    method: 'post',
    data: data
  });
}
function supplieraccount(data) {
  return (0, _requestJson.default)({
    url: '/system/supplier/account/getList',
    method: 'get',
    params: data
  });
}