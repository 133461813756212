var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "90px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "店铺账号：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入店铺账号",
                            "remote-method": _vm.add_getShopList,
                            loading: _vm.getShopList_loading,
                            clearable: "",
                          },
                          on: { change: _vm.add_shopId },
                          model: {
                            value: _vm.listQuery.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shopId", $$v)
                            },
                            expression: "listQuery.shopId",
                          },
                        },
                        _vm._l(_vm.envNameOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-bottom": "0px",
                      },
                      attrs: { label: "订单号：" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入订单号", clearable: "" },
                        model: {
                          value: _vm.listQuery.orderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderId", $$v)
                          },
                          expression: "listQuery.orderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-bottom": "0px",
                      },
                      attrs: { label: "运单号：" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入运单号", clearable: "" },
                        model: {
                          value: _vm.listQuery.shipNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "shipNo", $$v)
                          },
                          expression: "listQuery.shipNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "订单状态：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择订单状态",
                          },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status",
                          },
                        },
                        _vm._l(_vm.status_arr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.get_list },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.get_list,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "sort-change": _vm.add_sortchange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单号", align: "center", prop: "orderId" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺账号", align: "center", prop: "username" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货仓库", align: "center", prop: "warehouse" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "物流服务",
              align: "center",
              prop: "carrierServiceLevel",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "州/省", align: "center", prop: "province" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "邮编", align: "center", prop: "zipCode" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运单号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(scope.row.shipNo)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.status == 1
                            ? "发货成功"
                            : scope.row.status == 8
                            ? "申请取消中"
                            : "订单已取消"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "updTime",
              sortable: "custom",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.updTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "250",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.add_SKU(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          SKU明细\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 8
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.add_apply(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          申请审批\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogType === "edit" ? "编辑发货单" : "新增发货单",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "productForm",
                      attrs: {
                        model: _vm.productForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单号", prop: "orderId" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入订单号" },
                            on: { input: _vm.changeMemberId },
                            model: {
                              value: _vm.productForm.orderId,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "orderId", $$v)
                              },
                              expression: "productForm.orderId",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              订单号长度最少8个字符，由数字组成，不允许带字母或特殊字符\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "数量", prop: "num" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                              placeholder: "请输入数量",
                            },
                            model: {
                              value: _vm.productForm.num,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "num", _vm._n($$v))
                              },
                              expression: "productForm.num",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收件人", prop: "receiveUsername" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入收件人" },
                            model: {
                              value: _vm.productForm.receiveUsername,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "receiveUsername",
                                  $$v
                                )
                              },
                              expression: "productForm.receiveUsername",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "收件人电话", prop: "receivePhone" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "custom-input",
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入收件人电话" },
                            model: {
                              value: _vm.productForm.receivePhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "receivePhone", $$v)
                              },
                              expression: "productForm.receivePhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址1", prop: "address1" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入地址1" },
                            model: {
                              value: _vm.productForm.address1,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "address1", $$v)
                              },
                              expression: "productForm.address1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "门牌号", prop: "address2" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入门牌号" },
                            model: {
                              value: _vm.productForm.address2,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "address2", $$v)
                              },
                              expression: "productForm.address2",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              如果没有门牌号就输入一个空格\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市", prop: "city" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入城市" },
                            model: {
                              value: _vm.productForm.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "city", $$v)
                              },
                              expression: "productForm.city",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "州/省", prop: "province" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入州/省" },
                            model: {
                              value: _vm.productForm.province,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "province", $$v)
                              },
                              expression: "productForm.province",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮编", prop: "zipCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { placeholder: "请输入邮编" },
                            model: {
                              value: _vm.productForm.zipCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "zipCode", $$v)
                              },
                              expression: "productForm.zipCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "SKU", label: "SKU" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "500px",
                                  "margin-bottom": "10px",
                                },
                                attrs: {
                                  placeholder: "请搜索商品选择SKU",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.productForm.skuId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.productForm, "skuId", $$v)
                                  },
                                  expression: "productForm.skuId",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                请搜索商品添加SKU\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-question icon_question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#606266",
                                      "line-height": "17px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-bottom": "10px",
                            },
                            attrs: { placeholder: "搜索品类名称" },
                            model: {
                              value: _vm.commodity_typeName,
                              callback: function ($$v) {
                                _vm.commodity_typeName = $$v
                              },
                              expression: "commodity_typeName",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-bottom": "10px",
                            },
                            attrs: { placeholder: "搜索产品标题" },
                            model: {
                              value: _vm.commodity_name,
                              callback: function ($$v) {
                                _vm.commodity_name = $$v
                              },
                              expression: "commodity_name",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.commodity_url },
                            },
                            [_vm._v("搜索商品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "20px",
                                  },
                                  attrs: {
                                    data: _vm.commodity_list,
                                    "row-key": "id",
                                    border: "",
                                    "default-expand-all": "",
                                    "max-height": "450",
                                    "tree-props": {
                                      children: "children",
                                      hasChildren: "hasChildren",
                                    },
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      prop: "id",
                                      align: "center",
                                      width: "50",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(_vm._s(scope.row.id)),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      773642443
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: "产品标题",
                                      align: "center",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "typeName",
                                      label: "品类名称",
                                      align: "center",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "right",
                                      label: "操作",
                                      width: "100",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              !scope.row.button_sw
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "medium",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.add_skuId(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    添加skuId\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3796720219
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm("productForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btn_loading ? "创建中" : "确定"))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "申请审核",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "560px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请审核", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "status", $$v)
                            },
                            expression: "ruleForm.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 9 } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 7 } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      key: "3",
                      attrs: {
                        label: "原因",
                        prop: "auditReason",
                        rules:
                          _vm.ruleForm.status == 7
                            ? [
                                {
                                  required: true,
                                  message: "请输入原因",
                                  trigger: "blur",
                                },
                              ]
                            : [],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          size: "small",
                          maxlength: "100",
                          "show-word-limit": "",
                          type: "textarea",
                          rows: 8,
                        },
                        model: {
                          value: _vm.ruleForm.auditReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "auditReason", $$v)
                          },
                          expression: "ruleForm.auditReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.detailsskuList,
                border: "",
                fit: "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "SKU名称", align: "center", prop: "skuName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发货SKU数量", align: "center", prop: "num" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }