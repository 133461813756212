import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime } from "@/utils";
import { adminAuditPage, getById, getShopCateList as _getShopCateList, getGoodsCateList, pushShopProduct, audit, getConditionallyConfigListByCateId, infringementCheck } from "@/api/product/product";
import Upload from "@/components/Upload/singleImageUs.vue";
import MultipleImageUpload from "@/components/Upload/multipleImageUs.vue";
import UploadPdf from "@/components/UploadPdf/index";
import Pagination from "@/components/Pagination";
import WangEnduit from "@/components/WangEditorUs/index";
import Cropper from "@/components/Cropper";
import SortableUpload from "@/components/Upload/sortableupload.vue";
import UploadSKU from "@/components/Upload/singleImageUsSKU.vue";
import dragUpload from "@/components/Upload/dragUpload.vue";
import seeSkulist from "@/components/Skulist/seeskulist.vue";
export default {
  name: "ProductManagement",
  components: {
    Upload: Upload,
    Pagination: Pagination,
    UploadPdf: UploadPdf,
    WangEnduit: WangEnduit,
    MultipleImageUpload: MultipleImageUpload,
    Cropper: Cropper,
    SortableUpload: SortableUpload,
    UploadSKU: UploadSKU,
    dragUpload: dragUpload,
    seeSkulist: seeSkulist
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      conditionalConfigList: [],
      auditDialogVisible: false,
      // 控制审核弹框的显示
      auditFormData: {
        id: 0,
        auditStatus: 2,
        // 默认通过，您可以根据需求更改默认值
        auditReason: ""
      },
      searchType: "1",
      // 默认查询类型为可推送产品
      isClear: false,
      props: {
        label: "cnName",
        value: "id",
        children: "children"
      },
      searchTitle: "",
      searchTypeName: "",
      searchUserName: "",
      searchAuditStatus: 1,
      productList: [],
      goodsCateOptions: [],
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 50
      },
      dialogFormVisible: false,
      dialogType: "",
      productForm: {
        title: "",
        typeName: "",
        status: false,
        skuList: [],
        // SKU列表
        brand: "No brand"
      },
      rules: {
        title: [{
          required: true,
          message: "请输入商品标题",
          trigger: "blur"
        }],
        typeName: [{
          required: true,
          message: "请输入品类名称",
          trigger: "blur"
        }]
      },
      shopCateOptions: [],
      groupList: [],
      pushDialogVisible: false,
      // 控制推送弹框显示
      shopList: [],
      // 店铺列表
      pushForm: {
        shopAccountId: "" // 选择的店铺ID
      },

      show_cropper: false,
      img_url: "",
      imgUrl_index: "",
      SortableUpload_sw: false,
      picture_arr: [],
      expressPrice: 0,
      Skulist_sw: false
    };
  },
  created: function created() {
    this.searchAuditStatus = "1";
    this.getList();
    this.init();
  },
  watch: {
    searchType: function searchType(newType, oldType) {
      if (newType !== oldType) {
        this.handleSearch(); // 调用查询方法
      }
    }
  },

  methods: {
    add_infringementCheck: function add_infringementCheck(row) {
      var _this = this;
      console.log("row", row);
      // return;
      infringementCheck(row.id).then(function (res) {
        _this.$message({
          message: "检测发起成功！",
          type: "success"
        });
        console.log("检测成功吗", res);
        _this.getList();
      }).catch(function (error) {
        _this.getList();
        // 处理审核失败的逻辑
      });
    },
    // 初始化裁切框
    InitCropper: function InitCropper(img) {
      var _this2 = this;
      this.img_url = img;
      console.log("this.img_url11111", this.$refs.cropper1);
      if (this.$refs.cropper1.myCropper) {
        this.$refs.cropper1.myCropper.replace(this.img_url);
      } else {
        this.$nextTick(function () {
          _this2.$refs.cropper1.init();
        });
      }
    },
    // 网络图片地址转本地图片
    getBase64ByURL: function getBase64ByURL(imgUrl) {
      return new Promise(function (resolve) {
        window.URL = window.URL || window.webkitURL;
        var xhr = new XMLHttpRequest();
        xhr.open("get", imgUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (this.status == 200) {
            var blob = this.response;
            var oFileReader = new FileReader();
            oFileReader.onloadend = function (e) {
              resolve({
                blob: blob,
                base64: e.target.result
              });
            };
            oFileReader.readAsDataURL(blob);
          }
        };
        xhr.send();
      });
    },
    // 显示裁切框
    showImgCropper: function showImgCropper(val, index) {
      var _this3 = this;
      console.log("val", val, index);
      this.imgUrl_index = index;
      if (val) {
        this.getBase64ByURL(val).then(function (res) {
          var blob = res.blob,
            base64 = res.base64;
          _this3.img_url = URL.createObjectURL(blob);
          _this3.show_cropper = true;
          console.log("111111", _this3.$refs.cropper1);
          if (_this3.$refs.cropper1.myCropper) {
            _this3.$refs.cropper1.myCropper.replace(_this3.img_url);
          } else {
            _this3.$nextTick(function () {
              _this3.$refs.cropper1.init();
            });
          }
        });
      } else {
        console.log("22222");
        // this.img_url = "";
        this.show_cropper = true;
      }
    },
    // 关闭裁切图片弹窗
    CloseCropper: function CloseCropper() {
      // this.img_url = "";
      this.show_cropper = false;
    },
    // 裁切上传完接口返回的信息
    SaveCallBack: function SaveCallBack(res) {
      var _this4 = this;
      console.log("信息", res, this.imgUrl_index, this.productForm.skuList);
      if (res.code == 200) {
        this.productForm.skuList.forEach(function (val, index) {
          if (index == _this4.imgUrl_index) {
            val.imgUrl = res.data.imgUrl;
          }
        });
        this.$message({
          message: "图片上传成功！",
          type: "success"
        });
        this.CloseCropper();
        return;
      } else {
        this.$message.error(res.message);
      }
    },
    // 接受图片改变的值
    emit_picture: function emit_picture(val) {
      this.productForm.imgUrls = val;
      console.log("图片变了吗", val);
    },
    handleCascaderChange: function handleCascaderChange(selectedCateIds) {
      var _this5 = this;
      this.conditionalConfigList = [];
      if (selectedCateIds.length > 0) {
        var lastElement = selectedCateIds[selectedCateIds.length - 1];
        getConditionallyConfigListByCateId(lastElement).then(function (resp) {
          var data = resp.data;
          data.forEach(function (item) {
            var conditionalConfig = {
              fieldName: item.fieldDisplayName,
              fieldType: item.fieldType,
              options: item.dropdownOptions,
              productConditionallyConfigId: item.id
            };
            _this5.conditionalConfigList.push(conditionalConfig);
          });
        });
      }
    },
    submitAudit: function submitAudit() {
      var _this6 = this;
      // 调用审核接口
      var auditData = {
        id: this.auditFormData.id,
        auditStatus: this.auditFormData.auditStatus,
        auditReason: this.auditFormData.auditReason
      };
      if (this.auditFormData.auditStatus == 9) {
        auditData.auditStatus = 2;
      }

      // 调用您的审核接口，这里是一个示例，请根据您的实际接口进行调整
      audit(auditData).then(function (response) {
        // 处理审核成功的逻辑
        _this6.$message.success("审核成功");
        _this6.auditDialogVisible = false;
        // 刷新产品列表或执行其他操作
        _this6.getList(); // 重新加载产品列表的方法，请根据您的实际情况调整
      }).catch(function (error) {
        // 处理审核失败的逻辑
        console.error(error);
        _this6.$message.error("审核失败");
      });
    },
    showAuditDialog: function showAuditDialog(row) {
      // 保存当前行的数据到 data 中，以便在弹框中使用
      this.auditFormData = {
        id: row.id,
        auditStatus: 2,
        // 默认设置为通过，您可以根据需求更改默认值
        auditReason: ""
      };

      // 打开审核弹框
      this.auditDialogVisible = true;
    },
    changeEditor: function changeEditor(val) {
      this.productForm.intro = val;
    },
    init: function init() {
      var _this7 = this;
      this.getShopCateList();
      getGoodsCateList().then(function (response) {
        _this7.goodsCateOptions = response.data;
      }).catch(function (err) {
        console.log(err);
      });
      this.groupList.push({
        num: null,
        discounts: null
      });
    },
    confirmPush: function confirmPush() {
      var _this8 = this;
      // 获取选中的产品ID数组
      var selectedProductIds = this.$refs.orderTable.selection.map(function (product) {
        return product.id;
      });

      // 调用推送商品的接口
      var pushParams = {
        productIds: selectedProductIds,
        shopAccountId: this.pushForm.shopAccountId
      };
      pushShopProduct(pushParams).then(function () {
        _this8.$message.success("推送成功");
        _this8.pushDialogVisible = false;
        // 可以根据情况刷新列表或执行其他操作
      }).catch(function (error) {
        console.error(error);
      });
    },
    handlePushDialogClose: function handlePushDialogClose() {
      this.pushForm.shopAccountId = ""; // 关闭弹框时清空选择的店铺ID
    },
    handleSearch: function handleSearch() {
      this.listQuery.type = this.searchType;
      this.listQuery.title = this.searchTitle; // Set the search title in the list query
      this.listQuery.typeName = this.searchTypeName; // Set the search title in the list query
      this.listQuery.page = 1; // Reset page to 1 to start from the first page
      this.getList();
    },
    getList: function getList() {
      var _this9 = this;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        title: this.searchTitle,
        typeName: this.searchTypeName,
        type: this.searchType,
        userName: this.searchUserName,
        auditStatus: this.searchAuditStatus
      };
      adminAuditPage(params).then(function (response) {
        _this9.productList = response.data.list;
        _this9.total = response.data.total;
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this10 = this;
      this.dialogType = "edit";
      this.dialogFormVisible = true;
      this.conditionalConfigList = [];
      this.SortableUpload_sw = false;
      this.Skulist_sw = false;
      getById(row.id).then(function (response) {
        var product = response.data;
        if (product.cateId) {
          product.shopCateId = product.cateId.split(",").map(function (i) {
            return parseInt(i, 0);
          });
        }
        // 这里把imgUrls转一下
        var urlList = JSON.parse(product.imgUrl);
        product.imgUrls = urlList;
        _this10.productForm = product;
        _this10.SortableUpload_sw = true;
        _this10.Skulist_sw = true;
        _this10.productForm = product;
        console.log("数据11111212121212", _this10.productForm.imgUrls);
        if (_this10.productForm.conditionalConfigList) {
          _this10.productForm.conditionalConfigList.forEach(function (item) {
            var conditionalConfig = {
              fieldName: item.fieldName,
              fieldType: item.fieldType,
              options: item.options,
              productConditionallyConfigId: item.productConditionallyConfigId,
              value: item.value
            };
            _this10.conditionalConfigList.push(conditionalConfig);
          });
        } else {
          _this10.handleCascaderChange(_this10.productForm.shopCateId);
          console.log("没有", _this10.productForm);
        }
      }).catch(function (error) {
        console.error(error);
      });
      this.$nextTick(function () {
        _this10.$refs["productForm"].clearValidate();
      });
    },
    getShopCateList: function getShopCateList() {
      var _this11 = this;
      _getShopCateList().then(function (response) {
        _this11.shopCateOptions = response.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleConfirm: function handleConfirm() {
      this.dialogFormVisible = false;
    },
    handleCancel: function handleCancel() {
      this.dialogFormVisible = false;
    },
    handleAddSku: function handleAddSku() {
      this.productForm.skuList.push({
        title: "",
        inv: 0,
        price: 0.0
        // Add other SKU fields based on actual requirements
      });
    },
    handleEditSku: function handleEditSku(sku) {
      // Handle editing of a specific SKU item
    }
  },
  filters: {
    parseTime: parseTime
  }
};