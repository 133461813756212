"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _default = {
  name: "lemonMessageNotice",
  inheritAttrs: false,
  inject: ["IMUI"],
  render: function render() {
    var h = arguments[0];
    return h("lemon-message-basic", (0, _babelHelperVueJsxMergeProps.default)([{
      "class": "lemon-message-notice"
    }, {
      "props": (0, _objectSpread2.default)({}, this.$attrs)
    }, {
      "scopedSlots": {
        content: function content(props) {
          var data = JSON.parse(props.content);
          var msg = JSON.parse(data.content);
          return h("div", {
            "class": "f-c f-a-s  bg-color-w"
          }, [h("div", {
            "class": "flex f-a-c  w100 f11-size t-color-3 margin-t6 f-w-b"
          }, [data.title]), h("div", {
            "class": "flex margin-t6"
          }, [h("img", {
            "class": "flex f-s-0 w-70 h-70 bg-img margin-r10",
            "attrs": {
              "src": msg.goodsMainImg
            }
          }), h("div", {
            "class": "flex flex-1 f-c f-j-s h100"
          }, [h("div", {
            "class": "w-200"
          }, [h("div", {
            "class": "line2 f12-size t-color-6"
          }, [msg.goodsName])]), h("div", {
            "class": "flex f-a-c  w100 f12-size t-color-9 margin-t2"
          }, [data.type == 5 ? '退款单号:' : '', " ", msg.refundNo]), h("div", {
            "class": "flex f-a-c w100 f12-size t-color-10 margin-t2"
          }, ["\u53BB\u5904\u7406>>"])])])]);
        }
      }
    }]));
  }
};
exports.default = _default;