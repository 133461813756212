var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            data: _vm.dataObj,
            multiple: false,
            "show-file-list": false,
            "on-progress": _vm.handleUploading,
            "on-success": _vm.handleImageSuccess,
            drag: "",
            accept: ".mp4,.avi,.rm,.rmvb,.mov,.mtv,.dat,.flv,.ogv,.mp3",
            action: _vm.uploadUrl,
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _vm._v(" "),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将视频拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", [_c("p", [_vm._v("正在上传，请稍等...")])])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "image-preview" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.imageUrl &&
                  _vm.imageUrl != "NVL" &&
                  _vm.imageUrl.length > 1,
                expression:
                  "imageUrl && imageUrl != 'NVL' && imageUrl.length > 1",
              },
            ],
            staticClass: "image-preview-wrapper",
          },
          [
            _c("video", {
              attrs: {
                id: "video",
                height: "200",
                controls: "controls",
                src: _vm.imageUrl,
              },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }