"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var warehouseRouter = {
  path: '/supplier',
  component: _layout.default,
  redirect: '/supplier',
  name: 'Supplier',
  alwaysShow: true,
  meta: {
    title: '供应商管理',
    icon: 'report-brand',
    roles: ['supplier']
  },
  children: [{
    path: 'managelist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/supplier/managelist'));
      });
    },
    name: 'Managelist',
    meta: {
      title: '供应商关联列表',
      role: ['supplier:managelist']
    }
  }]
};
var _default = warehouseRouter;
exports.default = _default;