import request from '@/utils/requestJson';
export function getShopCateList(data) {
  return request({
    url: 'member/shopCate/getList',
    method: 'get',
    params: data
  });
}
export function updateIsSalesPromotion(data) {
  return request({
    url: '/product/normal/updateIsSalesPromotion',
    method: 'post',
    data: data
  });
}
export function getGoodsCateList(data) {
  return request({
    url: 'member/shopCate/getList',
    method: 'get',
    params: data
  });
}
export function addShopCate(data) {
  return request({
    url: 'member/shopCate/add',
    method: 'post',
    data: data
  });
}
export function updateShopCate(data) {
  return request({
    url: 'member/shopCate/editSubmit',
    method: 'post',
    data: data
  });
}
export function deleteShopCate(data) {
  return request({
    url: 'member/shopCate/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
export function adminSave(data) {
  return request({
    url: 'product/admin/save',
    method: 'post',
    data: data
  });
}
export function adminUpdateById(data) {
  return request({
    url: 'product/admin/updateById',
    method: 'post',
    data: data
  });
}
export function adminPage(data) {
  return request({
    url: '/product/admin/page',
    method: 'get',
    params: data
  });
}
export function adminAuditPage(data) {
  return request({
    url: '/product/admin/audit/page',
    method: 'get',
    params: data
  });
}
export function normalSave(data) {
  return request({
    url: 'product/normal/save',
    method: 'post',
    data: data
  });
}
export function normalUpdateById(data) {
  return request({
    url: 'product/normal/updateById',
    method: 'post',
    data: data
  });
}
export function normalPage(data) {
  return request({
    url: '/product/normal/page',
    method: 'get',
    params: data
  });
}
export function getProductStatusNum() {
  return request({
    url: '/product/getProductStatusNum',
    method: 'get'
  });
}
export function deleteById(id) {
  return request({
    url: "/product/deleteById/".concat(id),
    method: 'post'
  });
}
export function getById(id) {
  return request({
    url: 'product/getById?id=' + id,
    method: 'get'
  });
}
export function getConditionallyConfigListByCateId(id) {
  return request({
    url: 'product/getConditionallyConfigListByCateId?cateId=' + id,
    method: 'get'
  });
}
export function getShopList(keyword) {
  return request({
    url: "/system/getShopList?pageSize=100000&keyword=" + keyword,
    method: 'get'
  });
}
export function pushShopProduct(data) {
  return request({
    url: "/product/pushShopProduct",
    method: 'post',
    data: data
  });
}
export function skuSave(data) {
  return request({
    url: "/product/sku/save",
    method: 'post',
    data: data
  });
}
export function skuUpdateById(data) {
  return request({
    url: "/product/sku/updateById",
    method: 'post',
    data: data
  });
}
export function skuDeleteById(id) {
  return request({
    url: "/product/sku/deleteById/".concat(id),
    method: 'post'
  });
}
export function productUpOn(id) {
  return request({
    url: "/product/productUpOn?ids=" + id,
    method: 'post'
  });
}
export function applyAudit(productId) {
  return request({
    url: "/product/applyAudit?productId=" + productId,
    method: 'post'
  });
}
export function audit(data) {
  return request({
    url: "/product/audit",
    method: 'post',
    data: data
  });
}
export function updateProductOwner(data) {
  return request({
    url: "/product/updateProductOwner",
    method: 'post',
    data: data
  });
}
export function getSupplyListapi() {
  return request({
    url: "/small/getSupplyList",
    method: 'get'
  });
}
export function forcedRemoval(data) {
  return request({
    url: "/product/admin/forcedRemoval",
    method: 'post',
    data: data
  });
}
export function infringementCheck(productId) {
  return request({
    url: '/product/infringementCheck?productId=' + productId,
    method: 'post'
  });
}
export function pageProductSku(data) {
  return request({
    url: 'supplier/pageProductSku',
    method: 'get',
    params: data
  });
}
export function invLockPage(data) {
  return request({
    url: 'product/invLockPage',
    method: 'post',
    data: data
  });
}
export function invLockExaminePage(data) {
  return request({
    url: 'product/invLockExaminePage',
    method: 'post',
    data: data
  });
}
export function mappingPage(data) {
  return request({
    url: 'product/mappingSkuPage',
    method: 'post',
    data: data
  });
}
export function delMappingSkuPage(id) {
  return request({
    url: "/product/delMappingSkuPage?id=" + id,
    method: 'get'
  });
}
export function addOrUpdateMappingSkuPage(data) {
  return request({
    url: 'product/addOrUpdateMappingSkuPage',
    method: 'post',
    data: data
  });
}
export function unLockInv(data) {
  return request({
    url: 'product/unLockInv',
    method: 'post',
    params: data
  });
}
export function invLockExamine(data) {
  return request({
    url: 'product/invLockExamine',
    method: 'post',
    data: data
  });
}
export function getBindWarehouse() {
  return request({
    url: 'supplier/getBindWarehouse',
    method: 'get'
  });
}
export function getBindApi() {
  return request({
    url: 'supplier/getBindApi',
    method: 'get'
  });
}
export function pageProductAdd(data) {
  return request({
    url: '/supplier/pageProductAdd',
    method: 'post',
    data: data
  });
}
export function pageProductUpdate(data) {
  return request({
    url: '/supplier/pageProductUpdate',
    method: 'post',
    data: data
  });
}
export function wmsSyncSkuInv() {
  return request({
    url: 'supplier/wmsSyncSkuInv',
    method: 'get'
  });
}
// 添加wssku
export function wsSkuSave(data) {
  return request({
    url: 'ws/sku/save',
    method: 'post',
    data: data
  });
}
//编辑wssku
export function updateById(data) {
  return request({
    url: 'ws/sku/updateById',
    method: 'post',
    data: data
  });
}
//查询wssku
export function pageWsSku(data) {
  return request({
    url: 'ws/sku/getPage',
    method: 'get',
    params: data
  });
}
export function getPageByNameAndWarehouseId(data) {
  return request({
    url: 'ws/sku/getPageByNameAndWarehouseId',
    method: 'get',
    params: data
  });
}
export function getWsSkuCountBySku(data) {
  return request({
    url: 'ws/sku/getWsSkuCountBySku',
    method: 'get',
    params: data
  });
}
export function createBatch(data) {
  return request({
    url: 'ws/createShipmentBatch',
    method: 'post',
    params: {
      ids: data
    }
  });
}
export function cancelOrder(data) {
  return request({
    url: 'ws/cancelOrder',
    method: 'post',
    params: {
      orderIds: data
    }
  });
}
export function applyRefund(data) {
  return request({
    url: 'ws/applyRefund',
    method: 'post',
    params: {
      orderIds: data
    }
  });
}
export function refundReview(data) {
  return request({
    url: 'ws/refundReview',
    method: 'post',
    params: data
  });
}
export function handleBatchstatus(data) {
  return request({
    url: "/ws/order/handleBatchStatus",
    method: 'post',
    data: data
  });
}
export function refundWsSupplier(data) {
  return request({
    url: '/ws/supplier/refund',
    method: 'put',
    data: data
  });
}
export function inventorySync(data) {
  return request({
    url: '/ws/inv/inventorySync',
    method: 'get',
    params: data
  });
}
export function createOrderList(data) {
  return request({
    url: '/ws/createOrderList',
    method: 'post',
    data: data
  });
}
export function uploadExcelBatchSave(data) {
  return request({
    url: '/ws/sku/uploadExcelBatchSave',
    method: 'post',
    data: data
  });
}
export function syncWsSku(data) {
  return request({
    url: '/ws/sku/syncWsSku?type=' + data,
    method: 'get'
  });
}
export function delWsSku(data) {
  return request({
    url: '/ws/sku/delete/' + data,
    method: 'delete'
  });
}
export function getProductSku(title) {
  return request({
    url: '/product/normal/getProductSku?title=' + title,
    method: 'post'
  });
}