import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPageByNameAndWarehouseId } from "@/api/product/product";
import { parseTime, exportMethod, renderTime } from "@/utils";
import Pagination from "@/components/Pagination";
import { Message } from "element-ui";
import store from "@/store";
import { getOrderPage } from "@/api/ship/ship";
import axios from 'axios';
import { getPackingWarehouseList, getPackingSkuList, getPackingNewGetPage, packingNeworderwarehousing, packingNewUpdateOrder, packingNewCreateOrder, getSupplier, skuWarehousingNew, getWsAreaLocation, delNew } from "@/api/warehouse/warehouse";
import { getShopList as _getShopList } from "@/api/product/product";
import { getToken } from '@/utils/auth';
import SkuSelect from "@/components/Skulist/SkuSelect.vue";
export default {
  name: "ShipList",
  components: {
    Pagination: Pagination,
    SkuSelect: SkuSelect
  },
  data: function data() {
    return {
      title_dialogType: "创建订单",
      smallsave_sw: false,
      areaLocationOptions: [],
      productForm: {
        packingId: '',
        packageList: []
      },
      showStatusDialog: false,
      // 控制修改推送状态对话框的显示
      orderStatus: "",
      // 用于绑定下拉框选中值
      selectedOrderId: "",
      // 用于存储当前选中的产品ID
      syncLogDialogVisible: false,
      syncLogList: [],
      logTotal: 0,
      supplierOptions: [],
      logQuery: {
        page: 1,
        pageSize: 10
      },
      cancelDialogVisible: false,
      selectedOrderToCancel: null,
      detailDialogVisible: false,
      currentDetail: {},
      warehoussingDialogVisible: false,
      warehoussingDetail: {},
      warehoussingdetailLoading: false,
      detailLoading: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        warehouseId: "",
        status: "",
        startTime: "",
        endTime: "",
        phone: "",
        title: "",
        orderNo: "",
        shopId: ""
      },
      form: {},
      dialogFormVisible: false,
      dialogType: "",
      typeOptions: [],
      isClear: false,
      timePickerDialogVisible: false,
      // 控制时间选择器弹框的显示与隐藏
      timeRangeForm: {
        startTime: "",
        // 选择的开始时间
        endTime: "" // 选择的结束时间
      },

      manualShipDialogVisible: false,
      manualShipForm: {
        shipNo: "",
        warehouseId: "",
        carrier: "",
        carrierServiceName: ""
      },
      warehouseOptions: [],
      statusOptions: [{
        status: 0,
        name: '已发'
      }, {
        status: 1,
        name: '已入库'
      }, {
        status: 2,
        name: '部分入库'
      }],
      skuListOptions: [],
      envNameOptions: [],
      editAddressDialogVisible: false,
      // 编辑地址对话框的显示与隐藏
      editAddressForm: {
        recipient: "",
        phone: "",
        city: "",
        state: "",
        detailAddress: "",
        zipcode: "",
        additionalAddressInformation: ""
      },
      selectedAddressOrder: null,
      // 存储选中的订单对象
      selectedOrder: null,
      // 用于存储选中的订单对象
      updateStatusForm: {
        orderId: "",
        status: ""
      },
      addmoneydialogVisible: false,
      selectedOrders: null,
      showSyncShipNoDialog: false,
      headers: {},
      token: "",
      action: "https://www.qixunetwork.com/cjdniwo/small/uploadCsv",
      uploadPackingExcel: process.env.VUE_APP_BASE_API + 'ws/packing/order/uploadPackingExcel',
      zcUploadPackingExcel: process.env.VUE_APP_BASE_API + 'ws/packing/orderNew/uploadExcel',
      loading_01: false,
      handleSyncShipNo_loading: false,
      getShopList_loading: false,
      small_rules: {
        packingId: [{
          required: true,
          message: "请输入订单号",
          trigger: "blur"
        },
        // { min: 6, message: "密码不能少于6位", trigger: "blur" },
        // {
        //   min: 8,
        //   message: "订单号长度最少8个字符",
        //   trigger: "blur"
        // },
        {
          type: "number",
          message: "订单号必须为数字",
          trigger: "blur",
          transform: function transform(value) {
            if (value !== null && value !== "") {
              if (String(value).trim() === "" || Number(value) < 100000 || Number(value) > 999999999999999999999999999999) {
                return false;
              } else {
                return Number(value);
              }
            } else {
              return null;
            }
          }
        }],
        num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        weight: [{
          required: true,
          message: "请输入重量",
          trigger: "blur"
        }],
        warehouseId: [{
          required: true,
          message: "请选择仓库",
          trigger: "change"
        }]
      },
      sku_title: "",
      btn_loading: false,
      shipSkuList: [],
      id_quantity: [],
      location_sw: false,
      zc_location_sw: false,
      zc_warehouseId: '',
      location: "",
      warehoussing_location_sw: false,
      warehoussing_location: "",
      list_id: "",
      multipleSelection: [],
      multipleWarehoussingSelection: [],
      packageListSelectIdex: -1,
      selectSkuList: []
    };
  },
  created: function created() {
    if (this.$route.query.status) {
      this.listQuery.status = this.$route.query.status;
      // console.log("值", this.listQuery.status);
    }

    if (this.$route.query.highlightBtn) {
      if (this.$route.query.highlightBtn == 1) {
        this.$notify.info({
          title: '入库提示',
          message: '请点击【部分入库】或者【整箱入库】',
          duration: 10000
        });
      }
    }
    this.getList();
    this.loadWarehouseOptions();
    this.loadSkuOptions();
    this.getSupplierdata();
    // this.getShopList();
    this.token = store.getters.token;
    this.headers = {
      Authorization: 'Bearer ' + getToken()
    };
    // console.log("store", store.getters.token);
    this.getWsAreaLocationData();
  },
  methods: {
    getWsAreaLocationData: function getWsAreaLocationData() {
      var _this = this;
      getWsAreaLocation().then(function (res) {
        if (res.code === 200) {
          _this.areaLocationOptions = res.data;
        } else {
          Message.error(res.message);
        }
      });
    },
    add_edit: function add_edit(val) {
      this.productForm = JSON.parse(JSON.stringify(val)); //深度拷贝，避免原对象更改
      this.shipSkuList = [];
      this.title_dialogType = "编辑订单";
      this.list_id = val.id;
      this.smallsave_sw = true;
    },
    add_location: function add_location(val) {
      this.list_id = val.id;
      this.location = "";
      this.location_sw = true;
    },
    labelDown: function labelDown(orderId) {
      var myObj = {
        method: 'post',
        url: 'ws/packing/orderNew/handExport?id=' + orderId,
        fileName: 'packingExport'
      };
      this.exportPdfMethod(myObj);
    },
    delPackingOrder: function delPackingOrder(id) {
      var _this2 = this;
      delNew(id).then(function (response) {
        if (response.code === 200) {
          _this2.getList();
          _this2.$message({
            message: "删除成功",
            type: "success"
          });
        } else {
          _this2.$message.error(response.data.reason);
        }
      });
    },
    exportPdfMethod: function exportPdfMethod(data) {
      var _this3 = this;
      axios({
        method: data.method,
        url: process.env.VUE_APP_BASE_API + "".concat(data.url).concat(data.params ? '?' + data.params : ''),
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      }).then(function (res) {
        var link = document.createElement('a');
        var blob = new Blob([res.data], {
          type: 'application/pdf;chartset=UTF-8'
        });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; // 下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this3.btn_loading = false;
        _this3.$message({
          message: '导出完成',
          type: 'success'
        });
        _this3.getList();
      }).catch(function (error) {
        _this3.$message({
          message: error,
          type: 'error'
        });
        _this3.btn_loading = false;
      });
    },
    add_location_sw: function add_location_sw() {
      var _this4 = this;
      if (this.location == "") {
        this.$message.error("请输入库位");
      } else {
        this.btn_loading = true;
        packingNeworderwarehousing(this.list_id, this.location).then(function (response) {
          _this4.getList();
          _this4.btn_loading = false;
          if (response.code === 200) {
            _this4.location_sw = false;
            _this4.$message({
              message: "入库成功",
              type: "success"
            });
          } else {
            _this4.$message.error(response.data.reason);
          }
          _this4.location_sw = false;
          _this4.btn_loading = false;
          console.log("保存成功吗", response);
        }).catch(function (error) {
          _this4.btn_loading = false;
          // this.smallsave_sw = false;
          // this.$message.error("失败");
        });
      }
    },
    add_confir_warehoussing_sw: function add_confir_warehoussing_sw() {
      if (this.multipleWarehoussingSelection.length <= 0) {
        this.$message.error("请选择SKU再入库");
        return;
      }
      this.warehoussing_location_sw = true;
    },
    add_location_warehoussing_sw: function add_location_warehoussing_sw() {
      var _this5 = this;
      if (this.warehoussing_location == "") {
        this.$message.error("请输入库位");
      } else {
        var selectedOrders = this.multipleWarehoussingSelection;
        if (!selectedOrders || selectedOrders.length <= 0) {
          this.$message({
            message: "请至少选择一条数据",
            type: "warning",
            duration: 2000
          });
          return;
        }
        var skuIds = selectedOrders.map(function (order) {
          return {
            id: order.id,
            num: order.skuRemarks
          };
        }); // 提取选中订单的ID数组\
        var par = {
          location: this.warehoussing_location,
          packageReqs: skuIds
        };
        this.btn_loading = true;
        skuWarehousingNew(par).then(function (response) {
          _this5.getList();
          _this5.btn_loading = false;
          if (response.code === 200) {
            _this5.warehoussing_location_sw = false;
            _this5.warehoussingDialogVisible = false;
            _this5.getList();
            _this5.$message({
              message: "入库成功",
              type: "success"
            });
          } else {
            _this5.$message.error(response.data.reason);
          }
        }).catch(function (error) {
          _this5.btn_loading = false;
          // this.smallsave_sw = false;
          // this.$message.error("失败");
        });
      }
    },
    validNum: function validNum() {
      var idx = true;
      if (!this.productForm.packageList || this.productForm.packageList.length == 0) {
        this.$message.warning("至少存在一条包裹记录！");
        idx = false;
        return idx;
      }
      for (var i = 0; i < this.productForm.packageList.length; i++) {
        var x = this.productForm.packageList[i];
        if (!x.num || x.num < 1) {
          this.$message.warning("包裹数量至少大于1！");
          idx = false;
          return idx;
        }
        if (x.skuList && x.skuList.length > 0) {
          for (var j = 0; j < x.skuList.length; j++) {
            var y = x.skuList[j];
            if (!y.num || y.num < 1) {
              this.$message.warning("包裹中的SKU数量至少大于1！");
              idx = false;
              return idx;
            }
          }
        } else {
          this.$message.warning("包裹中至少存在一条SKU记录！");
          idx = false;
          return idx;
        }
      }
      return idx;
    },
    handleConfirm: function handleConfirm(ruleForm) {
      var _this6 = this;
      if (this.btn_loading) {
        return;
      }
      this.$refs[ruleForm].validate(function (valid) {
        if (valid && _this6.validNum()) {
          _this6.btn_loading = true;
          var url = "";
          if (_this6.title_dialogType == "编辑订单") {
            url = packingNewUpdateOrder;
          } else {
            url = packingNewCreateOrder;
          }
          if (_this6.productForm.expectedArrivalTime) {
            _this6.productForm.expectedArrivalTime = renderTime(_this6.productForm.expectedArrivalTime);
          }
          url(_this6.productForm).then(function (response) {
            _this6.getList();
            _this6.btn_loading = false;
            if (response.code === 200) {
              _this6.smallsave_sw = false;
              _this6.$message({
                message: _this6.title_dialogType == "编辑订单" ? "编辑成功" : "新增成功",
                type: "success"
              });
            } else {
              _this6.$message.error(response.data.reason);
            }
            console.log("保存成功吗", response);
          }).catch(function (error) {
            _this6.btn_loading = false;
            // this.smallsave_sw = false;
            // this.$message.error("失败");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleWarehoussingSelectionChange: function handleWarehoussingSelectionChange(val) {
      this.multipleWarehoussingSelection = val;
    },
    handleBatchExport: function handleBatchExport() {
      var selectedOrders = this.multipleSelection;
      if (!selectedOrders || selectedOrders.length <= 0) {
        this.$message({
          message: "请至少选择一条数据",
          type: "warning",
          duration: 2000
        });
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.id;
      }); // 提取选中订单的ID数组
      localStorage.setItem("selectedOrderIds", JSON.stringify(selectedOrders));
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      var params = {
        ids: orderIdsString
      };
      var myObj = {
        method: 'post',
        url: 'ws/packing/order/handExportExcel',
        fileName: 'exportPacking',
        data: params
      };
      this.exportMethodPost(myObj);
    },
    exportMethodPost: function exportMethodPost(data) {
      var _this7 = this;
      axios({
        method: data.method,
        url: process.env.VUE_APP_BASE_API + data.url,
        data: data.data,
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + getToken()
        }
      }).then(function (res) {
        var link = document.createElement('a');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; // 下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this7.exportMethodPostLoading = false;
      }).catch(function (error) {
        alert('该时间段没有导出的数据');
        _this7.exportMethodPostLoading = false;
      });
    },
    // 选择skuId
    add_skuId: function add_skuId(val) {
      if (val.num != "") {
        var obj = {
          sku: val.sku,
          saleSku: val.saleSku,
          wsSkuId: val.wsSkuId,
          num: val.num,
          skuRemarks: val.skuRemarks
        };
        var box = parseInt(val.num) % parseInt(val.qty) === 0 ? parseInt(val.num) / parseInt(val.qty) : parseInt(val.num) / parseInt(val.qty) + 1;
        var obj_1 = {
          sku: val.sku,
          declarationName: val.sku + '\n' + val.cnTitle,
          saleSku: val.saleSku,
          wsSkuId: val.wsSkuId,
          cnTitle: val.cnTitle,
          num: val.num,
          skuRemarks: val.skuRemarks,
          boxRemarks: Math.floor(box) + ' boxes'
        };
        this.productForm.packageList.skuList.push(obj_1);
        this.$message({
          type: 'success',
          message: '添加成功'
        });
        console.info('this.productForm.skuId', val, this.productForm);
      } else {
        this.$message.error('请输入发货SKU数量');
      }
    },
    //商品列表
    commodity_url: function commodity_url() {
      var _this8 = this;
      var obj = {
        sku: this.sku_title
      };
      console.info(obj);
      getPackingSkuList(obj).then(function (res) {
        console.info(res);
        _this8.shipSkuList = res.data;
        console.log("res", res);
      });
    },
    getSupplierdata: function getSupplierdata() {
      var _this9 = this;
      getSupplier().then(function (res) {
        if (res.code === 200) {
          _this9.supplierOptions = res.data;
        } else {
          Message.error(res.message);
        }
      });
    },
    // 点击复制
    copyname: function copyname(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      console.log("类型", file);
      var isJPG = file.type === "csv";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    generateOrderId: function generateOrderId() {
      var now = new Date();
      var orderId = now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, 0) + now.getDate().toString().padStart(2, 0) + now.getHours().toString().padStart(2, 0) + now.getMinutes().toString().padStart(2, 0) + now.getSeconds().toString().padStart(2, 0) + now.getMilliseconds().toString().padStart(3, 0);
      console.info(orderId);
      this.productForm.packingId = orderId;
    },
    downloadFun: function downloadFun(data) {
      window.location.href = process.env.VUE_APP_BASE_API + 'ws/downloadTemplate?fileName=' + data;
    },
    zcUploadPacking: function zcUploadPacking() {
      this.zc_warehouseId = '';
      this.zc_location_sw = true;
    },
    // 导入
    importExcel: function importExcel(val) {
      console.log("导入", val.message);
    },
    upload_success: function upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    zc_upload_success: function zc_upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
        this.zc_location_sw = false;
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    // 上传失败回调
    upload_error: function upload_error(val) {
      this.$notify({
        title: "导入失败",
        message: "数据导入失败" + val.message,
        type: "error",
        duration: 2000
      });
      console.log("上传失败回调", val.message);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      this.loading_01 = true;
    },
    getShopList: function getShopList() {
      var _this10 = this;
      _getShopList().then(function (resp) {
        _this10.envNameOptions = resp.data;
      });
    },
    add_getShopList: function add_getShopList(val) {
      var _this11 = this;
      if (val != "") {
        this.getShopList_loading = true;
        _getShopList(val).then(function (resp) {
          _this11.envNameOptions = resp.data.list;
          _this11.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }

      // console.log("输入了什么", val);
    },
    // 点击创建订单
    add_smallsave_sw: function add_smallsave_sw() {
      this.title_dialogType = "创建订单";
      this.productForm = {
        packingId: '',
        skuList: []
      };
      this.shipSkuList = [];
      this.smallsave_sw = true;
    },
    handleEditAddress: function handleEditAddress(row) {
      this.selectedAddressOrder = row; // 存储选中的订单对象
      // 将选中订单的地址信息赋值给编辑表单
      this.editAddressForm = {
        recipient: row.recipient,
        phone: row.phone,
        city: row.city,
        state: row.state,
        detailAddress: row.detailAddress,
        zipcode: row.zipcode,
        additionalAddressInformation: row.additionalAddressInformation
      };
      this.editAddressDialogVisible = true; // 打开编辑地址对话框
    },
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this12 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      getPackingWarehouseList().then(function (response) {
        if (response.code === 200) {
          _this12.warehouseOptions = response.data;
        } else {
          Message.error(response.message);
        }
      });
    },
    loadSkuOptions: function loadSkuOptions() {
      var _this13 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      getPackingSkuList().then(function (response) {
        if (response.code === 200) {
          _this13.skuListOptions = response.data;
        } else {
          Message.error(response.message);
        }
      });
    },
    // handleExceptionSearch() {
    //   this.listQuery.page = 1; // 查询时重置页码
    //   this.listQuery.type = 1;
    //   this.getList();
    // },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1; // 查询时重置页码
      this.getList();
    },
    // 新添加的方法用于获取状态描述
    getStatusDescription: function getStatusDescription(row) {
      switch (row.status) {
        case 0:
          return "已发";
        case 1:
          return "已入库";
        case 2:
          return "部分入库";
        default:
          return "未知状态";
      }
    },
    // 新添加的方法用于获取状态描述
    getPackingType: function getPackingType(row) {
      if (row === 0) {
        return "包裹";
      } else if (row === 1) {
        return "货柜";
      } else if (row === 3) {
        return "托盘";
      } else {
        return "未知类型";
      }
    },
    // 新添加的方法用于获取状态描述
    getSkuNum: function getSkuNum(row) {
      var num = 0;
      if (row) {
        row.forEach(function (val) {
          num += val.num;
        });
      }
      return num;
    },
    rowKey: function rowKey(row) {
      return row.id;
    },
    handleShowDetail: function handleShowDetail(row) {
      this.currentDetail = row;
      this.detailLoading = true;
      this.detailDialogVisible = true;
      this.detailLoading = false;
    },
    handleWarehoussingShowDetail: function handleWarehoussingShowDetail(row) {
      this.btn_loading = false;
      this.warehoussingDetail = row;
      this.warehoussingdetailLoading = true;
      this.warehoussingDialogVisible = true;
      this.warehoussingdetailLoading = false;
      this.multipleWarehoussingSelection = [];
    },
    getList: function getList() {
      var _this14 = this;
      this.listLoading = true;
      console.info(this.listQuery);
      getPackingNewGetPage(this.listQuery).then(function (response) {
        _this14.list = response.data.list;
        _this14.total = response.data.total;
        _this14.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      var _this15 = this;
      this.dialogStatus = "add";
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this15.$refs["dataForm"].clearValidate();
      });
    },
    /*包裹选择开始*/handleAddPackageList: function handleAddPackageList() {
      var obj = {
        num: 1,
        remark: "",
        status: 0,
        skuList: []
      };
      if (!this.productForm.packageList) {
        this.$set(this.productForm, 'packageList', []);
      }
      this.productForm.packageList.push(obj);
    },
    selectSkuCall: function selectSkuCall(ids, selections) {
      console.log("选择成功==》", selections);
      var tkSkuList = this.productForm.packageList[this.packageListSelectIdex].skuList || [];
      if (selections) {
        selections.map(function (x) {
          x.id = '';
          x.num = 1;
          var i = tkSkuList.find(function (y) {
            return x.wsSkuId == y.wsSkuId;
          });
          if (!i) {
            tkSkuList.push(x);
          }
        });
      }
      this.$set(this.productForm.packageList[this.packageListSelectIdex], 'skuList', tkSkuList);
    },
    // 删除
    delete_skulist: function delete_skulist(row, index, pidx) {
      var _this16 = this;
      this.packageListSelectIdex = pidx;
      console.log(row, index, pidx);
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this16.productForm.packageList[_this16.packageListSelectIdex].skuList.splice(index, 1);
        _this16.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this16.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    // 删除
    delete_list: function delete_list(rowidx, index) {
      var _this17 = this;
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this17.productForm.packageList.splice(index, 1);
        _this17.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this17.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    addSku_list: function addSku_list(row, idx) {
      console.log(row, idx);
      this.selectSkuList = row.skuList;
      this.packageListSelectIdex = idx;
      this.$refs.skuSelectPage.openDialog();
    } /*包裹结束*/
  }
};