"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var slideshowRouter = {
  path: '/slideshow',
  component: _layout.default,
  redirect: '/slideshow/type',
  name: 'slideshow',
  alwaysShow: true,
  meta: {
    title: '轮播图',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/slideshow/type/index'));
      });
    },
    name: 'slideshowType',
    meta: {
      title: '轮播类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/slideshow/list/index'));
      });
    },
    name: 'slideshowList',
    meta: {
      title: '轮播图列表',
      role: []
    }
  }]
};
var _default = slideshowRouter;
exports.default = _default;