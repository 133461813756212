var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-view", [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-alert", {
                  attrs: {
                    title: "菜单URL必须与前端路由path一致",
                    type: "info",
                    "show-icon": "",
                    closable: false,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-left" }, [
            _c(
              "div",
              { staticClass: "my-card" },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.menuList,
                    "node-key": "id",
                    "expand-on-click-node": false,
                    "default-expand-all": true,
                    indent: 16,
                    props: _vm.defaultProps,
                  },
                  on: { "node-click": _vm.handleNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c("span", [_vm._v(_vm._s(node.label))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function () {
                                      return _vm.append(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function () {
                                      return _vm.remove(node, data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-right" },
            [
              _c(
                "el-card",
                { staticClass: "box-card", staticStyle: { height: "100%" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "postForm",
                      staticClass: "form-container",
                      attrs: {
                        model: _vm.menu,
                        rules: _vm.rules,
                        "label-width": "160px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级菜单:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: {
                              disabled: "",
                              placeholder: "直接添加顶级菜单",
                            },
                            model: {
                              value: _vm.menu.topName,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "topName", $$v)
                              },
                              expression: "menu.topName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name", label: "菜单名称:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入菜单名称" },
                            model: {
                              value: _vm.menu.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "name", $$v)
                              },
                              expression: "menu.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "url", label: "菜单路径:" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入菜单路径" },
                            model: {
                              value: _vm.menu.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "url", $$v)
                              },
                              expression: "menu.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: { prop: "sort", label: "菜单排序:" },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "50%" },
                            attrs: { min: 1, label: "修改排序" },
                            model: {
                              value: _vm.menu.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "sort", $$v)
                              },
                              expression: "menu.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCreate()
                                },
                              },
                            },
                            [_vm._v("添加顶级菜单")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSave()
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }