"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdminLoginLog = getAdminLoginLog;
exports.getAdminOperationLog = getAdminOperationLog;
exports.getverifyCodeLog = getverifyCodeLog;
var _request = _interopRequireDefault(require("@/utils/request"));
function getAdminLoginLog(data) {
  return (0, _request.default)({
    url: '/system/adminLogin/getList',
    method: 'get',
    params: data
  });
}
function getAdminOperationLog(data) {
  return (0, _request.default)({
    url: '/system/adminOperation/getList',
    method: 'get',
    params: data
  });
}
function getverifyCodeLog(data) {
  return (0, _request.default)({
    url: '/system/verifyCode/getList',
    method: 'get',
    params: data
  });
}