"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 处理图片视频接口
// 封装签名
var COS = require('cos-js-sdk-v5');
// 实例化js
var cos = new COS({
  SecretId: 'AKID8DE8tXjv5P7jFftGTVTM4Ynoc2Vs8GEF',
  SecretKey: '2zELKCuV6d2StOlzEUoDZsFcDiBctLn6',
  SimpleUploadMethod: 'putObject'
});
var _default = {
  getPicUrl: function getPicUrl(picurl) {
    var coverImg = picurl.substring(57, picurl.length);
    var data = cos.getObjectUrl({
      Bucket: "yhvideo-1256822164",
      //对象储存桶的名称
      Region: "ap-guangzhou",
      //所属地域
      Key: coverImg
    }, function (err, data) {
      return data.Url;
    });
    return data;
  }
};
exports.default = _default;