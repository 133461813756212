"use strict";

var _interopRequireWildcard = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var echarts = _interopRequireWildcard(require("echarts"));
//
//
//
//
//

require("echarts/theme/shine"); //引入主题
var _default = {
  props: ["number", "time", "visitor", "legend", "title", "colour"],
  data: function data() {
    return {
      chartLine: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.drawLineChart();
    });
  },
  methods: {
    drawLineChart: function drawLineChart() {
      this.chartLine = echarts.init(this.$el, "shine"); // 基于准备好的dom，初始化echarts实例
      var option = {
        title: {
          text: this.title
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          data: this.time
        },
        yAxis: {
          type: "value"
        },
        // legend: {
        //   data: [this.legend[0], this.legend[1]]
        // },
        series: [(0, _defineProperty2.default)({
          name: this.legend[0],
          data: this.number,
          type: "bar",
          smooth: true,
          itemStyle: {
            normal: {
              color: "#409eff",
              lineStyle: {
                color: "#409eff"
              }
            }
          },
          barWidth: 31
        }, "itemStyle", {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: this.colour[2] // 0% 处的颜色
            }, {
              offset: 0.6,
              color: this.colour[3] // 60% 处的颜色
            }, {
              offset: 1,
              color: this.colour[3] // 100% 处的颜色
            }], false)
          }
        }), {
          name: this.legend[1],
          data: this.visitor,
          type: "line",
          smooth: true,
          itemStyle: {
            // normal: {
            //   color: "#00EE00",
            //   lineStyle: {
            //     color: "#00EE00"
            //   }
            // }
            normal: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: this.colour[0] // 0% 处的颜色
              }, {
                offset: 0.6,
                color: this.colour[1] // 60% 处的颜色
              }, {
                offset: 1,
                color: this.colour[1] // 100% 处的颜色
              }], false)
            }
          }
        }]
      };
      // 使用刚指定的配置项和数据显示图表
      this.chartLine.setOption(option);
    }
  }
};
exports.default = _default;