/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var shopRouter = {
  path: '/shop',
  component: Layout,
  name: 'shop',
  alwaysShow: true,
  meta: {
    title: '店铺',
    icon: 'shop-list'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/shop/index/index');
    },
    name: 'shopList',
    meta: {
      title: '店铺列表',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'verify',
    component: function component() {
      return import('@/views/shop/verify/index');
    },
    name: 'verifyShop',
    meta: {
      title: '新店铺审核',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'data',
    component: function component() {
      return import('@/views/shop/data/index');
    },
    name: 'shopDataList',
    meta: {
      title: '店铺数据',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'shopService',
    component: function component() {
      return import('@/views/shop/service/index');
    },
    name: 'shopService',
    meta: {
      title: '服务管理',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'freight',
    component: function component() {
      return import('@/views/shop/freight/index');
    },
    name: 'freightTemplate',
    meta: {
      title: '运费模板',
      icon: '',
      role: ['admin']
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/shop/index/detailInfo');
    },
    name: 'detailInfo',
    meta: {
      title: '店铺详情',
      role: ['admin'],
      activeMenu: '/shop/list'
    },
    hidden: true
  }, {
    path: 'detail/:id(\\d+)',
    component: function component() {
      return import('@/views/shop/index/detail');
    },
    name: 'editMember',
    meta: {
      title: '编辑店铺详情',
      noCache: true,
      role: [],
      activeMenu: '/shop/list'
    },
    hidden: true
  }]
};
export default shopRouter;