import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime, exportMethod } from "@/utils";
import { getShipOrder, auditshipOrder
//   createShipOrder,
//   getSubordinateShopList,
//   getMainInd
} from "@/api/shop";
import { getShopList as _getShopList } from "@/api/product/product";
// import { warehouseGetList } from "@/api/warehouse";
// import { page, getById } from "@/api/product";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      listLoading: false,
      list: [],
      total: 0,
      obj: {
        page: 1,
        pageSize: 10
      },
      listQuery: {
        shopId: "",
        status: 8,
        page: 1,
        pageSize: 10
      },
      productForm: {
        orderId: "",
        num: "",
        receiveUsername: "",
        receivePhone: "",
        street: "",
        city: "",
        province: "",
        zipCode: "",
        address1: "",
        address2: "",
        warehouseId: "",
        skuId: ""
      },
      dialogFormVisible: false,
      dialogType: "add",
      warehouseOptions: [],
      commodity_name: "",
      commodity_typeName: "",
      commodity_list: [],
      btn_loading: false,
      mainFlag: false,
      envNameOptions: [],
      checkedStatus: [],
      status_arr: [{
        value: 1,
        label: "发货成功"
      }, {
        value: 8,
        label: "申请取消中"
      }, {
        value: 9,
        label: "订单已取消"
      }],
      dialogVisible: false,
      ruleForm: {
        orderId: "",
        status: 9,
        auditReason: ""
      },
      rules: {
        orderId: [{
          required: true,
          message: "请输入订单号",
          trigger: "blur"
        },
        // { min: 6, message: "密码不能少于6位", trigger: "blur" }
        {
          min: 8,
          message: "订单号长度最少8个字符",
          trigger: "blur"
        }, {
          type: "number",
          message: "订单号必须为数字",
          trigger: "blur",
          transform: function transform(value) {
            if (value !== null && value !== "") {
              if (String(value).trim() === "" || Number(value) < 100000 || Number(value) > 999999999999999999999999999999) {
                return false;
              } else {
                return Number(value);
              }
            } else {
              return null;
            }
          }
        }],
        num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        receiveUsername: [{
          required: true,
          message: "请输入收件人",
          trigger: "blur"
        }],
        receivePhone: [{
          required: true,
          message: "请输入收件人电话",
          trigger: "blur"
        }],
        // street: [{ required: true, message: "请输入街道", trigger: "blur" }],
        city: [{
          required: true,
          message: "请输入城市",
          trigger: "blur"
        }],
        province: [{
          required: true,
          message: "请输入州",
          trigger: "blur"
        }],
        zipCode: [{
          required: true,
          message: "请输入邮编",
          trigger: "blur"
        }],
        address1: [{
          required: true,
          message: "请输入地址1",
          trigger: "blur"
        }],
        address2: [{
          required: true,
          message: "请输入门牌号，如果没有门牌号就输入一个空格",
          trigger: "blur"
        }],
        // warehouseId: [
        //   { required: true, message: "请选择仓库", trigger: "change" }
        // ],
        skuId: [{
          required: true,
          message: "请选择SKU序号",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        auditReason: [{
          required: true,
          message: "请输入备注",
          trigger: "blur"
        }]
      },
      getShopList_loading: false,
      detailsskuList: [],
      detailDialogVisible: false
    };
  },
  methods: {
    add_shopId: function add_shopId(val) {
      console.log("val", val, this.listQuery.shopId);
    },
    //
    add_SKU: function add_SKU(val) {
      this.detailsskuList = val.detailList;
      this.detailDialogVisible = true;
      console.log("val", val, this.detailsskuList);
    },
    // 保存按钮
    submitForm: function submitForm(ruleForm) {
      var _this = this;
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          console.log("选中的", _this.ruleForm);
          // return;
          auditshipOrder(_this.ruleForm).then(function (success) {
            _this.$message({
              message: "审核成功",
              type: "success"
            });
            _this.get_list();
            _this.dialogVisible = false;
            console.log("申请成功", success);
          }).catch(function (error) {
            console.log("审核失败", error);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //申请审批
    add_apply: function add_apply(row) {
      this.ruleForm.orderId = row.orderId;
      this.ruleForm.status = 9;
      this.ruleForm.auditReason = "";
      this.dialogVisible = true;
    },
    // 删除
    //   delete_list(val, index) {
    //     console.log("数据", val, index);
    //     this.$confirm("此操作将删除该订单, 是否继续?", "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning"
    //     })
    //       .then(() => {
    //         this.list.splice(index, 1);
    //         this.$router.push({
    //           path: "goods",
    //           query: {
    //             obj: JSON.stringify(this.list)
    //           }
    //         });
    //         // this.goods_api();
    //         this.$message({
    //           type: "success",
    //           message: "删除成功!"
    //         });
    //       })
    //       .catch(() => {
    //         this.$message({
    //           type: "info",
    //           message: "已取消删除"
    //         });
    //       });
    //   },
    // 排序
    add_sortchange: function add_sortchange(row) {
      if (row.prop == "updTime") {
        if (row.order == "ascending") {
          this.listQuery.sort = 1;
        } else if (row.order == "descending") {
          this.listQuery.sort = 2;
        } else {
          this.listQuery.sort = "";
        }
      }
      this.$notify({
        title: "成功",
        message: "列表排序成功",
        type: "success",
        duration: 2000
      });
      this.get_list();
      console.log("触发了吗", row, this.listQuery);
    },
    changeMemberId: function changeMemberId(val) {
      this.productForm.orderId = val.replace(/[^\d]/g, "");
    },
    // 选择skuId
    add_skuId: function add_skuId(val) {
      this.productForm.skuId = val.productSkuId;
      console.log("this.productForm.skuId", val, this.productForm.skuId);
      this.$message({
        type: "success",
        message: "添加成功"
      });
    },
    // 商品列表
    commodity_url: function commodity_url() {
      var _this2 = this;
      var obj = {
        title: this.commodity_name,
        typeName: this.commodity_typeName
      };
      page(obj).then(function (response) {
        //   this.commodity_list = response.data.list;
        response.data.list.forEach(function (val) {
          val.button_sw = true;
          getById(val.id).then(function (res) {
            val.children = res.data.skuList;
            // res.button_sw = true;
            _this2.commodity_list.splice();
          }).catch(function (error) {
            console.error(error);
          });
        });
        //   setTimeout(() => {
        _this2.commodity_list = response.data.list;
        //   }, 1000);
      }).catch(function (error) {
        console.error(error);
      });
    },
    // 仓库列表
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this3 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      warehouseGetList().then(function (response) {
        if (response.code === 200) {
          _this3.warehouseOptions = response.data;
        } else {
          Message.error(response.message);
        }
      });
    },
    // 取消发货单
    handleCancel: function handleCancel() {
      this.dialogFormVisible = false;
    },
    // 保存发货单
    handleConfirm: function handleConfirm(ruleForm) {
      var _this4 = this;
      this.$refs[ruleForm].validate(function (valid) {
        console.log("参数", _this4.productForm);
        if (valid) {
          _this4.btn_loading = true;
          createShipOrder(_this4.productForm).then(function (response) {
            _this4.get_list();
            _this4.btn_loading = false;
            if (response.code == 500) {
              _this4.$message.error(response.data.reason);
            } else {
              _this4.dialogFormVisible = false;
              _this4.$message({
                message: "新增成功",
                type: "success"
              });
            }
            console.log("保存成功吗", response);
          }).catch(function (error) {
            _this4.listLoading = false;
            _this4.btn_loading = false;
            // this.$message.error("失败");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 创建发货单
    add_list: function add_list() {
      this.productForm = {
        orderId: "",
        num: "",
        receiveUsername: "",
        receivePhone: "",
        street: "",
        city: "",
        province: "",
        zipCode: "",
        address1: "",
        address2: "",
        warehouseId: "",
        skuId: ""
      };
      this.dialogFormVisible = true;
    },
    get_list: function get_list() {
      var _this5 = this;
      this.listLoading = true;
      console.log("this.listQuery", this.listQuery);
      // this.listQuery.shopId = "";
      if (this.checkedStatus && this.checkedStatus.length > 0) {
        this.listQuery.shopId = this.checkedStatus.join(",");
      }
      getShipOrder(this.listQuery).then(function (response) {
        _this5.list = response.data.list;
        _this5.total = response.data.total;
        _this5.listLoading = false;
      }).catch(function (error) {
        _this5.listLoading = false;
        _this5.$message.error("失败");
      });
    },
    getShopList: function getShopList() {
      var _this6 = this;
      console.log("触发了嘛");
      _getShopList().then(function (resp) {
        _this6.envNameOptions = resp.data;
      });
    },
    add_getShopList: function add_getShopList(val) {
      var _this7 = this;
      if (val != "") {
        this.getShopList_loading = true;
        _getShopList(val).then(function (resp) {
          _this7.envNameOptions = resp.data.list;
          _this7.getShopList_loading = false;
          console.log("搜索的值", resp);
        });
      }
    }
  },
  created: function created() {
    // this.loadWarehouseOptions();
    this.get_list();
    // this.getShopList();
    //   this.getMainFlag();
  }
};