import Layout from '@/layout';
var slideshowRouter = {
  path: '/slideshow',
  component: Layout,
  redirect: '/slideshow/type',
  name: 'slideshow',
  alwaysShow: true,
  meta: {
    title: '轮播图',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/slideshow/type/index');
    },
    name: 'slideshowType',
    meta: {
      title: '轮播类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/slideshow/list/index');
    },
    name: 'slideshowList',
    meta: {
      title: '轮播图列表',
      role: []
    }
  }]
};
export default slideshowRouter;