import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//

export default {
  name: 'Space',
  props: {
    type: {
      type: String,
      default: 'small'
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator: function validator(value) {
        return ['horizontal', 'vertical'].includes(value);
      }
    }
  },
  computed: {
    styles: function styles() {
      var baseStyle = {
        display: 'flex'
      };
      if (this.direction === 'horizontal') {
        baseStyle.flexDirection = 'row';
        baseStyle.justifyContent = 'space-between';
      } else {
        baseStyle.flexDirection = 'column';
        baseStyle.alignItems = 'stretch';
      }
      return baseStyle;
    }
  }
};