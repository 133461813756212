"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WarehouseList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      form: {
        name: "",
        sort: null,
        type: null
      },
      typeMap: {
        1: "海外仓",
        2: "虚拟仓",
        3: "自定义",
        4: "开亚仓",
        5: "易境通仓",
        6: "WS仓"
      },
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 20,
        name: "",
        warehouseId: null
      },
      name: "",
      adminName: "",
      Warehouse_list: [],
      Warehouse_sw: false,
      warehouseOptions: [],
      apiList: [],
      gongyingshang: [],
      statusOptions: [{
        id: 0,
        name: '启用'
      }, {
        id: 1,
        name: '停用'
      }]
    };
  },
  created: function created() {
    this.getList();
    this.getWsWarehouse_url();
    this.getApiList();
    this.getgongyingshangList();
  },
  methods: {
    // 查看仓库号
    add_warehousegetWarehouseInfo: function add_warehousegetWarehouseInfo(row) {
      var _this = this;
      (0, _warehouse.warehousegetWarehouseInfo)(row.id).then(function (res) {
        res.data = JSON.parse(res.data);
        _this.Warehouse_list = res.data.Data;
        console.log("仓库号", res.data, _this.Warehouse_list);
        _this.Warehouse_sw = true;
      });
    },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1; // Reset page to 1 to start from the first page
      this.listQuery.name = this.name, this.listQuery.id = this.id, this.listQuery.adminName = this.adminName, this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        warehouseName: this.listQuery.name,
        adminName: this.listQuery.adminName
      };
      this.listLoading = true;
      (0, _warehouse.apiBandingPage)(params).then(function (response) {
        _this2.list = response.data.list;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.form = {
        name: "",
        sort: null,
        type: null
      };
      this.dialogFormVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form = (0, _objectSpread2.default)({}, row);
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      (0, _warehouse.apiBandDel)(id).then(function () {
        _this3.getList();
        _elementUi.Message.success("删除成功");
      }).catch(function (error) {
        console.error("删除失败", error);
        _elementUi.Message.error("删除失败");
      });
    },
    // 仓库列表
    getWsWarehouse_url: function getWsWarehouse_url() {
      var _this4 = this;
      (0, _warehouse.wsWarehouseList)().then(function (res) {
        if (res.code === 200) {
          _this4.warehouseOptions = res.data;
        } else {
          _elementUi.Message.error(res.message);
        }
      });
    },
    // 仓库列表
    getApiList: function getApiList() {
      var _this5 = this;
      (0, _warehouse.wsApiList)().then(function (res) {
        if (res.code === 200) {
          _this5.apiList = res.data;
        } else {
          _elementUi.Message.error(res.message);
        }
      });
    },
    // 仓库列表
    getgongyingshangList: function getgongyingshangList() {
      var _this6 = this;
      var param = {
        pageSize: 999
      };
      (0, _warehouse.supplierGetPage)(param).then(function (res) {
        if (res.code === 200) {
          _this6.gongyingshang = res.data.list;
        } else {
          _elementUi.Message.error(res.message);
        }
      });
    },
    saveWarehouse: function saveWarehouse() {
      var _this7 = this;
      this.form.addTime = "";
      this.form.updTime = "";
      if (this.form.id) {
        (0, _warehouse.apiBandUpdateOrAdd)(this.form).then(function () {
          _this7.getList();
          _elementUi.Message.success("编辑成功");
        }).catch(function (error) {
          console.error("编辑失败", error);
          _elementUi.Message.error("编辑失败");
        });
      } else {
        (0, _warehouse.apiBandUpdateOrAdd)(this.form).then(function () {
          _this7.getList();
          _elementUi.Message.success("新增成功");
        }).catch(function (error) {
          console.error("新增失败", error);
          _elementUi.Message.error("新增失败");
        });
      }
      this.dialogFormVisible = false;
    }
  }
};
exports.default = _default;