"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WarehouseList",
  components: {
    Pagination: _index.default,
    Upload: _singleImage.default
  },
  data: function data() {
    return {
      warehouseId: 3,
      productName: "",
      // 搜索产品标题
      typeName: "",
      // 搜索产品名称
      skuTitle: "",
      // 搜索SKU名称
      skuTypeName: "",
      // 搜索SKU品类名称
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      warehouseDialogVisible: false,
      warehouseList: [],
      addInventoryDialogVisible: false,
      addForm: {
        skuId: null,
        // 这里将skuId设置为null，待具体行数据时再设置
        warehouseId: null,
        totalInv: null
      },
      warehouseOptions: [],
      // 用来存放仓库列表
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10
      },
      inventoryDialogVisible: false,
      inventoryDialogType: "add",
      // 'add'表示新增库存，'reduce'表示减少库存
      inventoryForm: {
        num: null // 数量字段
      },

      applyStorageDialogVisible: false,
      // 申请入库对话框可见性
      applyStorageDialogVisibleA: false,
      // 申请入库对话框可见性
      applyStorageData: {
        warehouseId: null,
        // 当前页面查询的 warehouseId
        skuId: null,
        // 当前行数据的 id
        addInv: null,
        // 用户输入的新增库存数量
        decInv: null,
        // 用户输入的新增库存数量
        content: "",
        // 用户输入的入库说明
        imgUrl: ""
      }
    };
  },
  created: function created() {
    this.getList();
    this.loadWarehouseOptions(); // 在created钩子中加载仓库列表
  },
  mounted: function mounted() {},
  methods: {
    add_cancellation: function add_cancellation(row) {
      var _this = this;
      this.$confirm("库存取消后记录将无法找回，请确认是否取消", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var params = {
          warehouseId: _this.warehouseId,
          skuId: row.id
        };
        (0, _warehouse.cancel)(params).then(function () {
          _this.$notify({
            title: "成功",
            message: "取消成功",
            type: "success",
            duration: 2000
          });
        });
      }).catch(function () {});
    },
    handleApplyStorage: function handleApplyStorage(row) {
      // 弹出申请入库对话框
      this.applyStorageDialogVisible = true;

      // 设置当前行的数据到数据模型中，以供提交使用
      this.applyStorageData.warehouseId = this.warehouseId;
      this.applyStorageData.skuId = row.id; // 当行数据的id
    },
    handleApplyStorageA: function handleApplyStorageA(row) {
      // 弹出申请入库对话框
      this.applyStorageDialogVisibleA = true;

      // 设置当前行的数据到数据模型中，以供提交使用
      this.applyStorageData.warehouseId = this.warehouseId;
      this.applyStorageData.skuId = row.id; // 当行数据的id
    },
    handleApplyStorageConfirm: function handleApplyStorageConfirm() {
      var _this2 = this;
      // 调用 applyStorage 接口提交申请入库数据
      (0, _warehouse.applyStorage)(this.applyStorageData).then(function (response) {
        if (response.code === 200) {
          _this2.applyStorageDialogVisible = false;
          _this2.applyStorageDialogVisibleA = false;
          _elementUi.Message.success("操作成功");
          // 可以刷新数据或执行其他操作
          _this2.applyStorageData.addInv = null;
          _this2.applyStorageData.decInv = null;
          _this2.applyStorageData.content = null;
          _this2.applyStorageData.imgUrl = null;
          _this2.getList();
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    formatTitle: function formatTitle(title) {
      var maxLength = 100; // Adjust this value as needed
      if (title.length > maxLength) {
        return title.substring(0, maxLength) + "...";
      }
      return title;
    },
    handleSearch: function handleSearch() {
      // 在这里触发搜索操作，调用接口并传递搜索参数
      var params = {};
      this.listQuery.page = 1;
      this.listQuery.pageSize = 10;
      // 调用搜索接口并更新列表数据
      this.getList(params);
    },
    handleAdd: function handleAdd(row) {
      this.inventoryDialogType = "add";
      this.inventoryForm.id = row.id; // 设置需要操作的 SKU
      this.inventoryDialogVisible = true;
    },
    handleReduceInventory: function handleReduceInventory(row) {
      this.inventoryDialogType = "add";
      this.inventoryForm.id = row.id; // 设置需要操作的 SKU
      this.inventoryDialogVisible = true;
    },
    handleInventoryConfirm: function handleInventoryConfirm() {
      var _this3 = this;
      var method = this.inventoryDialogType === "add" ? _warehouse.invAdd : _warehouse.invReduce;
      method(this.inventoryForm).then(function (response) {
        if (response.code === 200) {
          _elementUi.Message.success("操作成功");
          _this3.inventoryDialogVisible = false;
          // 刷新库存列表或执行其他操作
          _this3.getList();
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this4 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.warehouseGetList)().then(function (response) {
        if (response.code === 200) {
          _this4.warehouseOptions = response.data;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    getList: function getList() {
      var _this5 = this;
      var params = {
        warehouseId: this.warehouseId,
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        productName: this.productName,
        typeName: this.typeName,
        skuTitle: this.skuTitle,
        skuTypeName: this.skuTypeName
      };
      this.listLoading = true;
      (0, _warehouse.getPageByWarehouseId)(params).then(function (response) {
        _this5.list = response.data.list;
        _this5.listLoading = false;
        _this5.total = response.data.total;
      });
    },
    handleShowWarehouse: function handleShowWarehouse(id) {
      var _this6 = this;
      this.addForm.skuId = id;
      (0, _warehouse.warehousesInvGetById)(id).then(function (response) {
        _this6.warehouseList = response.data.list;
        _this6.warehouseDialogVisible = true;
      });
    },
    handleAddInventory: function handleAddInventory() {
      // this.addForm.skuId = this.selectedRowId; // 设置skuId为选中行的id
      this.addInventoryDialogVisible = true;
    },
    handleAddInventoryConfirm: function handleAddInventoryConfirm() {
      var _this7 = this;
      // 调用 warehouseInvSave 方法进行新增库存操作
      console.log(this.addForm);
      (0, _warehouse.warehouseInvSave)(this.addForm).then(function (response) {
        if (response.code === 200) {
          _elementUi.Message.success("新增库存成功");
          _this7.addInventoryDialogVisible = false;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    }
  }
};
exports.default = _default;