"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var shipRouter = {
  path: '/ship',
  component: _layout.default,
  redirect: '/ship',
  name: 'ship',
  alwaysShow: true,
  meta: {
    title: '订单管理',
    icon: 'report-brand'
  },
  children: [{
    path: 'cancel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/index/index'));
      });
    },
    name: 'shipCancel',
    meta: {
      title: '取消订单',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/order/index'));
      });
    },
    name: 'shipOrder',
    meta: {
      title: '订单列表',
      role: []
    }
  }, {
    path: 'invoice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/invoice/index'));
      });
    },
    name: 'Invoice',
    meta: {
      title: '手动发货台订单',
      role: []
    }
  }, {
    path: 'auxiliary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/auxiliary/index'));
      });
    },
    name: 'Auxiliary',
    meta: {
      title: '辅助发货列表',
      role: []
    }
  }, {
    path: 'summary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/summary/index'));
      });
    },
    name: 'Summary',
    meta: {
      title: '订单汇总',
      role: []
    }
  }, {
    path: 'skuorder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ship/skuorder/skuorder'));
      });
    },
    name: 'Skuorder',
    meta: {
      title: '自传订单',
      role: []
    }
  }
  // {
  //   path: 'type',
  //   component: () => import('@/views/ship/type/index'),
  //   name: 'shipType',
  //   meta: { title: '品类管理',  role: [] }
  // },
  // {
  //   path: 'count',
  //   component: () => import('@/views/ship/count/index'),
  //   name: 'shipCount',
  //   meta: { title: '库存统计',  role: [] }
  // }
  ]
};
var _default = shipRouter;
exports.default = _default;