import "core-js/modules/es6.number.constructor";
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    num: {
      type: Number,
      default: null
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      num = _context$props.num;
    var vnodes = [];
    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }
    if (title) {
      if (num && Number(num) > 0) {
        vnodes.push(h("span", {
          "slot": "title",
          "style": "font-size:14px;"
        }, [title, h("span", {
          "style": "color:#ff4949;"
        }, [" (", num, ")"])]));
      } else {
        vnodes.push(h("span", {
          "slot": "title",
          "style": "font-size:14px;"
        }, [title]));
      }
    }
    return vnodes;
  }
};