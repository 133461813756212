var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "100px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺账号：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入店铺账号",
                            "remote-method": _vm.add_getShopList,
                            loading: _vm.getShopList_loading,
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shopId", $$v)
                            },
                            expression: "listQuery.shopId",
                          },
                        },
                        _vm._l(_vm.envNameOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主账号名称：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择店铺",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.shopMainId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shopMainId", $$v)
                            },
                            expression: "listQuery.shopMainId",
                          },
                        },
                        _vm._l(_vm.getShop_list, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$checkPermission(["createOrder"]),
                          expression: "! $checkPermission(['createOrder'])",
                        },
                      ],
                      attrs: { label: "供应商名称：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择供应商",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.supplyIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "supplyIds", $$v)
                            },
                            expression: "listQuery.supplyIds",
                          },
                        },
                        _vm._l(_vm.supplyOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期时间：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "380px" },
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          size: "small",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.add_effective_time },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " 订单号：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          placeholder: "请输入订单号名称",
                        },
                        model: {
                          value: _vm.listQuery.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderNo", $$v)
                          },
                          expression: "listQuery.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流SKU：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入物流SKU" },
                        model: {
                          value: _vm.listQuery.skuTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "skuTitle", $$v)
                          },
                          expression: "listQuery.skuTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择仓库",
                            size: "mini",
                            multiple: "",
                            "collapse-tags": "",
                          },
                          model: {
                            value: _vm.warehouseIds,
                            callback: function ($$v) {
                              _vm.warehouseIds = $$v
                            },
                            expression: "warehouseIds",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (warehouse) {
                          return _c("el-option", {
                            key: warehouse.id,
                            attrs: {
                              value: warehouse.id,
                              label: warehouse.name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "运单号：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入运单号" },
                        model: {
                          value: _vm.listQuery.shipNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "shipNo", $$v)
                          },
                          expression: "listQuery.shipNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择结算状态",
                            size: "mini",
                          },
                          model: {
                            value: _vm.listQuery.settlementFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "settlementFlag", $$v)
                            },
                            expression: "listQuery.settlementFlag",
                          },
                        },
                        _vm._l(_vm.settlementFlag_data, function (warehouse) {
                          return _c("el-option", {
                            key: warehouse.value,
                            attrs: {
                              value: warehouse.value,
                              label: warehouse.label,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("查询\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                          },
                          on: { click: _vm.handleTimePickerConfirm },
                        },
                        [_vm._v("导出\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footerL",
          fn: function () {
            return [
              _c("div", { staticClass: "order-statistic" }, [
                _c("span", { staticClass: "order-item" }, [
                  _vm._v(" 订单合计：" + _vm._s(_vm.list.length) + "笔 "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "order-item" }, [
                  _vm._v(
                    "\n          订单金额合计(美元)：" +
                      _vm._s(_vm.total_amount / 100) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "order-item" }, [
                  _vm._v(
                    "\n          佣金金额合计(美元)：" +
                      _vm._s(_vm.total_commission / 100) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "order-item" }, [
                  _vm._v(
                    "\n          结算总金额合计(美元)：" +
                      _vm._s(_vm.total_lumpsum / 100) +
                      "\n        "
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
            "row-key": _vm.rowKey,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客户单号", align: "center", prop: "orderId" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺账号", align: "center", prop: "username" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运单号", align: "center", prop: "shipNo" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货仓库", align: "center", prop: "warehouse" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单状态", align: "center", prop: "statusStr" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单金额", align: "center", prop: "orderAmount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "物流费", align: "center", prop: "expressFee" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "物流服务费",
              align: "center",
              prop: "expressProcessFee",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "萤火服务费", align: "center", prop: "serviceFee" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "佣金金额", align: "center", prop: "mission" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "结算金额",
              align: "center",
              prop: "supplierAmount",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "供应商服务费",
              align: "center",
              prop: "supplyFee",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "结算状态",
              align: "center",
              prop: "settlementFlag",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            scope.row.settlementFlag === 0
                              ? "未结算"
                              : scope.row.settlementFlag === 1
                              ? "已结算"
                              : scope.row.settlementFlag === 2
                              ? "取消结算"
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货时间", align: "center", prop: "orderAmount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.expressTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.add_SKU(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          SKU明细\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.detailsskuList,
                border: "",
                fit: "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "SKUId", align: "center", prop: "skuId" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "产品名称", align: "center", prop: "typeName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "SKU品类名称",
                  align: "center",
                  prop: "typeSkuName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "物流SKU", align: "center", prop: "skuTitle" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "SKU销售数量",
                  align: "center",
                  prop: "quantity",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "供应商名称",
                  align: "center",
                  prop: "supplierName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }