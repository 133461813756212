"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse/warehouse");
var _elementUi = require("element-ui");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WarehouseList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      total: 0,
      loading_01: false,
      uploadExcel: process.env.VUE_APP_BASE_API + 'ws/zone/uploadExcel',
      headers: {},
      apiList: [],
      queryApiId: ''
    };
  },
  created: function created() {
    this.getApiList();
    this.getList();
    this.headers = {
      Authorization: 'Bearer ' + (0, _auth.getToken)()
    };
  },
  methods: {
    getApiList: function getApiList() {
      var _this = this;
      (0, _warehouse.wsApiList)().then(function (res) {
        if (res.code === 200) {
          _this.apiList = res.data;
        } else {
          _elementUi.Message.error(res.message);
        }
      });
    },
    // 导入
    importExcel: function importExcel(val) {
      console.log("导入", val.message);
    },
    upload_success: function upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    // 上传失败回调
    upload_error: function upload_error(val) {
      this.$notify({
        title: "导入失败",
        message: "数据导入失败" + val.message,
        type: "error",
        duration: 2000
      });
      console.log("上传失败回调", val.message);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      this.loading_01 = true;
    },
    handleSearch: function handleSearch() {
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _warehouse.listUp)(this.queryApiId).then(function (response) {
        _this2.list = response.data.data;
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;