import Layout from '@/layout';
var businessRouter = {
  path: '/business',
  component: Layout,
  redirect: '/business/type',
  name: 'business',
  alwaysShow: true,
  meta: {
    title: '业务',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/business/type/index');
    },
    name: 'businessType',
    meta: {
      title: '业务类型',
      role: []
    }
  }, {
    path: 'form',
    component: function component() {
      return import('@/views/business/form/index');
    },
    name: 'businessForm',
    meta: {
      title: '业务类型SKU',
      role: []
    }
  }, {
    path: 'goods',
    component: function component() {
      return import('@/views/business/goods/index');
    },
    name: 'businessGoods',
    meta: {
      title: '业务商品',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/business/order/index');
    },
    name: 'businessGoods',
    meta: {
      title: '业务订单',
      role: []
    }
  }]
};
export default businessRouter;