"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _singleImageUsSKU = _interopRequireDefault(require("@/components/Upload/singleImageUsSKU.vue"));
var _product = require("@/api/product/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["value", "variations", "dialogType_sw", "productForm_id"],
  //   computed: {
  //     skuList() {
  //       console.log("父数据的值", this.value);
  //       return this.value;
  //     }
  //   },
  components: {
    UploadSKU: _singleImageUsSKU.default
  },
  data: function data() {
    return {
      main: false,
      //是否有图片
      name_select: [{
        value: "Color",
        label: "Color"
      }],
      ruleForm: {
        productSkuVariations: []
      },
      rules: {
        name: [{
          required: true,
          message: "请选择或输入规则名称",
          trigger: "change"
        }]
      },
      skuList: [],
      dialogType: "add",
      expressPrice: "",
      codeType_list: [{
        value: "GTIN",
        label: "GTIN规则:14位，仅限数字"
      }, {
        value: "EAN",
        label: "EAN规则:8、13、14位，仅限数字"
      }, {
        value: "UPC",
        label: "UPC规则:12位，仅限数字"
      }, {
        value: "ISBN",
        label: "ISBN规则:13位，最后一位支持输入X（大写）"
      }]
    };
  },
  methods: {
    // 点击商品编码类型
    //input变化时执行
    add_input: function add_input() {
      this.$emit("input", this.skuList);
      console.info("this.skuList", this.skuList);
    },
    // 列表删除规则
    handleDeleteSku: function handleDeleteSku(row, index) {
      console.log("index", row, index);
      if (this.dialogType === "add") {
        this.skuList.splice(index, 1);
      } else {
        this.skuList.splice(index, 1);
        console.log("index", index);
      }
    },
    // 删除规则
    remove_productSkuVariations: function remove_productSkuVariations(big_index) {
      this.ruleForm.productSkuVariations.splice(big_index, 1);
    },
    // 添加规则
    add_productSkuVariations: function add_productSkuVariations() {
      var obj = {
        name: "",
        domains: [{
          value: ""
        }]
      };
      this.ruleForm.productSkuVariations.push(obj);
    },
    // 删除
    removeDomain: function removeDomain(big_index, item) {
      var index = this.ruleForm.productSkuVariations[big_index].domains.indexOf(item);
      if (index !== -1) {
        this.ruleForm.productSkuVariations[big_index].domains.splice(index, 1);
      }
    },
    // 添加
    addDomain: function addDomain(big_index) {
      this.ruleForm.productSkuVariations[big_index].domains.push({
        value: "",
        key: Date.now()
      });
    },
    //保存
    submitForm: function submitForm(ruleForm) {
      var _this = this;
      //   return;
      console.log("验证", this.ruleForm.productSkuVariations, this.$refs[ruleForm]);
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          if (_this.ruleForm.productSkuVariations.length == 1) {
            _this.productSkuVariations_length_1();
          } else if (_this.ruleForm.productSkuVariations.length == 2) {
            _this.productSkuVariations_length_2();
          } else if (_this.ruleForm.productSkuVariations.length == 3) {
            _this.productSkuVariations_length_3();
          }
          console.log("数据", _this.ruleForm.productSkuVariations);
        } else {
          _this.$message.error("规格名称、规则数值不能为空！");
          console.log("error submit!!");
          return false;
        }
      });
    },
    productSkuVariations_length_1: function productSkuVariations_length_1() {
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        var key1 = obj[0].name;
        var combination = {
          title: "",
          skuTitleType: 1,
          inv: 0,
          price: 0.0,
          productSkuVariations: [{
            name: key1,
            value: colorDomain.value,
            main: true
            //   imgUrl: this.main ? colorDomain.imgUrl : ""
          }]
        };

        combination[key1] = colorDomain.value;
        result.push(combination);
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    productSkuVariations_length_2: function productSkuVariations_length_2() {
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        obj[1].domains.forEach(function (sizeDomain) {
          var key1 = obj[0].name;
          var key2 = obj[1].name;
          var combination = {
            title: "",
            inv: 0,
            price: 0.0,
            skuTitleType: 1,
            productSkuVariations: [{
              name: key1,
              value: colorDomain.value,
              main: true
              // imgUrl: this.main ? colorDomain.imgUrl : ""
            }, {
              name: key2,
              value: sizeDomain.value,
              main: false
            }]
          };
          combination[key1] = colorDomain.value;
          combination[key2] = sizeDomain.value;
          result.push(combination);
        });
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    productSkuVariations_length_3: function productSkuVariations_length_3() {
      console.log("数据", this.ruleForm.productSkuVariations, this.skuList);
      var result = [];
      var obj = this.ruleForm.productSkuVariations;
      obj[0].domains.forEach(function (colorDomain) {
        obj[1].domains.forEach(function (sizeDomain) {
          obj[2].domains.forEach(function (bigDomain) {
            var key1 = obj[0].name;
            var key2 = obj[1].name;
            var key3 = obj[2].name;
            var combination = {
              title: "",
              inv: 0,
              price: 0.0,
              skuTitleType: 1,
              productSkuVariations: [{
                name: key1,
                value: colorDomain.value,
                main: true
                //   imgUrl: this.main ? colorDomain.imgUrl : ""
              }, {
                name: key2,
                value: sizeDomain.value,
                main: false
              }, {
                name: key3,
                value: bigDomain.value,
                main: false
              }]
            };
            combination[key1] = colorDomain.value;
            combination[key2] = sizeDomain.value;
            combination[key3] = bigDomain.value;
            result.push(combination);
          });
        });
      });
      if (this.dialogType != "edit") {
        this.skuList = result;
      } else {
        this.add_result(result);
      }
      this.skuList.splice();
      console.log("result", result, this.skuList);
    },
    add_result: function add_result(result) {
      var _this2 = this;
      this.skuList.forEach(function (item) {
        result.forEach(function (val, index) {
          if (_this2.arraysAreEqual(val.productSkuVariations, item.productSkuVariations)) {
            _this2.$set(result, index, item);
          } else {
            console.log("aaa", val);
          }
        });
      });
      this.skuList = result;
    },
    arraysAreEqual: function arraysAreEqual(arr1, arr2) {
      // 判断两个数组是否相同
      console.log("arr1", arr1, arr2);
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    }
  },
  created: function created() {
    var _this3 = this;
    // this.ruleForm.productSkuVariations
    this.dialogType = this.dialogType_sw;
    console.log("父数据", this.dialogType_sw, this.value);
    if (this.dialogType == "edit") {
      this.ruleForm.productSkuVariations = [];
      if (this.variations.length > 0) {
        this.variations.forEach(function (val) {
          //   if (val.url != "") {
          //     this.main = true;
          //   }
          var obj = {
            name: val.name,
            main: val.main,
            domains: []
          };
          val.values.forEach(function (item) {
            var obj_value = {
              value: item.value
              //   imgUrl: item.url
            };

            obj.domains.push(obj_value);
          });
          console.log("val", val);
          _this3.ruleForm.productSkuVariations.push(obj);
        });
        this.ruleForm.productSkuVariations = this.ruleForm.productSkuVariations.slice().sort(function (a, b) {
          return (b.main === true) - (a.main === true);
        });
      }
      if (this.value) {
        this.skuList = this.value;
        this.skuList.forEach(function (val) {
          val.productSkuVariations.forEach(function (item) {
            var name = item.name;
            val[name] = item.value;
          });
        });
      }
      console.log("最终数据", this.skuLis);
      console.log("SKU子数据", this.value, this.variations, this.ruleForm);
    }
  },
  directives: {
    "numeric-14": {
      bind: function bind(el, val) {
        console.log("input", el, val);
        el.handler = function (event) {
          var input = event.target;
          var max = val.value.maxval;
          // 限制输入长度为14位
          if (input.value.length > max) {
            input.value = input.value.slice(0, max);
            input.dispatchEvent(new Event("input"));
          }
          // 只允许输入数字
          var regex = /^[0-9]*$/;
          if (!regex.test(input.value)) {
            input.value = input.value.replace(/[^0-9]/g, "");
            input.dispatchEvent(new Event("input"));
          }
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function unbind(el) {
        el.removeEventListener("input", el.handler);
      }
    },
    "numeric-length": {
      bind: function bind(el) {
        el.handler = function (event) {
          var input = event.target;
          var value = input.value.trim();
          // 只允许输入数字
          var regex = /^[0-9]*$/;
          if (!regex.test(value)) {
            input.value = value.replace(/[^0-9]/g, "");
            input.dispatchEvent(new Event("input"));
          }
          // 检查输入值长度是否为8、13或14
          if (value.length !== 8 && value.length !== 13 && value.length !== 14) {
            input.value = value.slice(0, 14); // 截取前14位
            input.dispatchEvent(new Event("input"));
          }
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function unbind(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  }
};
exports.default = _default;