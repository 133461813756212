import _slicedToArray from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMemberList } from "@/api/member";
import { getShopList } from "@/api/product/product";
import { getVipMember, addVipMember } from "@/api/shopvip/shopvip.js";
import { getTypeList as _getTypeList } from "@/api/shopvip/shopvip.js";
export default {
  name: "VipMemberPage",
  data: function data() {
    return {
      tableKey: 0,
      selectUserTableKey: 0,
      list: [],
      listLoading: false,
      createMemberDialogVisible: false,
      createMemberForm: {
        uid: "",
        vipConfigId: ""
      },
      typeList: [],
      selectUserDialogVisible: false,
      selectUserForm: {
        phone: "",
        nickname: "",
        code: ""
      },
      selectUserList: [],
      selectUserLoading: false,
      envNameOptions: [],
      getShopList_loading: false
    };
  },
  mounted: function mounted() {
    this.getVipMemberList();
    this.getTypeList();
  },
  methods: {
    add_getShopList: function add_getShopList(val) {
      var _this = this;
      if (val != "") {
        this.getShopList_loading = true;
        getShopList(val).then(function (resp) {
          _this.envNameOptions = resp.data.list;
          _this.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }

      // console.log("输入了什么", val);
    },
    getVipMemberList: function getVipMemberList() {
      var _this2 = this;
      // 调用 getVipMember 获取会员列表数据
      getVipMember({
        page: 1,
        pageSize: 10
      }).then(function (response) {
        _this2.list = response.data.list;
      }).catch(function (error) {
        console.log(error);
      });
    },
    getTypeList: function getTypeList() {
      var _this3 = this;
      // 调用 getTypeList 获取会员配置列表数据
      _getTypeList().then(function (response) {
        _this3.typeList = response.data;
      }).catch(function (error) {
        console.log(error);
      });
    },
    formatDate: function formatDate(date) {
      // 格式化日期
      var _date = _slicedToArray(date, 3),
        year = _date[0],
        month = _date[1],
        day = _date[2];
      return "".concat(year, "-").concat(month, "-").concat(day);
    },
    handleCreateMember: function handleCreateMember() {
      this.createMemberDialogVisible = true;
    },
    handleSearchUser: function handleSearchUser() {
      var _this4 = this;
      this.selectUserLoading = true;
      getMemberList(this.selectUserForm).then(function (response) {
        _this4.selectUserList = response.data.list;
        _this4.selectUserLoading = false;
      }).catch(function () {
        _this4.selectUserLoading = false;
      });
    },
    handleSelectUser: function handleSelectUser(user) {
      this.createMemberForm.uid = user.uid;
      this.selectUserDialogVisible = false;
    },
    handleAddMember: function handleAddMember() {
      var _this5 = this;
      // 调用 addVipMember 方法新增会员
      addVipMember(this.createMemberForm).then(function () {
        _this5.createMemberDialogVisible = false;
        _this5.getVipMemberList();
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};