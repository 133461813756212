import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import _objectSpread from "/root/workspace/tk-admin_Z7th/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
export default {
  name: "lemonMessageGoods",
  inheritAttrs: false,
  inject: ["IMUI"],
  render: function render() {
    var h = arguments[0];
    return h("lemon-message-basic", _mergeJSXProps([{
      "class": "lemon-message-goods"
    }, {
      "props": _objectSpread({}, this.$attrs)
    }, {
      "scopedSlots": {
        content: function content(props) {
          var msg = JSON.parse(props.content);
          return h("div", {
            "class": " flex f-a-s bg-color-w"
          }, [h("img", {
            "class": "flex f-s-0 w-70 h-70 bg-img margin-r10",
            "attrs": {
              "src": msg.mainImg
            }
          }), h("div", {
            "class": "flex flex-1 f-c f-j-s h100"
          }, [h("div", {
            "class": "w-200"
          }, [h("div", {
            "class": "line2 f12-size t-color-6"
          }, [msg.title])]), h("div", {
            "class": "text-price f12-size t-color-9 margin-t6"
          }, [msg.price, ".00"])])]);
        }
      }
    }]));
  }
};