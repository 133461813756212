"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSpecialUseDesc = addSpecialUseDesc;
exports.deleteSpecialUseDesc = deleteSpecialUseDesc;
exports.getSpecialUseDescList = getSpecialUseDescList;
exports.getSpecialUseDescPage = getSpecialUseDescPage;
exports.updateSpecialUseDesc = updateSpecialUseDesc;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getSpecialUseDescPage(data) {
  return (0, _requestJson.default)({
    url: '/special/useDesc/getPage',
    method: 'get',
    params: data
  });
}
function getSpecialUseDescList(data) {
  return (0, _requestJson.default)({
    url: '/special/useDesc/getList',
    method: 'get',
    params: data
  });
}
function addSpecialUseDesc(data) {
  return (0, _requestJson.default)({
    url: '/special/useDesc/save',
    method: 'post',
    data: data
  });
}
function updateSpecialUseDesc(data) {
  return (0, _requestJson.default)({
    url: '/special/useDesc/updateById',
    method: 'post',
    data: data
  });
}
function deleteSpecialUseDesc(id) {
  return (0, _requestJson.default)({
    url: "/special/useDesc/deleteById/".concat(id),
    method: 'post'
  });
}