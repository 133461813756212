import _objectSpread from "D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSpecialAreaPage, addSpecialArea, updateSpecialArea, deleteSpecialArea } from "@/api/special/area";
import { parseTime } from "@/utils";
import Upload from '@/components/Upload/singleImage';
import Pagination from "@/components/Pagination/index.vue";
export default {
  name: "SpecialAreaPage",
  components: {
    Upload: Upload,
    Pagination: Pagination
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      dialogType: "add",
      form: {
        area: "",
        imgUrl: "",
        sort: 0
      },
      rules: {
        area: [{
          required: true,
          message: "请输入地区",
          trigger: "blur"
        }],
        imgUrl: [{
          required: true,
          message: "请输入图片URL",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }]
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  mounted: function mounted() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;
      this.listLoading = true;
      var _this$listQuery = this.listQuery,
        pageNum = _this$listQuery.pageNum,
        pageSize = _this$listQuery.pageSize;
      getSpecialAreaPage(pageNum, pageSize).then(function (response) {
        var _response$data = response.data,
          list = _response$data.list,
          total = _response$data.total;
        _this.list = list;
        _this.total = total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.dialogType = "add";
      this.dialogFormVisible = true;
      this.form = {
        area: "",
        imgUrl: "",
        sort: 0
      };
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogType = "edit";
      this.dialogFormVisible = true;
      this.form = _objectSpread({}, row);
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;
      this.$confirm("确认删除该地区吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        deleteSpecialArea(row.id).then(function () {
          _this2.$message.success("删除成功");
          _this2.fetchList();
        });
      }).catch(function () {
        // do nothing
      });
    },
    confirm: function confirm() {
      var _this3 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          if (_this3.dialogType === "add") {
            addSpecialArea(_this3.form).then(function () {
              _this3.$message.success("新增成功");
              _this3.dialogFormVisible = false;
              _this3.fetchList();
            });
          } else if (_this3.dialogType === "edit") {
            updateSpecialArea(_this3.form).then(function () {
              _this3.$message.success("修改成功");
              _this3.dialogFormVisible = false;
              _this3.fetchList();
            });
          }
        }
      });
    },
    parseTime: parseTime
  }
};