"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMaterial = getMaterial;
exports.updateMaterial = updateMaterial;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getMaterial(data) {
  return (0, _requestJson.default)({
    url: '/material/getOne',
    method: 'get',
    params: data
  });
}
function updateMaterial(data) {
  return (0, _requestJson.default)({
    url: '/material/updateById',
    method: 'post',
    data: data
  });
}