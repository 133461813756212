"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configGetList = configGetList;
exports.configUpdateById = configUpdateById;
exports.deleteConfig = deleteConfig;
exports.getConfigByNid = getConfigByNid;
exports.getConfigList = getConfigList;
exports.getModConfigList = getModConfigList;
exports.updateConfig = updateConfig;
exports.updateModConfig = updateModConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getConfigList(data) {
  return (0, _request.default)({
    url: '/system/config/getList',
    method: 'get',
    params: data
  });
}
function updateConfig(data) {
  return (0, _request.default)({
    url: 'system/config/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteConfig(data) {
  return (0, _request.default)({
    url: 'system/config/delete',
    method: 'post',
    params: {
      nids: data
    }
  });
}
function getConfigByNid(data) {
  return (0, _request.default)({
    url: '/system/config/getByNid',
    method: 'get',
    params: data
  });
}
function getModConfigList(data) {
  return (0, _request.default)({
    url: '/system/modConfig/getList',
    method: 'get',
    params: data
  });
}
function updateModConfig(data) {
  return (0, _request.default)({
    url: 'system/modConfig/editSubmit',
    method: 'post',
    data: data
  });
}
// 新增
function configGetList(data) {
  return (0, _request.default)({
    url: '/config/getList',
    method: 'get',
    params: data
  });
}
function configUpdateById(data) {
  return (0, _request.default)({
    url: 'config/updateById',
    method: 'post',
    data: data
  });
}