import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadSKU from "@/components/Upload/singleImageUsSKU.vue";
export default {
  props: ["value", "variations"],
  components: {
    UploadSKU: UploadSKU
  },
  data: function data() {
    return {
      expressPrice: "",
      skuList: [],
      productSkuVariations: []
    };
  },
  methods: {
    add_input: function add_input() {}
  },
  created: function created() {
    var _this = this;
    if (this.variations.length > 0) {
      this.productSkuVariations = [];
      this.variations.forEach(function (val) {
        var obj = {
          name: val.name,
          main: val.main
        };
        _this.productSkuVariations.push(obj);
      });
      this.productSkuVariations = this.productSkuVariations.slice().sort(function (a, b) {
        return (b.main === true) - (a.main === true);
      });
    }
    if (this.value) {
      this.skuList = this.value;
      this.skuList.forEach(function (val) {
        if (val.productSkuVariations) {
          val.productSkuVariations.forEach(function (item) {
            var name = item.name;
            val[name] = item.value;
          });
        }
      });
    }
    console.log("数据", this.skuList, this.value, this.variations);
  }
};