var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 10px 10px 10px" } },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.config,
            "label-width": "140px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { column: 1, size: "small", border: "" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          网站名称\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.web_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "web_name", $$v)
                      },
                      expression: "config.web_name",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          网站LOGO\n        "),
                  ]),
                  _vm._v(" "),
                  _c("Upload", {
                    model: {
                      value: _vm.config.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "logo", $$v)
                      },
                      expression: "config.logo",
                    },
                  }),
                  _vm._v(" "),
                  _c("ImageSource", {
                    attrs: { mul: false },
                    model: {
                      value: _vm.config.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "logo", $$v)
                      },
                      expression: "config.logo",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          默认头像\n        "),
                  ]),
                  _vm._v(" "),
                  _c("Upload", {
                    model: {
                      value: _vm.config.default_head_img,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "default_head_img", $$v)
                      },
                      expression: "config.default_head_img",
                    },
                  }),
                  _vm._v(" "),
                  _c("ImageSource", {
                    attrs: { mul: false },
                    model: {
                      value: _vm.config.default_head_img,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "default_head_img", $$v)
                      },
                      expression: "config.default_head_img",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          客服电话\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.kefu_tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "kefu_tel", $$v)
                      },
                      expression: "config.kefu_tel",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          主站备案号\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.config.icp_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "icp_no", $$v)
                      },
                      expression: "config.icp_no",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          高强度网站维护\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": "1", "inactive-value": "0" },
                    model: {
                      value: _vm.config.weihu_no_login,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "weihu_no_login", $$v)
                      },
                      expression: "config.weihu_no_login",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*开启后, 会员只能进行登录, 无法进行其他操作"),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          低强度网站维护\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": "1", "inactive-value": "0" },
                    model: {
                      value: _vm.config.weihu_no_take_task,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "weihu_no_take_task", $$v)
                      },
                      expression: "config.weihu_no_take_task",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("*开启后, 会员能进行登录和提现, 无法进行其他操作"),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          上架审核显示\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": "1", "inactive-value": "0" },
                    model: {
                      value: _vm.config.caner_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "caner_status", $$v)
                      },
                      expression: "config.caner_status",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          逛逛是否开启\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": "1", "inactive-value": "0" },
                    model: {
                      value: _vm.config.guangguang_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "guangguang_status", $$v)
                      },
                      expression: "config.guangguang_status",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          国际语言\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.config.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.config, "language", $$v)
                        },
                        expression: "config.language",
                      },
                    },
                    _vm._l(_vm.languageOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.text, value: item.key },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v(
                      "*修改语言设置仅能修改用户小程序，H5，App端的语言，后台和商家后台仅支持中文"
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          操作\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("保存")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }