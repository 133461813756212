"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBusiness = addBusiness;
exports.deleteBusiness = deleteBusiness;
exports.getBusinessFormList = getBusinessFormList;
exports.getBusinessPage = getBusinessPage;
exports.updateBusiness = updateBusiness;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getBusinessPage(data) {
  return (0, _requestJson.default)({
    url: '/business/getPage',
    method: 'get',
    params: data
  });
}
function updateBusiness(data) {
  return (0, _requestJson.default)({
    url: '/business/updateById',
    method: 'post',
    data: data
  });
}
function addBusiness(data) {
  return (0, _requestJson.default)({
    url: '/business/save',
    method: 'post',
    data: data
  });
}
function deleteBusiness(id) {
  return (0, _requestJson.default)({
    url: "/business/deleteById/".concat(id),
    method: 'post'
  });
}
function getBusinessFormList(data) {
  return (0, _requestJson.default)({
    url: '/business/type/form/getList',
    method: 'get',
    params: data
  });
}