"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/product/product");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _ship = require("@/api/ship/ship");
var _axios = _interopRequireDefault(require("axios"));
var _warehouse = require("@/api/warehouse/warehouse");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ShipList",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      title_dialogType: "创建订单",
      smallsave_sw: false,
      productForm: {
        packingId: '',
        skuList: []
      },
      showStatusDialog: false,
      // 控制修改推送状态对话框的显示
      orderStatus: "",
      // 用于绑定下拉框选中值
      selectedOrderId: "",
      // 用于存储当前选中的产品ID
      syncLogDialogVisible: false,
      syncLogList: [],
      logTotal: 0,
      supplierOptions: [],
      logQuery: {
        page: 1,
        pageSize: 10
      },
      cancelDialogVisible: false,
      selectedOrderToCancel: null,
      detailDialogVisible: false,
      currentDetail: {},
      warehoussingDialogVisible: false,
      warehoussingDetail: {},
      warehoussingdetailLoading: false,
      detailLoading: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        warehouseId: "",
        status: "",
        startTime: "",
        endTime: "",
        phone: "",
        title: "",
        orderNo: "",
        shopId: ""
      },
      form: {},
      dialogFormVisible: false,
      dialogType: "",
      typeOptions: [],
      isClear: false,
      timePickerDialogVisible: false,
      // 控制时间选择器弹框的显示与隐藏
      timeRangeForm: {
        startTime: "",
        // 选择的开始时间
        endTime: "" // 选择的结束时间
      },

      manualShipDialogVisible: false,
      manualShipForm: {
        shipNo: "",
        warehouseId: "",
        carrier: "",
        carrierServiceName: ""
      },
      warehouseOptions: [],
      statusOptions: [{
        status: 0,
        name: '已发'
      }, {
        status: 1,
        name: '已签收'
      }, {
        status: 2,
        name: '已入库'
      }, {
        status: 3,
        name: '部分入库'
      }],
      skuListOptions: [],
      envNameOptions: [],
      editAddressDialogVisible: false,
      // 编辑地址对话框的显示与隐藏
      editAddressForm: {
        recipient: "",
        phone: "",
        city: "",
        state: "",
        detailAddress: "",
        zipcode: "",
        additionalAddressInformation: ""
      },
      selectedAddressOrder: null,
      // 存储选中的订单对象
      selectedOrder: null,
      // 用于存储选中的订单对象
      updateStatusForm: {
        orderId: "",
        status: ""
      },
      addmoneydialogVisible: false,
      selectedOrders: null,
      showSyncShipNoDialog: false,
      headers: {},
      token: "",
      action: "https://www.qixunetwork.com/cjdniwo/small/uploadCsv",
      uploadPackingExcel: process.env.VUE_APP_BASE_API + 'ws/packing/order/uploadPackingExcel',
      zcUploadPackingExcel: process.env.VUE_APP_BASE_API + 'ws/packing/order/uploadExcel',
      loading_01: false,
      handleSyncShipNo_loading: false,
      getShopList_loading: false,
      small_rules: {
        packingId: [{
          required: true,
          message: "请输入订单号",
          trigger: "blur"
        },
        // { min: 6, message: "密码不能少于6位", trigger: "blur" },
        // {
        //   min: 8,
        //   message: "订单号长度最少8个字符",
        //   trigger: "blur"
        // },
        {
          type: "number",
          message: "订单号必须为数字",
          trigger: "blur",
          transform: function transform(value) {
            if (value !== null && value !== "") {
              if (String(value).trim() === "" || Number(value) < 100000 || Number(value) > 999999999999999999999999999999) {
                return false;
              } else {
                return Number(value);
              }
            } else {
              return null;
            }
          }
        }],
        num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        weight: [{
          required: true,
          message: "请输入重量",
          trigger: "blur"
        }],
        warehouseId: [{
          required: true,
          message: "请选择仓库",
          trigger: "change"
        }]
      },
      sku_title: "",
      btn_loading: false,
      tabskulist: [],
      shipSkuList: [],
      id_quantity: [],
      location_sw: false,
      zc_location_sw: false,
      zc_warehouseId: '',
      location: "",
      warehoussing_location_sw: false,
      warehoussing_location: "",
      list_id: "",
      multipleSelection: [],
      multipleWarehoussingSelection: []
    };
  },
  created: function created() {
    if (this.$route.query.status) {
      this.listQuery.status = this.$route.query.status;
      // console.log("值", this.listQuery.status);
    }

    this.getList();
    this.loadWarehouseOptions();
    this.loadSkuOptions();
    this.getSupplierdata();
    // this.getShopList();
    this.token = _store.default.getters.token;
    this.headers = {
      Authorization: 'Bearer ' + (0, _auth.getToken)()
    };
    // console.log("store", store.getters.token);
  },

  methods: {
    add_edit: function add_edit(val) {
      this.productForm = val;
      this.shipSkuList = [];
      this.tabskulist = val.skuList;
      this.title_dialogType = "编辑订单";
      this.list_id = val.id;
      this.smallsave_sw = true;
    },
    add_location: function add_location(val) {
      this.list_id = val.id;
      this.location = "";
      this.location_sw = true;
    },
    labelDown: function labelDown(orderId) {
      var myObj = {
        method: 'post',
        url: 'ws/packing/order/handExport?id=' + orderId,
        fileName: 'packingExport'
      };
      this.exportPdfMethod(myObj);
    },
    delPackingOrder: function delPackingOrder(id) {
      var _this = this;
      (0, _warehouse.del)(id).then(function (response) {
        if (response.code === 200) {
          _this.getList();
          _this.$message({
            message: "删除成功",
            type: "success"
          });
        } else {
          _this.$message.error(response.data.reason);
        }
      });
    },
    exportPdfMethod: function exportPdfMethod(data) {
      var _this2 = this;
      (0, _axios.default)({
        method: data.method,
        url: process.env.VUE_APP_BASE_API + "".concat(data.url).concat(data.params ? '?' + data.params : ''),
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + (0, _auth.getToken)()
        }
      }).then(function (res) {
        var link = document.createElement('a');
        var blob = new Blob([res.data], {
          type: 'application/pdf;chartset=UTF-8'
        });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; // 下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this2.btn_loading = false;
        _this2.$message({
          message: '导出完成',
          type: 'success'
        });
        _this2.getList();
      }).catch(function (error) {
        _this2.$message({
          message: error,
          type: 'error'
        });
        _this2.btn_loading = false;
      });
    },
    add_location_sw: function add_location_sw() {
      var _this3 = this;
      if (this.location == "") {
        this.$message.error("请输入库位");
      } else {
        this.btn_loading = true;
        (0, _warehouse.packingorderwarehousing)(this.list_id, this.location).then(function (response) {
          _this3.getList();
          _this3.btn_loading = false;
          if (response.code === 200) {
            _this3.location_sw = false;
            _this3.$message({
              message: "入库成功",
              type: "success"
            });
          } else {
            _this3.$message.error(response.data.reason);
          }
          _this3.location_sw = false;
          _this3.btn_loading = false;
          console.log("保存成功吗", response);
        }).catch(function (error) {
          _this3.btn_loading = false;
          // this.smallsave_sw = false;
          // this.$message.error("失败");
        });
      }
    },
    add_confir_warehoussing_sw: function add_confir_warehoussing_sw() {
      this.warehoussing_location_sw = true;
    },
    add_location_warehoussing_sw: function add_location_warehoussing_sw() {
      var _this4 = this;
      if (this.warehoussing_location == "") {
        this.$message.error("请输入库位");
      } else {
        this.btn_loading = true;
        var selectedOrders = this.multipleWarehoussingSelection;
        if (!selectedOrders || selectedOrders.length <= 0) {
          this.$message({
            message: "请至少选择一条数据",
            type: "warning",
            duration: 2000
          });
          return;
        }
        var skuIds = selectedOrders.map(function (order) {
          return {
            id: order.id,
            skuRemark: order.skuRemarks
          };
        }); // 提取选中订单的ID数组\
        var par = {
          location: this.warehoussing_location,
          skuReqs: skuIds
        };
        (0, _warehouse.skuWarehousing)(par).then(function (response) {
          _this4.getList();
          _this4.btn_loading = false;
          if (response.code === 200) {
            _this4.warehoussing_location_sw = false;
            _this4.warehoussingDialogVisible = false;
            _this4.getList();
            _this4.$message({
              message: "入库成功",
              type: "success"
            });
          } else {
            _this4.$message.error(response.data.reason);
          }
        }).catch(function (error) {
          _this4.btn_loading = false;
          // this.smallsave_sw = false;
          // this.$message.error("失败");
        });
      }
    },
    handleConfirm: function handleConfirm(ruleForm) {
      var _this5 = this;
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          _this5.btn_loading = true;
          var url = "";
          console.info(_this5.title_dialogType);
          if (_this5.title_dialogType == "编辑订单") {
            url = _warehouse.packingUpdateOrder;
          } else {
            url = _warehouse.packingCreateOrder;
          }
          url(_this5.productForm).then(function (response) {
            _this5.getList();
            _this5.btn_loading = false;
            if (response.code === 200) {
              _this5.smallsave_sw = false;
              _this5.$message({
                message: _this5.title_dialogType == "编辑订单" ? "编辑成功" : "新增成功",
                type: "success"
              });
            } else {
              _this5.$message.error(response.data.reason);
            }
            console.log("保存成功吗", response);
          }).catch(function (error) {
            _this5.btn_loading = false;
            // this.smallsave_sw = false;
            // this.$message.error("失败");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleWarehoussingSelectionChange: function handleWarehoussingSelectionChange(val) {
      this.multipleWarehoussingSelection = val;
    },
    handleBatchExport: function handleBatchExport() {
      var selectedOrders = this.multipleSelection;
      if (!selectedOrders || selectedOrders.length <= 0) {
        this.$message({
          message: "请至少选择一条数据",
          type: "warning",
          duration: 2000
        });
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.id;
      }); // 提取选中订单的ID数组
      localStorage.setItem("selectedOrderIds", JSON.stringify(selectedOrders));
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      var params = {
        ids: orderIdsString
      };
      var myObj = {
        method: 'post',
        url: 'ws/packing/order/handExportExcel',
        fileName: 'exportPacking',
        data: params
      };
      this.exportMethodPost(myObj);
    },
    exportMethodPost: function exportMethodPost(data) {
      var _this6 = this;
      (0, _axios.default)({
        method: data.method,
        url: process.env.VUE_APP_BASE_API + data.url,
        data: data.data,
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + (0, _auth.getToken)()
        }
      }).then(function (res) {
        var link = document.createElement('a');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; // 下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this6.exportMethodPostLoading = false;
      }).catch(function (error) {
        alert('该时间段没有导出的数据');
        _this6.exportMethodPostLoading = false;
      });
    },
    //订单状态操作
    handleBatchSigin: function handleBatchSigin() {
      var _this7 = this;
      var selectedOrders = this.multipleSelection;
      if (!selectedOrders || selectedOrders.length <= 0) {
        this.$message({
          message: "请至少选择一条数据",
          type: "warning",
          duration: 2000
        });
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.id;
      }); // 提取选中订单的ID数组
      localStorage.setItem("selectedOrderIds", JSON.stringify(selectedOrders));
      var orderIdsString = selectedOrderIds.join(","); // 将订单ID用逗号拼接成字符串
      (0, _warehouse.packingSign)(orderIdsString).then(function () {
        _this7.getList(); // 刷新列表，你可能需要根据你的代码结构修改这部分
        _this7.$message.success("签收成功");
      }).catch(function (error) {
        console.error(error);
        _this7.$message.error("状态修改失败");
      });
      console.info(orderIdsString);
    },
    // 删除
    delete_list: function delete_list(val, index) {
      var _this8 = this;
      console.log("数据", val, index);
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this8.id_quantity.splice(index, 1);
        _this8.tabskulist.splice(index, 1);
        _this8.productForm.skuList.splice(index, 1);
        _this8.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this8.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    // 选择skuId
    add_skuId: function add_skuId(val) {
      if (val.num != "") {
        var obj = {
          sku: val.sku,
          saleSku: val.saleSku,
          wsSkuId: val.wsSkuId,
          num: val.num,
          skuRemarks: val.skuRemarks
        };
        var box = parseInt(val.num) % parseInt(val.qty) === 0 ? parseInt(val.num) / parseInt(val.qty) : parseInt(val.num) / parseInt(val.qty) + 1;
        var obj_1 = {
          sku: val.sku,
          declarationName: val.sku + '\n' + val.cnTitle,
          saleSku: val.saleSku,
          wsSkuId: val.wsSkuId,
          cnTitle: val.cnTitle,
          num: val.num,
          skuRemarks: val.skuRemarks,
          boxRemarks: Math.floor(box) + ' boxes'
        };
        // this.tabskulist.push(obj_1);
        this.productForm.skuList.push(obj_1);
        this.$message({
          type: 'success',
          message: '添加成功'
        });
        console.info('this.productForm.skuId', val, this.productForm);
      } else {
        this.$message.error('请输入发货SKU数量');
      }
    },
    //商品列表
    commodity_url: function commodity_url() {
      var _this9 = this;
      var obj = {
        sku: this.sku_title
      };
      console.info(obj);
      (0, _warehouse.getPackingSkuList)(obj).then(function (res) {
        console.info(res);
        _this9.shipSkuList = res.data;
        console.log("res", res);
      });
    },
    getSupplierdata: function getSupplierdata() {
      var _this10 = this;
      (0, _warehouse.getSupplier)().then(function (res) {
        if (res.code === 200) {
          _this10.supplierOptions = res.data;
        } else {
          _elementUi.Message.error(res.message);
        }
      });
    },
    // 点击复制
    copyname: function copyname(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      console.log("类型", file);
      var isJPG = file.type === "csv";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    generateOrderId: function generateOrderId() {
      var now = new Date();
      var orderId = now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, 0) + now.getDate().toString().padStart(2, 0) + now.getHours().toString().padStart(2, 0) + now.getMinutes().toString().padStart(2, 0) + now.getSeconds().toString().padStart(2, 0) + now.getMilliseconds().toString().padStart(3, 0);
      console.info(orderId);
      this.productForm.packingId = orderId;
    },
    downloadFun: function downloadFun(data) {
      window.location.href = process.env.VUE_APP_BASE_API + 'ws/downloadTemplate?fileName=' + data;
    },
    zcUploadPacking: function zcUploadPacking() {
      this.zc_warehouseId = '';
      this.zc_location_sw = true;
    },
    // 导入
    importExcel: function importExcel(val) {
      console.log("导入", val.message);
    },
    upload_success: function upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    zc_upload_success: function zc_upload_success(val) {
      console.info("成功的数据", val.message);
      if (val.code === 200) {
        this.loading_01 = false;
        this.getList();
        this.$message({
          message: "导入成功",
          type: "success"
        });
        this.zc_location_sw = false;
      } else {
        this.$message.error("导入失败:" + val.message);
      }
      this.loading_01 = false;
      console.log("上传成功回调", val);
    },
    // 上传失败回调
    upload_error: function upload_error(val) {
      this.$notify({
        title: "导入失败",
        message: "数据导入失败" + val.message,
        type: "error",
        duration: 2000
      });
      console.log("上传失败回调", val.message);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      this.loading_01 = true;
    },
    getShopList: function getShopList() {
      var _this11 = this;
      (0, _product.getShopList)().then(function (resp) {
        _this11.envNameOptions = resp.data;
      });
    },
    add_getShopList: function add_getShopList(val) {
      var _this12 = this;
      if (val != "") {
        this.getShopList_loading = true;
        (0, _product.getShopList)(val).then(function (resp) {
          _this12.envNameOptions = resp.data.list;
          _this12.getShopList_loading = false;
          // console.log("搜索的值", resp);
        });
      }

      // console.log("输入了什么", val);
    },
    // 点击创建订单
    add_smallsave_sw: function add_smallsave_sw() {
      this.title_dialogType = "创建订单";
      this.productForm = {
        packingId: '',
        skuList: []
      };
      this.tabskulist = [];
      this.shipSkuList = [];
      this.smallsave_sw = true;
    },
    handleEditAddress: function handleEditAddress(row) {
      this.selectedAddressOrder = row; // 存储选中的订单对象
      // 将选中订单的地址信息赋值给编辑表单
      this.editAddressForm = {
        recipient: row.recipient,
        phone: row.phone,
        city: row.city,
        state: row.state,
        detailAddress: row.detailAddress,
        zipcode: row.zipcode,
        additionalAddressInformation: row.additionalAddressInformation
      };
      this.editAddressDialogVisible = true; // 打开编辑地址对话框
    },
    loadWarehouseOptions: function loadWarehouseOptions() {
      var _this13 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.getPackingWarehouseList)().then(function (response) {
        if (response.code === 200) {
          _this13.warehouseOptions = response.data;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    loadSkuOptions: function loadSkuOptions() {
      var _this14 = this;
      // 调用 warehouseGetList 方法获取仓库列表
      (0, _warehouse.getPackingSkuList)().then(function (response) {
        if (response.code === 200) {
          _this14.skuListOptions = response.data;
        } else {
          _elementUi.Message.error(response.message);
        }
      });
    },
    // handleExceptionSearch() {
    //   this.listQuery.page = 1; // 查询时重置页码
    //   this.listQuery.type = 1;
    //   this.getList();
    // },
    handleSearch: function handleSearch() {
      this.listQuery.page = 1; // 查询时重置页码
      this.getList();
    },
    // 新添加的方法用于获取状态描述
    getStatusDescription: function getStatusDescription(row) {
      switch (row.status) {
        case 0:
          return "已发";
        case 1:
          return "已签收";
        case 2:
          return "已入库";
        case 3:
          return "部分入库";
        default:
          return "未知状态";
      }
    },
    // 新添加的方法用于获取状态描述
    getPackingType: function getPackingType(row) {
      if (row === 0) {
        return "包裹";
      } else if (row === 1) {
        return "货柜";
      } else if (row === 3) {
        return "托盘";
      } else {
        return "未知类型";
      }
    },
    // 新添加的方法用于获取状态描述
    getSkuNum: function getSkuNum(row) {
      var num = 0;
      if (row) {
        row.forEach(function (val) {
          num += val.num;
        });
      }
      return num;
    },
    rowKey: function rowKey(row) {
      return row.id;
    },
    handleShowDetail: function handleShowDetail(row) {
      this.currentDetail = row;
      this.detailLoading = true;
      this.detailDialogVisible = true;
      this.detailLoading = false;
    },
    handleWarehoussingShowDetail: function handleWarehoussingShowDetail(row) {
      this.warehoussingDetail = row;
      this.warehoussingdetailLoading = true;
      this.warehoussingDialogVisible = true;
      this.warehoussingdetailLoading = false;
      this.multipleWarehoussingSelection = [];
    },
    handleDelete: function handleDelete() {
      var _this15 = this;
      var selectedOrders = this.$refs["orderTable"].selection; // 获取选中的订单对象数组
      if (selectedOrders.length === 0) {
        this.$message.warning("请选择要删除的订单");
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.orderNo;
      }); // 提取选中订单的ID数组
      // 调用 API 删除选中的订单
      deleteById(selectedOrderIds).then(function () {
        _this15.$refs["orderTable"].clearSelection();
        _this15.getList(); // 成功删除后刷新列表
        _this15.$message.success("成功删除订单");
      }).catch(function (error) {
        console.error("删除订单失败", error);
        _this15.$message.error("删除订单失败");
      });
    },
    getList: function getList() {
      var _this16 = this;
      this.listLoading = true;
      console.info(this.listQuery);
      (0, _warehouse.getPackingGetPage)(this.listQuery).then(function (response) {
        _this16.list = response.data.list;
        _this16.total = response.data.total;
        _this16.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      var _this17 = this;
      this.dialogStatus = "add";
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this17.$refs["dataForm"].clearValidate();
      });
    }
  }
};
exports.default = _default;