var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            size: "mini",
                            "label-width": "110px",
                            "label-position": "right",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "装箱单号：" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  placeholder: "请输入装箱单号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.packingId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "packingId", $$v)
                                  },
                                  expression: "listQuery.packingId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "请选择状态",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  on: { change: _vm.handleSearch },
                                  model: {
                                    value: _vm.listQuery.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "status", $$v)
                                    },
                                    expression: "listQuery.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.status,
                                    attrs: {
                                      value: item.status,
                                      label: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "请选择仓库",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  on: { change: _vm.handleSearch },
                                  model: {
                                    value: _vm.listQuery.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.warehouseId",
                                  },
                                },
                                _vm._l(
                                  _vm.warehouseOptions,
                                  function (warehouse) {
                                    return _c("el-option", {
                                      key: warehouse.id,
                                      attrs: {
                                        value: warehouse.id,
                                        label: warehouse.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "供应商/货商：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.adminId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "adminId", $$v)
                                    },
                                    expression: "listQuery.adminId",
                                  },
                                },
                                _vm._l(_vm.supplierOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "filter-item",
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.add_smallsave_sw },
                                },
                                [
                                  _vm._v(
                                    "\n              创建订单\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "contentT",
          fn: function () {
            return [
              _c(
                "space",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleBatchSigin },
                    },
                    [_vm._v("批量签收")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadPackingExcel,
                        "show-file-list": false,
                        "auto-upload": true,
                        "on-change": _vm.importExcel,
                        "on-success": _vm.upload_success,
                        "on-error": _vm.upload_error,
                        headers: _vm.headers,
                        "before-upload": _vm.handleBeforeUpload,
                        type: "file",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-upload",
                            loading: _vm.loading_01,
                          },
                        },
                        [_vm._v("批量导入\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-download",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFun(
                            "wsBatchPackingTemplate_01.xlsx"
                          )
                        },
                      },
                    },
                    [_vm._v("下载模板\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-download",
                      },
                      on: { click: _vm.zcUploadPacking },
                    },
                    [_vm._v("ZC批量导入\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-download",
                      },
                      on: { click: _vm.handleBatchExport },
                    },
                    [_vm._v("批量导出装箱单\n      ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            height: "85%",
            "row-key": _vm.rowKey,
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              width: "45",
              fixed: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "60",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "装箱单号", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(scope.row.packingId)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "仓库", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.warehouseName) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "装箱类型", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(
                        _vm._s(_vm.getPackingType(scope.row.packingType)) +
                          " *\n            " +
                          _vm._s(scope.row.num)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.weight) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "sku信息", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(
                        "预报数量：" + _vm._s(_vm.getSkuNum(scope.row.skuList))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "时间",
              align: "center",
              width: "180",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.addTime
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "创:" +
                                _vm._s(
                                  _vm._f("parseTime")(
                                    scope.row.addTime,
                                    "{y}-{m}-{d} {h}:{i}:{s}"
                                  )
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.signTime
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "签:" +
                                _vm._s(
                                  _vm._f("parseTime")(
                                    scope.row.signTime,
                                    "{y}-{m}-{d} {h}:{i}:{s}"
                                  )
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.warehousingTime
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "入:" +
                                _vm._s(
                                  _vm._f("parseTime")(
                                    scope.row.warehousingTime,
                                    "{y}-{m}-{d} {h}:{i}:{s}"
                                  )
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "相关人员",
              align: "center",
              width: "180",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.addByName
                      ? _c("div", [
                          _c("span", [
                            _vm._v("创:" + _vm._s(scope.row.addByName)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.signByName
                      ? _c("div", [
                          _c("span", [
                            _vm._v("签:" + _vm._s(scope.row.signByName)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.warehouseName
                      ? _c("div", [
                          _c("span", [
                            _vm._v("入:" + _vm._s(scope.row.warehouseName)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              align: "center",
              "min-width": "70",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(_vm.getStatusDescription(scope.row))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-tickets",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("SKU明细")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.add_edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.add_location(scope.row)
                              },
                            },
                          },
                          [_vm._v("整箱入库")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleWarehoussingShowDetail(
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("部分入库")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.labelDown(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("导出PDF\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.delPackingOrder(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.currentDetail.skuList, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "#",
                  align: "center",
                  prop: "id",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.id))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku",
                  align: "center",
                  prop: "sku",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.sku)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku自编码",
                  align: "center",
                  prop: "selfCode",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.selfCode)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "申报名称",
                  align: "center",
                  prop: "declarationName",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.variation)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.declarationName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", prop: "num" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.num)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱数 备注",
                  align: "center",
                  prop: "boxRemarks",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.boxRemarks)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku 备注",
                  align: "center",
                  prop: "skuRemarks",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.skuRemarks)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部分入库明细",
            visible: _vm.warehoussingDialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.warehoussingDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.warehoussingdetailLoading,
                  expression: "warehoussingdetailLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.warehoussingDetail.skuList,
                border: "",
                fit: "",
              },
              on: { "selection-change": _vm.handleWarehoussingSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "45",
                  fixed: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "#",
                  align: "center",
                  prop: "id",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.id))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku",
                  align: "center",
                  prop: "sku",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.sku)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku自编码",
                  align: "center",
                  prop: "selfCode",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.selfCode)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "申报名称",
                  align: "center",
                  prop: "declarationName",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.variation)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.declarationName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", prop: "num" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.num)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱数 备注",
                  align: "center",
                  prop: "boxRemarks",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.boxRemarks)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "入库状态",
                  align: "center",
                  prop: "boxRemarks",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(
                            _vm._s(
                              scope.row.status == 0
                                ? "未入库"
                                : scope.row.status == 1
                                ? "已入库"
                                : "未知状态"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "sku 备注",
                  align: "center",
                  prop: "skuRemarks",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入商家SKU" },
                          model: {
                            value: scope.row.skuRemarks,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "skuRemarks", $$v)
                            },
                            expression: "scope.row.skuRemarks",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: { click: _vm.add_confir_warehoussing_sw },
                },
                [_vm._v("确定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.smallsave_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title_dialogType,
                visible: _vm.smallsave_sw,
                "close-on-click-modal": false,
                width: "95%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.smallsave_sw = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sync-dialog__div" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "productForm",
                      attrs: {
                        inline: true,
                        model: _vm.productForm,
                        rules: _vm.small_rules,
                        "label-width": "100px",
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "装箱单号" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "338px" },
                            attrs: { placeholder: "请输入订单号" },
                            model: {
                              value: _vm.productForm.packingId,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "packingId", $$v)
                              },
                              expression: "productForm.packingId",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              订单号长度最少8个字符，最多18个字符，由数字组成，不允许带字母或特殊字符\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question icon_question",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#606266",
                                  "line-height": "17px",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.generateOrderId()
                                },
                              },
                            },
                            [_vm._v("生成订单号\n          ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            prop: "warehouseId",
                            label: "仓库",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "450px" },
                              attrs: { placeholder: "请选择仓库" },
                              model: {
                                value: _vm.productForm.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productForm, "warehouseId", $$v)
                                },
                                expression: "productForm.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$checkPermission([
                                "warehousePackingSup",
                              ]),
                              expression:
                                "$checkPermission(['warehousePackingSup'])",
                            },
                          ],
                          attrs: { label: "供货商：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择", clearable: true },
                              model: {
                                value: _vm.productForm.adminId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productForm, "adminId", $$v)
                                },
                                expression: "productForm.adminId",
                              },
                            },
                            _vm._l(_vm.supplierOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "运输公司" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入运输公司" },
                            model: {
                              value: _vm.productForm.transportCompany,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "transportCompany",
                                  $$v
                                )
                              },
                              expression: "productForm.transportCompany",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "航班(海运)号" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入航班(海运)号" },
                            model: {
                              value: _vm.productForm.shipNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "shipNo", $$v)
                              },
                              expression: "productForm.shipNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "重量(LB)", prop: "weight" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入重量(LB)" },
                            model: {
                              value: _vm.productForm.weight,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "weight", $$v)
                              },
                              expression: "productForm.weight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "装箱单类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "450px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.productForm.packingType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.productForm, "packingType", $$v)
                                },
                                expression: "productForm.packingType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "包裹", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "货柜", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "托盘", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "数量", prop: "num" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入数量" },
                            model: {
                              value: _vm.productForm.num,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "num", $$v)
                              },
                              expression: "productForm.num",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预计到货日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "450px" },
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.productForm.expectedArrivalTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "expectedArrivalTime",
                                  $$v
                                )
                              },
                              expression: "productForm.expectedArrivalTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "提货代码" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入提货代码" },
                            model: {
                              value: _vm.productForm.pickupCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "pickupCode", $$v)
                              },
                              expression: "productForm.pickupCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "提货地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入提货地址" },
                            model: {
                              value: _vm.productForm.pickupAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "pickupAddress", $$v)
                              },
                              expression: "productForm.pickupAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "450px" },
                            attrs: { placeholder: "请输入备注" },
                            model: {
                              value: _vm.productForm.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "remarks", $$v)
                              },
                              expression: "productForm.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "flex-wrap": "wrap",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "SKU", required: "" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "450px" },
                                attrs: { placeholder: "搜索SKU" },
                                on: { input: _vm.commodity_url },
                                model: {
                                  value: _vm.sku_title,
                                  callback: function ($$v) {
                                    _vm.sku_title = $$v
                                  },
                                  expression: "sku_title",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                请输入SKU搜索添加SKU\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-question icon_question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#606266",
                                      "line-height": "17px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "form_tab",
                          staticStyle: { width: "100%" },
                          attrs: { label: " " },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: {
                                data: _vm.productForm.skuList,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU信息",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.sku) +
                                                "  " +
                                                _vm._s(scope.row.selfCode)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1128593783
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "申报名称",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.declarationName)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  72942753
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  prop: "id",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.num)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2460652848
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "箱数 备注",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.boxRemarks)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  972145634
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU 备注",
                                  prop: "id",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.skuRemarks)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3414144698
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                size: "medium",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delete_list(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  删除\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1294575019
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: {
                                data: _vm.shipSkuList,
                                "row-key": "id",
                                border: "",
                                "max-height": "450",
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "SKU信息",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.sku) +
                                                "  " +
                                                _vm._s(scope.row.selfCode)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1128593783
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "申报名称",
                                  prop: "productIdentifierCode",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.declarationName)
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  72942753
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发货SKU数量",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: scope.row.num,
                                                expression: "scope.row.num",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "输入发货SKU数量",
                                              oninput:
                                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                                            },
                                            domProps: { value: scope.row.num },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  scope.row,
                                                  "num",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2780856644
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "SKU备注", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: scope.row.skuRemarks,
                                                expression:
                                                  "scope.row.skuRemarks",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入SKU备注",
                                            },
                                            domProps: {
                                              value: scope.row.skuRemarks,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  scope.row,
                                                  "skuRemarks",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2329675997
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  width: "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          !scope.row.button_sw
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "medium",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.add_skuId(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  添加\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2385016603
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.smallsave_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm("productForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "创建中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.location_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "整箱入库",
                visible: _vm.location_sw,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.location_sw = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.statuForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库位", required: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "338px" },
                        attrs: { placeholder: "请输入库位" },
                        model: {
                          value: _vm.location,
                          callback: function ($$v) {
                            _vm.location = $$v
                          },
                          expression: "location",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.location_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: { click: _vm.add_location_sw },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "入库中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.zc_location_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "ZC批量导入",
                visible: _vm.zc_location_sw,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zc_location_sw = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.statuForm,
                    "label-width": "90px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择仓库", prop: "bank" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            placeholder: "银行列表",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.zc_warehouseId,
                            callback: function ($$v) {
                              _vm.zc_warehouseId = $$v
                            },
                            expression: "zc_warehouseId",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action:
                      _vm.zcUploadPackingExcel +
                      "?warehouseId=" +
                      _vm.zc_warehouseId,
                    "show-file-list": false,
                    "auto-upload": true,
                    "on-change": _vm.importExcel,
                    "on-success": _vm.zc_upload_success,
                    "on-error": _vm.upload_error,
                    headers: _vm.headers,
                    "before-upload": _vm.handleBeforeUpload,
                    type: "file",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-upload",
                        loading: _vm.loading_01,
                      },
                    },
                    [_vm._v("批量导入\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.warehoussing_location_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "部分入库",
                visible: _vm.warehoussing_location_sw,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.warehoussing_location_sw = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "90px", rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库位", required: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "338px" },
                        attrs: { placeholder: "请输入库位" },
                        model: {
                          value: _vm.warehoussing_location,
                          callback: function ($$v) {
                            _vm.warehoussing_location = $$v
                          },
                          expression: "warehoussing_location",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.warehoussing_location_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: { click: _vm.add_location_warehoussing_sw },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.btn_loading ? "入库中" : "确定") + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }