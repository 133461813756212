//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime, exportMethod } from "@/utils";
import Pagination from "@/components/Pagination";
import { Message } from "element-ui";
import { getAuditPage, uploadExcel, deleteById, audit } from "@/api/ship/ship";
export default {
  name: "ShipList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      approvalDialogVisible: false,
      approvalForm: {
        status: 9,
        // Default to '通过'
        reason: "",
        orderId: "" // To store the orderId for the selected row
      },

      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20
      },
      form: {},
      dialogFormVisible: false,
      dialogType: "",
      typeOptions: [],
      isClear: false,
      timePickerDialogVisible: false,
      // 控制时间选择器弹框的显示与隐藏
      timeRangeForm: {
        startTime: "",
        // 选择的开始时间
        endTime: "" // 选择的结束时间
      },

      detailsskuList: [],
      detailDialogVisible: false,
      selectedAddressOrder: [],
      editAddressForm: {},
      editAddressDialogVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 查看地址
    handleEditAddress: function handleEditAddress(row) {
      this.selectedAddressOrder = row; // 存储选中的订单对象
      // 将选中订单的地址信息赋值给编辑表单
      this.editAddressForm = {
        recipient: row.recipient,
        phone: row.phone,
        city: row.city,
        state: row.state,
        detailAddress: row.detailAddress,
        zipcode: row.zipcode,
        additionalAddressInformation: row.additionalAddressInformation
      };
      this.editAddressDialogVisible = true; // 打开编辑地址对话框
    },
    // 点击复制
    copyname: function copyname(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    add_SKU: function add_SKU(val) {
      this.detailsskuList = val.skuList;
      this.detailDialogVisible = true;
      console.log("val", val, this.detailsskuList);
    },
    handleApprovalClick: function handleApprovalClick(row) {
      // Show the approval dialog and set the orderId for the selected row
      this.approvalDialogVisible = true;
      this.approvalForm.orderId = row.orderNo;
    },
    handleApprovalCancel: function handleApprovalCancel() {
      // Reset the approval form and hide the dialog
      this.approvalForm.status = 9;
      this.approvalForm.reason = "";
      this.approvalForm.orderId = "";
      this.approvalDialogVisible = false;
    },
    handleApprovalConfirm: function handleApprovalConfirm() {
      var _this = this;
      // Validate and send the approval request
      if (!this.approvalForm.reason) {
        this.$message.warning("请输入审批原因");
        return;
      }

      // Call the audit API with the approvalForm data
      audit(this.approvalForm).then(function () {
        _this.approvalDialogVisible = false;
        _this.getList();
        _this.$message.success("审批成功");
        // Refresh the table data or update the specific row as needed
      }).catch(function (error) {
        console.error("审批失败", error);
        _this.$message.error("审批失败");
      });
    },
    handleDelete: function handleDelete() {
      var _this2 = this;
      var selectedOrders = this.$refs["orderTable"].selection; // 获取选中的订单对象数组
      if (selectedOrders.length === 0) {
        this.$message.warning("请选择要删除的订单");
        return;
      }
      var selectedOrderIds = selectedOrders.map(function (order) {
        return order.id;
      }); // 提取选中订单的ID数组

      // 调用 API 删除选中的订单
      deleteById(selectedOrderIds).then(function () {
        _this2.getList(); // 成功删除后刷新列表
        _this2.$message.success("成功删除订单");
      }).catch(function (error) {
        console.error("删除订单失败", error);
        _this2.$message.error("删除订单失败");
      });
    },
    handleTimePickerConfirm: function handleTimePickerConfirm() {
      if (!this.timeRangeForm.startTime || !this.timeRangeForm.endTime) {
        this.$message.warning("请选择开始时间和结束时间");
        return;
      }
      var myObj = {
        method: "get",
        url: "ship/exportExcel?startTime=" + this.timeRangeForm.startTime + "&endTime=" + this.timeRangeForm.endTime,
        fileName: "shipOrder"
      };
      exportMethod(myObj);
    },
    handleTimePickerCancel: function handleTimePickerCancel() {
      this.timePickerDialogVisible = false;
    },
    handleExportClick: function handleExportClick() {
      this.timeRangeForm.startTime = ""; // 清空上次选择的开始时间
      this.timeRangeForm.endTime = ""; // 清空上次选择的结束时间
      this.timePickerDialogVisible = true; // 显示时间选择器弹框
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      getAuditPage(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.total;
        _this3.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      var _this4 = this;
      this.dialogStatus = "add";
      this.dialogFormVisible = true;
      this.form = {};
      this.$nextTick(function () {
        _this4.$refs["dataForm"].clearValidate();
      });
    },
    // 点击上传按钮的事件处理
    handleUploadClick: function handleUploadClick() {
      this.$refs.fileInput.click(); // 触发文件输入的点击事件
    },
    // 文件输入发生改变时的事件处理
    handleFileChange: function handleFileChange(event) {
      var _this5 = this;
      var file = event.target.files[0]; // 获取选择的文件
      if (file) {
        var formData = new FormData();
        formData.append("file", file); // 将文件添加到FormData对象
        var loadingMessage = Message.info({
          message: "文件上传处理中，请稍候...",
          duration: 0 // 不自动关闭
        });

        // 调用上传接口
        uploadExcel(formData).then(function (response) {
          // 处理上传成功后的逻辑
          // 可能是显示上传成功的消息，刷新列表等操作
          loadingMessage.close(); // 关闭处理中的提示
          _this5.getList();
          _this5.$notify({
            title: "成功",
            message: "导入成功",
            type: "success",
            duration: 2000
          });
        }).catch(function (error) {
          // 处理上传失败的逻辑
          // 可能是显示上传失败的消息，处理错误等操作

          loadingMessage.close(); // 关闭处理中的提示
          _this5.getList();
          _this5.$notify({
            title: "导入失败",
            message: "数据导入失败",
            type: "error",
            duration: 2000
          });
        });
      }
    }
  }
};