var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "品类名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入品类名称", clearable: "" },
                        model: {
                          value: _vm.searchTypeName,
                          callback: function ($$v) {
                            _vm.searchTypeName = $$v
                          },
                          expression: "searchTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "产品标题：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入产品标题", clearable: "" },
                        model: {
                          value: _vm.searchTitle,
                          callback: function ($$v) {
                            _vm.searchTitle = $$v
                          },
                          expression: "searchTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "sku名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入sku名称", clearable: "" },
                        model: {
                          value: _vm.skuName,
                          callback: function ($$v) {
                            _vm.skuName = $$v
                          },
                          expression: "skuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "" },
                    },
                    [
                      _c("span", [
                        _vm._v("\n            供应商名称：\n          "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "200px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            placeholder: "请选择供应商",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.supplyIds,
                            callback: function ($$v) {
                              _vm.supplyIds = $$v
                            },
                            expression: "supplyIds",
                          },
                        },
                        _vm._l(_vm.supplyOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n          查询\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: { click: _vm.add_auditStatus },
                        },
                        [_vm._v("\n          待分配产品\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: { click: _vm.handlePush },
                        },
                        [_vm._v("\n          推送\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticStyle: { width: "90%" },
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.productList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "title", label: "产品标题", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "typeName", label: "品类名称", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrls",
              label: "封面图",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "50px", width: "50px" },
                      attrs: {
                        src: scope.row.imgUrls[0],
                        "preview-src-list": [scope.row.imgUrls[0]],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.status ? "上架" : "下架") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "供应商", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.userName) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "class-name": "small-padding",
              fixed: "right",
              "min-width": "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看明细\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-sold-out",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.offshelf(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          强制下架\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推送商品",
            visible: _vm.pushDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.pushDialogVisible = $event
            },
            close: _vm.handlePushDialogClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "pushForm",
              attrs: {
                model: _vm.pushForm,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择店铺", prop: "shopAccountId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择店铺" },
                      model: {
                        value: _vm.pushForm.shopAccountId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pushForm, "shopAccountId", $$v)
                        },
                        expression: "pushForm.shopAccountId",
                      },
                    },
                    _vm._l(_vm.shopList, function (shop) {
                      return _c("el-option", {
                        key: shop.id,
                        attrs: {
                          value: shop.id,
                          label: shop.shopName + " - " + shop.envName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.pushDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmPush } },
                [_vm._v("确认推送")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑产品" : "新增产品",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "productForm",
              attrs: {
                model: _vm.productForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品标题" },
                    model: {
                      value: _vm.productForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "title", $$v)
                      },
                      expression: "productForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "sellerSku" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名称" },
                    model: {
                      value: _vm.productForm.sellerSku,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "sellerSku", $$v)
                      },
                      expression: "productForm.sellerSku",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品中文名称", prop: "typeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品中文名称" },
                    model: {
                      value: _vm.productForm.typeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "typeName", $$v)
                      },
                      expression: "productForm.typeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "imgUrls" } },
                [
                  _vm.SortableUpload_sw
                    ? _c("dragUpload", {
                        attrs: { imgUrls: _vm.productForm.imgUrls },
                        on: { emit_picture: _vm.emit_picture },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", {
                attrs: { label: "供应商", prop: "addBy" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择供应商" },
                            on: {
                              change: function ($event) {
                                return _vm.updateSupplier(_vm.productForm)
                              },
                            },
                            model: {
                              value: _vm.productForm.addBy,
                              callback: function ($$v) {
                                _vm.$set(_vm.productForm, "addBy", $$v)
                              },
                              expression: "productForm.addBy",
                            },
                          },
                          _vm._l(_vm.supplyOptions, function (supplier) {
                            return _c("el-option", {
                              key: supplier.id,
                              attrs: {
                                label: supplier.name,
                                value: supplier.id,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "cateIds",
                    label: "产品类目",
                    rules: [
                      { required: false, message: "请选择", trigger: "change" },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "60%" },
                    attrs: {
                      props: _vm.props,
                      placeholder: "试试搜索",
                      options: _vm.shopCateOptions,
                      filterable: "",
                    },
                    model: {
                      value: _vm.productForm.shopCateId,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "shopCateId", $$v)
                      },
                      expression: "productForm.shopCateId",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/product/cate")
                        },
                      },
                    },
                    [_vm._v("新建分类")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "danger" },
                      on: { click: _vm.getShopCateList },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产地", prop: "place" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产地" },
                    model: {
                      value: _vm.productForm.place,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "place", $$v)
                      },
                      expression: "productForm.place",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "素材链接", prop: "link" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入素材链接" },
                    model: {
                      value: _vm.productForm.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "link", $$v)
                      },
                      expression: "productForm.link",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brand" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入品牌" },
                    model: {
                      value: _vm.productForm.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "brand", $$v)
                      },
                      expression: "productForm.brand",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "认证材料", prop: "material" } },
                [
                  _c("UploadPdf", {
                    model: {
                      value: _vm.productForm.material,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "material", $$v)
                      },
                      expression: "productForm.material",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否反倾销", prop: "isAntiDumping" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.productForm.isAntiDumping,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "isAntiDumping", $$v)
                      },
                      expression: "productForm.isAntiDumping",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否促销", prop: "isSalesPromotion" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.productForm.isSalesPromotion,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "isSalesPromotion", $$v)
                      },
                      expression: "productForm.isSalesPromotion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "HS编码", prop: "hsCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入HS编码" },
                    model: {
                      value: _vm.productForm.hsCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "hsCode", $$v)
                      },
                      expression: "productForm.hsCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用途", prop: "hsCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用途" },
                    model: {
                      value: _vm.productForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "desc", $$v)
                      },
                      expression: "productForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品介绍", prop: "intro" } },
                [
                  _c("WangEnduit", {
                    attrs: { isClear: _vm.isClear },
                    on: {
                      change: _vm.changeEditor,
                      changeClear: function ($event) {
                        return _vm.$emit("changeClear")
                      },
                    },
                    model: {
                      value: _vm.productForm.intro,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "intro", $$v)
                      },
                      expression: "productForm.intro",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.conditionalConfigList, function (field, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: field.fieldName } },
                      [
                        field.fieldType === 1
                          ? [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                model: {
                                  value: _vm.conditionalConfigList[index].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.conditionalConfigList[index],
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "conditionalConfigList[index].value",
                                },
                              }),
                            ]
                          : field.fieldType === 2
                          ? [
                              _c("Upload", {
                                model: {
                                  value: _vm.conditionalConfigList[index].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.conditionalConfigList[index],
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "conditionalConfigList[index].value",
                                },
                              }),
                            ]
                          : field.fieldType === 3
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value:
                                      _vm.conditionalConfigList[index].value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.conditionalConfigList[index],
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "conditionalConfigList[index].value",
                                  },
                                },
                                _vm._l(
                                  field.options.split(","),
                                  function (option) {
                                    return _c("el-option", {
                                      key: option,
                                      attrs: { label: option, value: option },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "SKU列表" } },
                [
                  _vm.Skulist_sw
                    ? _c("seeSkulist", {
                        attrs: { variations: _vm.productForm.variations },
                        model: {
                          value: _vm.productForm.skuList,
                          callback: function ($$v) {
                            _vm.$set(_vm.productForm, "skuList", $$v)
                          },
                          expression: "productForm.skuList",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleCancel } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.forcedRemoval_sw
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "强制下架",
                visible: _vm.forcedRemoval_sw,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.forcedRemoval_sw = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "下架原因", prop: "auditReason" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.ruleForm.auditReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "auditReason", $$v)
                          },
                          expression: "ruleForm.auditReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.forcedRemoval_sw = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.add_forcedRemoval("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Cropper", {
        ref: "cropper1",
        attrs: {
          show_cropper: _vm.show_cropper,
          id: "avater_id_2",
          img_url: _vm.img_url,
        },
        on: {
          InitCropper: _vm.InitCropper,
          CloseCropper: _vm.CloseCropper,
          SaveCallBack: _vm.SaveCallBack,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }