var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClickLocationTab },
                  model: {
                    value: _vm.locationActiveName,
                    callback: function ($$v) {
                      _vm.locationActiveName = $$v
                    },
                    expression: "locationActiveName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "全部", name: "first" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "已提交资质", name: "second" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "完善店铺", name: "third" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "审核中", name: "four" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "通过", name: "five" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "未通过", name: "six" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "店铺名称：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", placeholder: "请输入店铺名称" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                              },
                              on: { click: _vm.handleDelete },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "企业名/姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.realname))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "证件", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "30px" },
                      attrs: {
                        src: scope.row.cardImg,
                        "preview-src-list": [scope.row.cardImg],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺类型", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 2
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm._f("typeFilter")(scope.row.type))),
                        ])
                      : scope.row.type == 3
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm._f("typeFilter")(scope.row.type))),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm._f("typeFilter")(scope.row.type))),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺头像", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "30px" },
                      attrs: {
                        src: scope.row.logo,
                        "preview-src-list": [scope.row.logo],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "统一信用/身份证号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.idcard))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "微信号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weixin))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("el-tag", [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 3
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 4
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.status))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              "class-name": "small-padding",
              fixed: "right",
              "min-width": "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-view",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleView(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-circle-check",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleVerify(scope.row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "详情", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "", column: 1, border: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "店铺类型" } }, [
                _vm.shopInfo.type == 2
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm._f("typeFilter")(_vm.shopInfo.type))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.shopInfo.type == 3
                  ? _c("span", { staticStyle: { color: "green" } }, [
                      _vm._v(_vm._s(_vm._f("typeFilter")(_vm.shopInfo.type))),
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(_vm._f("typeFilter")(_vm.shopInfo.type))),
                    ]),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "企业名/姓名" } }, [
                _vm._v(_vm._s(_vm.shopInfo.realname)),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "统一信用/身份证号" } },
                [_vm._v(_vm._s(_vm.shopInfo.idcard))]
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "证件" } },
                [
                  _c("el-image", {
                    staticStyle: { height: "30px" },
                    attrs: {
                      src: _vm.shopInfo.cardImg,
                      "preview-src-list": [_vm.shopInfo.cardImg],
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shopInfo.brandImg
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "商标证书" } },
                    [
                      _c("el-image", {
                        staticStyle: { height: "30px" },
                        attrs: {
                          src: _vm.shopInfo.brandImg,
                          "preview-src-list": [_vm.shopInfo.brandImg],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                _vm._v(_vm._s(_vm.shopInfo.phone)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "微信号" } }, [
                _vm._v(_vm._s(_vm.shopInfo.weixin)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "店铺名称" } }, [
                _vm._v(_vm._s(_vm.shopInfo.name)),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "店铺头像" } },
                [
                  _c("el-image", {
                    staticStyle: { height: "30px" },
                    attrs: {
                      src: _vm.shopInfo.logo,
                      "preview-src-list": [_vm.shopInfo.logo],
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "店铺简介" } }, [
                _vm._v(_vm._s(_vm.shopInfo.remark)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "店铺描述" } }, [
                _vm._v(_vm._s(_vm.shopInfo.produce)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "省市" } }, [
                _vm._v(
                  _vm._s(_vm.shopInfo.province) +
                    _vm._s(_vm.shopInfo.city) +
                    _vm._s(_vm.shopInfo.area)
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "状态" } },
                [
                  _vm.shopInfo.status == 0
                    ? _c("el-tag", [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(_vm.shopInfo.status))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shopInfo.status == 1
                    ? _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(_vm.shopInfo.status))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shopInfo.status == 2
                    ? _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(_vm.shopInfo.status))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shopInfo.status == 3
                    ? _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(_vm.shopInfo.status))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shopInfo.status == 4
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(_vm.shopInfo.status))
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shopInfo.reason
                ? _c("el-descriptions-item", { attrs: { label: "驳回原因" } }, [
                    _vm._v(_vm._s(_vm.shopInfo.reason)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.dialogVerifyVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVerifyVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.verifyForm,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.verifyForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "status", $$v)
                        },
                        expression: "verifyForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 3, border: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4, border: "" } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", [_vm._v("拒绝原因")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", prop: "reasonCheck" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.verifyForm,
                            "remark",
                            _vm.verifyForm.reasonCheck
                          )
                        },
                      },
                      model: {
                        value: _vm.verifyForm.reasonCheck,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "reasonCheck", $$v)
                        },
                        expression: "verifyForm.reasonCheck",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: {
                          value: "企业名填写错误",
                          label: "企业名填写错误",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          value: "统一信用填写错误",
                          label: "统一信用填写错误",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "2",
                        attrs: {
                          value: "上传图片不清晰",
                          label: "上传图片不清晰",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "3",
                        attrs: { value: "上传图片违规", label: "上传图片违规" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "10",
                        attrs: { value: "其他", label: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "填写原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入拒绝理由",
                      rows: 6,
                    },
                    model: {
                      value: _vm.verifyForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "remark", $$v)
                      },
                      expression: "verifyForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVerifyVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }