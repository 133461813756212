"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCityPage = getCityPage;
exports.getStatePage = getStatePage;
exports.getzipCodePage = getzipCodePage;
exports.uscitydeleteById = uscitydeleteById;
exports.uscitysave = uscitysave;
exports.uscityupdateById = uscityupdateById;
exports.usdeleteById = usdeleteById;
exports.ussave = ussave;
exports.usupdateById = usupdateById;
exports.uszipCodedeleteById = uszipCodedeleteById;
exports.uszipCodesave = uszipCodesave;
exports.uszipCodeupdateById = uszipCodeupdateById;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
// import request from '@/utils/request'

function getStatePage(data) {
  return (0, _requestJson.default)({
    url: '/us/area/getStatePage',
    method: 'get',
    params: data
  });
}
function ussave(data) {
  return (0, _requestJson.default)({
    url: '/us/area/state/save',
    method: 'post',
    data: data
  });
}
function usupdateById(data) {
  return (0, _requestJson.default)({
    url: '/us/area/state/updateById',
    method: 'post',
    data: data
  });
}
function usdeleteById(id) {
  return (0, _requestJson.default)({
    url: '/us/area/state/deleteById/' + id,
    method: 'post'
  });
}
function getCityPage(data) {
  return (0, _requestJson.default)({
    url: '/us/area/getCityPage',
    method: 'get',
    params: data
  });
}
function uscitysave(data) {
  return (0, _requestJson.default)({
    url: '/us/area/city/save',
    method: 'post',
    data: data
  });
}
function uscityupdateById(data) {
  return (0, _requestJson.default)({
    url: '/us/area/city/updateById',
    method: 'post',
    data: data
  });
}
function uscitydeleteById(id) {
  return (0, _requestJson.default)({
    url: '/us/area/city/deleteById/' + id,
    method: 'post'
  });
}
function getzipCodePage(data) {
  return (0, _requestJson.default)({
    url: '/us/area/getZipCodePage',
    method: 'get',
    params: data
  });
}
function uszipCodesave(data) {
  return (0, _requestJson.default)({
    url: '/us/area/zipCode/save',
    method: 'post',
    data: data
  });
}
function uszipCodeupdateById(data) {
  return (0, _requestJson.default)({
    url: '/us/area/zipCode/updateById',
    method: 'post',
    data: data
  });
}
function uszipCodedeleteById(id) {
  return (0, _requestJson.default)({
    url: '/us/area/zipCode/deleteById/' + id,
    method: 'post'
  });
}