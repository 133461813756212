"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _cropperjs = _interopRequireDefault(require("cropperjs"));
var _axios = _interopRequireDefault(require("axios"));
require("cropperjs/dist/cropper.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      myCropper: null,
      afterImg: "" // 裁剪之后的图片base64
    };
  },

  props: {
    show_cropper: {
      type: Boolean,
      default: true
    },
    img_url: {
      type: String,
      default: ""
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    id: {
      type: String,
      default: "image"
    }
  },
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var _this = this;
      this.$nextTick(function () {
        var image = document.querySelector("#".concat(_this.id));
        console.log("image1111111", image);
        _this.myCropper = new _cropperjs.default(image, {
          viewMode: 1,
          // 定义cropper的视图模式 0/1/2/3
          aspectRatio: _this.aspectRatio == 99 ? NaN : 1,
          // 裁剪框的宽高比
          preview: ".previewImg".concat(_this.id),
          // 添加额外的元素(容器)以供预览
          responsive: true,
          // 在调整窗口大小的时候重新渲染cropper
          background: true,
          //显示容器的网格背景
          modal: true,
          // 显示图片上方的黑色模态并在裁剪框下面
          movable: true,
          //是否允许移动剪裁框
          guides: false,
          //显示在裁剪框上方的虚线
          resizable: true,
          //是否允许改变裁剪框的大小
          dragCrop: true,
          //是否允许移除当前的剪裁框，并通过拖动来新建一个剪裁框区域
          autoCrop: true,
          // 当初始化时，可以自动生成图像
          autoCropArea: 1,
          //定义自动裁剪面积大小
          zoomOnWheel: true,
          //是否可以通过移动鼠标来放大图像
          zoomOnTouch: false,
          // 是否可以通过拖动触摸来放大图像
          cropBoxMovable: true,
          // 是否通过拖拽来移动剪裁框
          // minContainerWidth: 300, //容器的最小宽度
          // minContainerHeight: 300, //容器的最小高度
          minCropBoxHeight: 600,
          //变裁切框的最小高度
          minCropBoxWidth: 600 // 变裁切框的最小宽度
        });
      });
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob: function convertBase64UrlToBlob(urlData, fileName) {
      var arr = urlData.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bytes = atob(arr[1]); // 解码base64
      var n = bytes.length;
      var ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, {
        type: mime
      });
    },
    sureSave: function sureSave() {
      var _this2 = this;
      // debugger;
      var that = this;
      if (!that.myCropper) {
        this.$message.error("图片未选择");
        return;
      }
      that.afterImg = that.myCropper.getCroppedCanvas({
        imageSmoothingQuality: "high",
        imageSmoothingEnabled: true
      }).toDataURL("image/jpg");
      console.log("执行这里了吗");
      that.$nextTick(function () {
        var pic = that.afterImg.replace(/^data:image\/\w+;base64,/, "");
        console.log("new_pic", that.convertBase64UrlToBlob(that.afterImg, "file"));
        pic = that.convertBase64UrlToBlob(that.afterImg, "file");
        var url = process.env.VUE_APP_BASE_API + "system/upload/US";
        var formData = new FormData();
        formData.append("file", pic, "pic");
        _axios.default.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(function (res) {
          console.log("上传成功吗", res);
          if (res.data.code == 200) {
            that.$emit("SaveCallBack", res.data);
          } else {
            _this2.$message.error("上传失败");
          }
        });
      });
    },
    // 选择图片
    selectImg: function selectImg() {
      var _this3 = this;
      var file = document.querySelector("#chooseImg" + this.id);
      var accept = ["image/jpeg", "image/png", "image/jpg"];
      if (file.files[0].size > 1 * 1024 * 1024) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return;
      } else if (accept.indexOf(file.files[0].type) == -1) {
        this.$message.error("上传图片只能是 PNG、JPG 格式");
        return;
      }
      // if(){
      var img = new Image();
      img.src = window.URL.createObjectURL(file.files[0]);
      img.onload = function () {
        console.log("img", img.width, img.height);
        if (img.width < 600 || img.height < 600) {
          // 处理尺寸超过限制的情况 这里是建议所以只是提示，依然是通过的
          _this3.$message.error("上传图片尺寸最小为 600 X 600");
          console.log("11111");
          _this3.$refs.refUpload.abort();
          return false;
        } else {
          // 尺寸符合要求，可以继续上传
          console.log("22222");
          var new_img_url = URL.createObjectURL(file.files[0]);
          console.log("选择图片", new_img_url);
          if (_this3.myCropper) {
            // this.img_url = new_img_url;
            console.log("1121212", _this3.myCropper);
            _this3.myCropper.replace(new_img_url);
          } else {
            console.log("222222", _this3.myCropper);
            _this3.$emit("InitCropper", new_img_url);
          }
        }
      };
      // }
    },
    hideCropper: function hideCropper() {
      if (this.myCropper) {
        console.log("this.myCropper", this.myCropper, document.querySelector("#".concat(this.id)));
        this.myCropper.destroy();
        // document.querySelector(`#${this.id}`).remove();
      }

      if (document.querySelector("#".concat(this.id))) {
        // window.URL.revokeObjectURL(document.querySelector(`#${this.id}`).src);
        // console.log(document.querySelector(`#${this.id}`).src);
      }
      this.$emit("CloseCropper");
    }
  },
  created: function created() {}
};
exports.default = _default;