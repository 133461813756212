var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺账号：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入店铺账号",
                            "remote-method": _vm.add_getShopList,
                            loading: _vm.getShopList_loading,
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shopId", $$v)
                            },
                            expression: "listQuery.shopId",
                          },
                        },
                        _vm._l(_vm.envNameOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "mini",
                          placeholder: "订单号",
                          clearable: "",
                        },
                        on: { clear: _vm.handleSearch },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderNo", $$v)
                          },
                          expression: "listQuery.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入产品名称",
                          clearable: "",
                        },
                        on: { clear: _vm.handleSearch },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "title", $$v)
                          },
                          expression: "listQuery.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("span", { staticClass: "el-form-item__label" }, [
                        _vm._v("用户手机号："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入用户手机号",
                          clearable: "",
                        },
                        on: { clear: _vm.handleSearch },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "phone", $$v)
                          },
                          expression: "listQuery.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            size: "mini",
                            placeholder: "请选择订单状态",
                          },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "待发货", value: "0" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "待回传物流单号", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "待同步物流单号", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已同步物流单号", value: "3" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已取消订单", value: "9" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择仓库", size: "mini" },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.listQuery.warehouseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "warehouseId", $$v)
                            },
                            expression: "listQuery.warehouseId",
                          },
                        },
                        _vm._l(_vm.warehouseOptions, function (warehouse) {
                          return _c("el-option", {
                            key: warehouse.id,
                            attrs: {
                              value: warehouse.id,
                              label: warehouse.name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间日期：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "170px" },
                        attrs: {
                          size: "mini",
                          type: "datetime",
                          placeholder: "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.handleSearch },
                        model: {
                          value: _vm.listQuery.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "startTime", $$v)
                          },
                          expression: "listQuery.startTime",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "170px" },
                        attrs: {
                          size: "mini",
                          type: "datetime",
                          placeholder: "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.handleSearch },
                        model: {
                          value: _vm.listQuery.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "endTime", $$v)
                          },
                          expression: "listQuery.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-download",
                          },
                          on: { click: _vm.handleTimePickerConfirm },
                        },
                        [_vm._v("导出\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": _vm.rowKey,
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              width: "45",
              fixed: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              align: "center",
              width: "60",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单号", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(scope.row.orderNo)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运单号", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.shipNo) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺账号", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.username) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "imgUrl", label: "商品图片", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _c("img", {
                        staticClass: "avatar",
                        staticStyle: {
                          height: "50px",
                          width: "50px",
                          "margin-right": "5px",
                        },
                        attrs: { src: scope.row.imgUrl },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单金额", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.orderAmount) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "发货仓库",
              align: "center",
              "min-width": "70",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.warehouse) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              align: "center",
              "min-width": "70",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(_vm.getStatusDescription(scope.row))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "订单创建时间（美区时间）",
              align: "center",
              prop: "orderAddTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.orderAddTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "同步时间",
              align: "center",
              prop: "addTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.addTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-tickets",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("SKU明细")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-map-location",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditAddress(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看地址")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推送状态",
            visible: _vm.showStatusDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showStatusDialog = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择状态" },
              model: {
                value: _vm.orderStatus,
                callback: function ($$v) {
                  _vm.orderStatus = $$v
                },
                expression: "orderStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "待发货", value: "0" } }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "待回传物流单号", value: "1" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "待同步物流单号", value: "2" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "已同步物流单号", value: "3" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updatePushStatus },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showStatusDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步日志",
            visible: _vm.syncLogDialogVisible,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.syncLogDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.syncLogList, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "同步类型", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === 1
                          ? _c("span", [_vm._v("同步待发货订单")])
                          : scope.row.type === 2
                          ? _c("span", [_vm._v("回传运单号")])
                          : scope.row.type === 3
                          ? _c("span", [_vm._v("同步产品")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "同步状态", prop: "syncStatus", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.syncStatus == 1 ? "发起" : "回调")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "处理状态", prop: "status", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.status == 200 ? "成功" : "失败")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "处理结果",
                  prop: "reason",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.reason)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.reason))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "时间", prop: "addTime", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.formatTime(scope.row.addTime)) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.logTotal > 0,
                expression: "logTotal > 0",
              },
            ],
            attrs: {
              total: _vm.logTotal,
              page: _vm.logQuery.page,
              limit: _vm.logQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.logQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.logQuery, "pageSize", $event)
              },
              pagination: _vm.getSyncLogList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认取消订单",
            visible: _vm.cancelDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.cancelDialogVisible = $event
            },
            close: _vm.resetCancelDialog,
          },
        },
        [
          _c("p", [_vm._v("是否确认取消订单？")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.cancelDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.cancelOrderConfirm },
                },
                [_vm._v("确认取消\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步运单号",
            visible: _vm.showSyncShipNoDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSyncShipNoDialog = $event
            },
            close: _vm.resetManualShipDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "manualShipForm",
              attrs: { model: _vm.manualShipForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择仓库" },
                      model: {
                        value: _vm.manualShipForm.warehouseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.manualShipForm, "warehouseId", $$v)
                        },
                        expression: "manualShipForm.warehouseId",
                      },
                    },
                    _vm._l(_vm.warehouseOptions, function (warehouse) {
                      return _c("el-option", {
                        key: warehouse.id,
                        attrs: { value: warehouse.id, label: warehouse.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSyncShipNoDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.handleSyncShipNo_loading,
                  },
                  on: { click: _vm.handleSyncShipNoApi },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.handleSyncShipNo_loading ? "同步中" : "确定同步"
                    ) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手动发货",
            visible: _vm.manualShipDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.manualShipDialogVisible = $event
            },
            close: _vm.resetManualShipDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "manualShipForm",
              attrs: { model: _vm.manualShipForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "运单号", prop: "shipNo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.manualShipForm.shipNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.manualShipForm, "shipNo", $$v)
                      },
                      expression: "manualShipForm.shipNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流商", prop: "shipNo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.manualShipForm.carrier,
                      callback: function ($$v) {
                        _vm.$set(_vm.manualShipForm, "carrier", $$v)
                      },
                      expression: "manualShipForm.carrier",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流服务", prop: "shipNo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.manualShipForm.carrierServiceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.manualShipForm, "carrierServiceName", $$v)
                      },
                      expression: "manualShipForm.carrierServiceName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择仓库" },
                      model: {
                        value: _vm.manualShipForm.warehouseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.manualShipForm, "warehouseId", $$v)
                        },
                        expression: "manualShipForm.warehouseId",
                      },
                    },
                    _vm._l(_vm.warehouseOptions, function (warehouse) {
                      return _c("el-option", {
                        key: warehouse.id,
                        attrs: { value: warehouse.id, label: warehouse.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.manualShipDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleManualShipConfirm },
                },
                [_vm._v("确认发货\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改地址",
            visible: _vm.editAddressDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editAddressDialogVisible = $event
            },
            close: _vm.resetEditAddressDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editAddressForm",
              attrs: { model: _vm.editAddressForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "recipient" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.recipient,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "recipient", $$v)
                      },
                      expression: "editAddressForm.recipient",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收件人电话", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "phone", $$v)
                      },
                      expression: "editAddressForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "城市", prop: "city" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "city", $$v)
                      },
                      expression: "editAddressForm.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "州/省", prop: "state" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "state", $$v)
                      },
                      expression: "editAddressForm.state",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "detailAddress" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.detailAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "detailAddress", $$v)
                      },
                      expression: "editAddressForm.detailAddress",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门牌地址",
                    prop: "additionalAddressInformation",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.additionalAddressInformation,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editAddressForm,
                          "additionalAddressInformation",
                          $$v
                        )
                      },
                      expression:
                        "editAddressForm.additionalAddressInformation",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮编", prop: "zipcode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editAddressForm.zipcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.editAddressForm, "zipcode", $$v)
                      },
                      expression: "editAddressForm.zipcode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editAddressDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleEditAddressConfirm },
                },
                [_vm._v("确认修改\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SKU明细",
            visible: _vm.detailDialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.currentDetail.skuList, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品标题",
                  align: "center",
                  prop: "productName",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.productName)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.productName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: "center",
                  prop: "cnName",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.sellerSku)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "品类名称",
                  align: "center",
                  prop: "cnName",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(
                            _vm._s(
                              scope.row.cnName ? scope.row.cnName : "未关联品类"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "SKU名称", align: "center", prop: "cnName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "tab_id_zi",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyname(scope.row.variation)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.variation))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "配货备注", align: "center", prop: "content" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.content)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", prop: "quantity" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.quantity)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "净重", align: "center", prop: "weight" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.weight)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "价值",
                  align: "center",
                  prop: "originalPrice",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.originalPrice)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用途", align: "center", prop: "desc" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.desc)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "SKU类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isDian === 0
                          ? _c("span", [_vm._v("普通")])
                          : scope.row.isDian === 2
                          ? _c("span", [_vm._v("液体")])
                          : scope.row.isDian === 1
                          ? _c("span", [_vm._v("带电")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量发货",
            visible: _vm.addmoneydialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addmoneydialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.selectedOrders,
                "max-height": "480",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(scope.row.orderNo)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "imgUrl", label: "商品图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("img", {
                            staticClass: "avatar",
                            staticStyle: {
                              height: "50px",
                              width: "50px",
                              "margin-right": "5px",
                            },
                            attrs: { src: scope.row.imgUrl },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单金额", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticClass: "ellipsis",
                          domProps: {
                            innerHTML: _vm._s(scope.row.orderAmount),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "处理结果", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reason
                          ? _c("span", [_vm._v(_vm._s(scope.row.reason))])
                          : _c("span", [_vm._v("加载中... ")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.statusDesc
                          ? _c("span", [_vm._v(_vm._s(scope.row.statusDesc))])
                          : _c("span", [_vm._v("加载中... ")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }