var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor" }, [
    _c("div", {
      ref: "toolbar",
      staticClass: "toolbar",
      staticStyle: { "margin-bottom": "-1px" },
    }),
    _vm._v(" "),
    _c("div", { ref: "editor", staticClass: "text" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }