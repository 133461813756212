var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "" },
                    },
                    [
                      _c("span", [_vm._v("地址名称：")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          cl: "",
                          earable: "",
                          placeholder: "请输入地址名称",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.search($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.addressor,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "addressor", $$v)
                          },
                          expression: "listQuery.addressor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("查询\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.add_state },
                        },
                        [_vm._v("新增地址\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "仓库",
              prop: "name",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "发件人",
              prop: "addressor",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "地址",
              prop: "site",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "国家",
              prop: "country",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "城市",
              prop: "city",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "州/省",
              prop: "state",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "邮编",
              prop: "postcode",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "电话",
              prop: "phone",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.edit_state(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _vm.editAddressDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.text,
                visible: _vm.editAddressDialogVisible,
                width: "30%",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editAddressDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "editAddressForm",
                  attrs: {
                    model: _vm.editAddressForm,
                    "label-width": "80px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库名称", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "name", $$v)
                          },
                          expression: "editAddressForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发件人", prop: "addressor" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.addressor,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "addressor", $$v)
                          },
                          expression: "editAddressForm.addressor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址", prop: "site" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.site,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "site", $$v)
                          },
                          expression: "editAddressForm.site",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "国家", prop: "country" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "country", $$v)
                          },
                          expression: "editAddressForm.country",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "州/省", prop: "state" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "state", $$v)
                          },
                          expression: "editAddressForm.state",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "城市", prop: "city" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "city", $$v)
                          },
                          expression: "editAddressForm.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮编", prop: "postcode" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.postcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "postcode", $$v)
                          },
                          expression: "editAddressForm.postcode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editAddressForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.editAddressForm, "phone", $$v)
                          },
                          expression: "editAddressForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editAddressDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.submitForm_loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("editAddressForm")
                        },
                      },
                    },
                    [_vm._v("确认" + _vm._s(_vm.text) + "\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }