"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var goodsRouter = {
  path: '/goods',
  component: _layout.default,
  redirect: '/goods/type',
  name: 'goods',
  alwaysShow: true,
  meta: {
    title: '陪跑营',
    icon: 'report-brand'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/type/index'));
      });
    },
    name: 'goodsType',
    meta: {
      title: '商品类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/list/index'));
      });
    },
    name: 'goodsList',
    meta: {
      title: '商品列表',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/order/index'));
      });
    },
    name: 'goodsOrder',
    meta: {
      title: '陪跑营订单',
      role: []
    }
  }]
};
var _default = goodsRouter;
exports.default = _default;