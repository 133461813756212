var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "0px",
                        "font-size": "16px",
                      },
                      attrs: { label: "仓库名字：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: { placeholder: "请输入仓库名字", clearable: "" },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "margin-bottom": "10px",
                        "font-size": "16px",
                      },
                      attrs: { label: "仓库id：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-right": "20px" },
                        attrs: {
                          oninput: "value=value.replace(/[^\\d]/g, '')",
                          placeholder: "请输入仓库id",
                          clearable: "",
                        },
                        model: {
                          value: _vm.id,
                          callback: function ($$v) {
                            _vm.id = $$v
                          },
                          expression: "id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("\n            查询\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-circle-plus-outline",
                          },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("\n            新增\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.id))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "仓库名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.name))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "别名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.aliasName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "权重", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.sort))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "物流名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.warehouseName))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "物流服务", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.warehouseService))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "启用状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(_vm._s(scope.row.status == "0" ? "启用" : "禁用")),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "取消订单运费扣除率", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.cancelRate))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "仓库邮编", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.warehouseAddressInfo
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(scope.row.warehouseAddressInfo.zipcode) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作费", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.operationFee))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "仓库信息",
            visible: _vm.dialogFormVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "90px",
                rules: _vm.Warehouse_rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入仓库名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "别名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入别名" },
                    model: {
                      value: _vm.form.aliasName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "aliasName", $$v)
                      },
                      expression: "form.aliasName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库标识", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, placeholder: "请输入权重" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库号", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.form.warehouseCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "warehouseCode", $$v)
                      },
                      expression: "form.warehouseCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择类型" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "海外仓" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 2, label: "虚拟仓" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 3, label: "自定义" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 4, label: "开亚仓" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 5, label: "易境通仓" },
                      }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 6, label: "WS仓库" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "启用状态", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择是否启用" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: false, label: "启用" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: true, label: "禁用" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最低操作费", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[^0-9.]/g,'')",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.form.operatingLow,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "operatingLow", $$v)
                      },
                      expression: "form.operatingLow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最高操作费", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[^0-9.]/g,'')",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.form.operatingHighest,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "operatingHighest", $$v)
                      },
                      expression: "form.operatingHighest",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "操作费类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择操作费类型" },
                      model: {
                        value: _vm.form.operationFeeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operationFeeType", $$v)
                        },
                        expression: "form.operationFeeType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "按单" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 1, label: "按sku(类目)" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 2, label: "按sku(数量)" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "操作费", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[^0-9.]/g,'')",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.form.operationFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "operationFee", $$v)
                      },
                      expression: "form.operationFee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "起始操作费", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[^0-9.]/g,'')",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.form.startOperationFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startOperationFee", $$v)
                      },
                      expression: "form.startOperationFee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "扣除供应商物流费", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选类型" },
                      model: {
                        value: _vm.form.logisticsFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "logisticsFlag", $$v)
                        },
                        expression: "form.logisticsFlag",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "否" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 1, label: "是" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "扣除物流费类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选类型" },
                      model: {
                        value: _vm.form.logisticsFeeIncreaseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "logisticsFeeIncreaseType", $$v)
                        },
                        expression: "form.logisticsFeeIncreaseType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: 0, label: "固定金额" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: 1, label: "按百分比" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流费用/比例", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[^0-9.]/g,'')",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.form.logisticsFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logisticsFee", $$v)
                      },
                      expression: "form.logisticsFee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "warehouseAddressInfo.recipient",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.recipient,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.warehouseAddressInfo,
                          "recipient",
                          $$v
                        )
                      },
                      expression: "form.warehouseAddressInfo.recipient",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "电话", prop: "warehouseAddressInfo.phone" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.warehouseAddressInfo, "phone", $$v)
                      },
                      expression: "form.warehouseAddressInfo.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "国家",
                    prop: "warehouseAddressInfo.country",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入国家" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.warehouseAddressInfo, "country", $$v)
                      },
                      expression: "form.warehouseAddressInfo.country",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "州", prop: "warehouseAddressInfo.state" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入州" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.warehouseAddressInfo, "state", $$v)
                      },
                      expression: "form.warehouseAddressInfo.state",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "城市", prop: "warehouseAddressInfo.city" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入城市" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.warehouseAddressInfo, "city", $$v)
                      },
                      expression: "form.warehouseAddressInfo.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "邮编",
                    prop: "warehouseAddressInfo.zipcode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入邮编" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.zipcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.warehouseAddressInfo, "zipcode", $$v)
                      },
                      expression: "form.warehouseAddressInfo.zipcode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址1",
                    prop: "warehouseAddressInfo.detailAddress1",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地址1" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.detailAddress1,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.warehouseAddressInfo,
                          "detailAddress1",
                          $$v
                        )
                      },
                      expression: "form.warehouseAddressInfo.detailAddress1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址2",
                    prop: "warehouseAddressInfo.detailAddress2",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地址2" },
                    model: {
                      value: _vm.form.warehouseAddressInfo.detailAddress2,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.warehouseAddressInfo,
                          "detailAddress2",
                          $$v
                        )
                      },
                      expression: "form.warehouseAddressInfo.detailAddress2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveWarehouse("form")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "仓库号", visible: _vm.Warehouse_sw, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.Warehouse_sw = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.Warehouse_list,
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "仓库号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.WarehouseCode))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "物流名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.WarehouseName))]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.Warehouse_sw = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }