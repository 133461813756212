"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _member = _interopRequireDefault(require("./modules/member"));
var _vip = _interopRequireDefault(require("./modules/vip"));
var _course = _interopRequireDefault(require("./modules/course"));
var _special = _interopRequireDefault(require("./modules/special"));
var _system = _interopRequireDefault(require("./modules/system"));
var _information = _interopRequireDefault(require("./modules/information"));
var _third = _interopRequireDefault(require("./modules/third"));
var _log = _interopRequireDefault(require("./modules/log"));
var _shop = _interopRequireDefault(require("./modules/shop"));
var _business = _interopRequireDefault(require("@/router/modules/business"));
var _goods = _interopRequireDefault(require("@/router/modules/goods"));
var _material = _interopRequireDefault(require("@/router/modules/material"));
var _notice = _interopRequireDefault(require("@/router/modules/notice"));
var _customer = _interopRequireDefault(require("@/router/modules/customer"));
var _slideshow = _interopRequireDefault(require("@/router/modules/slideshow"));
var _ship = _interopRequireDefault(require("@/router/modules/ship"));
var _product = _interopRequireDefault(require("@/router/modules/product"));
var _warehouse = _interopRequireDefault(require("@/router/modules/warehouse"));
var _wswarehouse = _interopRequireDefault(require("@/router/modules/wswarehouse"));
var _fund = _interopRequireDefault(require("@/router/modules/fund"));
var _shopvip = _interopRequireDefault(require("@/router/modules/shopvip"));
var _usaaddress = _interopRequireDefault(require("@/router/modules/usaaddress"));
var _association = _interopRequireDefault(require("@/router/modules/association"));
var _supplier = _interopRequireDefault(require("@/router/modules/supplier"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

// import bindingshop from "@/router/modules/bindingshop";

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
 **/
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '',
  component: _layout.default,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [_shop.default, _member.default, _vip.default, _course.default, _special.default, _system.default, _information.default, _business.default, _goods.default, _log.default, _notice.default, _customer.default, _slideshow.default, _third.default, _material.default, _ship.default, _product.default, _warehouse.default, _wswarehouse.default, _fund.default, _shopvip.default, _association.default,
// bindingshop,
_usaaddress.default, _supplier.default, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;