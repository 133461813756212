//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PDFUpload',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      imgUrl: this.value,
      uploadUrl: process.env.VUE_APP_BASE_API + 'system/upload',
      dataObj: {
        token: '',
        key: ''
      }
    };
  },
  methods: {
    handleFileSuccess: function handleFileSuccess(result) {
      this.imgUrl = result.data.imgUrl;
      this.emitInput(result.data.imgUrl);
    },
    emitInput: function emitInput(val) {
      this.$emit('input', val);
    },
    previewPDF: function previewPDF() {
      // Handle the PDF preview here, if required
    }
  }
};