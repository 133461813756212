"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _duration = require("@/api/special/duration");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SpecialDurationPage',
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      listLoading: true,
      dialogFormVisible: false,
      dialogType: 'add',
      form: {
        duration: '',
        num: 1,
        discount: ''
      },
      rules: {
        duration: [{
          required: true,
          message: '请输入时长',
          trigger: 'blur'
        }],
        num: [{
          required: true,
          message: '请输入数量',
          trigger: 'blur'
        }, {
          type: 'integer',
          message: '数量必须为整数',
          trigger: 'blur'
        }, {
          validator: this.validateNum,
          trigger: 'blur'
        }],
        discount: [{
          required: true,
          message: '请输入折扣',
          trigger: 'blur'
        }]
      },
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize
      };
      (0, _duration.getSpecialDurationPage)(params).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.dialogType = 'add';
      this.form.duration = '';
      this.form.num = 1;
      this.form.discount = '';
      this.dialogFormVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogType = 'edit';
      this.form = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;
      this.$confirm('确认删除该数据吗?', '提示', {
        type: 'warning'
      }).then(function () {
        (0, _duration.deleteSpecialDuration)(row.id).then(function () {
          _this2.$message.success('删除成功');
          _this2.getList();
        });
      }).catch(function () {});
    },
    confirm: function confirm() {
      var _this3 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          var api = _this3.dialogType === 'add' ? _duration.addSpecialDuration : _duration.updateSpecialDuration;
          api(_this3.form).then(function () {
            _this3.$message.success(_this3.dialogType === 'add' ? '添加成功' : '编辑成功');
            _this3.getList();
            _this3.dialogFormVisible = false;
          });
        }
      });
    },
    parseTime: function parseTime(time) {
      return (0, _utils.parseTime)(time);
    },
    validateNum: function validateNum(rule, value, callback) {
      if (value < 1) {
        callback(new Error('数量必须大于等于1'));
      } else {
        callback();
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList();
    },
    handlePageChange: function handlePageChange(val) {
      this.listQuery.page = val;
      this.getList();
    }
  }
};
exports.default = _default;