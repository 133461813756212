import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import wap from "./wap.js";
import goodsDialog from "./goods-dialog";
import templateWay from "./template/index";
export default {
  components: {
    goodsDialog: goodsDialog
  },
  data: function data() {
    return {
      templateWay: templateWay,
      // 模板数据
      changed: "",
      // 变更模板
      selected: 0,
      // 已选数据
      selectedLink: "",
      //选中的链接
      wap: wap // tab标签
    };
  },

  watch: {
    changed: {
      handler: function handler(val) {
        this.$emit("selectedLink", val[0]); //因为是单选，所以直接返回第一个
      },

      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs["lili-component"][0].type = "single"; //商品页面设置成为单选
    });

    this.wap.forEach(function (item) {
      if (item) {
        item.selected = false;
      }
    });
  },
  methods: {}
};