import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermissionCustom(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = store.getters && store.getters.wsAnthor;
    // console.log('roles1111111111', roles);
    var permissionRoles = value;
    var mermberId = store.getters && store.getters.id;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    var idListFilter = [97];
    if (!hasPermission && !idListFilter.includes(mermberId)) {
      return false;
    }
    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}