"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _lib = require("@/api/material/lib");
var _index = _interopRequireDefault(require("@/components/WangEditor/index"));
var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));
var _singleVideo = _interopRequireDefault(require("@/components/Upload/singleVideo"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    WangEnduit: _index.default,
    Upload: _singleImage.default,
    UploadVideo: _singleVideo.default
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      tableKey: 0,
      dialogVisible: false,
      form: {
        id: null,
        title: "",
        coverImg: "",
        details: "",
        video: ""
      },
      isClear: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    changeEditor: function changeEditor(val) {
      this.form.content = val;
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _lib.getMaterialLibPage)(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.list.forEach(function (val) {
          val.video = _this.$dateUtil.getPicUrl(val.video);
        });
        console.log("数据", _this.list);
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.form = {
        id: null,
        title: "",
        coverImg: "",
        details: "",
        video: ""
      };
      this.dialogVisible = true;
    },
    handleEdit: function handleEdit(row) {
      this.form = (0, _objectSpread2.default)({}, row);
      this.dialogVisible = true;
    },
    handleSave: function handleSave() {
      var _this2 = this;
      if (this.form.id) {
        (0, _lib.updateMaterialLib)(this.form).then(function () {
          _this2.dialogVisible = false;
          _this2.getList();
        });
      } else {
        (0, _lib.saveMaterialLib)(this.form).then(function () {
          _this2.dialogVisible = false;
          _this2.getList();
        });
      }
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;
      (0, _lib.deleteMaterialLib)(row.id).then(function () {
        _this3.getList();
        _this3.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      var date = new Date(timestamp);
      return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate(), " ").concat(date.getHours(), ":").concat(date.getMinutes(), ":").concat(date.getSeconds());
    }
  }
};
exports.default = _default;