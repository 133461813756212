"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoodsCate = addGoodsCate;
exports.deleteGoods = deleteGoods;
exports.deleteGoodsCate = deleteGoodsCate;
exports.getGoods = getGoods;
exports.getGoodsCateList = getGoodsCateList;
exports.getGoodsList = getGoodsList;
exports.getGoodsQualityList = getGoodsQualityList;
exports.getPlatformGoodsList = getPlatformGoodsList;
exports.getSkuList = getSkuList;
exports.getThirdDetail = getThirdDetail;
exports.shangjiaGoods = shangjiaGoods;
exports.updateGoods = updateGoods;
exports.updateGoodsCate = updateGoodsCate;
exports.xiajiaGoods = xiajiaGoods;
var _request = _interopRequireDefault(require("@/utils/request"));
function getGoodsCateList(data) {
  return (0, _request.default)({
    url: 'system/goodsCate/getList',
    method: 'get',
    params: data
  });
}
function addGoodsCate(data) {
  return (0, _request.default)({
    url: 'system/goodsCate/add',
    method: 'post',
    data: data
  });
}
function updateGoodsCate(data) {
  return (0, _request.default)({
    url: 'system/goodsCate/editSubmit',
    method: 'post',
    data: data
  });
}
function deleteGoodsCate(data) {
  return (0, _request.default)({
    url: 'system/goodsCate/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getGoodsList(data) {
  return (0, _request.default)({
    url: 'system/goods/getList',
    method: 'get',
    params: data
  });
}
function getGoods(data) {
  return (0, _request.default)({
    url: 'system/goods/get',
    method: 'get',
    params: data
  });
}
function getThirdDetail(data) {
  return (0, _request.default)({
    url: 'system/goods/getThirdDetail',
    method: 'get',
    params: data
  });
}
function updateGoods(data) {
  return (0, _request.default)({
    url: 'system/goods/editSubmit',
    method: 'post',
    data: data
  });
}
function shangjiaGoods(data) {
  return (0, _request.default)({
    url: 'system/goods/shangjia',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function xiajiaGoods(data) {
  return (0, _request.default)({
    url: 'system/goods/xiajia',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function deleteGoods(data) {
  return (0, _request.default)({
    url: 'system/goods/delete',
    method: 'post',
    params: {
      ids: data
    }
  });
}
function getGoodsQualityList(data) {
  return (0, _request.default)({
    url: 'system/goodsQuality/query',
    method: 'get',
    params: {
      goodsId: data
    }
  });
}
function getPlatformGoodsList(data) {
  return (0, _request.default)({
    url: 'system/goods/getListByPlatform',
    method: 'get',
    params: data
  });
}
function getSkuList(data) {
  return (0, _request.default)({
    url: 'system/goods/getSkuList',
    method: 'get',
    params: data
  });
}