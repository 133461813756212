"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _category = _interopRequireDefault(require("./category.vue"));
var _shops = _interopRequireDefault(require("./shops.vue"));
var _marketing = _interopRequireDefault(require("./marketing.vue"));
var _pages = _interopRequireDefault(require("./pages.vue"));
var _goodsDialog = _interopRequireDefault(require("../goods-dialog.vue"));
var _other = _interopRequireDefault(require("./other.vue"));
var _default = {
  pages: _pages.default,
  marketing: _marketing.default,
  shops: _shops.default,
  category: _category.default,
  goods: _goodsDialog.default,
  other: _other.default
};
exports.default = _default;