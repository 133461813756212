var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 10px 10px 10px" } },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.config,
            "label-width": "140px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                contentStyle: _vm.CS,
                "label-style": _vm.LS,
                column: 1,
                size: "small",
                border: "",
              },
            },
            _vm._l(_vm.configList, function (item, index) {
              return _c(
                "el-descriptions-item",
                { key: index },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          " + _vm._s(item.desc) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right_input" },
                    [
                      item.desc == "商务合作联系方式"
                        ? _c(
                            "div",
                            { staticClass: "WangEnduitbox" },
                            [
                              _c("WangEnduit", {
                                staticClass: "WangEnduit",
                                attrs: { isClear: _vm.isClear },
                                on: {
                                  change: _vm.changeEditor,
                                  changeClear: function ($event) {
                                    return _vm.$emit("changeClear")
                                  },
                                },
                                model: {
                                  value: item.content,
                                  callback: function ($$v) {
                                    _vm.$set(item, "content", $$v)
                                  },
                                  expression: "item.content",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.desc == "首页中置轮播图" ||
                      item.desc == "首页置顶轮播图"
                        ? _c("MultipleImageUpload", {
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      item.desc != "首页中置轮播图" &&
                      item.desc != "首页置顶轮播图" &&
                      item.desc != "商务合作联系方式"
                        ? _c("el-input", {
                            staticStyle: { width: "80%" },
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirm(item)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }