"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _resources = require("@/api/special/resources");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SpecialResourcesPage',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      tableKey: 0,
      listQuery: {
        page: 1,
        pageSize: 10
      },
      listLoading: true,
      dialogFormVisible: false,
      dialogType: 'add',
      form: {
        resource: ''
      },
      rules: {
        resource: [{
          required: true,
          message: '请输入资源',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _resources.getSpecialResourcesPage)(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.dialogType = 'add';
      this.form.resource = '';
      this.dialogFormVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.dialogType = 'edit';
      this.form = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;
      this.$confirm('确认删除该数据吗?', '提示', {
        type: 'warning'
      }).then(function () {
        (0, _resources.deleteSpecialResources)(row.id).then(function () {
          _this2.$message.success('删除成功');
          _this2.getList();
        });
      }).catch(function () {});
    },
    confirm: function confirm() {
      var _this3 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          var api = _this3.dialogType === 'add' ? _resources.addSpecialResources : _resources.updateSpecialResources;
          api(_this3.form).then(function () {
            _this3.$message.success(_this3.dialogType === 'add' ? '添加成功' : '编辑成功');
            _this3.getList();
            _this3.dialogFormVisible = false;
          });
        }
      });
    },
    parseTime: function parseTime(time, pattern) {
      return (0, _utils.parseTime)(time, pattern);
    }
  }
};
exports.default = _default;