var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "mini",
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "true" },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status",
                          },
                        },
                        _vm._l(_vm.status, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("查询\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "id", align: "center", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.id) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "仓库", prop: "warehouseName", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "批次名", prop: "warehouseName", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "ellipsis",
                      domProps: { innerHTML: _vm._s(scope.row.batchNo) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "物流方式", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " + _vm._s(scope.row.carrier) + "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单数量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.add_orderList(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v("\n            " + _vm._s(scope.row.orderNum)),
                        _c("i", { staticClass: "el-icon-caret-bottom\n" }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "时间", align: "center", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            创：" +
                          _vm._s(
                            _vm._f("parseTime")(
                              scope.row.addTime,
                              "{y}-{m}-{d} {h}:{i}:{s}"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 4
                      ? _c("el-tag", { attrs: { effect: "dark" } }, [
                          _vm._v("已发货"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 3
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "success" } },
                          [_vm._v("已打包")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "success" } },
                          [_vm._v("已拣")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "warning" } },
                          [_vm._v("锁定拣货单")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$checkPermission([
                                  "orderBatchManage",
                                ]),
                                expression:
                                  "$checkPermission(['orderBatchManage'])",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_table(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$checkPermission([
                                  "orderBatchManage",
                                ]),
                                expression:
                                  "$checkPermission(['orderBatchManage'])",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_state(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改批次状态\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改仓库批次状态",
            visible: _vm.editAddressDialogVisible,
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editAddressDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editAddressForm",
              attrs: {
                model: _vm.editAddressForm,
                "label-width": "110px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库批次状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editAddressForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.editAddressForm, "status", $$v)
                        },
                        expression: "editAddressForm.status",
                      },
                    },
                    _vm._l(_vm.status, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editAddressDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("editAddressForm")
                    },
                  },
                },
                [_vm._v("确认" + _vm._s(_vm.text) + "\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.edittable_sw,
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.edittable_sw = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editAddressForm",
              attrs: {
                model: _vm.edittable,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "物流方式", prop: "carrier" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.edittable.carrier,
                      callback: function ($$v) {
                        _vm.$set(_vm.edittable, "carrier", $$v)
                      },
                      expression: "edittable.carrier",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.edittable.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.edittable, "status", $$v)
                        },
                        expression: "edittable.status",
                      },
                    },
                    _vm._l(_vm.status, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.edittable_sw = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.table_submitForm("editAddressForm")
                    },
                  },
                },
                [_vm._v("保存\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批次号 xxxx 发货单",
            visible: _vm.ordertable_sw,
            width: "900px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ordertable_sw = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "orderTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.orderList,
                border: "",
                fit: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
                "max-height": "600",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "id", align: "center", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticClass: "ellipsis",
                          domProps: { innerHTML: _vm._s(scope.row.id) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderNo", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "跟踪号", prop: "trackingNo", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "物流方式", prop: "carrier", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "SKU信息",
                  align: "center",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.skuList, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n              " + _vm._s(item) + "\n            "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 6
                          ? _c("el-tag", { attrs: { effect: "dark" } }, [
                              _vm._v("已删除"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 5
                          ? _c(
                              "el-tag",
                              { attrs: { effect: "dark", type: "warning" } },
                              [_vm._v("异常单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 4
                          ? _c("el-tag", { attrs: { effect: "dark" } }, [
                              _vm._v("已发货"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 3
                          ? _c(
                              "el-tag",
                              { attrs: { effect: "dark", type: "success" } },
                              [_vm._v("已打包")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c(
                              "el-tag",
                              { attrs: { effect: "dark", type: "success" } },
                              [_vm._v("已拣")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c(
                              "el-tag",
                              { attrs: { effect: "dark", type: "warning" } },
                              [_vm._v("锁定拣货单")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$checkPermission([
                                  "orderBatchManage",
                                ]),
                                expression:
                                  "$checkPermission(['orderBatchManage'])",
                              },
                            ],
                            staticClass: "filter-item",
                            attrs: { size: "mini", type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.releaseOrder(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("解绑订单\n            ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }