var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-view",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.loadTableData,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-table",
        {
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
            "header-cell-style": { background: "#eef1f6", color: "#606266" },
            height: "100%",
          },
        },
        [
          _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "供应商数量", prop: "joinNum" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showDetailDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          明细\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.detailDialogVisible, title: "明细" },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.showAddDialog },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.detailData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "供应商名称", prop: "supplyName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "addTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteDetail(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.addDialogVisible, title: "新增" },
          on: {
            "update:visible": function ($event) {
              _vm.addDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "选择供应商" },
                  model: {
                    value: _vm.selectedSupplies,
                    callback: function ($$v) {
                      _vm.selectedSupplies = $$v
                    },
                    expression: "selectedSupplies",
                  },
                },
                _vm._l(_vm.availableSupplies, function (supply) {
                  return _c("el-option", {
                    key: supply.id,
                    attrs: { label: supply.name, value: supply.id },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addDetails } },
                [_vm._v("确认新增")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }