"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listBalance = listBalance;
exports.supplierList = supplierList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询会员账户列表
function listBalance(query) {
  return (0, _request.default)({
    url: '/userBalance/balance/list',
    method: 'get',
    params: query
  });
}
function supplierList(query) {
  return (0, _request.default)({
    url: '/userBalance/balance/supplierList',
    method: 'get',
    params: query
  });
}