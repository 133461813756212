"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _wap = _interopRequireDefault(require("./wap.js"));
var _goodsDialog = _interopRequireDefault(require("./goods-dialog"));
var _index = _interopRequireDefault(require("./template/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsDialog: _goodsDialog.default
  },
  data: function data() {
    return {
      templateWay: _index.default,
      // 模板数据
      changed: "",
      // 变更模板
      selected: 0,
      // 已选数据
      selectedLink: "",
      //选中的链接
      wap: _wap.default // tab标签
    };
  },

  watch: {
    changed: {
      handler: function handler(val) {
        this.$emit("selectedLink", val[0]); //因为是单选，所以直接返回第一个
      },

      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs["lili-component"][0].type = "single"; //商品页面设置成为单选
    });

    this.wap.forEach(function (item) {
      if (item) {
        item.selected = false;
      }
    });
  },
  methods: {}
};
exports.default = _default;