"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShopRelationPage = getShopRelationPage;
exports.getShopSubAccount = getShopSubAccount;
exports.memberupdateAccount = memberupdateAccount;
var _request = _interopRequireDefault(require("@/utils/request"));
function getShopRelationPage(data) {
  return (0, _request.default)({
    url: '/supplierShopRelation/getShopRelationPage',
    method: 'get',
    params: data
  });
}
function getShopSubAccount(data) {
  return (0, _request.default)({
    url: '/supplierShopRelation/getShopSubAccountPage',
    method: 'get',
    params: data
  });
}
function memberupdateAccount(data) {
  return (0, _request.default)({
    url: 'system/member/updateAccount',
    method: 'post',
    data: data
  });
}