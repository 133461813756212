import Layout from '@/layout';
var informationRouter = {
  path: '/information',
  component: Layout,
  redirect: '/information/type',
  name: 'information',
  alwaysShow: true,
  meta: {
    title: '资讯',
    icon: 'report-brand'
  },
  children: [{
    path: 'type',
    component: function component() {
      return import('@/views/information/type/index');
    },
    name: 'InformationType',
    meta: {
      title: '资讯类型',
      role: []
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/information/list/index');
    },
    name: 'InformationArticle',
    meta: {
      title: '资讯列表',
      role: []
    }
  }, {
    path: 'comments/:id(\\d+)',
    component: function component() {
      return import('@/views/information/list/comments');
    },
    name: 'InformationComments',
    meta: {
      title: '查看评论列表',
      role: [],
      activeMenu: '/course/detail'
    },
    hidden: true
  }]
};
export default informationRouter;