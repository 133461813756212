"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBusinessForm = addBusinessForm;
exports.deleteBusinessForm = deleteBusinessForm;
exports.getBusinessFormList = getBusinessFormList;
exports.getBusinessTypeList = getBusinessTypeList;
exports.updateBusinessForm = updateBusinessForm;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getBusinessFormList(data) {
  return (0, _requestJson.default)({
    url: '/business/type/form/getPage',
    method: 'get',
    params: data
  });
}
function updateBusinessForm(data) {
  return (0, _requestJson.default)({
    url: '/business/type/form/updateById',
    method: 'post',
    data: data
  });
}
function addBusinessForm(data) {
  return (0, _requestJson.default)({
    url: '/business/type/form/save',
    method: 'post',
    data: data
  });
}
function deleteBusinessForm(id) {
  return (0, _requestJson.default)({
    url: "/business/type/form/deleteById/".concat(id),
    method: 'post'
  });
}
function getBusinessTypeList(data) {
  return (0, _requestJson.default)({
    url: '/business/type/getList',
    method: 'get',
    params: data
  });
}