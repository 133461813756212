var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cropper-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "裁剪图片",
            visible: _vm.show_cropper,
            "custom-class": "choose_dialog",
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: { close: _vm.hideCropper },
        },
        [
          _c("div", { staticClass: "cropper" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "tailoring-box-parcel" }, [
                _c("img", {
                  ref: _vm.id,
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.img_url, id: _vm.id },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "preview-box-parcel" }, [
                _c("p", [_vm._v("效果预览：")]),
                _vm._v(" "),
                _c("div", { class: ["previewImg", "previewImg" + _vm.id] }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn_box" }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "choose_img_btn",
                      class: { noimg: !_vm.img_url },
                      attrs: { size: "mini", type: "primary" },
                    },
                    [
                      _c("input", {
                        staticClass: "choose_img",
                        attrs: {
                          type: "file",
                          accept: "image/jpeg,image/png",
                          name: "file",
                          id: "chooseImg" + _vm.id,
                          onblur: "value=''",
                        },
                        on: { change: _vm.selectImg },
                      }),
                      _vm._v(
                        _vm._s(_vm.img_url ? "重新上传" : "选择图片") +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.hideCropper } },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.sureSave },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }