"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoods = addGoods;
exports.deleteGoods = deleteGoods;
exports.getGoodsList = getGoodsList;
exports.getTypeList = getTypeList;
exports.updateGoods = updateGoods;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getGoodsList(data) {
  return (0, _requestJson.default)({
    url: '/goods/getPage',
    method: 'get',
    params: data
  });
}
function addGoods(data) {
  return (0, _requestJson.default)({
    url: '/goods/save',
    method: 'post',
    data: data
  });
}
function updateGoods(data) {
  return (0, _requestJson.default)({
    url: '/goods/updateById',
    method: 'post',
    data: data
  });
}
function deleteGoods(id) {
  return (0, _requestJson.default)({
    url: "/goods/deleteById/".concat(id),
    method: 'post'
  });
}
function getTypeList() {
  return (0, _requestJson.default)({
    url: "/goods/type/getList",
    method: 'get'
  });
}