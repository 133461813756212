"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBusinessType = addBusinessType;
exports.deleteBusinessType = deleteBusinessType;
exports.getBusinessTypeList = getBusinessTypeList;
exports.updateBusinessType = updateBusinessType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getBusinessTypeList(data) {
  return (0, _requestJson.default)({
    url: '/business/type/getPage',
    method: 'get',
    params: data
  });
}
function addBusinessType(data) {
  return (0, _requestJson.default)({
    url: '/business/type/save',
    method: 'post',
    data: data
  });
}
function updateBusinessType(data) {
  return (0, _requestJson.default)({
    url: '/business/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteBusinessType(id) {
  return (0, _requestJson.default)({
    url: "/business/type/deleteById/".concat(id),
    method: 'post'
  });
}