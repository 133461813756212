"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addInformationType = addInformationType;
exports.deleteInformationType = deleteInformationType;
exports.getInformationTypeList = getInformationTypeList;
exports.updateInformationType = updateInformationType;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getInformationTypeList(data) {
  return (0, _requestJson.default)({
    url: '/information/type/page',
    method: 'get',
    params: data
  });
}
function addInformationType(data) {
  return (0, _requestJson.default)({
    url: '/information/type/save',
    method: 'post',
    data: data
  });
}
function updateInformationType(data) {
  return (0, _requestJson.default)({
    url: '/information/type/updateById',
    method: 'post',
    data: data
  });
}
function deleteInformationType(id) {
  return (0, _requestJson.default)({
    url: "/information/type/deleteById/".concat(id),
    method: 'post'
  });
}