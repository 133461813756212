var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "top",
          },
        },
        [
          _vm._l(
            _vm.ruleForm.productSkuVariations,
            function (big_item, big_index) {
              return _c(
                "el-row",
                {
                  key: big_index,
                  staticStyle: {
                    "background-color": "#f5f5f5",
                    padding: "20px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { display: "flex", position: "relative" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            staticStyle: {
                              position: "absolute",
                              top: "7px",
                              left: "66px",
                            },
                            attrs: { effect: "dark", placement: "top-start" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n              · 您最多可以添加3种变化 (如颜色、尺寸等)。\n              "
                                ),
                                _c("br"),
                                _vm._v(
                                  "· 将最重要的变化设置为第一个，并为不同的变化有这些。\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-question icon_question",
                              staticStyle: {
                                "font-size": "14px",
                                color: "#606266",
                                "line-height": "17px",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "规格名称",
                              prop:
                                "productSkuVariations." + big_index + ".name",
                              rules: {
                                required: true,
                                message: "请选择或输入规格名称",
                                trigger: "change",
                              },
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请选择或输入规格名称",
                                },
                                model: {
                                  value: big_item.name,
                                  callback: function ($$v) {
                                    _vm.$set(big_item, "name", $$v)
                                  },
                                  expression: "big_item.name",
                                },
                              },
                              _vm._l(_vm.name_select, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", plain: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.remove_productSkuVariations(
                                      big_index
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除规则")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { display: "flex", position: "relative" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            staticStyle: {
                              position: "absolute",
                              top: "8px",
                              left: "66px",
                            },
                            attrs: { effect: "dark", placement: "top-start" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n              · 每个单词的第一个字母大写 (连词、冠词、介词除外)。\n              "
                                ),
                                _c("br"),
                                _vm._v(
                                  "·\n              将最重要的变体设置为第一个变体，并为不同变体添加相应的产品图像，而不是为所有变体使用相同的图像。\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-question icon_question",
                              staticStyle: {
                                "font-size": "14px",
                                color: "#606266",
                                "line-height": "17px",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "规格数值", required: "" } },
                          [
                            _vm._l(big_item.domains, function (domain, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { display: "flex" },
                                },
                                [
                                  big_index == 0 && _vm.main
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "productSkuVariations." +
                                              big_index +
                                              ".domains." +
                                              index +
                                              ".imgUrl",
                                            rules: {
                                              required: true,
                                              message: "图片不能为空",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("UploadSKU", {
                                            model: {
                                              value: domain.imgUrl,
                                              callback: function ($$v) {
                                                _vm.$set(domain, "imgUrl", $$v)
                                              },
                                              expression: "domain.imgUrl",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "15px",
                                        "margin-left": "5px",
                                      },
                                      attrs: {
                                        prop:
                                          "productSkuVariations." +
                                          big_index +
                                          ".domains." +
                                          index +
                                          ".value",
                                        rules: {
                                          required: true,
                                          message: "规格数值不能为空",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-right": "5px",
                                        },
                                        model: {
                                          value: domain.value,
                                          callback: function ($$v) {
                                            _vm.$set(domain, "value", $$v)
                                          },
                                          expression: "domain.value",
                                        },
                                      }),
                                      _vm._v(" "),
                                      big_item.domains.length != 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeDomain(
                                                    big_index,
                                                    domain
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除数值")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addDomain(big_index)
                                  },
                                },
                              },
                              [_vm._v("新增数值")]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }
          ),
          _vm._v(" "),
          _vm.ruleForm.productSkuVariations.length <= 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.add_productSkuVariations },
                },
                [_vm._v("添加规则")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("生成列表")]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.skuList.length > 0
        ? _c(
            "el-table",
            {
              ref: "outerTable",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.skuList,
                border: "",
                fit: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
                "row-class-name": _vm.getRowClass,
                "max-height": "600",
              },
            },
            [
              _vm._l(_vm.ruleForm.productSkuVariations, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    prop: item.name,
                    label: item.name,
                    fixed: "",
                  },
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "SKU名称",
                  align: "center",
                  fixed: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { min: 0 },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.title,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "title", $$v)
                              },
                              expression: "scope.row.title",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  107233772
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "typeSkuName",
                  label: "品类中文名称",
                  align: "center",
                  fixed: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { min: 0 },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.typeSkuName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "typeSkuName", $$v)
                              },
                              expression: "scope.row.typeSkuName",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2067389406
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "imgUrl",
                  label: "图片",
                  align: "center",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("UploadSKU", {
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.imgUrl,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "imgUrl", $$v)
                              },
                              expression: "scope.row.imgUrl",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  834178920
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "价格(美元)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入价格(美元)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.price,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  385115062
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "height", label: "高度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入高度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.height,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "height", $$v)
                              },
                              expression: "scope.row.height",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4122167157
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "width", label: "宽度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入宽度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.width,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "width", $$v)
                              },
                              expression: "scope.row.width",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3485556649
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "length", label: "长度(英寸)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入长度(英寸)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.length,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "length", $$v)
                              },
                              expression: "scope.row.length",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2004653265
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "weight", label: "重量(磅)", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入重量(磅)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.weight,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "weight", $$v)
                              },
                              expression: "scope.row.weight",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3457839066
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "costPrice",
                  label: "出仓价(美元)",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入一件出仓价(美元)",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.costPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "costPrice", $$v)
                              },
                              expression: "scope.row.costPrice",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3337906782
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "maxYunFee",
                  label: "最高运费",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入最高运费",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: scope.row.maxYunFee,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "maxYunFee", $$v)
                              },
                              expression: "scope.row.maxYunFee",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3408192637
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "buyerFinishRate",
                  label: "佣金 99.00等于99%",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入佣金 99.00等于99%",
                              oninput:
                                "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                            },
                            on: { input: _vm.add_input },
                            model: {
                              value: _vm.expressPrice,
                              callback: function ($$v) {
                                _vm.expressPrice = $$v
                              },
                              expression: "expressPrice",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2418403793
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "isDian", label: "属性", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择属性" },
                              on: { input: _vm.add_input },
                              model: {
                                value: scope.row.isDian,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isDian", $$v)
                                },
                                expression: "scope.row.isDian",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "普货", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "带电", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "特货", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2190080464
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "packageType",
                  label: "是否是套餐",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择是否是套餐" },
                              on: {
                                input: _vm.add_input,
                                change: function ($event) {
                                  return _vm.add_packageType(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.packageType,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "packageType", $$v)
                                },
                                expression: "scope.row.packageType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "不是", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "是", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2417526824
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "选择套餐SKU", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.packageType == 1
                            ? _c("i", {
                                staticClass:
                                  "el-icon-arrow-down packageType_icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.add_package(scope.row)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1633899165
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "class-name": "small-padding",
                  fixed: "right",
                  "min-width": "110",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "5px 0 0 0" },
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDeleteSku(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            删除\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1305127035
                ),
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "套餐SKU",
            visible: _vm.packageSku_sw,
            "close-on-click-modal": false,
            width: "900px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.packageSku_sw = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                data: _vm.props_obj.packageSkuList,
                border: "",
                "max-height": "450",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "SKU",
                  prop: "productSkuTitle",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("span", [_vm._v(_vm._s(props.row.productSkuTitle))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发货SKU数量", prop: "id", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_c("span", [_vm._v(_vm._s(props.row.quantity))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (propschildren) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.delete_list(
                                  _vm.props_obj.packageSkuList,
                                  propschildren.$index,
                                  propschildren.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "SKu名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    model: {
                      value: _vm.searchtitle,
                      callback: function ($$v) {
                        _vm.searchtitle = $$v
                      },
                      expression: "searchtitle",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.add_title()
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.props_obj.shipSkuList,
                border: "",
                "max-height": "450",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "SKU", prop: "title", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_c("span", [_vm._v(_vm._s(props.row.title))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "中文名称",
                  prop: "typeSkuName",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("span", [_vm._v(_vm._s(props.row.typeSkuName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", prop: "price", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_c("span", [_vm._v(_vm._s(props.row.price))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存", prop: "price", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return _vm._l(props.row.invList, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.warehouse) +
                              " " +
                              _vm._s(item.totalInv) +
                              "\n          "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发货SKU数量", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: props.row.quantity,
                              expression: "props.row.quantity",
                            },
                          ],
                          attrs: {
                            type: "text",
                            placeholder: "输入发货SKU数量",
                            oninput:
                              "value=value.replace(/[^\\d.]/g,'').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^\\./g, '')",
                          },
                          domProps: { value: props.row.quantity },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                props.row,
                                "quantity",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (childProps) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.add_skuId(childProps.row)
                              },
                            },
                          },
                          [_vm._v("\n            添加\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }