"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindShop = bindShop;
exports.deleteById = deleteById;
exports.getBindPage = getBindPage;
exports.getListByShopId = getListByShopId;
exports.getShopList = getShopList;
exports.getSupplyList = getSupplyList;
exports.listShop = listShop;
exports.listShopCatalog = listShopCatalog;
exports.pullCatalog = pullCatalog;
exports.pullProducts = pullProducts;
exports.save = save;
var _requestJson = _interopRequireDefault(require("@/utils/requestJson"));
function getShopList() {
  return (0, _requestJson.default)({
    url: 'shopSupply/getShopList',
    method: 'get'
  });
}
function getSupplyList(ids) {
  return (0, _requestJson.default)({
    url: 'shopSupply/getSupplyList?supplyIds=' + ids,
    method: 'get'
  });
}
function getBindPage(data) {
  return (0, _requestJson.default)({
    url: 'shopSupply/getBindPage',
    method: 'get',
    params: data
  });
}
function getListByShopId(id) {
  return (0, _requestJson.default)({
    url: 'shopSupply/getListByShopId?id=' + id,
    method: 'get'
  });
}
function save(data) {
  return (0, _requestJson.default)({
    url: 'shopSupply/save',
    method: 'post',
    data: data
  });
}
function deleteById(id) {
  return (0, _requestJson.default)({
    url: 'shopSupply/deleteById?id=' + id,
    method: 'post'
  });
}

//绑定小店
function bindShop(data) {
  return (0, _requestJson.default)({
    url: '/pull/bindShop',
    method: 'post',
    data: data
  });
}
// 已经绑定小店列表
function listShop(data) {
  return (0, _requestJson.default)({
    // url: '/pull/listShop?page=' + page + '&pageSize=' + pageSize,
    url: '/pull/listShop',
    method: 'get',
    params: data
  });
}

// 拉取小店产品目录
function pullCatalog(adminShopId) {
  return (0, _requestJson.default)({
    url: '/pull/pullCatalog/' + adminShopId,
    method: 'get'
  });
}

// 列举拉取回的产品目录
function listShopCatalog(data) {
  return (0, _requestJson.default)({
    url: '/pull/listShopCatalog',
    method: 'get',
    params: data
  });
}

// 拉取产品列表
function pullProducts(data) {
  return (0, _requestJson.default)({
    url: '/pull/pullProducts',
    method: 'post',
    data: data
  });
}