import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime } from "@/utils";
import { getSupplyList } from "@/api/product/bind";
import { adminPage, adminSave, adminUpdateById, deleteById, getById, getShopCateList as _getShopCateList, getGoodsCateList, getShopList, pushShopProduct, skuSave, skuDeleteById, skuUpdateById, productUpOn, updateProductOwner, getSupplyListapi, forcedRemoval } from "@/api/product/product";
import Upload from "@/components/Upload/singleImageUs.vue";
import MultipleImageUpload from "@/components/Upload/multipleImageUs.vue";
import UploadPdf from "@/components/UploadPdf/index";
import Pagination from "@/components/Pagination";
import WangEnduit from "@/components/WangEditorUs/index";
import Cropper from "@/components/Cropper";
import SortableUpload from "@/components/Upload/sortableupload.vue";
import UploadSKU from "@/components/Upload/singleImageUsSKU.vue";
import dragUpload from "@/components/Upload/dragUpload.vue";
import seeSkulist from "@/components/Skulist/seeskulist.vue";
export default {
  name: "ProductManagement",
  components: {
    seeSkulist: seeSkulist,
    Upload: Upload,
    Pagination: Pagination,
    UploadPdf: UploadPdf,
    WangEnduit: WangEnduit,
    MultipleImageUpload: MultipleImageUpload,
    Cropper: Cropper,
    SortableUpload: SortableUpload,
    UploadSKU: UploadSKU,
    dragUpload: dragUpload
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      conditionalConfigList: [],
      supplyOptions: [],
      searchType: "1",
      // 默认查询类型为可推送产品
      isClear: false,
      props: {
        label: "cnName",
        value: "id",
        children: "children"
      },
      searchTitle: "",
      skuName: "",
      searchTypeName: "",
      productList: [],
      goodsCateOptions: [],
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 50
      },
      dialogFormVisible: false,
      dialogType: "",
      productForm: {
        title: "",
        typeName: "",
        status: false,
        skuList: [],
        // SKU列表
        brand: "No brand"
      },
      shopCateOptions: [],
      groupList: [],
      pushDialogVisible: false,
      // 控制推送弹框显示
      shopList: [],
      // 店铺列表
      pushForm: {
        shopAccountId: "" // 选择的店铺ID
      },

      updateOwnerForm: {
        id: "",
        addBy: ""
      },
      supplyIds: "",
      forcedRemoval_sw: false,
      ruleForm: {
        auditReason: ""
      },
      rules: {
        title: [{
          required: true,
          message: "请输入商品标题",
          trigger: "blur"
        }],
        typeName: [{
          required: true,
          message: "请输入品类名称",
          trigger: "blur"
        }],
        auditReason: [{
          required: true,
          message: "请填写强制下架原因",
          trigger: "blur"
        }]
      },
      show_cropper: false,
      img_url: "",
      imgUrl_index: "",
      SortableUpload_sw: false,
      picture_arr: [],
      expressPrice: 0,
      Skulist_sw: false
    };
  },
  created: function created() {
    this.getList();
    this.init();
    this.loadSupplyOptions();
  },
  watch: {
    searchType: function searchType(newType, oldType) {
      if (newType !== oldType) {
        this.handleSearch(); // 调用查询方法
      }
    }
  },

  methods: {
    // 初始化裁切框
    InitCropper: function InitCropper(img) {
      var _this = this;
      this.img_url = img;
      console.log("this.img_url11111", this.$refs.cropper1);
      if (this.$refs.cropper1.myCropper) {
        this.$refs.cropper1.myCropper.replace(this.img_url);
      } else {
        this.$nextTick(function () {
          _this.$refs.cropper1.init();
        });
      }
    },
    // 网络图片地址转本地图片
    getBase64ByURL: function getBase64ByURL(imgUrl) {
      return new Promise(function (resolve) {
        window.URL = window.URL || window.webkitURL;
        var xhr = new XMLHttpRequest();
        xhr.open("get", imgUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (this.status == 200) {
            var blob = this.response;
            var oFileReader = new FileReader();
            oFileReader.onloadend = function (e) {
              resolve({
                blob: blob,
                base64: e.target.result
              });
            };
            oFileReader.readAsDataURL(blob);
          }
        };
        xhr.send();
      });
    },
    // 显示裁切框
    showImgCropper: function showImgCropper(val, index) {
      var _this2 = this;
      console.log("val", val, index);
      this.imgUrl_index = index;
      if (val) {
        this.getBase64ByURL(val).then(function (res) {
          var blob = res.blob,
            base64 = res.base64;
          _this2.img_url = URL.createObjectURL(blob);
          _this2.show_cropper = true;
          console.log("111111", _this2.$refs.cropper1);
          if (_this2.$refs.cropper1.myCropper) {
            _this2.$refs.cropper1.myCropper.replace(_this2.img_url);
          } else {
            _this2.$nextTick(function () {
              _this2.$refs.cropper1.init();
            });
          }
        });
      } else {
        console.log("22222");
        // this.img_url = "";
        this.show_cropper = true;
      }
    },
    // 关闭裁切图片弹窗
    CloseCropper: function CloseCropper() {
      // this.img_url = "";
      this.show_cropper = false;
    },
    // 裁切上传完接口返回的信息
    SaveCallBack: function SaveCallBack(res) {
      var _this3 = this;
      console.log("信息", res, this.imgUrl_index, this.productForm.skuList);
      if (res.code == 200) {
        this.productForm.skuList.forEach(function (val, index) {
          if (index == _this3.imgUrl_index) {
            val.imgUrl = res.data.imgUrl;
          }
        });
        this.$message({
          message: "图片上传成功！",
          type: "success"
        });
        this.CloseCropper();
        return;
      } else {
        this.$message.error(res.message);
      }
    },
    // 接受图片改变的值
    emit_picture: function emit_picture(val) {
      this.productForm.imgUrls = val;
      console.log("图片变了吗", val);
    },
    // 确定强制下架
    add_forcedRemoval: function add_forcedRemoval(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          forcedRemoval(_this4.ruleForm).then(function () {
            _this4.$message.success("强制下架成功");
            _this4.forcedRemoval_sw = false;
            _this4.getList();
          }).catch(function (error) {
            console.error(error);
          });
        } else {}
      });
    },
    // 强制下架功能
    offshelf: function offshelf(row) {
      this.ruleForm.id = row.id;
      this.ruleForm.auditReason = "";
      this.forcedRemoval_sw = true;
    },
    updateSupplier: function updateSupplier(row) {
      var _this5 = this;
      console.log(row);
      // 调用接口 updateProductOwner，将所选供应商的 ID 更新到产品数据中
      this.updateOwnerForm.id = row.id;
      this.updateOwnerForm.addBy = row.addBy;
      updateProductOwner(this.updateOwnerForm).then(function () {
        _this5.$message.success("供应商更新成功");
      }).catch(function (error) {
        console.error(error);
      });
    },
    loadSupplyOptions: function loadSupplyOptions() {
      var _this6 = this;
      // 调用接口获取供应商选项并更新supplyOptions
      getSupplyList().then(function (response) {
        _this6.supplyOptions = response.data;
        console.log("this.supplyOptions", _this6.supplyOptions);
      });
    },
    handleSearchForIncompleteProducts: function handleSearchForIncompleteProducts() {
      this.searchType = "2"; // 设置搜索类型为待补全产品
      this.handleSearch(); // 调用查询方法
    },
    handleSaveSku: function handleSaveSku(sku) {
      var _this7 = this;
      sku.productId = this.productForm.id;

      // If SKU has no ID, it's a new SKU, so call the save API
      if (!sku.id) {
        skuSave(sku).then(function () {
          _this7.$message.success("SKU保存成功");
          // Update the product's SKU list
          // this.productForm.skuList.push(sku);
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        // If SKU has an ID, it's an existing SKU, so call the update API
        skuUpdateById(sku).then(function () {
          _this7.$message.success("SKU更新成功");
        }).catch(function (error) {
          console.error(error);
        });
      }
    },
    changeEditor: function changeEditor(val) {
      this.productForm.intro = val;
    },
    init: function init() {
      var _this8 = this;
      this.getShopCateList();
      getGoodsCateList().then(function (response) {
        _this8.goodsCateOptions = response.data;
      }).catch(function (err) {
        console.log(err);
      });
      this.groupList.push({
        num: null,
        discounts: null
      });
    },
    confirmPush: function confirmPush() {
      var _this9 = this;
      // 获取选中的产品ID数组
      var selectedProductIds = this.$refs.orderTable.selection.map(function (product) {
        return product.id;
      });

      // 调用推送商品的接口
      var pushParams = {
        productIds: selectedProductIds,
        shopAccountId: this.pushForm.shopAccountId
      };
      pushShopProduct(pushParams).then(function () {
        _this9.$message.success("推送成功");
        _this9.pushDialogVisible = false;
        // 可以根据情况刷新列表或执行其他操作
      }).catch(function (error) {
        console.error(error);
      });
    },
    handlePushDialogClose: function handlePushDialogClose() {
      this.pushForm.shopAccountId = ""; // 关闭弹框时清空选择的店铺ID
    },
    handlePush: function handlePush() {
      var _this10 = this;
      var selectedProducts = this.$refs.orderTable.selection; // 获取选中的产品对象数组
      if (selectedProducts.length === 0) {
        this.$message.warning("请选择要推送的产品");
        return;
      }
      var keyword = "";
      // 调用获取店铺列表的接口
      getShopList(keyword).then(function (response) {
        _this10.shopList = response.data.list;
        console.log("数据", _this10.shopList);
        _this10.pushDialogVisible = true; // 打开推送弹框
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleSearch: function handleSearch() {
      this.listQuery.type = this.searchType;
      this.listQuery.title = this.searchTitle; // Set the search title in the list query
      this.listQuery.typeName = this.searchTypeName; // Set the search title in the list query
      this.listQuery.supplyIds = this.supplyIds;
      this.listQuery.page = 1; // Reset page to 1 to start from the first page
      this.listQuery.auditStatus = "";
      this.listQuery.skuName = this.skuName;
      this.getList();
    },
    add_auditStatus: function add_auditStatus() {
      // console.log("触发几次11111");
      this.searchTitle = ""; // Set the search title in the list query
      this.searchTypeName = ""; // Set the search title in the list query
      this.supplyIds = "";
      this.listQuery.page = 1;
      this.listQuery.auditStatus = 4;
      this.getList();
    },
    getList: function getList() {
      var _this11 = this;
      var params = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        title: this.searchTitle,
        typeName: this.searchTypeName,
        supplyIds: this.supplyIds,
        type: this.searchType,
        auditStatus: this.listQuery.auditStatus,
        skuName: this.skuName
      };
      adminPage(params).then(function (response) {
        console.log("触发几次", response);
        _this11.productList = response.data.list;
        _this11.total = response.data.total;
      }).catch(function (error) {
        console.error(error);
      });
    },
    handleCreate: function handleCreate() {
      var _this12 = this;
      this.dialogType = "add";
      this.dialogFormVisible = true;
      this.SortableUpload_sw = false;
      this.productForm = {
        title: "",
        status: false,
        skuList: [],
        imgUrls: [],
        brand: "No brand"
      };
      this.$nextTick(function () {
        _this12.SortableUpload_sw = true;
        _this12.$refs["productForm"].clearValidate();
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this13 = this;
      this.dialogType = "edit";
      this.dialogFormVisible = true;
      this.conditionalConfigList = [];
      this.SortableUpload_sw = false;
      this.Skulist_sw = false;
      getById(row.id).then(function (response) {
        var product = response.data;
        if (product.cateId) {
          product.shopCateId = product.cateId.split(",").map(function (i) {
            return parseInt(i, 0);
          });
        }
        // 这里把imgUrls转一下
        var urlList = JSON.parse(product.imgUrl);
        product.imgUrls = urlList;
        _this13.productForm = product;
        _this13.SortableUpload_sw = true;
        _this13.Skulist_sw = true;
        _this13.productForm = product;
        console.log("数据11111212121212", _this13.productForm.imgUrls);
        if (_this13.productForm.conditionalConfigList) {
          _this13.productForm.conditionalConfigList.forEach(function (item) {
            var conditionalConfig = {
              fieldName: item.fieldName,
              fieldType: item.fieldType,
              options: item.options,
              productConditionallyConfigId: item.productConditionallyConfigId,
              value: item.value
            };
            _this13.conditionalConfigList.push(conditionalConfig);
          });
        } else {
          _this13.handleCascaderChange(_this13.productForm.shopCateId);
          console.log("没有", _this13.productForm);
        }
      }).catch(function (error) {
        console.error(error);
      });
      this.$nextTick(function () {
        _this13.$refs["productForm"].clearValidate();
      });
    },
    getShopCateList: function getShopCateList() {
      var _this14 = this;
      _getShopCateList().then(function (response) {
        _this14.shopCateOptions = response.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleDelete: function handleDelete(productId) {
      var _this15 = this;
      this.$confirm("确认删除该商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        deleteById(productId).then(function () {
          _this15.getList();
          _this15.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000
          });
        }).catch(function (error) {
          console.error(error);
        });
      }).catch(function () {});
    },
    handleConfirm: function handleConfirm() {
      var _this16 = this;
      this.$refs["productForm"].validate(function (valid) {
        if (valid) {
          if (_this16.dialogType === "add") {
            adminSave(_this16.productForm).then(function () {
              _this16.getList();
              _this16.dialogFormVisible = false;
              _this16.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
            }).catch(function (error) {
              console.error(error);
            });
          } else if (_this16.dialogType === "edit") {
            adminUpdateById(_this16.productForm).then(function () {
              _this16.getList();
              _this16.dialogFormVisible = false;
              _this16.$notify({
                title: "成功",
                message: "编辑成功",
                type: "success",
                duration: 2000
              });
            }).catch(function (error) {
              console.error(error);
            });
          }
        }
      });
    },
    handleCancel: function handleCancel() {
      this.dialogFormVisible = false;
    },
    handleAddSku: function handleAddSku() {
      this.productForm.skuList.push({
        title: "",
        inv: 0,
        price: 0.0
        // Add other SKU fields based on actual requirements
      });
    },
    handleEditSku: function handleEditSku(sku) {
      // Handle editing of a specific SKU item
    },
    handleDeleteSku: function handleDeleteSku(index) {
      var _this17 = this;
      this.productForm.skuList.splice(index, 1);
      console.log("index", index);
      skuDeleteById(index.id).then(function () {
        _this17.getList();
        _this17.dialogFormVisible = false;
        _this17.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      }).catch(function (error) {
        console.error(error);
      });
    }
  },
  filters: {
    parseTime: parseTime
  }
};