"use strict";

var _interopRequireDefault = require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ideaProject/tk-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var businessRouter = {
  path: '/business',
  component: _layout.default,
  redirect: '/business/type',
  name: 'business',
  alwaysShow: true,
  meta: {
    title: '业务',
    icon: 'food-list'
  },
  children: [{
    path: 'type',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/business/type/index'));
      });
    },
    name: 'businessType',
    meta: {
      title: '业务类型',
      role: []
    }
  }, {
    path: 'form',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/business/form/index'));
      });
    },
    name: 'businessForm',
    meta: {
      title: '业务类型SKU',
      role: []
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/business/goods/index'));
      });
    },
    name: 'businessGoods',
    meta: {
      title: '业务商品',
      role: []
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/business/order/index'));
      });
    },
    name: 'businessGoods',
    meta: {
      title: '业务订单',
      role: []
    }
  }]
};
var _default = businessRouter;
exports.default = _default;