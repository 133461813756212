var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-alert",
        { attrs: { title: "", type: "warning", "show-icon": "" } },
        [
          _c("span", [
            _vm._v(
              "\n      本次登录IP：" +
                _vm._s(_vm.loginData.loginIp) +
                "\n          上次登录IP："
            ),
            _vm.loginData.lastLoginIp
              ? _c("span", [_vm._v(_vm._s(_vm.loginData.lastLoginIp))])
              : _vm._e(),
            _vm._v("\n          上次登录时间："),
            _vm.loginData.lastLoginTime
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("parseTime")(
                        _vm.loginData.lastLoginTime,
                        "{y}-{m}-{d} {h}:{i}:{s}"
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-top": "10px" } }, [
            _vm._v(
              "\n      温馨提示：后台订单列表仅供查看，如需发货、修改订单信息，或需要添加新商家，请注册账户登录\n      商家后台 操作\n    "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("panel-group", { attrs: { "panel-data": _vm.panelData } }),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        _vm._l(_vm.amountData, function (item) {
          return _c(
            "el-col",
            { staticStyle: { "margin-bottom": "10px" }, attrs: { span: 6 } },
            [
              _c("el-card", { attrs: { shadow: "hover" } }, [
                _c(
                  "div",
                  { staticClass: "card-panel-text" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "red",
                          "font-size": "20px",
                          "font-weight": "600",
                        },
                      },
                      [
                        _vm._v(
                          "$ " + _vm._s(_vm._f("moneyFormat")(item.amount))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        staticStyle: { float: "right" },
                        attrs: { effect: "dark", type: "success" },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.tips) + "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "tips", staticStyle: { "font-size": "14px" } },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("line-chart", {
                  attrs: { "chart-data": _vm.lineChartData },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.numData, function (item) {
            return _c(
              "el-col",
              { staticStyle: { "margin-bottom": "10px" }, attrs: { span: 4 } },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push(item.router)
                      },
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: {
                          padding: "10px",
                          "background-image":
                            "linear-gradient(180deg,#f5f8ff,#fff)",
                          "box-shadow":
                            "inset 0 1px 3px 0 hsl(0deg 0% 100% / 50%)",
                        },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "20px",
                          },
                          attrs: {
                            "start-val": 0,
                            "end-val": item.num,
                            duration: 2600,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 24 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("chart", { attrs: { "mix-data": _vm.mixData } })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }