var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            data: _vm.dataObj,
            multiple: true,
            "list-type": "picture-card",
            "on-preview": _vm.handlePictureCardPreview,
            "on-success": _vm.handleImageSuccess,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            action: _vm.uploadUrl,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }